import axios from "axios";
import config from "./config";

const proxyServer = config.supportApi +"routes-app/jobcard";

export const GetJobcard = (userToken) => {
  const criterion = { userToken };

  return axios
    .post(proxyServer + "/get-jobcard-lists", { criterion })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
