import React, { Component } from "react";
import axios from "axios";
import { Card, CardTitle, CardBody, Button } from "reactstrap";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";
import apiTaskAppPath from "../../api-tasks/app-path";

import { subscriberSelectDetailsRequest } from "../../redux/actions";

class CompanyImageEdit extends Component {
  constructor(props) {
    super(props);
    this.defaultLogoImage = "/assets/img/no-image-box.png";
    this.state = {
      updating: false,
      logoFileName: "",
      uploadedImage: "/assets/img/no-image-box.png"
    };
  }

  handleSubmit = newFile => {
    this.setState({ updating: true });

    const bodyFormData = new FormData();
    const user = this.props.user;
    const criterion = { userToken: user.userToken };

    const postParams = { oldFileName: this.props.subscriberDetails.logo_image };

    bodyFormData.append("criterion", JSON.stringify(criterion));
    bodyFormData.append("postParams", JSON.stringify(postParams));

    // If file selected
    if (newFile) {
      bodyFormData.append("subscriberImage", newFile, newFile.name);

      axios
        .post(apiTaskAppPath.IMAGE_HANDLING_COMPANY_IMAGE, bodyFormData, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${
              bodyFormData._boundary
            }`
          }
        })
        .then(response => {
          this.setState({ updating: false });
          if (200 === response.status) {
            // If file size is larger than expected.
            if (response.data.error) {
              if ("LIMIT_FILE_SIZE" === response.data.error.code) {
                this.ocShowAlert("Max size: 2MB", "red");
              } else {
                // If not the given file type
                this.ocShowAlert(response.data.error, "red");
              }
            } else if ("failed" === response.data.status) {
              // If not the given file type
              this.ocShowAlert(response.data.message, "red");
            } else {
              // Success
              this.props.subscriberSelectDetailsRequest(this.props.history);
              let fileName = response.data;
              this.setState({
                uploadedImage: `${apiTaskAppPath.CDN_IMAGE_URL}${
                  fileName.imageName
                }`,
                logoFileName: fileName.imageName
              });
              this.ocShowAlert("File Uploaded", "#3089cf");
            }
          }
        })
        .catch(error => {
          // If another error
          this.setState({ updating: false });
          this.ocShowAlert(error, "red");
        });
    } else {
      // if file not selected throw error
      this.ocShowAlert("Please upload file", "red");
      this.setState({ updating: false });
    }
  };

  isString = value => {
    return typeof value === "string" || value instanceof String;
  };

  ocShowAlert = (message, type) => {
    if (!this.isString(message)) {
      message = "Something brocken!";
    }

    if (type !== "red") {
      NotificationManager.success(message, "Success", 3000, null, null, "");
    } else {
      NotificationManager.error(message, "Failed", 3000, null, null, "");
    }
  };

  singleFileChangedHandler = event => {
    this.handleSubmit(event.target.files[0]);
  };

  triggerInputFile = () => this.fileInput.click();

  handleOnClickChangeLogo = () => {
    this.fileInput.click();
  };
  render() {

    
    const logoImage =
      this.props.subscriberDetails && this.props.subscriberDetails.logo_image
        ? `${apiTaskAppPath.CDN_IMAGE_URL}${
            this.props.subscriberDetails.logo_image
          }`
        : this.defaultLogoImage;

        console.log('the logo', logoImage);

    return (
      <Colxx xl="6" lg="6" md="12" className="mb-4">
        <Card>
          <CardBody>
            <CardTitle><IntlMessages id="profile.company-logo" /></CardTitle>

            <div>
              <input
                className="d-none"
                ref={fileInput => (this.fileInput = fileInput)}
                type="file"
                onChange={this.singleFileChangedHandler}
              />
              <img
                src={logoImage}
                onClick={this.triggerInputFile}
                alt="img"
                className="company-logo-view"
              />
            </div>
            <Button color="primary" onClick={this.handleOnClickChangeLogo}>
              {this.state.updating ? (
                <span className="spinner d-inline-block">
                  <span className="bounce1" />
                  <span className="bounce2" />
                  <span className="bounce3" />
                </span>
              ) : (
                <IntlMessages id="profile.change" />
              )}
            </Button>
          </CardBody>
        </Card>
      </Colxx>
    );
  }
}

const mapStateToProps = ({ authUser, subscriber }) => {
  const { user } = authUser;
  const { details } = subscriber;
  return { user, subscriberDetails: details };
};

export default connect(
  mapStateToProps,
  {
    subscriberSelectDetailsRequest
  }
)(injectIntl(CompanyImageEdit));
