import axios from "axios";
import config from "./config";

const proxyServer = config.serverApi + "routes-app/visit";

export const GetVisit = (userToken) => {
  const criterion = { userToken };

  return axios
    .post(proxyServer + "/get-visit-list", { criterion })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
