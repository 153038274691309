import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row  } from "reactstrap";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import Efficiency from "../../../containers/dashboards/efficiency";
 
class DashboardAnalytics extends Component {
  render() {
    
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.efficiency" match={this.props.match}/>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Efficiency />
       
      </Fragment>
    );
  }
}

export default injectIntl(DashboardAnalytics);
