import React from "react";
import ReactSelect from "react-select";
import { connect } from "react-redux";

import IntlMessages from "../helpers/IntlMessages";
import {
  Button,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";

import CustomSelectInput from "./common/CustomSelectInput";

const customFilterOption = (option, rawInput) => {
  if (option && rawInput) {
    const words = rawInput.split(" ");
    return words.reduce(
      (acc, cur) =>
        acc &&
        option.data.searchWords.toLowerCase().includes(cur.toLowerCase()),
      true
    );
  } else {
    return true;
  }
};

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];

    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

    return objectsByKeyValue;
  }, {});

const LabelWithIcon = vehicle => {
  return (
    <div key={vehicle.key}>
      <img
        title={vehicle.manufacture_name}
        className="app-select-icon"
        src={vehicle.manufactureIcon}
        alt="icon"
      />
      <img
        title={vehicle.typeText}
        className="app-select-icon"
        src={vehicle.typeIcon}
        alt="icon"
      />
      <strong title="Plate">{vehicle.plate_number}</strong>{" "}
      <strong title="Asset No">{vehicle.fleet_number}</strong>{" "}
      <span title="Model">{vehicle.model}</span>{" "}
      <span title="year">{vehicle.year}</span>
    </div>
  );
};

class VehicleSelectWithBatchCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     
      nestingDropdownOpen: false,
      options: [],
      batchOptions: [],
      isOpenSizingXs: false,
      isOpenSizingXsGroup: false,
    };
  }

  componentDidMount() {
    this.loadMasters();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      
      this.loadMasters();
    }
  }

  loadMasters = () => {
    if(!this.props.vehiclesAll){ return; }
     
    const options = this.generateOptions(this.props.vehiclesAll );
    this.setState({ options });
    this.generateBatch(options);
  };

  generateBatch = options => {
    const all = options;

    if (!all) {
      this.setState({ batchOptions: [] });
      return;
    }

    const gModel = groupBy("model");
    const resultModel = gModel(all);
    const itemModels = Object.keys(resultModel).map(function(key, index) {
      return {
        label: key,
        data: resultModel[key],
        icons: "",
        value: `m${index}`
      };
    });

    const gType = groupBy("typeText");
    const resultType = gType(all);
    const itemType = Object.keys(resultType).map(function(key, index) {
      return {
        label: key,
        data: resultType[key],
        icon: resultType[key][0].typeIcon,
        value: `t${index}`
      };
    });

    const gProject = groupBy("project");
    const resultProject = gProject(all);
    const itemProject = Object.keys(resultProject).map(function(key, index) {
      const el = !key || key === "null" ? "No Cluster" : key;
      return {
        label: ` ${el}` ,
        data: resultProject[key],
        icon: '',
        value: `l${index}`
      };
    });

    const resultA = itemProject.concat( itemModels ) || [];
    const result = resultA.concat(itemType);

    result.sort(function(a, b) {
      return a.label.localeCompare(b.label);
    });

    if (result[0] && result[0].label === "") {
      result[0].label = "Others";
    }

    this.setState({ batchOptions: result });
  };

  generateOptions = vehicles => {
    const vList = vehicles || [] ;
    const options = vList.map(item => ({
      label: LabelWithIcon(item),
      value: item.autoid.toString(),
      searchWords:
        item.manufacture_name +
        " " +
        item.plate_number +
        " " +
        item.fleet_number +
        " " +
        item.model +
        " " +
        item.year,
      key: item.autoid,
      model: item.model,
      project: item.project,
      typeText: item.typeText,
      typeIcon: item.typeIcon,
      item: item
    }));
    return options;
  };

  nestingToggle = () => {
    this.setState({
      nestingDropdownOpen: !this.state.nestingDropdownOpen
    });
  };

  handleChange = selectedVehicles => {
     
    if (this.props.onChange) {
      this.props.onChange(selectedVehicles);
    }
  };

  handleChangeAppend = selectedVehicles => {
    const selected = [
      ...new Set(this.props.selectedVehicles.concat(selectedVehicles))
    ];

   
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  };

  handleChangeAppendGroup = (selectedGroup) => {

    console.log('approval kadayaathu', selectedGroup);

    const selectedVehicle = [];
    this.state.options.forEach(el => {
      if (selectedGroup.vehicleData.indexOf(el.value) !== -1) {
        selectedVehicle.push(el);
      }
    });

    if (this.props.onChange) {
      this.props.onChange(selectedVehicle);
    }
 
  };

  toggleSizingXs = () => {
    this.setState(prevState => ({
      isOpenSizingXs: !prevState.isOpenSizingXs
    }));
  };

  toggleSizingXsGroup = () => {
    this.setState((prevState) => ({
      isOpenSizingXsGroup: !prevState.isOpenSizingXsGroup,
    }));
  };

  render() {
    const classNamePrefix = this.props.classNamePrefix || "rs";
    const name = this.props.name || "allVehicles";
    const selectedVehicles = this.props.selectedVehicles || [];
    const groups = this.props.groups || [];

    return (
      <React.Fragment>
        <div className="w-100">
          <span className="float-right">
            {selectedVehicles.length} <IntlMessages id="common.selected" />
          </span>

          <ButtonGroup className="flex-wrap">
            <Button outline size="xs" onClick={() => this.handleChange([])}>
            <IntlMessages id="common.clear" />
            </Button>
            <Button
              outline
              size="xs"
              onClick={() => this.handleChange(this.state.options)}
            >
             <IntlMessages id="common.select-all" />
            </Button>

            <ButtonDropdown
              className="bd-container"
              isOpen={this.state.isOpenSizingXs}
              toggle={this.toggleSizingXs}
            >
              <DropdownToggle caret size="xs" outline color="secondary">
              <IntlMessages id="common.select-by" />
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: data => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: "auto",
                          maxHeight: 221
                        }
                      };
                    }
                  }
                }}
              >
                {this.state.batchOptions.map((el, index) => (
                  <DropdownItem
                    key={el.value}
                    value={index}
                    onClick={() => this.handleChangeAppend(el.data)}
                  >
                    {el.icon ? (
                      <img
                        className="app-select-icon"
                        src={el.icon}
                        alt="icon"
                      />
                    ) : (
                      ""
                    )}
                    {`${el.label} (${el.data.length})`}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>



            <ButtonDropdown
              className="bd-container"
              isOpen={this.state.isOpenSizingXsGroup}
              toggle={this.toggleSizingXsGroup}
            >
              <DropdownToggle caret size="xs" outline color="secondary">
                By Group
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: (data) => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: "auto",
                          maxHeight: 221,
                        },
                      };
                    },
                  },
                }}
              >
                {groups.map((el, index) => (
                  <DropdownItem
                    key={el.autoid}
                    value={el}
                    onClick={() => this.handleChangeAppendGroup(el)}
                  >
                    {`${el.group_name} (${el.vehicleData.length})`}
                  </DropdownItem>
                ))}
              </DropdownMenu>


            </ButtonDropdown>

          </ButtonGroup>
        </div>

        <div className="w-100">
          <ReactSelect
            filterOption={customFilterOption}
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix={classNamePrefix}
            maxMenuHeight={240}
            name={name}
            options={this.state.options}
            placeholder={this.props.placeholder}
            isMulti
            onChange={this.handleChange}
            value={selectedVehicles}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ groups }) => {
  const { items } = groups;

  return { groups: items };
};

export default connect(mapStateToProps)(VehicleSelectWithBatchCustom);
