import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Button, Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";

import { Colxx } from "../../components/common/CustomBootstrap";
import DriverSelectWithBatch from "../../components/DriverSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
import DateRangePicker from "../../components/DateRangePicker";
import LocationName from "../../components/LocationName";
import { OverSpeedGetByDrivers } from "../../api-tasks/over-speed";
import { AccelerationsGetByDrivers } from "../../api-tasks/accelerations";
import { BreakingsGetByDrivers } from "../../api-tasks/breakings";
import { CorneringGetByDrivers } from "../../api-tasks/cornering";

import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
} from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

const pagingLimit = -1;

const headersOverSpeed = [
  { label: "Driver name", key: "driverName" },
  { label: "Vehicle", key: "vehicleName" },
  { label: "Time", key: "time" },
  { label: "Place", key: "place" },
  { label: "Geo", key: "geo" },
  { label: "Top Speed", key: "topspeed" },
  { label: "Speed Limit", key: "limit" },
  { label: "Duration", key: "duration" },
];

const headersForAlert = [
  { label: "Driver name", key: "driverName" },
  { label: "Vehicle", key: "vehicleName" },
  { label: "Time", key: "time" },
  { label: "Place", key: "place" },
  { label: "Geo", key: "geo" },
  { label: "Start Speed", key: "startspeed" },
  { label: "End Speed", key: "endspeed" },
  { label: "Rate Of Acceleration", key: "rate" },
];

const headersForCornering = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Time", key: "time" },
  { label: "Place", key: "place" },
  { label: "Geo", key: "geo" },
  { label: "Speed", key: "speed" },
  { label: "Rate Of Turn", key: "turn" },
  { label: "Driver name", key: "driverName" },
];

class Trips extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedDrivers: [],
      loading: false,
      allRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
      fileName: "Ctraac - alerts.csv",
      typeText: "Over speed",
    };
  }

  componentWillUnmount() {}

  componentDidMount() {}

  handleOnSelectedVehicleChange = (selectedDrivers) => {
    this.setState({ selectedDrivers });

    this.setState({
      selectedDrivers,
      allRows: [],
      limit: pagingLimit,
      skip: 0,
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };

  onDateRangeGo = (limit, skip) => {
    if (!this.state.selectedDrivers.length) {
      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    const vehicleIds = this.state.selectedDrivers.map((el) => {
      return el.key;
    });

    this.setState({ loading: true });

    let apiCall = OverSpeedGetByDrivers;

    switch (this.state.typeText) {
      case "Hard Acceleration":
        apiCall = AccelerationsGetByDrivers;
        break;

      case "Hard Breaking":
        apiCall = BreakingsGetByDrivers;
        break;

      case "Hard Cornering":
        apiCall = CorneringGetByDrivers;
        break;

      default:
        apiCall = OverSpeedGetByDrivers;
        break;
    }

    apiCall(user.userToken, vehicleIds, startTime, endTime, limit, skip).then(
      (response) => {
        this.setState({
          loading: false,
          limit,
          skip,
          fileName: `Ctraac - ${
            this.state.typeText
          } alerts, ${this.state.startTime.format(
            "YYY-MM-DD hh:mm A"
          )} to ${this.state.endTime.format("YYY-MM-DD hh:mm A")}.csv`,
        });

        if (response && Array.isArray(response.rows)) {
          const a = response.rows.map((x) => {
            return {
              ...x,
              duration: x.duration_sec > 15 ? "15+" : x.duration_sec + " ",
              time: AppHelperDateAndTimeToString(x.event_time),
              driverName: this.findDriverName(x),
              vehicleName: this.findVehicleName(x),
            };
          });

          this.setState({
            allRows: a,
            totalCount: response.count,
            touched: true,
          });
        }
      }
    );
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      limit: pagingLimit,
      skip: 0,
      allRows: [],
    });
    if (this.state.selectedDrivers.length) {
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);
    }
  };

  handlePagingChange = (limit, skip) => {
    this.onDateRangeGo(limit, skip);
  };

  formatedPushButton = (itemObject, buttonText, path) => {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <Button
            size="xs"
            color="link"
            onClick={() => {
              history.push({
                pathname: path,
                state: { itemObject: itemObject },
              });
            }}
          >
            {buttonText}
          </Button>
        )}
      />
    );
    return <ButtonLive />;
  };

  findDriverName = (item) => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  handleChangeType = (event) => {
    this.setState({ typeText: event.target.value });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  renderOverSpeedView() {
    const currentAppLocale = AppLocale[this.props.locale];
    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <div className="card-body-min-height">
        <div className="float-right">
          <CSVLink
            headers={headersOverSpeed}
            filename={this.state.fileName}
            data={this.state.allRows}
          >
            <Button title={this.state.fileName} size="xs" outline color="dark">
              <IntlMessages id="common.download" />
            </Button>
          </CSVLink>
        </div>
        {this.state.allRows.length +
          " " +
          intl.formatMessage({
            id: "report.records",
          })}

        <Table responsive hover bordered striped>
          <thead>
            <tr>
              <th>#</th>
              <th>
                {" "}
                <IntlMessages id="report.driver" />
              </th>
              <th>
                {" "}
                <IntlMessages id="report.vehicle" />
              </th>
              <th>
                {" "}
                <IntlMessages id="report.place" />
              </th>

              <th>
                {" "}
                <IntlMessages id="report.top-speed" />
              </th>
              <th>
                {" "}
                <IntlMessages id="report.speed-limit" />
              </th>
              <th>
                {" "}
                <IntlMessages id="report.seconds" />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.allRows.map((item, i) => (
              <tr key={this.state.skip + i + 1}>
                <th scope="row">{this.state.skip + i + 1}</th>
                <td>{item.driverName}</td>
                <td>{item.vehicleName}</td>
                <td>
                  {item.time}
                  <br />
                  <LocationName
                    place={item.place}
                    geo={item.geo}
                    objectItem={item}
                    showGoogle
                  />
                </td>

                <td>{item.topspeed}</td>
                <td>{item.limit}</td>
                <td>{item.duration}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  renderAlertsView() {
    const currentAppLocale = AppLocale[this.props.locale];
    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <div className="card-body-min-height">
        <div className="float-right">
          <CSVLink
            headers={headersForAlert}
            filename={this.state.fileName}
            data={this.state.allRows}
          >
            <Button title={this.state.fileName} size="xs" outline color="dark">
              <IntlMessages id="common.download" />
            </Button>
          </CSVLink>
        </div>
        {this.state.allRows.length +
          " " +
          intl.formatMessage({
            id: "report.records",
          })}

        <Table responsive hover bordered striped>
          <thead>
            <tr>
              <th>#</th>
              <th>
                {" "}
                <IntlMessages id="report.driver" />
              </th>
              <th>
                {" "}
                <IntlMessages id="report.vehicle" />
              </th>
              <th>
                {" "}
                <IntlMessages id="report.place" />
              </th>

              <th>
                <IntlMessages id="report.start-speed" />
              </th>
              <th>
                <IntlMessages id="report.end-speed" />
              </th>
              <th>
                <IntlMessages id="report.rate-of" />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.allRows.map((item, i) => (
              <tr key={this.state.skip + i + 1}>
                <th scope="row">{this.state.skip + i + 1}</th>
                <td>{item.driverName}</td>
                <td>{item.vehicleName}</td>
                <td>
                  {item.time}
                  <br />
                  <LocationName
                    place={item.place}
                    geo={item.geo}
                    objectItem={item}
                    showGoogle
                  />
                </td>

                <td>{item.startspeed}</td>
                <td>{item.endspeed}</td>
                <td>{item.rate}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  renderCorneringView() {
    const currentAppLocale = AppLocale[this.props.locale];
    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <div className="card-body-min-height">
        <div className="float-right">
          <CSVLink
            headers={headersForCornering}
            filename={this.state.fileName}
            data={this.state.allRows}
          >
            <Button title={this.state.fileName} size="xs" outline color="dark">
              <IntlMessages id="common.download" />
            </Button>
          </CSVLink>
        </div>
        {this.state.allRows.length +
          " " +
          intl.formatMessage({
            id: "report.records",
          })}

        <Table responsive hover bordered striped>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <IntlMessages id="report.driver" />
              </th>
              <th>
                <IntlMessages id="report.vehicle" />
              </th>
              <th>
                <IntlMessages id="report.place" />
              </th>

              <th>
                <IntlMessages id="report.speed-kmph" />
              </th>

              <th>
                <IntlMessages id="report.rate-of-turn" />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.allRows.map((item, i) => (
              <tr key={this.state.skip + i + 1}>
                <th scope="row">{this.state.skip + i + 1}</th>
                <td>{item.driverName}</td>
                <td>{item.vehicleName}</td>
                <td>
                  {item.time}
                  <br />
                  <LocationName
                    place={item.place}
                    geo={item.geo}
                    objectItem={item}
                    showGoogle
                  />
                </td>
                <td>{item.speed}</td>
                <td>{item.turn}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row className="mb-1">
                  <Colxx xxs="12">
                    <DriverSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                    />
                  </Colxx>
                </Row>
                <Row>
                  <Colxx xl="4" lg="4" md="6" sm="12">
                    <select
                      className="form-control"
                      value={this.state.typeText}
                      onChange={this.handleChangeType}
                    >
                      <option value="Over speed">
                        {intl.formatMessage({
                          id: "report.over-speed",
                        })}
                      </option>
                      <option value="Hard Breaking">
                        {intl.formatMessage({
                          id: "report.hard-breaking",
                        })}
                      </option>
                      <option value="Hard Acceleration">
                        {" "}
                        {intl.formatMessage({
                          id: "report.hard-acceleration",
                        })}
                      </option>
                      <option value="Hard Cornering">
                        {" "}
                        {intl.formatMessage({
                          id: "report.hard-cornering",
                        })}
                      </option>
                    </select>
                  </Colxx>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      suggestions="months"
                      startTime={moment().startOf("month")}
                    />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {this.state.selectedDrivers.length && this.state.touched ? (
                  <CardTitle>
                    {this.state.typeText}{" "}
                    {intl.formatMessage({
                      id: "report.alerts",
                    })}
                    {" from "}
                    {AppHelperDateAndTimeToString(
                      this.state.startTime.toDate()
                    )}
                    {" to "}
                    {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
                  </CardTitle>
                ) : (
                  <CardTitle>
                    {" "}
                    {intl.formatMessage({
                      id: "report.try-search-driver",
                    })}{" "}
                  </CardTitle>
                )}

                {this.state.touched && !this.state.totalCount ? (
                  <p>
                    {intl.formatMessage({
                      id: "report.nothing-show",
                    })}
                  </p>
                ) : (
                  ""
                )}

                <div className="card-body-min-height">
                  {this.state.loading ? (
                    <div className="bg-info">
                      <span className="spinner d-inline-block">
                        <span className="bounce1" />
                        <span className="bounce2" />
                        <span className="bounce3" />
                      </span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {this.state.typeText === "Over speed"
                        ? this.renderOverSpeedView()
                        : this.state.typeText === "Hard Cornering"
                        ? this.renderCorneringView()
                        : this.renderAlertsView()}
                    </React.Fragment>
                  )}
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Trips);
