import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import vehicles from './vehicles/reducer';
import subscriber from './subscriber/reducer';
import story from './story/reducer';
import drivers from './drivers/reducer';
import pages from './pages/reducer';
import geofence from './geofence/reducer';
import geofenceVehicles from './geofence-vehicles/reducer';
import passengers from './passengers/reducer';
import groups from './groups/reducer';
import favoritePlaces from './favorite-places/reducer';

import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  vehicles,
  subscriber,
  story,
  drivers,
  pages,
  geofence,
  geofenceVehicles,
  passengers,
  groups,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  favoritePlaces
});

export default reducers;