import React, { Component } from "react";
import { Row, Card, CardTitle, Label, Input, Button } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { Formik, Form } from "formik";
import { NotificationManager } from "../../components/common/react-notifications";
import { ResetPasswordWithOtp } from "../../api-sc/forgot-password";
export default class ResetPassword extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     oldPassword: "",
  //     newPassword: "",
  //   };
  // }
  onNewPassword = async (values) => {
    if (values.newPassword === values.confirmPassword) {
      const input = {
        newPassword: values.newPassword,
        otp: this.props.location.state.otp,
        mailId: this.props.location.state.email,
      };
      console.log("input", input);
      if (values.email !== "") {
        const verifyResult = await ResetPasswordWithOtp(input);
        console.log("verifyResult", verifyResult);
        this.onSubmitFailed(verifyResult.status, verifyResult.message);
        if (verifyResult.status !== "failed") {
          setTimeout(() => {
            this.props.history.push({
              pathname: `/user/login`,
              state: {
                email: values.email,
              },
            });
          }, 3000);
        }
      }
    } else {
      const status = "Failed";
      const message = "Passwords must match";
      this.onSubmitFailed(status, message);
    }
  };
  onSubmitFailed = (status, message) => {
    if (status) {
      NotificationManager.warning(
        message,
        status.toUpperCase(),
        3000,
        null,
        null,
        ""
      );
    }
  };
  render() {
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side ">
              <p className="text-white h2">
              ctraac.com - Cloud Telematics Remote Access Analytics Console
              </p>
              <p className="white mb-0">
                The tracking system of cloud telematics. <br />
                {/* If you are not a member, please{" "}
                <NavLink to={`/register`} className="white">
                  register
                </NavLink>
                . */}
              </p>
            </div>
            <div className="form-side">
              <CardTitle className="mb-4">
                <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  <IntlMessages id="user.reset-password" />
                </span>
              </CardTitle>
              <Formik
                initialValues={{
                  newPassword: "",
                  confirmPassword: "",
                }}
                enableReinitialize={true}
                // validationSchema={VehicleEditValidationSchema}
                onSubmit={async (values) => {
                  console.log(values);
                  try {
                    await this.onNewPassword(values);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <Label className="form-group has-float-label mb-4">
                      <span
                        style={{ fontWeight: "bold", fontSize: "0.813rem" }}
                      >
                        <IntlMessages id="user.newPassword" />
                      </span>
                      <Input
                        type="password"
                        name="newPassword"
                        value={values.newPassword}
                        onChange={handleChange}
                        required
                      />
                    </Label>
                    <Label className="form-group has-float-label mb-4">
                      <span
                        style={{ fontWeight: "bold", fontSize: "0.813rem" }}
                      >
                        <IntlMessages id="user.confirmPassword" />
                      </span>
                      <Input
                        type="password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        required
                      />
                    </Label>

                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        color="primary"
                        className="btn-shadow"
                        size="lg"
                        type="submit"
                        // onClick={() => this.onUserRegister()}
                      >
                        <IntlMessages id="user.reset-password-button" />
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
