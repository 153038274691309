import React, { Component } from "react";
import { Button, Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";

import { Colxx } from "../../components/common/CustomBootstrap";
import { authApiAccessCheck } from "../../redux/actions";
import { TrackingBasicGetByTimeRangeOptimised } from "../../api-tasks/tracking-basic";
import { IgnitionGetSuggestionRewind } from "../../api-tasks/ignition";
import DateRangePicker from "../../components/DateRangePicker";
import HistoryPlayback from "../../components/HistoryPlayback";
import CanvasJS from "../../components/CanvasJS";
import { NotificationManager } from "../../components/common/react-notifications";

import { AppHelperDateToDBString } from "../../helpers/AppHelper";
import moment from "moment";
import IntlMessages from "../../helpers/IntlMessages";

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: null,
      loading: false,
      wayPoints: [],
      suggestions: [],
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    if (this.props.selectedVehicle) {
      this.handleChangeVehicle(this.props.selectedVehicle);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedVehicle !== this.props.selectedVehicle) {
      this.handleChangeVehicle(this.props.selectedVehicle);
    }
  }

  handleChangeVehicle = (selectedVehicle) => {
    this.setState({ selectedVehicle: selectedVehicle, wayPoints: [] });

    setTimeout(() => {
      this.getSuggestion();
    }, 10);
  };

  onDateRangeGo = () => {
    if (!this.state.selectedVehicle) {
      NotificationManager.error(
        "please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const vehicleId = this.state.selectedVehicle.autoid;

    const vehicleValue = this.state.selectedVehicle.value | {};
    const timezone = vehicleValue.timezone || "Asia/Qatar";

    this.setState({ loading: true });

    TrackingBasicGetByTimeRangeOptimised(
      user.userToken,
      vehicleId,
      startTime,
      endTime,
      timezone
    ).then((response) => {
      this.setState({ loading: false });
      if (response && Array.isArray(response)) {
        this.setState({ wayPoints: response });
        this.getSuggestion();
      }
    });
  };

  getSuggestion = () => {
    const user = this.props.user;
    const vehicleId = this.state.selectedVehicle.autoid;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const limit = 7;

    IgnitionGetSuggestionRewind(
      user.userToken,
      vehicleId,
      startTime,
      limit
    ).then((response) => {
      if (response && Array.isArray(response)) {
        this.setState({ suggestions: response });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({ startTime, endTime });
  };

  onSuggestionSelect = (item) => {
    this.setState({
      startTime: moment(item.first_start),
      endTime: moment(item.last_end),
    });
    setTimeout(() => {
      this.onDateRangeGo();
    }, 5);
  };

  render() {
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker onChange={this.handleChangeDateRange} />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={this.onDateRangeGo}
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="tracking.go" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row className="mb-1">
          <Colxx xxs="12">
            {this.state.suggestions.map((x, index) => {
              return (
                <Button
                  outline
                  color="primary"
                  size="xs"
                  key={index}
                  className="mr-1"
                  onClick={() => {
                    this.onSuggestionSelect(x);
                  }}
                >
                  {x.dated} {x.mileage_sum}km
                </Button>
              );
            })}
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <HistoryPlayback wayPoints={this.state.wayPoints} />
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        {this.state.wayPoints.length ? (
          <Row>
            <Colxx xxs="12">
              <Card>
                <CardBody>
                  <CanvasJS
                    wayPoints={this.state.wayPoints}
                    title={"Speed Chart"}
                  />
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user } = authUser;
  return { vehiclesAll: all, user };
};

export default connect(mapStateToProps, { authApiAccessCheck })(History);
