import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "user-executive";

export const UserSelectByToken = userToken => {
  const criterion = { userToken };

  return axios
    .post(proxyServer + "/get-by-token", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const UserAdd = (
  userToken,
  firstName,
  lastName,
  nickName,
  email,
  contactNumber,
  userRole,
  password
) => {
  const criterion = {
    userToken,
    firstName,
    lastName,
    nickName,
    email,
    contactNumber,
    userRole,
    password
  };

  return axios
    .post(proxyServer + "/add", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const UserUpdate = (
  userToken,
  autoid,
  firstName,
  lastName,
  nickName,
  role,
  statusCode,

  contactNumber
) => {
  const criterion = {
    userToken,
    autoid,
    firstName,
    lastName,
    nickName,
    role,
    statusCode,
    contactNumber
  };

  return axios
    .post(proxyServer + "/update", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const UserUpdateStatus = (
  userToken,
  autoid,

  statusCode
) => {
  const criterion = {
    userToken,
    autoid,

    statusCode
  };

  return axios
    .post(proxyServer + "/update-status", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const UserUpdateGroup = ( userToken,  autoid,  groupId ) => {
  const criterion = { userToken, autoid, groupId };

  return axios
    .post(proxyServer + "/update-group", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
