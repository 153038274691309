import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Colxx } from "../components/common/CustomBootstrap";
import { Row } from "reactstrap";

class MegaMapGeoVehiclesModel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      filteredGeoVehicles: [],
      geoVehicles: [],
      searchTextGeoVehciles: '',
      selectedGeo: '',

    }
  }

  componentDidMount() {
    this.loadMasters();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.geoVehicles !== this.props.geoVehicles) {
      this.loadMasters();
    }
  }

  loadMasters = () => {

    this.setState({ geoVehicles: this.props.geoVehicles || [] });
    this.searchHandlerGeoVehicles(null, this.props.geoVehicles);
  };

  searchHandlerGeoVehicles = (event, geoVehicles) => {

    const allGeoVehicles = geoVehicles || this.state.geoVehicles || [];

    let searchValue = event
      ? event.target.value.toLowerCase()
      : this.state.searchTextGeoVehciles;

    if (!searchValue) {
      this.setState({
        filteredGeoVehicles: allGeoVehicles || [],
        searchTextGeoVehciles: searchValue,
      });
      return;
    }


    let searcjQery = searchValue;
    let arrData = searcjQery.split(" ");
    let tempDataRows = allGeoVehicles;
    let filteredDataRows = allGeoVehicles;

    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        return (
          el.title.toLowerCase().indexOf(element) !== -1 ||
          el.plate_number.toLowerCase().indexOf(element) !== -1 ||
          el.fleet_number.toLowerCase().indexOf(element) !== -1 ||
          el.project.toLowerCase().indexOf(element) !== -1 ||
          el.manufacture_name.toLowerCase().indexOf(element) !== -1 ||
          el.geofenceTitle.toLowerCase().indexOf(element) !== -1 ||
          el.year.toLowerCase().indexOf(element) !== -1 ||
          el.model.toLowerCase().indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredGeoVehicles: filteredDataRows,
      searchTextGeoVehciles: searcjQery,
    });
  };

  handleChange = (event) =>{
    
    this.setState({ selectedGeo: event.target.value })
    this.searchHandlerGeoVehicles(event);
  };

  render() {
    

    return (
      <Modal isOpen={this.props.isOpen} size="lg" toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>
          Geofence vehicles
          </ModalHeader>
        <ModalBody>

          <Row>
            <Colxx xxs="4" className="mb-1">

              <select name={'select-geofence-12'} className={"form-control"} value={this.state.selectedGeo} onChange={this.handleChange}>
                 
                <option key={'0'} value="">All</option>

                {this.props.geofenceAll.map((item, i) => (

                  <option key={i} value={item.title} >  {item.title}    </option>

                ))}

              </select>



            </Colxx>

            <Colxx xxs="4" className="mb-1">
              <input
                type="text"
                className="form-control w-auto "
                placeholder={"Search Vehicle"}
                value={this.state.searchTextGeoVehciles}
                onChange={this.searchHandlerGeoVehicles}
              />

            </Colxx>
          </Row>
          <Row>

            <Colxx xxs="12" className="mb-1">

              <ol>

                {this.state.filteredGeoVehicles.map((item, i) => (

                  <li key={i + 1}>  {item.geofenceTitle}  {item.plate_number}  {item.fleet_number} </li>

                ))}

              </ol>
            </Colxx>

          </Row>

        </ModalBody>
      </Modal>
    );
  }
}

export default MegaMapGeoVehiclesModel;
