
import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApiHeavy + "drill-downs/geofence";

export const GeofenceGetInAndOutByDateAndVehicle = (userToken, vehicleIds, geofenceIds, startTime, endTime, offsetMinutes) => {
    const criterion = { userToken, vehicleIds, geofenceIds, startTime, endTime, offsetMinutes};
    return axios
      .post(proxyServer + "/get-in-and-out", { criterion })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };