import React from "react";
import { connect } from "react-redux";
import { CardTitle } from "reactstrap";

import {
  UserSelectByToken,
  UserAdd,
  UserUpdate,
  UserUpdateStatus,
  UserUpdateGroup
} from "../../api-tasks/user-executive";
import { authApiAccessCheck } from "../../redux/actions";
import ExecutiveItem from "./ExecutiveItem";
import { NotificationManager } from "../../components/common/react-notifications";

import ChangePassModel from "./ChangePassModel";
import { ExecutiveUpdatePassword } from "../../api-sc/executive";

class Executive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLarge: false,
      allContacts: [],
      displayedContacts: [],
      subscriber_id: 0,
      executive_email: "",
      user_role: 3,
      first_name: "",
      last_name: "",
      nick_name: "",
      contact_number: "",
      newpassword: "",
      toastItems: [],
      selectedItemObject: null,
    };

    this.searchHandler = this.searchHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  searchHandler(event) {
    let searcjQery = event.target.value.toLowerCase(),
      displayedContacts = this.state.allContacts.filter(el => {
        let searchValue = el.email.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    this.setState({
      displayedContacts: displayedContacts
    });
  }

  loadMasterData = () => {
    const user = this.props.user;

    UserSelectByToken(user.userToken).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allContacts: res, displayedContacts: res });
        } else {
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  componentDidMount() {
    this.loadMasterData();
  }

  componentWillUnmount() { }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const user = this.props.user;
    const {
      first_name,
      last_name,
      nick_name,
      executive_email,
      contact_number,
      user_role,
      newpassword
    } = this.state;

    UserAdd(
      user.userToken,
      first_name,
      last_name,
      nick_name,
      executive_email.toLowerCase(),
      contact_number,
      user_role,
      newpassword
    ).then(res => {

      if (res) {
        this.onToastItemAdd(res.status, res.message);

        if (res.status === "success") {
          NotificationManager.success(
            res.message,
            "Success",
            3000,
            null,
            null,
            ""
          );
          this.loadMasterData();
        } else {
          NotificationManager.error(res.message, "ERROR", 3000, null, null, "");
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  }

  handleOnDelete = itemObject => {
    const newFilledItems = this.state.displayedContacts.filter(
      c => c.autoid !== itemObject.autoid
    );

    const newAllItems = this.state.allContacts.filter(
      c => c.autoid !== itemObject.autoid
    );

    this.setState({
      displayedContacts: newFilledItems,
      allContacts: newAllItems
    });

    const user = this.props.user;

    UserUpdateStatus(user.userToken, itemObject.autoid, 0).then(res => {
      if (res) {
        if (res.status === "success") {
          NotificationManager.success(
            "user removed",
            "Success",
            3000,
            null,
            null,
            ""
          );

          this.loadMasterData();
        } else {
          NotificationManager.error(res.message, "ERROR", 3000, null, null, "");
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  handleOnUpdate = itemObject => {
    const user = this.props.user;

    const {
      autoid,
      first_name,
      last_name,
      nick_name,
      contact_number,
      role,
      statuscode
    } = itemObject;

    UserUpdate(
      user.userToken,
      autoid,
      first_name,
      last_name,
      nick_name,
      role,
      statuscode,
      contact_number
    ).then(res => {
      if (res) {
        if (res.status === "success") {
          NotificationManager.success(
            res.message,
            "Success",
            3000,
            null,
            null,
            ""
          );

          this.loadMasterData();
        } else {
          NotificationManager.error(res.message, "ERROR", 3000, null, null, "");
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  handleOnUpdateGroup = (userId, groupId) => {
    const user = this.props.user;


    UserUpdateGroup(user.userToken, userId, groupId).then(res => {
      if (res) {
        if (res.status === "success") {
          NotificationManager.success(
            res.message,
            "Success",
            3000,
            null,
            null,
            ""
          );

          this.loadMasterData();
        } else {
          NotificationManager.error(res.message, "ERROR", 3000, null, null, "");
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  handleToastItemClose = itemObject => {
    const toastItems = this.state.toastItems.filter(
      c => c.id !== itemObject.id
    );
    this.setState({ toastItems });
  };

  onToastItemAdd = (type, message) => {
    let newid = this.state.toastItems.length + 1;
    if (
      this.state.toastItems.filter(function (e) {
        return e.id === newid;
      }).length > 0
    ) {
      newid = this.state.toastItems.length + Math.floor(Math.random() * 100);
    }

    let newToastItems = this.state.toastItems;
    let itemToast = {
      id: newid,
      type: type === "success" ? "success" : "danger",
      message: message
    };

    newToastItems.push(itemToast);
    this.setState({ toastItems: newToastItems });

    setTimeout(() => {
      this.handleToastItemClose(itemToast);
    }, 5000);
  };

  handlePasswordUpdate = async (autoid, email, newPassword) => {

    let updatePasswordObject = {
      autoid, email, newPassword
    };

    const user = this.props.user;
    const resetInput = updatePasswordObject;
    resetInput.userToken = user.userToken;
    const result = await ExecutiveUpdatePassword(resetInput);
    if (result) {
      if (result.status === "success") {
        this.setState({modalLarge: false});
        NotificationManager.success(
          result.message,
          "Success",
          3000,
          null,
          null,
          ""
        );

        this.loadMasterData();
      } else {
        NotificationManager.error(
          result.message,
          "ERROR",
          3000,
          null,
          null,
          ""
        );
        this.props.authApiAccessCheck(this.props.history, result);
      }
    }
  };

  toggleLarge = () => {

    this.setState(prevState => ({
      modalLarge: !prevState.modalLarge
    }));
  };

  render() {
    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;
    const userId = userDetails.executiveId || -1;

    if (userRole === 3) {
      return (
        <React.Fragment>
          <div className="alert alert-info" role="alert">
            User Access Restricted!
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="card">
                <div className="card-header"></div>
                <div className="card-content collpase show">
                  <div className="card-body">
                    <CardTitle>Executive List</CardTitle>

                    <div className="filterlist">
                      <ul>
                        {this.state.displayedContacts.map(el => {
                          if (userId !== el.autoid) {
                            return null;
                          }

                          return (
                            <ExecutiveItem
                              key={el.autoid}
                              itemObject={el}
                              onDelete={this.handleOnDelete}
                              onUpdate={this.handleOnUpdate}
                              onUpdateGroup={this.handleOnUpdateGroup}
                              onToastItemAdd={this.onToastItemAdd}
                              limitedAccess={true}
                            />
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>

        <ChangePassModel
          itemObject={this.state.selectedItemObject}
          isOpen={this.state.modalLarge}
          closeButton={

            <button type="button"
              onClick={this.toggleLarge}
              className="btn btn-warning mr-1">
              <i className="ft-x" /> Cancel
            </button>

          }
          toggle={this.toggleLarge}
          onUpdatePassword={this.handlePasswordUpdate }
        />


        <div className="row">
          <div className="col-md-6 col-12">
            <div className="card mb-1">
              <div className="card-header"></div>

              <div className="card-content collpase show">
                <div className="card-body">
                  <CardTitle>Executive Form</CardTitle>
                  <form className="form" onSubmit={this.onSubmit}>
                    <div className="form-body">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label>User Role</label>
                              <div className="position-relative has-icon-left">
                                <select
                                  name="user_role"
                                  className="form-control"
                                  onChange={this.onChange}
                                  value={this.state.user_role}
                                >
                                  <option value="2"> Admin </option>
                                  <option value="3">User</option>
                                </select>

                                <div className="form-control-position">
                                  <i className="ft-user" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label>Email</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="executive_email"
                                  required
                                  onChange={this.onChange}
                                  value={this.state.executive_email}
                                />
                                <div className="form-control-position">
                                  <i className="ft-user" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label>New Password</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="password"
                                  className="form-control"
                                  name="newpassword"
                                  required
                                  onChange={this.onChange}
                                  value={this.state.newpassword}
                                />
                                <div className="form-control-position">
                                  <i className="ft-user" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label>First Name</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="first_name"
                                  required
                                  onChange={this.onChange}
                                  value={this.state.first_name}
                                />
                                <div className="form-control-position">
                                  <i className="ft-user" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form-group">
                              <label>Last Name</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="last_name"
                                  onChange={this.onChange}
                                  required
                                  value={this.state.last_name}
                                />
                                <div className="form-control-position">
                                  <i className="ft-user" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <label>Nick Name </label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="nick_name"
                                  onChange={this.onChange}
                                  value={this.state.nick_name}
                                />
                                <div className="form-control-position">
                                  <i className="ft-user" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="zip_code">Contact Number</label>
                              <div className="position-relative has-icon-left">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="contact_number"
                                  onChange={this.onChange}
                                  value={this.state.contact_number}
                                />
                                <div className="form-control-position">
                                  <i className="ft-user" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-actions right">
                        <button type="button" className="btn btn-warning mr-1">
                          <i className="ft-x" /> Cancel
                        </button>
                        <button type="submit" className="btn btn-primary">
                          <i className="fa fa-check-square-o" /> Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="card">
              <div className="card-header"></div>
              <div className="card-content collpase show">
                <div className="card-body">
                  <input
                    type="text"
                    className="card-search float-right"
                    placeholder="Search... "
                    onChange={this.searchHandler}
                  />
                  <CardTitle>Executive List</CardTitle>
                  <div className="filterlist">
                    <ul>
                      {this.state.displayedContacts.map(el => {
                        return (
                          <ExecutiveItem
                            key={el.autoid}
                            itemObject={el}
                            onDelete={this.handleOnDelete}
                            onUpdate={this.handleOnUpdate}
                            onUpdateGroup={this.handleOnUpdateGroup}
                            onToastItemAdd={this.onToastItemAdd}
                            onChangePassword={(itemObject) => {

                              console.log('its  pass change', itemObject);


                              this.setState({ selectedItemObject: itemObject });
                              this.toggleLarge()
                            }}
                          />
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user, details } = authUser;
  return { user, userDetails: details };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Executive);
