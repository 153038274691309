import React, { Component, Fragment } from "react";

import {
  Card,
  UncontrolledAlert,
  Row,
  CardTitle,
  CardBody,
  FormGroup,
  Label,
  Table,
} from "reactstrap";

import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Link, withRouter } from "react-router-dom";

import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";
import CustomSubmitButton from "../../components/form-controls/CustomSubmitButton";

import ErrorPopover from "../../components/form-controls/ErrorPopover";

import { authApiAccessCheck, driverSelectRequest } from "../../redux/actions";
import { DriverAdd } from "../../api-tasks/drivers";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import * as Yup from "yup";
import { createIntl } from "react-intl";

const VehicleEditValidationSchema = Yup.object().shape({
  driverName: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required"),
  empCode: Yup.string()
    .min(1, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
  rfidCode: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
});

class DriverList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedDriver: null,
      filteredDataRows: [],
      allDataRows: [],
      driverName: "",
      empCode: "",
      rfidCode: "",
      readerType: "8",
      searchTex: "",
    };
  }

  defaultCustomData(){
    const subscriberDetails = this.props.subscriberDetails || {};
     if(subscriberDetails.autoid === 49){
      this.setState({readerType: "5"})
     }

     console.log("subscriberDetails", subscriberDetails);
  }

  generateDrivers() {
    const driversAll = this.props.driversAll || [];
    const dr = driversAll.map((el) => {
      return {
        ...el,
        pendingVehicles: el.pending_vehicles
          ? el.pending_vehicles.split(",")
          : [],
        allVehicles: el.pending_vehicles ? el.all_vehicles.split(",") : [],
      };
    });
    this.setState({ allDataRows: dr, filteredDataRows: dr });
  }

  componentDidMount() {
    this.generateDrivers();
    this.defaultCustomData();

  }

  componentDidUpdate(prevProps) {
    if (prevProps.driversAll !== this.props.driversAll) {
      this.generateDrivers();
    }

    if (prevProps.subscriberDetails !== this.props.subscriberDetails) {
      this.defaultCustomData();
    }
  }

  componentWillUnmount() {}

  handleChangeDriver = (selectedDriver) => {
    this.setState({ selectedDriver });
    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  handleSubmit = (values) => {
    this.setState({ updating: true });

    const { driverName, empCode, rfidCode } = values;

    const rfidCodeWithLimit = "00000000" + rfidCode;

    let newRfidCode = rfidCodeWithLimit.substring(
      rfidCodeWithLimit.length - parseInt(this.state.readerType, 10)
    );

    const user = this.props.user;
    DriverAdd(user.userToken, driverName, empCode, newRfidCode).then(
      (response) => {
        if (response) {
          this.setState({ updating: false });
          if (response) {
            if (response.status === "success") {
              this.setState({ driverName: "", empCode: "", rfidCode: "" });

              NotificationManager.success(
                response.message,
                "Success",
                3000,
                null,
                null,
                ""
              );
              this.props.driverSelectRequest(this.props.history);
            } else {
              console.error("driver.add", response);
              NotificationManager.warning(
                response.message,
                response.status,
                5000,
                null,
                null,
                ""
              );
              this.props.authApiAccessCheck(this.props.history, response);
            }
          }
        }
      }
    );
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSearch = (event) => {
    let searchQuery = event.target.value.toLowerCase();
    let arrData = searchQuery.split(" ");
    let tempDataRows = this.state.allDataRows;
    let filteredDataRows = this.state.allDataRows;
    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        const item = {
          ...el,
        };

        return (
          item.driver_name.toLowerCase().indexOf(element) !== -1 ||
          item.employee_code.toLowerCase().indexOf(element) !== -1 ||
          item.rfid_code.toLowerCase().indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredDataRows: filteredDataRows,
      searchTex: searchQuery,
    });
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <Fragment>
        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Row>
                  <Colxx xxs="12">
                    <Formik
                      initialValues={{
                        driverName: this.state.driverName,
                        empCode: this.state.empCode,
                        rfidCode: this.state.rfidCode,
                      }}
                      enableReinitialize={true}
                      validationSchema={VehicleEditValidationSchema}
                      onSubmit={this.handleSubmit}
                    >
                      {({ errors, touched, isValidating }) => (
                        <Form className="av-tooltip tooltip-label-right">
                          <Row>
                            <Colxx xl="4" lg="6" md="12">
                              <FormGroup>
                                <Label>
                                  <IntlMessages id="driver.reader-type" />
                                </Label>
                                <select
                                  className="form-control"
                                  name="readerType"
                                  onChange={this.onChange}
                                  value={this.state.readerType}
                                >
                                  <option value="5" label="iClass(5digit)" />
                                  <option value="8" label="Regular(8digit)" />
                                </select>
                              </FormGroup>
                            </Colxx>
                            <Colxx xl="4" lg="6" md="12">
                              <FormGroup>
                                <Label>
                                  <IntlMessages id="driver.rfid" />
                                </Label>
                                <Field
                                  className="form-control"
                                  name="rfidCode"
                                />
                                <ErrorPopover
                                  error={errors.rfidCode}
                                  touched={touched.rfidCode}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xl="4" lg="6" md="12">
                              <FormGroup>
                                <Label>
                                  <IntlMessages id="driver.emp" />
                                </Label>
                                <Field
                                  className="form-control"
                                  name="empCode"
                                />
                                <ErrorPopover
                                  error={errors.empCode}
                                  touched={touched.empCode}
                                />
                              </FormGroup>
                            </Colxx>

                            <Colxx xl="4" lg="6" md="12">
                              <FormGroup>
                                <Label>
                                  <IntlMessages id="driver.driver-name" />
                                </Label>
                                <Field
                                  className="form-control"
                                  name="driverName"
                                />
                                <ErrorPopover
                                  error={errors.driverName}
                                  touched={touched.driverName}
                                />
                              </FormGroup>
                            </Colxx>
                          </Row>

                          <CustomSubmitButton
                            validating={isValidating}
                            updating={this.state.updating}
                          />

                          {Object.keys(errors).length &&
                          Object.keys(touched).length ? (
                            <UncontrolledAlert
                              color="danger"
                              fade={false}
                              className="d-inline rounded ml-2"
                            >
                              {Object.keys(errors).length}{" "}
                              {Object.keys(errors).length > 1
                                ? "errors"
                                : "error"}
                            </UncontrolledAlert>
                          ) : (
                            ""
                          )}
                        </Form>
                      )}
                    </Formik>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="driver.drivers" />
                </CardTitle>
                <Row className="mt-2">
                  <Colxx xxs="12">
                    <div className="search">
                      <div className="float-right">
                        <input
                          className="input2 driver-td-action"
                          type="text"
                          onChange={this.onSearch}
                          value={this.state.searchTex}
                          placeholder={intl.formatMessage({
                            id: "driver.search-drivers",
                          })}
                        />
                      </div>
                    </div>
                  </Colxx>
                </Row>
                <Row>
                  <Colxx xxs="12">
                    <div className="card-body-min-height">
                      <Table responsive hover bordered striped>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <IntlMessages id="driver.name" />
                            </th>
                            <th
                              title={<IntlMessages id="driver.employee-code" />}
                            >
                              <IntlMessages id="driver.emp" />
                            </th>
                            <th>
                              <IntlMessages id="driver.rfid" />
                            </th>
                            <th>
                              <IntlMessages id="driver.assigned" />
                            </th>
                            <th>
                              <IntlMessages id="driver.action" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filteredDataRows.map((item, i) => (
                            <tr key={item.autoid}>
                              <th scope="row">{i + 1}</th>
                              <td>{item.driver_name}</td>
                              <td>{item.employee_code}</td>
                              <td>{item.rfid_code}</td>
                              <td>
                                {item.allVehicles.length}
                                <small className="">
                                  {!item.pending_vehicles
                                    ? " "
                                    : ` (${intl.formatMessage({
                                        id: "driver.pending",
                                      })} ${item.pendingVehicles.length})`}
                                </small>
                              </td>
                              <td>
                                <div className="float-right">
                                  <Link
                                    to={`driver-settings/driver-view?param1=${item.autoid}`}
                                    className="btn btn-link btn-xs"
                                  >
                                    <IntlMessages id="driver.view" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings, subscriber}) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  const { details } = subscriber;
  return { vehiclesAll: all, user, driversAll: items,locale, subscriberDetails: details };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
  driverSelectRequest,
})(withRouter(DriverList));
