import React from "react";
import {
  Button,
  Table,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import { CSVLink } from "react-csv";
import IntlMessages from "../../../helpers/IntlMessages";
import LocationName from "../../../components/LocationName";
import AppLocale from "../../../lang";
import { createIntl } from "react-intl";
import { connect } from "react-redux";
const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Time", key: "time" },
  { label: "Place", key: "place" },
  { label: "Geo", key: "geo" },
  { label: "HH:mm", key: "duration" },
  { label: "Driver name", key: "driverName" },
];

class TableData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allRows: [],
      showAllRows: false,
      isOpenSizingXs: false,
      selectedText: "Sort by",
    };
  }

  componentDidMount() {
    this.setState({ allRows: this.props.allRows || [] });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.allRows !== this.props.allRows) {
      this.setState({ allRows: this.props.allRows, selectedText: "Sort by" });
    }
  }

  handleSortChange = (label) => {
    this.setState({ selectedText: `${label}` });

    const allRows = this.state.allRows || [];
    let sortedRows = allRows || [];

    switch (label) {
      case "DriverAsc":
        sortedRows = allRows.sort((a, b) =>
          a.driverName.localeCompare(b.driverName)
        );
        break;

      case "DriverDesc":
        sortedRows = allRows.sort((a, b) =>
          b.driverName.localeCompare(a.driverName)
        );
        break;

      case "VehicleAsc":
        sortedRows = allRows.sort((a, b) =>
          a.vehicleName.localeCompare(b.vehicleName)
        );
        break;

      case "VehicleDesc":
        sortedRows = allRows.sort((a, b) =>
          b.vehicleName.localeCompare(a.vehicleName)
        );
        break;

      case "TimeAsc":
        sortedRows = allRows.sort((a, b) => a.timeStamp - b.timeStamp);
        break;

      case "TimeDesc":
        sortedRows = allRows.sort((a, b) => b.timeStamp - a.timeStamp);
        break;

      case "DurationAsc":
        sortedRows = allRows.sort((a, b) => a.duration_sec - b.duration_sec);
        break;

      case "DurationDesc":
        sortedRows = allRows.sort((a, b) => b.duration_sec - a.duration_sec);
        break;

      default:
        sortedRows = allRows || [];
        break;
    }

    this.setState({ allRows: sortedRows });

    /*
    if (this.props.onChange) {
      this.props.onChange(selectedVehicle);
    }*/
  };

  toggleSizingXs = () => {
    this.setState((prevState) => ({
      isOpenSizingXs: !prevState.isOpenSizingXs,
    }));
  };

  render() {
    const showRows =
      this.state.allRows.length > 100 && !this.state.showAllRows
        ? this.state.allRows.slice(0, 100)
        : this.state.allRows;
    const fileName = this.props.fileName || "new.csv";
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <div className="float-right">
          <ButtonGroup className="flex-wrap">
            <ButtonDropdown
              className="bd-container"
              isOpen={this.state.isOpenSizingXs}
              toggle={this.toggleSizingXs}
            >
              <DropdownToggle caret size="xs" outline color="secondary">
                {this.state.selectedText}
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: (data) => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: "auto",
                          maxHeight: 221,
                        },
                      };
                    },
                  },
                }}
              >
                <DropdownItem
                  onClick={() => this.handleSortChange("DurationAsc")}
                >
                  Duration Asc
                </DropdownItem>

                <DropdownItem
                  onClick={() => this.handleSortChange("DurationDesc")}
                >
                  Duration Desc
                </DropdownItem>

                <DropdownItem
                  onClick={() => this.handleSortChange("VehicleAsc")}
                >
                  Vehicle Asc
                </DropdownItem>

                <DropdownItem
                  onClick={() => this.handleSortChange("VehicleDesc")}
                >
                  Vehicle Desc
                </DropdownItem>

                <DropdownItem
                  onClick={() => this.handleSortChange("DriverAsc")}
                >
                  Driver Asc
                </DropdownItem>

                <DropdownItem
                  onClick={() => this.handleSortChange("DriverDesc")}
                >
                  Driver Desc
                </DropdownItem>

                <DropdownItem onClick={() => this.handleSortChange("TimeAsc")}>
                  Time Asc
                </DropdownItem>

                <DropdownItem onClick={() => this.handleSortChange("TimeDesc")}>
                  Time Desc
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </ButtonGroup>

          <CSVLink
            headers={headers}
            filename={fileName}
            data={this.state.allRows}
          >
            <Button title={fileName} size="xs" outline color="dark">
              <IntlMessages id="common.download" />
            </Button>
          </CSVLink>
        </div>

        <Table responsive hover bordered striped>
          <thead>
            <tr>
              <th>#</th>
              <th>
                <IntlMessages id="report.vehicle" />
              </th>
              <th>
                <IntlMessages id="report.place" />
              </th>

              <th>HH:mm</th>
              <th>
                <IntlMessages id="report.driver" />
              </th>
            </tr>
          </thead>
          <tbody>
            {showRows.map((item, i) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{item.vehicleName}</td>
                <td>
                  {item.time}
                  <br />
                  <LocationName
                    place={item.place}
                    geo={item.geo}
                    objectItem={item}
                    showGoogle
                  />
                </td>

                <td>{item.duration}</td>
                <td>{item.driverName}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {this.state.allRows.length > 100 && !this.state.showAllRows ? (
          <Button
            size="sm"
            outline
            color="dark"
            className="w-100"
            onClick={() => {
              this.setState({ showAllRows: true });
            }}
          >
            {intl.formatMessage({
              id: "report.show-all",
            })}{" "}
            {this.state.allRows.length}{" "}
            {intl.formatMessage({
              id: "report.rows",
            })}
          </Button>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return {
    locale,
  };
};
export default connect(mapStateToProps)(TableData);
