import React, { Component, Fragment } from "react";

import {
  Badge,
  Card,
  UncontrolledAlert,
  Row,
  CardTitle,
  CardBody,
  FormGroup,
  Label,
  Table,
  ButtonGroup,
  Button,
} from "reactstrap";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { withRouter } from "react-router-dom";

import queryString from "query-string";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";
import CustomSubmitButton from "../../components/form-controls/CustomSubmitButton";
import ErrorPopover from "../../components/form-controls/ErrorPopover";
import DropStateButton from "../../components/DropStateButton";
import StateButton from "../../components/StateButton";
import XButton from "../../components/common/XButton";
import { authApiAccessCheck, driverSelectRequest } from "../../redux/actions";
import DriverVehiclesAddByDriver from "../../components/DriverVehiclesAddByDriver";
import IntlMessages from "../../helpers/IntlMessages";
import {
  DriverDelete,
  DriverUpdate,
  DriverSelectByDriver,
  DriverVehicleRemove,
  DriverVehicleRedo,
} from "../../api-tasks/drivers";
import AppLocale from "../../lang";
import * as Yup from "yup";
import { createIntl } from "react-intl";

const VehicleEditValidationSchema = Yup.object().shape({
  driverName: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required"),
  empCode: Yup.string()
    .min(1, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
  rfidCode: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
});

class DriverList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDriver: null,
      filteredDataRows: [],
      allDataRows: [],
      driverId: 0,
      driverName: "",
      empCode: "",
      rfidCode: "",
      searchTex: "",
    };
  }

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return {
            title: `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`,
            device_type: foo[0].device_type,
          };
        }
      }
    }
    return {};
  };

  onSearch = (event) => {
    let searchQuery = event.target.value.toLowerCase();
    let arrData = searchQuery.split(" ");
    let tempDataRows = this.state.allDataRows;
    let filteredDataRows = this.state.allDataRows;
    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        const item = {
          ...el,
          statusText: el.statuscode === 2 ? "active" : "pending",
        };

        return (
          item.vehicleName.toLowerCase().indexOf(element) !== -1 ||
          item.statusText.indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredDataRows: filteredDataRows,
      searchTex: searchQuery,
    });
  };

  loadTable = (searchTex) => {
    const user = this.props.user;
    const driverId = this.state.driverId || 0;

    this.setState({ loading: true, searchTex: searchTex || "" });

    DriverSelectByDriver(user.userToken, driverId).then((res) => {
      this.setState({ loading: false });
      if (res) {
        if (Array.isArray(res)) {
          const theRows = res.map((x) => {
            const vItem = this.findVehicleName(x);
            return {
              ...x,
              vehicleName: vItem.title,
              device_type: vItem.device_type,
            };
          });

          this.setState({ allDataRows: theRows, filteredDataRows: theRows });

          let allVehicles = this.props.vehiclesAll || [];
          let vehicleDrivers = res;
          let vehiclesNotInDriver = [];

          if (allVehicles.length !== vehicleDrivers.length) {
            vehiclesNotInDriver = allVehicles;
            vehicleDrivers.forEach((vd) => {
              vehiclesNotInDriver = vehiclesNotInDriver.filter(function (
                value
              ) {
                return value.autoid !== vd.vehicle_id;
              });
            });
          } else {
            vehiclesNotInDriver = [];
          }

          console.log("vehiclesNotInDriver ", vehiclesNotInDriver);

          this.setState({ vehiclesNotInDriver });
        } else {
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  componentDidMount() {
    const paramValues = queryString.parse(this.props.location.search);

    const autoid = parseInt(paramValues.param1 || 0, 10);
    const driversAll = this.props.driversAll || [];

    const oneDriver =
      driversAll.filter((x) => {
        return x.autoid === autoid;
      })[0] || {};

    if (!oneDriver.autoid) {
      this.props.history.push("/app/settings/driver-settings");
    }

    this.setState({
      driverId: oneDriver.autoid,
      driverName: oneDriver.driver_name || "",
      empCode: oneDriver.employee_code || "",
      rfidCode: oneDriver.rfid_code || "",
      allDrivers: this.props.driversAll || [],
    });

    setTimeout(() => {
      this.loadTable();
    }, 10);
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  handleChangeDriver = (selectedDriver) => {
    this.setState({ selectedDriver });
    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  handleSubmit = (values) => {
    this.setState({ updating: true });

    const { driverName, empCode, rfidCode } = values;

    const user = this.props.user;
    DriverUpdate(
      user.userToken,
      this.state.driverId,
      driverName,
      empCode,
      rfidCode,
      this.state.rfidCode
    ).then((response) => {
      if (response) {
        this.setState({ updating: false });
        if (response) {
          if (response.status === "success") {
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
            this.props.driverSelectRequest(this.props.history);

            if (rfidCode !== this.state.rfidCode) {
              this.handleOnRedoListedClick();
            }
          } else {
            console.error("driver.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
            this.props.authApiAccessCheck(this.props.history, response);
          }
        }
      }
    });
  };

  handleOnRefresh = () => {
    this.loadTable();
  };

  handleOnRemove = (item) => {
    const user = this.props.user;
    const input = {
      autoid: item.autoid,
      driver_id: item.driver_id,
      vehicle_id: item.vehicle_id,
      device_type: item.device_type,
      cancel_only: item.statuscode === 1 ? 1 : 0,
    };

    DriverVehicleRemove(user.userToken, input).then((response) => {
      if (response) {
        this.setState({ loading: false, selectedDrivers: [] });
        if (response) {
          if (response.status === "success") {
            this.loadTable();
          } else {
            NotificationManager.error(
              response.message,
              "ERROR",
              3000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  handleOnDeleteDriverClick = (itemValue) => {
    return new Promise((success, fail) => {
      try {
        const thePromises = [];
        const user = this.props.user;
        const driverId = this.state.driverId;

        this.state.allDataRows.forEach((dvItems) => {
          const input = {
            autoid: dvItems.autoid,
            driver_id: driverId,
            vehicle_id: dvItems.vehicle_id,
            device_type: dvItems.device_type,
            cancel_only: dvItems.statuscode === 1 ? 1 : 0,
          };

          thePromises.push(DriverVehicleRemove(user.userToken, input));
        });

        thePromises.push(DriverDelete(user.userToken, driverId));

        Promise.all(thePromises)
          .then((responses) => {
            success("driver deleted ");
            this.props.driverSelectRequest(this.props.history);

            setTimeout(() => {
              this.props.history.push("/app/settings/driver-settings");
            }, 1500);
          })
          .catch((err) => {
            fail("Ooh, unexpected error");
          });

        /*
        const vehicles = this.state.filteredDataRows;

        const assignedItems = vehicles.map(item => ({
          autoid: item.autoid,
          driverId: item.driver_id,
          vehicleId: item.vehicle_id,
          deviceType: item.device_type
        }));

        const user = this.props.user;
        DriverVehicleRedo(user.userToken, assignedItems).then(response => {
          if (response) {
            if (response.status === "success") {
              success(response.message);
              this.loadTable(this.state.searchTex);
            } else {
              fail(response.message);
            }
          }
        });
        */
      } catch (error) {
        fail("Ooh, unexpected error");
      }
    });
  };

  handleOnRedoListedClick = () => {
    return new Promise((success, fail) => {
      if (!this.state.filteredDataRows.length) {
        fail("Hi, nothing to redo");
        return;
      }

      try {
        const vehicles = this.state.filteredDataRows;

        const assignedItems = vehicles.map((item) => ({
          autoid: item.autoid,
          driverId: item.driver_id,
          vehicleId: item.vehicle_id,
          deviceType: item.device_type,
        }));

        const user = this.props.user;
        DriverVehicleRedo(user.userToken, assignedItems).then((response) => {
          if (response) {
            if (response.status === "success") {
              success(response.message);
              this.loadTable(this.state.searchTex);
            } else {
              fail(response.message);
            }
          }
        });
      } catch (error) {
        fail("Ooh, unexpected error");
      }
    });
  };

  handleOnClickRedoItem = (item) => {
    return new Promise((success, fail) => {
      try {
        const assignedItems = [
          {
            autoid: item.autoid,
            driverId: item.driver_id,
            vehicleId: item.vehicle_id,
            deviceType: item.device_type,
          },
        ];

        const user = this.props.user;
        DriverVehicleRedo(user.userToken, assignedItems).then((response) => {
          if (response) {
            if (response.status === "success") {
              success(response.message);
              this.loadTable(this.state.searchTex);
            } else {
              fail(response.message);
            }
          }
        });
      } catch (error) {
        fail("Ooh, unexpected error");
      }
    });
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <Fragment>
        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <h1>
                  <IntlMessages id="driver.driver-view" />
                </h1>
                <Row>
                  <Colxx xxs="12">
                    <Formik
                      initialValues={{
                        driverName: this.state.driverName,
                        empCode: this.state.empCode,
                        rfidCode: this.state.rfidCode,
                      }}
                      enableReinitialize={true}
                      validationSchema={VehicleEditValidationSchema}
                      onSubmit={this.handleSubmit}
                    >
                      {({ errors, touched, isValidating }) => (
                        <Form className="av-tooltip tooltip-label-right">
                          <Row>
                            <Colxx xl="3" lg="6" md="12">
                              <FormGroup>
                                <Label>
                                  <IntlMessages id="driver.emp" />
                                </Label>
                                <Field
                                  className="form-control"
                                  name="empCode"
                                />
                                <ErrorPopover
                                  error={errors.empCode}
                                  touched={touched.empCode}
                                />
                              </FormGroup>
                            </Colxx>

                            <Colxx xl="3" lg="6" md="12">
                              <FormGroup>
                                <Label>
                                  <IntlMessages id="driver.driver-name" />
                                </Label>
                                <Field
                                  className="form-control"
                                  name="driverName"
                                />
                                <ErrorPopover
                                  error={errors.driverName}
                                  touched={touched.driverName}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xl="3" lg="6" md="12">
                              <FormGroup>
                                <Label>
                                  <IntlMessages id="driver.rfid" />
                                </Label>
                                <Field
                                  className="form-control"
                                  name="rfidCode"
                                />
                                <ErrorPopover
                                  error={errors.rfidCode}
                                  touched={touched.rfidCode}
                                />
                              </FormGroup>
                            </Colxx>
                          </Row>

                          <CustomSubmitButton
                            validating={isValidating}
                            updating={this.state.updating}
                            text={<IntlMessages id="common.update" />}
                          />

                          <DropStateButton
                            id={`redo_listed_driver_vehicles`}
                            color="danger"
                            className="btn ml-1"
                            title={intl.formatMessage({
                              id: "driver.re-apply",
                            })}
                            onClick={this.handleOnDeleteDriverClick}
                            confirmText={
                              <IntlMessages id="driver.confirm-delete" />
                            }
                          >
                            <IntlMessages id="driver.delete" />
                          </DropStateButton>

                          {Object.keys(errors).length &&
                          Object.keys(touched).length ? (
                            <UncontrolledAlert
                              color="danger"
                              fade={false}
                              className="d-inline rounded ml-2"
                            >
                              {Object.keys(errors).length}{" "}
                              {Object.keys(errors).length > 1
                                ? "errors"
                                : "error"}
                            </UncontrolledAlert>
                          ) : (
                            ""
                          )}
                        </Form>
                      )}
                    </Formik>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="driver.vehicles-of" />{" "}
                  {this.state.driverName} {this.state.empCode}
                </CardTitle>
                <Row>
                  <div className="w-100">
                    <Colxx xl="6" lg="6" md="12" sm="12">
                      <DriverVehiclesAddByDriver
                        options={this.state.vehiclesNotInDriver}
                        onRefresh={this.handleOnRefresh}
                        driver={{ driverId: this.state.driverId }}
                        user={this.props.user}
                      />
                    </Colxx>
                  </div>
                </Row>
                <Row className="mt-2">
                  <Colxx xxs="12">
                    <div className="search">
                      <div className="float-right">
                        <ButtonGroup className="flex-wrap">
                          <input
                            className="input2 w-50"
                            type="text"
                            onChange={this.onSearch}
                            value={this.state.searchTex}
                            placeholder={intl.formatMessage({
                              id: "driver.search-vehicles",
                            })}
                          />
                          <Button
                            color="secondary"
                            size="sm"
                            onClick={this.loadTable}
                          >
                            {this.state.loading ? (
                              <span className="spinner d-inline-block">
                                <span className="bounce1" />
                                <span className="bounce2" />
                                <span className="bounce3" />
                              </span>
                            ) : (
                              <IntlMessages id="driver.refresh" />
                            )}
                          </Button>
                          <DropStateButton
                            id={`redo_listed_driver_vehicles`}
                            color="secondary"
                            className="btn"
                            size="sm"
                            title={intl.formatMessage({
                              id: "driver.re-apply",
                            })}
                            onClick={this.handleOnRedoListedClick}
                            confirmText={`${intl.formatMessage({
                              id: "driver.confirm",
                            })} ${this.state.filteredDataRows.length} ${intl.formatMessage({
                              id: "driver.drivers",
                            })}?`}
                          >
                            <IntlMessages id="driver.redoList" />
                          </DropStateButton>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Colxx>
                </Row>
                <div className="card-body-min-height">
                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <IntlMessages id="driver.vehicles" />
                        </th>

                        <th>
                          <IntlMessages id="driver.status" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredDataRows.map((item, i) => (
                        <tr key={item.autoid}>
                          <th scope="row">{i + 1}</th>
                          <td>{item.vehicleName}</td>

                          <td>
                            <h6 className="d-inline pb-1">
                              <Badge
                                color={
                                  item.statuscode === 2
                                    ? "success"
                                    : "secondary"
                                }
                                className="mb-1 mr-1"
                              >
                                {item.statuscode === 2 ? (
                                  <IntlMessages id="driver.active" />
                                ) : (
                                  <IntlMessages id="driver.pending" />
                                )}
                              </Badge>

                              <Badge
                                color="info"
                                className="mb-1 mr-1"
                                title={item.updatetime}
                              >
                                <Moment fromNow>{item.updatetime}</Moment>
                              </Badge>
                            </h6>
                            <div className="driver-td-action float-right">
                              <ButtonGroup className="float-right">
                                <StateButton
                                  key={item.autoid}
                                  id={`redo${item.autoid}`}
                                  size="xs"
                                  color="secondary"
                                  title={intl.formatMessage({
                                    id: "driver.re-apply",
                                  })}
                                  onClick={this.handleOnClickRedoItem}
                                  value={item}
                                >
                                  <IntlMessages id="driver.redo" />
                                </StateButton>

                                <XButton
                                  onAction={this.handleOnRemove}
                                  value={item}
                                  title={intl.formatMessage({
                                    id: "driver.remove-driver",
                                  })}
                                />
                              </ButtonGroup>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers,settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items,locale };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
  driverSelectRequest,
})(withRouter(DriverList));
