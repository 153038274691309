import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from "reactstrap";

const trimLength =(param) =>{
  try {

    if(param.length < 9){
      return param;
    }
    return param.substr(0, 7)
  } catch (error) {
    return ""
  }
}

class LiveMapInfoBox extends React.Component {
  showTime(info) {
    if (info.vehicleState === 'idle' && info.theIdleTime) {
      return (
        <div className="d-inline-block">
          <i className="iconsminds-watch" />
          Since {info.theIdleTime}
        </div>
      );
    }

    if(info.vehicleState === 'no-data'){
      return  (
        <div className="d-inline-block w-100">
          
          {'GPS: ' +info.theMoment.format('DD-MMM-hh:mma')}
          <br />
          {'HB: ' +info.timeHBMoment.format('DD-MMM-hh:mma')}
          <br />
        </div>
      )
    }

    return (
      <div className="d-inline-block">
        <i className="iconsminds-watch" />
        {info.theTimeNow}
      </div>
    );
  }

  render() {
    const x = this.props.itemData || {};

    if(x.reminigDays < 0 && x.expiryExtensionDays < 1){
      return (
      <div className={`${this.props.className} bg-yellow`}>
        <b>
          {x.plate_number} {x.fleet_number} {trimLength(x.project)}
        </b>
        <small> Expired since {Math.abs(x.reminigDays)} days </small>

        {x.groupList.map((item, index) => {
                  return (
                    <Badge key={index} color="primary mb-1 ml-1 " >
                      {item.group_name}
                    </Badge>
                  );
                })}
      </div>
      )
    }

    return (
      <div className={this.props.className}>
        <b>
          {x.plate_number} {x.fleet_number} {trimLength(x.project)}
        </b>
        <br />
        {x.reminigDays < 0 && x.expiryExtensionDays > 0 ? (
         
                  <small className="bg-yellow">
                    Expiry extension {x.expiryExtensionDays} days
                  </small>
                ) : null}

        <div className="d-line-keeper" onClick={this.props.onClick}>
          {x.epic.ignition ? (
            <div className="d-inline-block">
              <i className="simple-icon-speedometer" />
              {x.epic.speed}
            </div>
          ) : (
            `Off`
          )}
          {x.epic.fuel > -1 ? (
            <div className="d-inline-block">
              <i className="iconsminds-gas-pump" />
              {x.epic.fuel}
            </div>
          ) : (
            ``
          )}
 {this.showTime(x)}
         

          <div className="d-inline-block">
            <button
              className="btn btn-link btn-xs text-light"
              onClick={() => {
                if (this.props.map) {
                  this.props.map.panTo({
                    lat: parseFloat(x.epic.lat),
                    lng: parseFloat(x.epic.lng),
                  });
                  if (this.props.map.getZoom() < 16) {
                    this.props.map.setZoom(16);
                  } else {
                    this.props.map.setZoom(this.props.map.getZoom() + 1);
                  }
                }
              }}
            >
              <b>
                <i className="simple-icon-magnifier-add" />
              </b>
            </button>

            <Link
              className="text-light"
              title="Open in new tab"
              to={`/app/tracking/vehicle-view?param1=${x.autoid}`}
            >
              <i className="simple-icon-link text-light" /> View
            </Link>

            {x.groupList.map((item, index) => {
                  return (
                    <Badge key={index} color="primary mb-1 ml-1 " >
                      {item.group_name}
                    </Badge>
                  );
                })}

          </div>
        </div>
      </div>
    );
  }
}

export default LiveMapInfoBox;
