import React, { Fragment } from 'react';
import { Button, Row, Badge } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import LocationName from '../../components/LocationName';
import CsvDownloader from "../../components/CsvDownloader";

const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Day", key: "summaryDayText" },
  { label: "Time", key: "timeText" },
  { label: "text", key: "text" },
  { label: "place", key: "place" },
  { label: "Map place", key: "geo" },
  { label: "Details", key: "details" },
  { label: "Remarks", key: "" }
];

class SummaryRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theRow: {},
      showInfo: false,
      idleShowAll: false,
      overSpeedShowAll: false,
      theTimeLine: [],
    };
  }

  getTimeLine = (theRow) => {
    // type 1 : idle, time, item
    let aTimeline = [];
    let idleTimeLine = [];
    if (theRow.idles && theRow.idles.length) {
      idleTimeLine = theRow.idles.map((x) => {
        return {
          vehicleName : theRow.vehicleName ,
          summaryDayText : theRow.summaryDayText ,
          type: 3,
          text: 'Idle',
          time: new Date(x.startTime),
          place: x.place,
          geo: x.geo,
          timeText: x.startTimeText,
          details: ' duration:' + x.duration,
          item: x,
        };
      });
    }

    // mobi trac , 250mb , 50
    let stopeTimeLine = [];
    if (theRow.stops && theRow.stops.length) {
      stopeTimeLine = theRow.stops.map((x) => {
        return {
          vehicleName : theRow.vehicleName ,
          summaryDayText : theRow.summaryDayText ,
          type: 4,
          text: 'Stop',
          time: new Date(x.startTime),
          place: x.place,
          geo: x.geo,
          timeText: x.startTimeText,
          item: x,
        };
      });
    }

    stopeTimeLine.splice(0, 1);

    const errands = theRow.errands;

    let errandsStartTimeLine = [];
    let errandsEndTimeLine = [];

    if (errands && errands.length) {
      errandsStartTimeLine = errands.map((x) => {
        return {
          vehicleName : theRow.vehicleName ,
          summaryDayText : theRow.summaryDayText ,
          type: 1,
          text: 'Start',
          time: new Date(x.startTime),
          place: x.startPlace,
          geo: x.startGeo,
          timeText: x.startTimeText,
          item: x,
        };
      });

      aTimeline = aTimeline.concat(errandsStartTimeLine);

      errandsEndTimeLine = errands.map((x) => {
        return {
          vehicleName : theRow.vehicleName ,
          summaryDayText : theRow.summaryDayText ,
          type: 2,
          text: 'End',
          time: new Date(x.endTime),
          place: x.endPlace,
          geo: x.endGeo,
          timeText: x.endTimeText,
          details: ' duration:' + x.duration + ', distance:' + x.distance,
          item: x,
        };
      });

      aTimeline = aTimeline.concat(errandsEndTimeLine);
    }

    aTimeline = aTimeline.concat(stopeTimeLine);
    aTimeline = aTimeline.concat(idleTimeLine);

    const theTimeLine = aTimeline.sort((a, b) => a.time - b.time);
    // const theTimeLineWithStart = [start, ...theTimeLine, end];

    this.setState({ theTimeLine: theTimeLine });
    // console.log('the timeline ', theTimeLine);
  };

  componentDidMount() {
    this.setState({ theRow: this.props.theRow || {} });
    this.getTimeLine(this.props.theRow);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.theRow !== this.props.theRow) {
      this.setState({ theRow: this.props.theRow || {} });
      this.getTimeLine(this.props.theRow);
    }
  }

  handleLocationNameOnClick = () => {
    alert('the call');
  };

  handleShowToggleOnClick = () => {
    this.setState({ showInfo: !this.state.showInfo });
  };

  handleIdleShowAllOnClick = () => {
    this.setState({ idleShowAll: !this.state.idleShowAll });
  };

  handleOverSpeedShowAllOnClick = () => {
    this.setState({ overSpeedShowAll: !this.state.overSpeedShowAll });
  };

  renderBadge(type, text) {
    switch (type) {
      case 3:
        return (
          <Badge color="danger" pill className="mb-1 mr-1">
            {text}
          </Badge>
        );

      case 4:
        return (
          <Badge color="warning" pill className="mb-1 mr-1">
            {text}
          </Badge>
        );

      default:
        return (
          <Badge color="info" pill className="mb-1 mr-1">
            {text}
          </Badge>
        );
    }
  }

  renderList() {
    return (
      <Fragment>
          <span className="float-right">
            <CsvDownloader
              headers={headers}
              filename={'ctraac-timeline.csv'}
              data={this.state.theTimeLine}
            />
          </span>
           
       
        <ol>
          {this.state.theTimeLine.map((item, i) => (
            <li key={i}>
              <span className="mr-1" title={`Time:${item.timeText}`}>
                <i className="iconsminds-watch mr-1"></i>
                {item.timeText}
              </span>
              <span>{this.renderBadge(item.type, item.text)}</span>
              <span>{item.details}</span>
              <span className="mr-1" title="Place">
                <LocationName
                  place={item.place}
                  geo={item.geo}
                  objectItem={item}
                  onClick={() =>
                    this.props.onShowMapModel(item.geo, item.place)
                  }
                />
              </span>
              {item.type === 2 ? (
                <div>
                  <br /> 
                </div>
              ) : (
                ''
              )}
            </li>
          ))}
        </ol>
      </Fragment>
    );
  }

  render() {
    const theRow = this.state.theRow || {};
    if (!theRow.summaryDay) {
      return <Fragment />;
    }

    if (this.props.expanted) {
      return this.renderList();
    }

    return (
      <Fragment>
        <tr>
          <td>
            {theRow.parkingHours === '24:00' ? (
              ''
            ) : (
              <Button
                onClick={this.handleShowToggleOnClick}
                outline
                color="info"
                size="xs"
              >
                {this.state.showInfo ? '-' : '+'}
              </Button>
            )}
          </td>
          <td>{theRow.vehicleName}</td>
          <td> {theRow.summaryDayText} </td>
          <td> {theRow.distance}</td>
          <td>{theRow.enginHours}</td>
          <td> {theRow.idleHours}</td>
          <td> {theRow.parkingHours}</td>
          <td> {theRow.errands.length}</td>
          <td> {theRow.overSpeeds.length}</td>
          <td> {theRow.firstStartTime}</td>
          <td>
            {theRow.lastEndTime}
            <small className="text-muted">
              {theRow.dayDiff ? '+' + theRow.dayDiff + 'd' : ''}
            </small>
          </td>
          <td> {theRow.first1kmText} </td>
          <td> {theRow.first3kmText} </td>
          <td> {theRow.first10kmText} </td>
        </tr>
        {this.state.showInfo ? (
          <tr>
            <td colSpan="10">
              <Row className="w-100">
                <Colxx xl="12" lg="12" md="12" sm="12" className="timeline56">
                  {this.renderList()}
                </Colxx>
              </Row>
            </td>
          </tr>
        ) : null}
      </Fragment>
    );
  }
}

export default SummaryRow;
