import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";

import { passengerSelectSuccess } from "./actions";
import { authApiAccessCheck } from "../auth/actions";

import { PassengerSelectBySubscriber } from "../../api-tasks/passenger";
import { PASSENGER_SELECT_REQUEST } from "../actions";

function* selectPassengers({payload}) {
    
    const state = yield select();
    const { history } = payload;
    try {
      const authUser = state.authUser;
      const user = authUser.user;
  
      const resultSet = yield call(PassengerSelectBySubscriber, user.userToken);
      
      if (resultSet) {
        if (Array.isArray(resultSet)) {
          yield put(passengerSelectSuccess(resultSet));
        } else { 
            yield put(authApiAccessCheck(history, resultSet));
        }
      }
    } catch (error) {
      console.log("error vehicle saga 1 :", error);
    }
  }

  export function* watchSelectAllRequest() {
    yield takeEvery(PASSENGER_SELECT_REQUEST, selectPassengers);
  }

  export default function* rootSaga() {
    yield all([fork(watchSelectAllRequest)]);
  }
