import React from "react";
import ReactApexChart from "react-apexcharts";

class GeofenceUtilizationChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          min: 0,
        },
        title: {
          text: "Geofence engagement by date",
          align: "left",
        },
      },
    };
  }

  componentDidMount() {}
  render() {
    return (
      <div className="w-100" style={{ direction: "ltr" }}>
        <ReactApexChart
          options={this.state.options}
          series={this.props.series || []}
          type="bar"
          height="250"
        />
      </div>
    );
  }
}

export default GeofenceUtilizationChart;
