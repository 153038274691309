import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "drivers";


export const DriverAdd = ( userToken, driverName, employeeCode, rfidCode) => {

  const criterion = { userToken, driverName, employeeCode, rfidCode };

  return axios
    .post(proxyServer + "/add", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DriverUpdate = ( userToken, driverId, driverName, employeeCode, newCode, oldCode) => {

  const criterion = { userToken, driverId, driverName, employeeCode, newCode, oldCode };

  return axios
    .post(proxyServer + "/update", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DriverDelete = ( userToken, driverId) => {

  const criterion = { userToken, driverId };

  return axios
    .post(proxyServer + "/delete", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DriverSelectByVehicle = (vehicleId, userToken) => {

  const criterion = {
    userToken,
    vehicleId
  };

  return axios
    .post(proxyServer + "/get-by-vehicle", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


export const DriverSelectByDriver = ( userToken, driverId) => {

  const criterion = { userToken, driverId };

  return axios
    .post(proxyServer + "/get-by-driver", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DriverSelectBySubscriber = (userToken) => {

  const criterion = {
    userToken
  };
  return axios
    .post(proxyServer + "/get-by-subscriber", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

// driver vehicle function 
export const DriverVehicleAdd = (userToken, items) => {

  const criterion = {
    userToken,
    items
  };

  return axios
    .post(proxyServer + "/vehicle-add", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DriverVehicleRemove = (userToken, item) => {

  const criterion = {
    userToken,
    item
  };

  return axios
    .post(proxyServer + "/vehicle-remove", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DriverVehicleRedo = (userToken, items) => {

  const criterion = {
    userToken,
    items
  };

  return axios
    .post(proxyServer + "/vehicle-redo", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
