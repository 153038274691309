import React, { Component } from "react";
import { Row, Card, CardBody, CardTitle, Table, Button } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../../components/common/CustomBootstrap";
import moment from "moment";
import { GetTicket } from "../../../api-sc/tickets";
import IntlMessages from "../../../helpers/IntlMessages";

class TicketList extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      allRows: [],
    };
  }

  // componentWillUnmount() {}

  componentDidMount() {
    this.getTickets();
    if (this.props.ticketList !== undefined) {
      this.setState({ allRows: this.props.ticketList });
    }
  }

  getTickets = async () => {
    const user = this.props.user;
    const userToken = user.userToken;

    const tickets = await GetTicket(userToken);
    if (tickets && Array.isArray(tickets)) {
      this.setState({
        allRows: tickets,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.ticketList !== this.props.ticketList) {
      this.getTickets();
    }
  }
  getTicketDetails = (values) => {
    this.props.ticketDetails(values);
    this.props.toggleSecondTab("3");
  };

  render() {
    const ticketList = this.state.allRows || [];
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle><IntlMessages id="ticket.list" /></CardTitle>

                <small>{ticketList.length} <IntlMessages id="support.records" /> </small>
                <div className="card-body-min-height">
                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th><IntlMessages id="ticket.subject" /></th>
                        <th><IntlMessages id="ticket.date" /></th>
                        <th><IntlMessages id="ticket.status" /></th>
                        <th><IntlMessages id="ticket.priority" /></th>
                        <th><IntlMessages id="ticket.last_update" /></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketList.map((item, i) => (
                        <tr key={i + 1}>
                          <th scope="row">{i + 1}</th>
                          <td>#{item.autoId + " " + item.subject}</td>
                          <td>{item.ticket_date}</td>
                          <td>{item.status}</td>
                          <td>{item.priority}</td>
                          <td>
                            {moment(item.update_time).format(
                              "YYYY-MM-DD h:mm:ss a"
                            )}
                          </td>
                          <td>
                            {" "}
                            <Button
                              outline
                              // color={"white"}
                              className="icon-button"
                              onClick={() =>
                                this.getTicketDetails({
                                  id: item.autoId,
                                  subject: item.subject,
                                  status: item.status_value,
                                })
                              }
                            >
                              <i
                                className="simple-icon-note btn-group-icon"
                                // aria-hidden="true"
                              />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return { user };
};

export default connect(mapStateToProps)(TicketList);
