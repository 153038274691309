import React, { Component } from "react";
import { Row, Card, CardBody } from "reactstrap";
import moment from "moment";

import MonthPicker from "../../components/MonthPicker";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectBatch from "../../components/VehicleSelectBatch";
import GeofenceSelect from "../../components/GeofenceSelect";
import HeatmapChart from "../../components/apexcharts/GeofenceHeatmapChart";
import GeofenceUtilizationChart from "../../components/apexcharts/GeofenceUtilizationChart";
import PagingBar from "../../components/PagingBar";

import {
  GeofenceChangesGetSummaryGroupByDate,
  GeofenceChangesGetSummaryGroupByDateAndVehicle,
} from "../../api-tasks/geofence-changes";
import { AppHelperDateToDBString } from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";

const pagingLimit = 10;

const getDateLabel = (theValue) => {
  try {
    const a = new Date(theValue);
    return moment(a).format("MMMD");
  } catch (error) {
    return "";
  }
};

function getDates(startDate, stopDate) {
  var dateArray = [];
  var currentDate = moment(startDate);
  stopDate = moment(stopDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
}

function getFurnishedNode(availableDates, availableUnites, theDates) {
  const theNodes = theDates.map((el) => {
    return {
      day: el,
      unit: 0,
    };
  });

  availableDates.forEach((el, index) => {
    let foo = theNodes.findIndex((obj) => obj.day === el);
    let bar = theNodes[foo];

    theNodes[foo] = {
      day: bar.day,
      unit: availableUnites[index] || 0,
    };
  });

  return theNodes;
}

class GeofenceSummaryCharts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      startTime: moment().startOf("month"),
      endTime: moment(new Date()),
      utilizationSeries: [],
      utilizationDaySeries: [],
      selectedVehicles: [],
      selectedGeofences: [],
      totalCount: 0,
      limit: pagingLimit,
      skip: 0,
    };
  }

  findVehicleName = (vehicleId) => {
    if (vehicleId && vehicleId > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === vehicleId;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model}`;
        }
      }
    }
    return "";
  };

  loadMasters = (limit, skip) => {
    this.setState({
      loading: true,
      utilizationSeries: [],
      utilizationDaySeries: [],
    });

    const user = this.props.user;
    const vehicles = this.state.selectedVehicles || [];
    const geofences = this.state.selectedGeofences || [];

    if (!vehicles.length || !geofences.length) {
      this.setState({ loading: false });
      return;
    }

    const vehicleIds = vehicles.map((el) => {
      return el.autoid;
    });

    const geofenceIds = geofences.map((el) => {
      return el.autoid;
    });

    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const offsetMinutes = this.props.offsetMinutes || 180;

    GeofenceChangesGetSummaryGroupByDate(
      user.userToken,
      vehicleIds,
      geofenceIds,
      startTime,
      endTime,
      offsetMinutes
    ).then((res) => {
      this.setState({ loading: false });
      if (res) {
        if (Array.isArray(res)) {
          const dataChart = res.map((x) => {
            return {
              x: getDateLabel(x.dated),
              y: x.vehicle_count,
            };
          });

          const series = [{ name: "Used vehicles", data: dataChart }];

          this.setState({ utilizationSeries: series });
        } else {
          // this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });

    this.loadHeatMap(limit, skip);
  };

  loadHeatMap = (limit, skip) => {
    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const offsetMinutes = this.props.offsetMinutes || 180;

    const vehicles = this.state.selectedVehicles || [];
    const geofences = this.state.selectedGeofences || [];

    if (!vehicles.length || !geofences.length) {
      this.setState({ loading: false });
      return;
    }

    const vehicleIds = vehicles.map((el) => {
      return el.autoid;
    });

    const geofenceIds = geofences.map((el) => {
      return el.autoid;
    });

    const theDates = getDates(startTime, endTime);

    this.setState({ loadingHeatMap: true });

    GeofenceChangesGetSummaryGroupByDateAndVehicle(
      user.userToken,
      vehicleIds,
      geofenceIds,
      startTime,
      endTime,
      offsetMinutes,
      limit,
      skip
    ).then((res) => {
      this.setState({ loadingHeatMap: false, limit, skip });

      if (res) {
        if (Array.isArray(res.rows)) {
          const series = [];

          res.rows.forEach((el) => {
            const dates = el.dates.split(",") || [];
            const inAndOuts = el.in_out_counts.split(",") || [];

            const theNode = getFurnishedNode(dates, inAndOuts, theDates);

            const dataNode = theNode.map((el) => {
              return {
                x: getDateLabel(el.day),
                y: el.unit,
              };
            });

            const v = {
              name: this.findVehicleName(el.vehicle_id),
              data: dataNode,
            };
            series.push(v);
          });

          this.setState({
            utilizationDaySeries: series,
            totalCount: res.count,
          });
        } else {
          // this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  componentDidMount() {
    this.setState({
      selectedVehicles: this.props.vehiclesAll || [],
      selectedGeofences: this.props.geofenceAll || [],
    });

    setTimeout(() => {
      this.loadMasters(this.state.limit, this.state.skip);
    }, 10);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.setState({ selectedVehicles: this.props.vehiclesAll || [] });

      setTimeout(() => {
        this.loadMasters(this.state.limit, this.state.skip);
      }, 10);
    }

    if (prevProps.geofenceAll !== this.props.geofenceAll) {
      this.setState({ selectedGeofences: this.props.geofenceAll || [] });

      setTimeout(() => {
        this.loadMasters(this.state.limit, this.state.skip);
      }, 10);
    }
  }

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
    });

    setTimeout(() => {
      this.loadMasters(this.state.limit, this.state.skip);
    }, 10);
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    setTimeout(() => {
      this.loadMasters(this.state.limit, this.state.skip);
    }, 10);
  };

  handleOnSelectedGeofenceChange = (selectedGeofences) => {
    const fences =
      selectedGeofences && selectedGeofences.length
        ? selectedGeofences
        : this.props.geofenceAll;
    this.setState({ selectedGeofences: fences || [] });

    setTimeout(() => {
      this.loadMasters(this.state.limit, this.state.skip);
    }, 10);
  };

  handlePagingChange = (limit, skip) => {
    this.loadHeatMap(limit, skip);
  };

  render() {
    return (
      <Card>
        <CardBody>
          <div className="float-right">
            <MonthPicker
              onChange={this.handleChangeDateRange}
              startTime={this.state.startTime}
              endTime={this.state.endTime}
            />{" "}
            <VehicleSelectBatch onChange={this.handleOnSelectedVehicleChange} />
          </div>

          <h5 className="d-inline-block">
            <IntlMessages id="dashboard.geo-summary" />
            <span className="text-muted">
              {" "}
              {this.state.startTime.format("MMMM YYYY")}{" "}
            </span>
            {this.state.loading || this.state.loadingHeatMap ? (
              <span className="bg-info">
                <span className="spinner d-inline-block">
                  <span className="bounce1" />
                  <span className="bounce2" />
                  <span className="bounce3" />
                </span>
              </span>
            ) : (
              ""
            )}{" "}
          </h5>
          <GeofenceSelect onChange={this.handleOnSelectedGeofenceChange} />
          <Row>
            <Colxx xl="6" lg="6" md="12" className="mb-2">
              <GeofenceUtilizationChart
                series={this.state.utilizationSeries || []}
              />
            </Colxx>
            <Colxx xl="6" lg="6" md="12" className="mb-2">
              <HeatmapChart series={this.state.utilizationDaySeries} />
              {this.state.totalCount > this.state.limit ? (
                <PagingBar
                  totalCount={this.state.totalCount}
                  limit={this.state.limit}
                  skip={this.state.skip}
                  onChange={this.handlePagingChange}
                  outline
                  loading={this.state.loading}
                />
              ) : (
                ""
              )}
            </Colxx>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default GeofenceSummaryCharts;
