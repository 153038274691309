import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "ai";


export const getTextToSpeech = ( userToken, vehcile, limit, speed, text) => {

  const criterion = { userToken, vehcile, limit, speed, text };

  return axios
    .post(proxyServer + "/get-audio", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

