

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "favorite-places";

export const FavoritePlaceAdd = (userToken, title, geo, radius, typeText, type ) => {
  const criterion = { userToken, title, geo, radius, typeText, type  };
  return axios
    .post(proxyServer + "/add", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const FavoritePlacesDelete = (userToken, autoid) => {
  const criterion = { userToken, autoid };
  return axios
    .post(proxyServer + "/delete", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const FavoritePlacesGetBySubscriber = (userToken) => {
  const criterion = { userToken };
  return axios
    .post(proxyServer + "/get-by-subscriber", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
 