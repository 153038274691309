import { DRIVER_SELECT_REQUEST, DRIVER_SELECT_SUCCESS } from "../actions";

  export const driverSelectRequest = ( history ) => ({
    type: DRIVER_SELECT_REQUEST,
    payload: { history }
  });

  export const driverSelectSuccess = ( items ) => ({
    type: DRIVER_SELECT_SUCCESS,
    payload: { items }
  });
