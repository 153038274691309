import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import GeofenceAnalysisContainer from "../../../containers/pages/geofence-analysis";
class PagesHistory extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.geofence-analysis" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <GeofenceAnalysisContainer />
      </Fragment>
    );
  }
}

export default PagesHistory;
