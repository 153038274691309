import { GROUP_SELECT_REQUEST , GROUP_SELECT_SUCCESS } from "../actions";

const INIT_STATE = {
    loading: false,
    items: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GROUP_SELECT_REQUEST:
      return { ...state, loading: true };

    case GROUP_SELECT_SUCCESS:
      return { ...state, items: action.payload.items, loading: false };
      
    default:
      return { ...state };
  }
};
