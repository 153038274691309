import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
 

// import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import VisitContainer from "../../../containers/support/visits"

class VisitLayout extends Component {
  render() {
    // const { messages } = this.props.intl;
    // const messages = [];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb
              heading="menu.visits"
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
       <VisitContainer/>
      </Fragment>
    );
  }
}

export default  VisitLayout ;
