
const dateFormat = require("dateformat");
const moment = require("moment");

export const AppHelperDateAndTimeToDay = param1 => {
  return dateFormat(param1, "dddd");
};

export const AppHelperDateAndTimeToString = param1 => {
  if(!param1) return '';
  return dateFormat(param1, "d mmm yyyy h:MM:ss TT");
};

export const AppHelperDateToString = param1 => {
  if(!param1) return '';
  return dateFormat(param1, "d-mmm");
};

export const AppHelper_DDhhmmT = param1 => {
  if(!param1) return '';
  return dateFormat(param1, "d-h:MM:ssTT");
};

export const AppHelperDateToDBString = param1 => {
  return new Date(param1.valueOf()).toISOString();
};

export const AppHelperDateAndTimeHHmmT = param1 => {
  return dateFormat(param1, "h:MM TT");
};

export const AppHelperDurationSecToHHmm = param1 => {
  return moment.utc(param1 * 1000).format("HH:mm");
};

export const AppHelperDurationAsHHmm = (start, end) => {
  const startTime = moment(start);
  const endTime = moment(end);

  // calculate total duration
  const duration = moment.duration(endTime.diff(startTime));

  // duration in hours
  const hours = parseInt(duration.asHours());

  // duration in minutes
  let minutes = parseInt(duration.asMinutes());
  if (minutes > 0) {
    minutes = minutes % 60;
  }

  return `${hours}:${minutes < 10? '0'+ minutes: minutes}`;
};

export const AppHelperSecondsAsHHmm = seconds => {
  if (!seconds) {
    return "-";
  }
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  return ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2);
};


function findDriverName (item, driversAll) {
  const ignition = item || {};
  if (ignition.driver_id && ignition.driver_id > 0) {
    if (driversAll && driversAll.length) {
      const selectedDriver = driversAll.filter(function(value) {
        return value.autoid === ignition.driver_id;
      });

      if (selectedDriver && selectedDriver.length) {
        return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
      }
    }
  }
  return "";
};

function getDriverNames(drivers, driversAll) {
  try {
    const arr = drivers.split(",");
    let driverNames = "";
    const arrPrev = [];
    arr.forEach(el => {
      if (el && el > 0) {
        if (arrPrev.indexOf(el) === -1) {
          driverNames =
            driverNames +
            (arrPrev.length? ", " : "") +
            findDriverName({ driver_id: parseInt(el, 10) }, driversAll);

          arrPrev.push(el);
        }
      }
    });

    return driverNames;
  } catch (error) {}

  return "";
}


function findVehicleName(item, vehiclesAll) {
  const ignition = item || {};
  if (ignition.vehicle_id && ignition.vehicle_id > 0) {
    if (vehiclesAll && vehiclesAll.length) {
      const foo = vehiclesAll.filter(function(value) {
        return value.autoid === ignition.vehicle_id;
      });

      if (foo && foo.length) {
        return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model}`;
      }
    }
  }
  return "";
};

export const AppHelperGetDriverNames = (drivers, driversAll) => {
   return getDriverNames(drivers, driversAll);
};

export const AppHelperFindDriverName = (item, driversAll) => {
  return findDriverName(item, driversAll);
};

export const AppHelperFindVehicleName = (item, vehiclesAll) => {
  return findVehicleName(item, vehiclesAll);
};
