import React, { Component } from "react";
import {
  Row,
  Card,
  CardTitle,
  CardBody,
  UncontrolledAlert,
  FormGroup,
  Label
} from "reactstrap";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Formik, Form, Field } from "formik";
import { Colxx } from "../../components/common/CustomBootstrap";
import ErrorPopover from "../../components/form-controls/ErrorPopover";
import CustomSubmitButton from "../../components/form-controls/CustomSubmitButton";
import { subscriberSelectDetailsRequest } from "../../redux/actions";
import { authApiAccessCheck } from "../../redux/actions";
import { SubscriberUpdate } from "../../api-tasks/subscriber";
import { NotificationManager } from "../../components/common/react-notifications";
import IntlMessages from "../../helpers/IntlMessages";


import * as Yup from "yup";
const CompanyDetailsEditValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .required("Required")
});

class CompanyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false
    };
  }

  handleSubmit = values => {
    this.setState({ updating: true });

    const user = this.props.user;

    const params = { title: values.title };
    SubscriberUpdate(user.userToken, params).then(res => {
      this.setState({ updating: false });
      if (res) {
        this.showMessage(res);
        this.props.authApiAccessCheck(this.props.history, res);
        this.props.subscriberSelectDetailsRequest(this.props.history);
      }
    });
  };

  showMessage = response => {
    if (response.status === "success") {
      NotificationManager.success(
        response.message,
        response.status.toUpperCase(),
        3000,
        null,
        null,
        ""
      );
    } else {
      NotificationManager.error(
        response.message,
        response.status.toUpperCase(),
        3000,
        null,
        null,
        ""
      );
    }
  };

  render() {
    const subscriberDetails = this.props.subscriberDetails || {};
    return (
      <Colxx xl="6" lg="6" md="12" className="mb-4">
        <Card>
          <CardBody>
            <CardTitle><IntlMessages id="profile.details-edit" /></CardTitle>
            <Formik
              initialValues={{
                title: subscriberDetails.company_name || ""
              }}
              enableReinitialize={true}
              validationSchema={CompanyDetailsEditValidationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ errors, touched, isValidating }) => (
                <Form className="av-tooltip tooltip-label-right">
                  <Row>
                    <Colxx xxs="12">
                      <FormGroup>
                        <Label><IntlMessages id="profile.cmpny-name" /></Label>
                        <Field className="form-control" name="title" />
                        <ErrorPopover
                          error={errors.title}
                          touched={touched.title}
                        />
                      </FormGroup>
                    </Colxx>
                  </Row>

                  <CustomSubmitButton
                    validating={isValidating}
                    updating={this.state.updating}
                  />

                  {Object.keys(errors).length && Object.keys(touched).length ? (
                    <UncontrolledAlert
                      color="danger"
                      fade={false}
                      className="d-inline rounded ml-2"
                    >
                      {Object.keys(errors).length}
                      {Object.keys(errors).length > 1 ? "errors" : "error"}
                    </UncontrolledAlert>
                  ) : (
                    ""
                  )}
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Colxx>
    );
  }
}

const mapStateToProps = ({ authUser, subscriber }) => {
  const { user } = authUser;
  const { details } = subscriber;
  return { user, subscriberDetails: details };
};

export default connect(
  mapStateToProps,
  {
    subscriberSelectDetailsRequest,
    authApiAccessCheck
  }
)(injectIntl(CompanyEdit));
