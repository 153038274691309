import axios from "axios";
import config from "./config";

const proxyServer = config.supportApi + "routes-app/forgot-password";

export const VerifyMail = (passwordInput) => {
  const criterion = passwordInput;

  return axios
    .post(proxyServer + "/send-mail", {
      criterion,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const VerifyOtp = (passwordInput) => {
    const criterion = passwordInput;
  
    return axios
      .post(proxyServer + "/check-otp", {
        criterion,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  export const ResetPasswordWithOtp = (passwordInput) => {
    const criterion = passwordInput;
  
    return axios
      .post(proxyServer + "/reset-password", {
        criterion,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
