function getGpsObj() {
  return {
    id: 0,
    speed: 0,
    ignition: 0,
    time: null,
    hex: "",
    buff: [],
  };
}
/*
function isDelayedMorethan3Min(eventTime) {
  const date = new Date();
  date.setMinutes(date.getMinutes() + 2);

  const FIVE_MIN = 5 * 60 * 1000;

  if (date - eventTime > FIVE_MIN) {
    return true;
  }

  return false;
}

function isHistory(lastKnowTime, eventTime) {
  try {
    if (isDelayedMorethan3Min(eventTime)) {
      return true;
    }

    if (lastKnowTime && lastKnowTime > eventTime) {
      return true;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return false;
}
*/

function hexToBytes(hex) {
    for (var bytes = [], c = 0; c < hex.length; c += 2)
        bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
}

function bytesToHex(bytes) {
    for (var hex = [], i = 0; i < bytes.length; i++) {
        var current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
        hex.push((current >>> 4).toString(16));
        hex.push((current & 0xF).toString(16));
    }
    return hex.join("");
}
 

function commandIdentifierFmb(inString) { 
  // console.log("inBuff", inBuff);
  const inBuff = hexToBytes(inString);
  
  const dLength = parseInt(bytesToHex( inBuff.slice(4, 8) )  , 16);

  const dCodeId = bytesToHex (inBuff.slice(8, 9)); //.toString("hex"); // .toString('hex');
  const dCount = parseInt( bytesToHex(inBuff.slice(9, 10) ), 16);

  // socketWrite(socket, [0, 0, 0, inBuff[9]]);

  // if (!webId) { return; }

  if (dCodeId !== "08") {
    // console.log('special dCodeId >', JSON.stringify({ dCodeId, buff: inBuff.toString('hex') }));
    return  { dCodeId, buff: inBuff  };
  }

  const avl = {
    dLength,
    dCodeId,
    dCount,
    data: [],
  };

  const ofset = 10;
  const len = 65;

  for (let index = 0; index < avl.dCount; index += 1) {
    const pointer = ofset + index * len;
    const g = getGpsObj();

    g.id = index;
    g.buff = inBuff.slice(pointer, pointer + len);
    g.hex = bytesToHex(g.buff)  
    g.timeUnix = parseInt( bytesToHex( g.buff.slice(0, 8) ) , 16);
    g.time = new Date(g.timeUnix);
    g.lng = parseInt( bytesToHex( g.buff.slice(9, 13) ) , 16) * 0.0000001;
    g.lat = parseInt( bytesToHex( g.buff.slice(13, 17) ) , 16) * 0.0000001;
    // g.latHex = bytesToHex( g.buff.slice(13, 17)) ;
    g.satellites = parseInt( bytesToHex(g.buff.slice(21, 22)) , 16);
    g.speed = parseInt( bytesToHex(g.buff.slice(22, 24)), 16);
    g.stable = true;

    g.lat = parseFloat(g.lat.toFixed(6));
    g.lng = parseFloat(g.lng.toFixed(6));

    g.ignitionHex = bytesToHex( g.buff.slice(27, 29)) ;

    if (g.speed > 180) {
      g.stable = false;
      g.speed = 0;
    }
    if (g.satellites < 8) {
      g.stable = false;
    }
    if (!g.ignitionHex.includes("ef")) {
      g.stable = false;
    }
    if (g.ignitionHex === "ef01") {
      g.ignition = true;
    } else {
      g.ignition = false;
    }
    if (g.time.toString() === "Invalid Date") {
      g.stable = false;
    }
    if (new Date() < g.time) {
      g.stable = false;
    }

    delete g.buff;
    delete g.hex;

    avl.data.push(g);
  }

  return avl;
}

export default commandIdentifierFmb;
