

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApiHeavy + "reports2/overspeed";

export const GetOverspeedByVehicles = (userToken, vehicleIds, startTime, endTime) => {
  const criterion = { userToken, vehicleIds, startTime, endTime };
  return axios
    .post(proxyServer + "/get-by-vehicles", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

 