const mapFavoritePlace = function getMarker(deleteCallback) {
  return {
    id: -1,
    visible: false,
    infowindow: null,
    marker: null,
    infoWindowIsOpen: false,
    showEditFom: false,
    deleteCallback: deleteCallback,
    init() {
      return this;
    },
    render(id, map, maps, points, itemObject) {


      const self = this;
      this.id = id;
      this.itemObject = itemObject;

      if (!points || !map || !maps) {
        return;
      }

      const coordinates = points.split(',');
      const thePoint = {
        lat: parseFloat(coordinates[0], 10),
        lng: parseFloat(coordinates[1], 10)
      };

      // "/map-assets/school-marker.png",
      let icon = null;
      if(itemObject.type_text === 'S'){
        icon = "/map-assets/school-marker.png";
      }

      this.marker = new maps.Marker({
        map: map,
        icon,
        position: thePoint,
        label: itemObject.type_text || "",
        title: itemObject.title || "",

      });

      // this.marker.setVisible(false);

      this.position = thePoint;

      this.infowindow = new maps.InfoWindow({
        content: self.getForm(self)
      });

      maps.event.addListener(this.marker, "click", function () {
        self.toggleMapClick(map);
      });

      return this;
    },
    dismiss(self) {
      self.infoWindowIsOpen = false;
      self.infowindow.close();
    },
    toggleMapClick(map) {
      this.toggle(map);
    },
    toggle(map, zoom) {

      // this.visible = !this.visible ;

      if (this.infoWindowIsOpen) {
        this.infoWindowIsOpen = false;
        this.infowindow.close();
      } else {

        this.marker.setVisible(true);
        this.visible = true ;
        
        this.infowindow.open(map, this.marker);
        this.infoWindowIsOpen = true;

        if (map && this.position && zoom) {
          map.panTo(this.position);
          map.setZoom(15);
        }
      }


    },
    show(visibility) {

      this.visible = visibility;

      if (visibility) {
        this.marker.setVisible(true);

      } else {

        if (this.infoWindowIsOpen) {
          this.infoWindowIsOpen = false;
          this.infowindow.close();
        }

        this.marker.setVisible(false);
      }

    },
    getContext() {
      return this;
    },

    delete: function (self) {

      if (self.deleteCallback) {
        self.deleteCallback(self.itemObject.autoid);
      }

      self.dismiss(self);
      self.removeFromMap(self);

    },
    removeFromMap(self) {

      if (self.marker) {
        self.marker.setVisible(false);
        self.marker.setMap(null);
      }


    },
    getForm(self) {
      let htmlObject = document.createElement("div");
      htmlObject.setAttribute("style", "width: 200px");

      let h3 = document.createElement("h4");
      h3.innerText = "Edit Geofence";

      let div2 = document.createElement("div");


      let buttonCancel = document.createElement("button");
      buttonCancel.setAttribute("type", "button");
      buttonCancel.setAttribute("class", "mr-1 btn btn-secondary btn-xs");
      buttonCancel.innerText = "Cancel";

      buttonCancel.addEventListener("click", function () {
        self.dismiss(self);
      });



      let buttonDelete = document.createElement("button");
      buttonDelete.setAttribute("type", "button");
      buttonDelete.setAttribute("class", "mr-1 btn btn-danger btn-xs");
      buttonDelete.innerText = "Delete";

      buttonDelete.addEventListener("click", function () {
        self.delete(self);
      });

      let br = document.createElement("br");

      div2.appendChild(br);

      div2.appendChild(buttonDelete);
      div2.appendChild(buttonCancel);

      // htmlObject.appendChild(h3);
      htmlObject.appendChild(div2);

      if (this.itemObject && !this.showEditFom) {

        let htmlObjectTitle = document.createElement("div");
        // htmlObjectTitle.setAttribute("style", "width: 200px");

        //htmlObjectTitle.innerText = this.itemObject.title +', ' + this.itemObject.speed_limit ;
        htmlObjectTitle.innerText = this.itemObject.speed_limit ?
          this.itemObject.title + ', ' + this.itemObject.speed_limit : this.itemObject.title;

        let buttonEditFom = document.createElement("button");
        buttonEditFom.setAttribute("type", "button");
        buttonEditFom.setAttribute("class", " btn btn-link btn-xs");
        buttonEditFom.innerText = "Edit";

        buttonEditFom.addEventListener("click", function () {
          self.showEditFom = !self.showEditFom;

          if (self.showEditFom) {
            htmlObjectTitle.appendChild(htmlObject);
            buttonEditFom.innerText = "Close";
          } else {
            htmlObjectTitle.removeChild(htmlObject);
            buttonEditFom.innerText = "Edit";
          }
        });

        htmlObjectTitle.appendChild(buttonEditFom);

        return htmlObjectTitle;
      }

      return htmlObject;
    }
  };
};

const mapFavoritePlaceManager = {
  markers: [],
  map: null,
  maps: null,
  updateMarkers(arrayPoly, map, maps, deleteCallback) {
    this.map = map;
    this.maps = maps;

    arrayPoly.forEach(el => {
      const found = this.markers.filter(x => x.id === el.autoid);
      if (found && found.length) {
        found[0].render(el.autoid, map, maps, el.geo_code, el);
      } else {
        const marker = mapFavoritePlace(deleteCallback);
        marker.render(el.autoid, map, maps, el.geo_code, el);
        this.markers.push(marker);
      }
    });
  },
  toggle(autoid, zoom) {
    const found = this.markers.filter(x => x.id === autoid);
    if (found && found.length) {
      found[0].toggle(this.map, zoom);
    }
  },
  showAll(visibility) {

    this.markers.forEach(element => {

      element.show(visibility);

    });

  }
};

module.exports = mapFavoritePlaceManager;
