import React from "react";

const dataSeries = {
  type: "splineArea",
  showInLegend: true,
  name: "Speed",
  xValueType: "dateTime",
};

const dataSeries_speedLimit = {
  type: "line",
  showInLegend: true,
  name: "Limit",
  xValueType: "dateTime",
};

function CreateNewChart(chart_title) {
  const chart = new window.CanvasJS.Chart("chartContainerABC", {
    zoomEnabled: true,
    animationEnabled: true,
    exportEnabled: true,
    title: {
      text: chart_title,
      fontSize:20,
      fontWeight:"normal",
      fontColor:"#000"
    },
    exportFileName: chart_title,
    axisX: {
      valueFormatString: "DD-hh:mm t",
      // lebelAngle: -50,
    },
    axisY: {
      valueFormatString: "0",
    },
    data: [
      {
        // Change type to "doughnut", "line", "splineArea", etc.
        type: "splineArea",
        dataPoints: [{ x: new Date(), y: 0 }],
      },
      {
        // Change type to "doughnut", "line", "splineArea", etc.
        type: "line",
        dataPoints: [{ x: new Date(), y: 120 }],
      },
    ],
  });
  chart.render();
  return chart;
}

class CanvasJS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataPoints: [],
    };
  }

  componentWillUnmount() {
    this.chart = null;
  }

  componentDidMount() {
    this.chart = CreateNewChart(this.props.title || "new chart ");
    this.loadingValues(this.props.wayPoints);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wayPoints !== this.props.wayPoints) {
      this.loadingValues(this.props.wayPoints);
    }
    if (prevProps.title !== this.props.title) {
      this.chart = CreateNewChart(this.props.title || "new chart ");
      // this.loadingValues(this.props.wayPoints);
    }
  }

  loadingValues = (wayPoints) => {
    if (this.props.type === "fuel") {
      this.setFuelOptions(wayPoints);
    } else {
      this.setSpeedOptions(wayPoints);
    }
  };

  setFuelOptions = (wayPoints) => {
    dataSeries.name = "Fuel";
    const dataPoints = wayPoints.map((x) => {
      return {
        x: new Date(x.updatetime),
        y: x.fuel,
      };
    });

    dataSeries.dataPoints = dataPoints;

    if (dataSeries.dataPoints.length === 0) {
      dataSeries.dataPoints = [
        {
          x: new Date(),
          y: 0,
        },
      ];
    }

    this.chart.options.data = [dataSeries];

    this.chart.render();
  };

  setSpeedOptions = (wayPoints) => {
    dataSeries.name = "Speed";

    const dataPoints = wayPoints.map((x) => {
      return {
        x: new Date(x.updatetime),
        y: x.speed,
      };
    });

    const selectedVehicle = this.props.selectedVehicle || {};
    const selectedVehicleValue =
      selectedVehicle.value || this.props.selectedVehicle || {};
    const speedLimit = selectedVehicleValue.speed_limit || 120;

    const dataPointsLimit = [];
    if (dataPoints.length) {
      dataPointsLimit.push({
        x: dataPoints[0].x,
        y: parseInt(speedLimit, 10),
      });

      dataPointsLimit.push({
        x: dataPoints[dataPoints.length - 1].x || new Date(),
        y: parseInt(speedLimit, 10),
      });
    }

    dataSeries.dataPoints = dataPoints;
    dataSeries_speedLimit.dataPoints = dataPointsLimit;

    if (dataSeries.dataPoints.length === 0) {
      dataSeries.dataPoints = [
        {
          x: new Date(),
          y: 0,
        },
      ];
    }

    this.chart.options.data = [dataSeries, dataSeries_speedLimit];

    this.chart.render();
  };

  render() {
    const height = this.props.height || 400;
    return (
      <div>
        <div
          id="chartContainerABC"
          className="w-100"
          style={{ height: `${height}px`, direction: "ltr" }}
        ></div>
        <span> Records show: {this.props.wayPoints.length} </span>
      </div>
    );
  }
}

export default CanvasJS;
