import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Row, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import ReactToPrint from "react-to-print";

import { authApiAccessCheck } from "../../../redux/actions";
import { Colxx } from "../../../components/common/CustomBootstrap";

import HistoryPlaybackPrinter from "../../../components/HistoryPlaybackPrinter";

import { TrackingBasicGetByTimeRange } from "../../../api-tasks/tracking-basic";
import { IgnitionGetSummaryByVehicles } from "../../../api-tasks/ignition";
import { AppHelperDateToDBString } from "../../../helpers/AppHelper";
import moment from "moment";

class SummaryViewContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      wayPoints: [],
      startTime: null,
      startPlace: "",
      startGeo: "",
      endTime: null,
      endPlace: "",
      endGeo: "",
      viewingObject: null,
      topSpeed: {},
      avgSpeed: 0
    };
  }

  componentDidMount() {
    this.getHistoryState();
    if (!this.props.vehiclesAll.length) {
      this.setState({ loading: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.getHistoryState();
    }
  }

  getHistoryState = () => {
    try {
      if (!this.props.vehiclesAll.length) {
        return;
      }
      const queryValue = queryString.parse(this.props.location.search);

      if (queryValue && queryValue.p1 && queryValue.p2) {
        const user = this.props.user;

        const startTime = AppHelperDateToDBString(
          moment(queryValue.p2).toDate()
        );
        const endTime = AppHelperDateToDBString(moment(queryValue.p3).toDate());

        

        IgnitionGetSummaryByVehicles(
          user.userToken,
          [queryValue.p1], // vehicleIds
          startTime,
          endTime,
          1, // limit
          0 // skip
        ).then(response => {
          if (response && response.rows && Array.isArray(response.rows)) {
            const x = response.rows[0] || null;

            const vehicle =
              this.props.vehiclesAll.filter(
                z => z.autoid === parseInt(queryValue.p1, 10)
              ) || [];

            this.setState({
              vehicle: vehicle[0],
              startTime: x.first_start,
              startPlace: "",
              startGeo: "",
              endTime: x.last_end,
              endPlace: "",
              endGeo: "",
              viewingObject: x
            });

            this.loadData(x);
          }
        });
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  loadData = viewingObject => {
    const x = viewingObject || {};

    if (!x) return;

    try {
      const user = this.props.user;
      const startTime = AppHelperDateToDBString(x.first_start);
      const endTime = AppHelperDateToDBString(x.last_end);
      const vehicleId = x.vehicle_id;

      this.setState({ loading: true });

      TrackingBasicGetByTimeRange(
        user.userToken,
        vehicleId,
        startTime,
        endTime
      ).then(response => {
        this.setState({ loading: false });

        if (response && Array.isArray(response)) {
          let avgSpeedSum = 0;
          let avgSpeedCount = 0;
          let avgSpeed = 0;
          const topSpeed = response.length
            ? response.reduce(function(prev, current) {
                avgSpeedSum += current.speed > 9 ? current.speed : 0;
                avgSpeedCount += current.speed > 9 ? 1 : 0;
                return prev.speed > current.speed ? prev : current;
              })
            : {};

          try {
            avgSpeed = Math.floor(avgSpeedSum / avgSpeedCount);
          } catch (err) {}

          this.setState({ wayPoints: response, topSpeed, avgSpeed });
        }
      });
    } catch (error) {
      console.log("the exception TV81", error);
    }
  };

  render() {

    if (this.state.loading || !this.state.viewingObject) {
      return (
        <React.Fragment>
          <Row>
            <Colxx xxs="12">
              <Card>
                <CardBody>
                  {this.state.loading ? (
                    <div>
                      Please wait, It's getting ready
                      <div className="bg-info">
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      </div>
                    </div>
                  ) : (
                    `Noting to show`
                  )}
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            
            

            <ReactToPrint
              trigger={() => (
                <Button size="sm" className="mb-1">
                  Print
                </Button>
              )}
              content={() => this.componentRef}
            />
              
                <HistoryPlaybackPrinter
                  wayPoints={this.state.wayPoints}
                  vehicle={this.state.vehicle}
                  avgSpeed={this.state.avgSpeed}
                  itemObject={this.state.viewingObject}
                  ref={el => (this.componentRef = el)}
                />
                <div className="clearfix"></div>
              
            
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user } = authUser;
  return { vehiclesAll: all, user };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  withRouter(SummaryViewContainer)
);
