import React, { Fragment } from "react";
import { Row, Table, Button } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import SummaryRow from "./SummaryRow";
import CsvDownloader from "../../components/CsvDownloader";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";
const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Date", key: "summaryDayText" },
  { label: "Distance", key: "distance" },
  { label: "EnginHours", key: "enginHours" },
  { label: "IdleHours", key: "idleHours" },
  { label: "ParkingHours", key: "parkingHours" },
  { label: "First Start", key: "firstStartTime" },
  { label: "Last End", key: "lastEndTime" },
  { label: "Last End day diff", key: "dayDiff" },
  { label: "First 1Km Time", key: "first1kmText" },
  { label: "First 3Km Time", key: "first3kmText" },
  { label: "First 10Km Time", key: "first10kmText" },
];

class Summary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      summaryRows: [],
      showAll: false,
    };
  }

  componentDidMount() {
    this.setState({ summaryRows: this.props.allRows || [] });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allRows !== this.props.allRows) {
      this.setState({ summaryRows: this.props.allRows || [] });
    }
  }

  render() {
    const showRows = this.state.summaryRows || [];
    const filteredRows = this.state.showAll
      ? showRows
      : showRows.length > 50
      ? showRows.splice(0, 49)
      : showRows;
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <Row>
        <Colxx xxs="12">
          <span className="float-right">
            <CsvDownloader
              headers={headers}
              filename={"summary-ctraac.csv"}
              data={this.state.summaryRows}
            />
          </span>
        </Colxx>

        <Colxx xxs="12">
          <Table responsive striped>
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <IntlMessages id="day-summary.vehicle" />
                </th>
                <th>
                  <IntlMessages id="day-summary.date" />
                </th>
                <th>
                  <IntlMessages id="day-summary.distance" />
                </th>
                <th>
                  <IntlMessages id="day-summary.enginehrs" />
                </th>
                <th>
                  <IntlMessages id="day-summary.idlehrs" />
                </th>
                <th>
                  <IntlMessages id="day-summary.parkinghrs" />
                </th>
                <th>Trips</th>
                <th>
                  <IntlMessages id="day-summary.over-speed" />
                </th>
                <th>
                  <IntlMessages id="day-summary.first-start" />
                </th>
                <th>
                  <IntlMessages id="day-summary.last-end" />
                </th>
                <th> First1km Time </th>
                <th> First3km Time </th>
                <th> First10km Time </th>
              </tr>
            </thead>
            <tbody>
              {filteredRows.map((item, i) => (
                <Fragment key={i}>
                  <SummaryRow
                    theRow={item}
                    onShowMapModel={this.props.onShowMapModel}
                  />
                </Fragment>
              ))}
            </tbody>
          </Table>

          {showRows.length > 50 ? (
            <Button
              title={this.props.filename}
              onClick={() => {
                this.setState({ showAll: !this.state.showAll });
              }}
              size="xs"
              outline
              color="dark"
            >
              {this.state.showAll
                ? intl.formatMessage({
                    id: "day-summary.show-less",
                  })
                : `${intl.formatMessage({
                    id: "day-summary.show-all",
                  })} ${showRows.length} ${intl.formatMessage({
                    id: "day-summary.records",
                  })}`}
            </Button>
          ) : (
            ""
          )}
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};

export default connect(mapStateToProps)(Summary);
