import React, { Component, Fragment } from "react";

import {
  Badge,
  Card,
  Row,
  CardTitle,
  CardBody,
  Table,
  ButtonGroup,
  Button,
} from "reactstrap";
import Moment from "react-moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import queryString from "query-string";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";
import CustomSubmitButton from "../../components/form-controls/CustomSubmitButton";
import DropStateButton from "../../components/DropStateButton";
import StateButton from "../../components/StateButton";
import XButton from "../../components/common/XButton";

import {
  authApiAccessCheck,
  passengerSelectRequest,
} from "../../redux/actions";
import PassengerVehiclesAddByPassenger from "../../components/PassengerVehiclesAddByPassenger";
import PassengerClusters from "../../data-const/passenger-types";

import {
  DriverDelete,
  DriverVehicleRemove,
  DriverVehicleRedo,
} from "../../api-tasks/drivers";

import {
  PassengerSelectVehiclesByPassenger,
  PassengerUpdate,
} from "../../api-tasks/passenger";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";
// PassengerSelectVehiclesByPassenger

class DriverList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDriver: null,
      filteredDataRows: [],
      allDataRows: [],
      passengerId: 0,
      passengerName: "",
      passengerCode: "",
      passengerRFID: "",
      passengerCluster: "0",
      searchTex: "",
      vehiclesNotInDriver: [],
    };
  }

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return {
            title: `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`,
            device_type: foo[0].device_type,
          };
        }
      }
    }
    return {};
  };

  onSearch = (event) => {
    let searchQuery = event.target.value.toLowerCase();
    let arrData = searchQuery.split(" ");
    let tempDataRows = this.state.allDataRows;
    let filteredDataRows = this.state.allDataRows;
    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        const item = {
          ...el,
          statusText: el.statuscode === 2 ? "active" : "pending",
        };

        return (
          item.vehicleName.toLowerCase().indexOf(element) !== -1 ||
          item.statusText.indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredDataRows: filteredDataRows,
      searchTex: searchQuery,
    });
  };

  loadTable = (searchTex) => {
    const user = this.props.user;
    const passengerId = this.state.passengerId || 0;

    this.setState({ loading: true, searchTex: searchTex || "" });

    PassengerSelectVehiclesByPassenger(user.userToken, passengerId).then(
      (res) => {
        console.log("the passenger vehicles ", res);
        this.setState({ loading: false });
        if (res) {
          if (Array.isArray(res)) {
            const theRows = res.map((x) => {
              const vItem = this.findVehicleName(x);
              return {
                ...x,
                vehicleName: vItem.title,
                device_type: vItem.device_type,
              };
            });

            this.setState({ allDataRows: theRows, filteredDataRows: theRows });

            let allVehicles = this.props.vehiclesAll || [];
            let vehicleDrivers = res;
            let vehiclesNotInDriver = [];

            allVehicles = allVehicles.filter(function (value) {
              return value.device_type === 3;
            });

            if (allVehicles.length !== vehicleDrivers.length) {
              vehiclesNotInDriver = allVehicles;
              vehicleDrivers.forEach((vd) => {
                vehiclesNotInDriver = vehiclesNotInDriver.filter(function (
                  value
                ) {
                  return value.autoid !== vd.vehicle_id;
                });
              });
            } else {
              vehiclesNotInDriver = [];
            }

            this.setState({ vehiclesNotInDriver });
          } else {
            this.props.authApiAccessCheck(this.props.history, res);
          }
        }
      }
    );
  };

  componentDidMount() {
    const paramValues = queryString.parse(this.props.location.search);

    const autoid = parseInt(paramValues.param1 || 0, 10);
    const passengersAll = this.props.passengersAll || [];

    const onePerson =
      passengersAll.filter((x) => {
        return x.autoid === autoid;
      })[0] || {};

    if (!onePerson.autoid) {
      this.props.history.push("/app/settings/passenger");
    }

    this.setState({
      passengerId: onePerson.autoid,
      passengerName: onePerson.passenger_name || "",
      passengerCode: onePerson.employee_code || "",
      passengerRFID: onePerson.rfid_code || "",
      passengerCluster: onePerson.cluster || "0",
      allDrivers: this.props.driversAll || [],
    });

    setTimeout(() => {
      this.loadTable();
    }, 10);
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeDriver = (selectedDriver) => {
    this.setState({ selectedDriver });
    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ updating: true });

    const {
      passengerId,
      passengerCluster,
      passengerName,
      passengerCode,
    } = this.state;

    const user = this.props.user;
    PassengerUpdate(
      user.userToken,
      passengerId,
      passengerCluster,
      passengerName,
      passengerCode
    ).then((response) => {
      if (response) {
        this.setState({ updating: false });
        if (response) {
          if (response.status === "success") {
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
            this.props.passengerSelectRequest(this.props.history);
          } else {
            console.error("driver.update", response);
            NotificationManager.warning(
              response.message,
              response.status,
              5000,
              null,
              null,
              ""
            );
            this.props.authApiAccessCheck(this.props.history, response);
          }
        }
      }
    });
  };

  handleOnRefresh = () => {
    this.loadTable();
  };

  handleOnRemove = (item) => {
    const user = this.props.user;
    const input = {
      autoid: item.autoid,
      driver_id: item.driver_id,
      vehicle_id: item.vehicle_id,
      device_type: item.device_type,
      cancel_only: item.statuscode === 1 ? 1 : 0,
    };

    DriverVehicleRemove(user.userToken, input).then((response) => {
      if (response) {
        this.setState({ loading: false, selectedDrivers: [] });
        if (response) {
          if (response.status === "success") {
            this.loadTable();
          } else {
            NotificationManager.error(
              response.message,
              "ERROR",
              3000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  handleOnDeleteDriverClick = (itemValue) => {
    return new Promise((success, fail) => {
      try {
        const thePromises = [];
        const user = this.props.user;
        const driverId = this.state.driverId;

        this.state.allDataRows.forEach((dvItems) => {
          const input = {
            autoid: dvItems.autoid,
            driver_id: driverId,
            vehicle_id: dvItems.vehicle_id,
            device_type: dvItems.device_type,
            cancel_only: dvItems.statuscode === 1 ? 1 : 0,
          };

          thePromises.push(DriverVehicleRemove(user.userToken, input));
        });

        thePromises.push(DriverDelete(user.userToken, driverId));

        Promise.all(thePromises)
          .then((responses) => {
            success("driver deleted ");
            this.props.driverSelectRequest(this.props.history);

            setTimeout(() => {
              this.props.history.push("/app/settings/driver-settings");
            }, 1500);
          })
          .catch((err) => {
            fail("Ooh, unexpected error");
          });

        /*
        const vehicles = this.state.filteredDataRows;

        const assignedItems = vehicles.map(item => ({
          autoid: item.autoid,
          driverId: item.driver_id,
          vehicleId: item.vehicle_id,
          deviceType: item.device_type
        }));

        const user = this.props.user;
        DriverVehicleRedo(user.userToken, assignedItems).then(response => {
          if (response) {
            if (response.status === "success") {
              success(response.message);
              this.loadTable(this.state.searchTex);
            } else {
              fail(response.message);
            }
          }
        });
        */
      } catch (error) {
        fail("Ooh, unexpected error");
      }
    });
  };

  handleOnRedoListedClick = (item) => {
    return new Promise((success, fail) => {
      if (!this.state.filteredDataRows.length) {
        fail("Hi, nothing to redo");
        return;
      }

      try {
        const vehicles = this.state.filteredDataRows;

        const assignedItems = vehicles.map((item) => ({
          autoid: item.autoid,
          driverId: item.driver_id,
          vehicleId: item.vehicle_id,
          deviceType: item.device_type,
        }));

        const user = this.props.user;
        DriverVehicleRedo(user.userToken, assignedItems).then((response) => {
          if (response) {
            if (response.status === "success") {
              success(response.message);
              this.loadTable(this.state.searchTex);
            } else {
              fail(response.message);
            }
          }
        });
      } catch (error) {
        fail("Ooh, unexpected error");
      }
    });
  };

  handleOnClickRedoItem = (item) => {
    return new Promise((success, fail) => {
      try {
        const assignedItems = [
          {
            autoid: item.autoid,
            driverId: item.driver_id,
            vehicleId: item.vehicle_id,
            deviceType: item.device_type,
          },
        ];

        const user = this.props.user;
        DriverVehicleRedo(user.userToken, assignedItems).then((response) => {
          if (response) {
            if (response.status === "success") {
              success(response.message);
              this.loadTable(this.state.searchTex);
            } else {
              fail(response.message);
            }
          }
        });
      } catch (error) {
        fail("Ooh, unexpected error");
      }
    });
  };

  render() {
    // console.log('searchTex ',this.state.searchTex)
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <Fragment>
        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <h1>
                  <IntlMessages id="passenger.passenger-view" />{" "}
                </h1>
                <Row>
                  <Colxx xxs="12">
                    <form className="form" onSubmit={this.handleSubmit}>
                      <div className="form-body">
                        <Row>
                          <Colxx xl="3" lg="6" md="12">
                            <div className="form-group">
                              <label className="title">
                                <IntlMessages id="passenger.clusters" />
                              </label>
                              <select
                                className="form-control"
                                name="passengerCluster"
                                onChange={this.handleChange}
                                value={this.state.passengerCluster}
                              >
                                {PassengerClusters.map((item, i) => (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    label={item.title}
                                  />
                                ))}
                              </select>
                            </div>
                          </Colxx>
                          <Colxx xl="5" lg="6" md="12">
                            <div className="form-group">
                              <label className="title">
                                <IntlMessages id="passenger.passenger-name" />
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="passengerName"
                                required
                                onChange={this.handleChange}
                                value={this.state.passengerName}
                              />
                            </div>
                          </Colxx>
                          <Colxx xl="2" lg="6" md="12">
                            <div className="form-group">
                              <label className="title">
                                <IntlMessages id="passenger.passenger-code" />
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="passengerCode"
                                required
                                onChange={this.handleChange}
                                value={this.state.passengerCode}
                              />
                            </div>
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xl="4" lg="6" md="12">
                            <CustomSubmitButton
                              updating={this.state.updating}
                              text={intl.formatMessage({
                                id: "common.update",
                              })}
                            />

                            <DropStateButton
                              id={`redo_listed_driver_vehicles`}
                              color="danger"
                              className="btn ml-1 d-none"
                              title={intl.formatMessage({
                                id: "passenger.re-apply",
                              })}
                              onClick={this.handleOnDeleteDriverClick}
                              confirmText={intl.formatMessage({
                                id: "passenger.confirm",
                              })}
                            >
                              <IntlMessages id="passenger.delete" />
                            </DropStateButton>
                          </Colxx>
                        </Row>
                      </div>
                    </form>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="passenger.vehicles-of" />{" "}
                  {this.state.passengerName} {this.state.passengerCode}
                </CardTitle>
                <Row>
                  <div className="w-100">
                    <Colxx xl="6" lg="6" md="12" sm="12">
                      <PassengerVehiclesAddByPassenger
                        options={this.state.vehiclesNotInDriver}
                        onRefresh={this.handleOnRefresh}
                        passenger={{ passengerId: this.state.passengerId }}
                        user={this.props.user}
                      />
                      <small>
                        {intl.formatMessage({
                          id: "passenger.vehicles-to",
                        })}{" "}
                        {this.state.vehiclesNotInDriver.length}
                      </small>
                    </Colxx>
                  </div>
                </Row>
                <Row className="mt-2">
                  <Colxx xxs="12">
                    <div className="search">
                      <div className="float-right">
                        <ButtonGroup className="flex-wrap">
                          <input
                            className="input2 w-50"
                            type="text"
                            onChange={this.onSearch}
                            placeholder={intl.formatMessage({
                              id: "passenger.search-vehicles",
                            })}
                          />
                          <Button
                            color="secondary"
                            size="sm"
                            onClick={this.loadTable}
                          >
                            {this.state.loading ? (
                              <span className="spinner d-inline-block">
                                <span className="bounce1" />
                                <span className="bounce2" />
                                <span className="bounce3" />
                              </span>
                            ) : (
                              <IntlMessages id="common.refresh" />
                            )}
                          </Button>
                          <DropStateButton
                            id={`redo_listed_driver_vehicles`}
                            color="secondary"
                            className="btn d-none"
                            size="sm"
                            title={intl.formatMessage({
                              id: "passenger.re-apply-drivers",
                            })}
                            onClick={this.handleOnRedoListedClick}
                            confirmText={`${intl.formatMessage({
                              id: "confirm-passenger",
                            })} ${
                              this.state.filteredDataRows.length
                            } ${intl.formatMessage({
                              id: "passenger.passengers-?",
                            })}`}
                          >
                            <IntlMessages id="common.redo-list" />
                          </DropStateButton>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Colxx>
                </Row>
                <div className="card-body-min-height">
                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <IntlMessages id="passenger.vehicles" />
                        </th>

                        <th>
                          <IntlMessages id="passenger.status" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredDataRows.map((item, i) => (
                        <tr key={item.autoid}>
                          <th scope="row">{i + 1}</th>
                          <td>{item.vehicleName}</td>

                          <td>
                            <h6 className="d-inline pb-1">
                              <Badge
                                color={
                                  item.statuscode === 2
                                    ? "success"
                                    : "secondary"
                                }
                                className="mb-1 mr-1"
                              >
                                {item.statuscode === 2 ? "Active" : "Pending"}
                              </Badge>

                              <Badge
                                color="info"
                                className="mb-1 mr-1"
                                title={item.updatetime}
                              >
                                <Moment fromNow>{item.updatetime}</Moment>
                              </Badge>
                            </h6>
                            <div className="driver-td-action float-right">
                              <ButtonGroup className="float-right d-none">
                                <StateButton
                                  key={item.autoid}
                                  id={`redo${item.autoid}`}
                                  size="xs"
                                  color="secondary"
                                  title={intl.formatMessage({
                                    id: "passenger.re-apply-passengers",
                                  })}
                                  onClick={this.handleOnClickRedoItem}
                                  value={item}
                                >
                                  <IntlMessages id="common.redo" />
                                </StateButton>

                                <XButton
                                  onAction={this.handleOnRemove}
                                  value={item}
                                  title={intl.formatMessage({
                                    id: "passenger.remove",
                                  })}
                                />
                              </ButtonGroup>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, passengers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = passengers;
  const { locale } = settings;
  return { vehiclesAll: all, user, passengersAll: items, locale };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
  passengerSelectRequest,
})(withRouter(DriverList));
