import React, { Component } from "react";
import { Button, Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";

import { Colxx } from "../../components/common/CustomBootstrap";
import { authApiAccessCheck } from "../../redux/actions";
import DateRangePicker from "../../components/DateRangePicker";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { NotificationManager } from "../../components/common/react-notifications";
import { AppHelperDateToDBString } from "../../helpers/AppHelper";
import { VehiclesGetDaySummary } from "../../api-tasks-analytics/vehicles";
import IntlMessages from "../../helpers/IntlMessages";
import GeofenceHistoryMap from "./geofence-history-map";
import GeofenceSelect from "../../components/GeofenceSelect";

class GeofenceAnalysisContainer extends Component {
  constructor() {
    super();

    this.state = {
      selectedVehicles: [],
      loading: false,
      displayVehicles: [],
      allRows: [],
      isDataLoaded: false,
      selectedGeofence: {}
    };
  }

  componentWillUnmount() {}

  componentDidMount() {}

  componentDidUpdate() {}

  onDateRangeGo = () => {
    if (!this.state.selectedVehicles || !this.state.selectedVehicles.length) {
      NotificationManager.error(
        "Please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    const offsetMinutes = null;
    const pathWay = true;

    const displayVehicles = this.state.selectedVehicles.map((el) => {
      return {
        item: el,
        historyCall: VehiclesGetDaySummary(
          user.userToken,
          [el.key],
          startTime,
          endTime,
          offsetMinutes,
          pathWay
        ),
        historyCallStatus: "pending",
        historyCallResponse: null,
      };
    });

    this.setState({ displayVehicles, isDataLoaded: true });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      allRows: [],
      isDataLoaded: false 
    });
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
      allRows: [],
      isDataLoaded: false
    });
  };


  handleOnSelectedGeofenceChange = (selectedGeofence) => {
    this.setState({ selectedGeofence: selectedGeofence });
    // console.log("selectedGeofence ", selectedGeofence);

    if(selectedGeofence.key > 0 && !this.state.isDataLoaded){
      this.onDateRangeGo();
    }

    /*
    const fences =
      selectedGeofences && selectedGeofences.length
        ? selectedGeofences
        : this.props.geofenceAll;
    this.setState({ selectedGeofences: fences || [] });

    setTimeout(() => {
      this.onDateRangeGo();
    }, 10);
    */
  };

  render() {
    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;

    if (userRole === 3) {
      return (
        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Row>
                  <Colxx xxs="12">
                    <div className="alert alert-info" role="alert">
                      <IntlMessages id="group.user-access" />
                    </div>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      );
    }

    return (
      <React.Fragment>
        <div className="rounded alert alert-info fade show" role="alert">
          This is an experimental features, There are chances of consuming 1000s
          of MB and intensive system resorces.
        </div>

       

        <Row className="mb-1" key="filter">
          <Colxx xxs="12">
            <Card className={""}>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row className="mb-1">
                  <Colxx xxs="12">
                    <VehicleSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                      dateOnly={true}
                      defaultSelectAll={true}
                    />
                  </Colxx>
                </Row>
                <Row className="mb-1">
                  <Colxx xl="8" lg="8" md="8" sm="12" className="pl-3">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      startTime={moment().startOf("day")}
                    />
                  </Colxx>
                  
                
                  <Colxx xl="6" lg="6" md="12">
                    <GeofenceSelect
                      onChange={this.handleOnSelectedGeofenceChange}
                      singleSelect={true}
                    />
                  </Colxx>
                  <Colxx xl="2" lg="2" md="2" sm="12">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="tracking.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row className="mb-1" key="map">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <GeofenceHistoryMap
                  displayVehicles={this.state.displayVehicles}
                  startTime={this.state.startTime}
                  endTime={this.state.endTime}
                  selectedGeofence={this.state.selectedGeofence}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, settings }) => {
  const { all } = vehicles;
  const { locale } = settings;
  const { user, details } = authUser;

  return {
    vehiclesAll: all,
    user,
    locale,
    userDetails: details
  };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
})(GeofenceAnalysisContainer);
