import React from "react";
import { Button, ButtonGroup, Card, CardBody } from "reactstrap";
import IntlMessages from "../helpers/IntlMessages";
import DriverSelect from "./DriverSelect";
import { NotificationManager } from "./common/react-notifications";
import { DriverVehicleAdd } from "../api-tasks/drivers";
import AppLocale from "../lang";
import { createIntl } from "react-intl";
import { connect } from "react-redux";

class DriverVehiclesAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoSaveRemainingSec: 7,
      loading: false,
      selectedDrivers: []
    };

    this.autoSaveTimer = null;
  }

  autoSaveTimerRestart = start => {
    this.setState({ autoSaveRemainingSec: 7 });
    if (this.autoSaveTimer) {
      clearInterval(this.autoSaveTimer);
    }

    if (start) {
      this.autoSaveTimer = setInterval(this.autoSaveCountDown, 1000);
    }
  };

  autoSaveCountDown = () => {
    const seconds = this.state.autoSaveRemainingSec - 1;
    this.setState({ autoSaveRemainingSec: seconds });

    if (seconds === 0) {
      if (this.autoSaveTimer) {
        clearInterval(this.autoSaveTimer);
      }
      this.onAddClick();
    }
  };

  onAddClick = () => {
    this.autoSaveTimerRestart(0);
    const drivers = this.state.selectedDrivers;

    if (!drivers.length) {
      NotificationManager.error(
        <IntlMessages id="driver.assign-driver" />,
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    if (!this.props.vehicle) {
      NotificationManager.error(
        <IntlMessages id="driver.assign-vehicle" />,
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    this.setState({ loading: true });
    const vehicle = this.props.vehicle;
    const assignedItems = drivers.map(item => ({
      driverId: item.value,
      vehicleId: vehicle.autoid,
      deviceType: vehicle.device_type
    }));

    const user = this.props.user;
    DriverVehicleAdd(user.userToken, assignedItems).then(response => {
      if (response) {
        this.setState({ loading: false, selectedDrivers: [] });
        if (response) {
          if (response.status === "success") {
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
            if (this.props.onRefresh) {
              this.props.onRefresh();
            }
          } else {
            console.error("driver.vehicle.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              3000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  onCancel = () => {
    this.setState({ selectedDrivers: [] });
    this.autoSaveTimerRestart(0);
  };

  handleOnDriverSelectChange = selectedDrivers => {
    this.setState({ selectedDrivers });
    this.autoSaveTimerRestart(selectedDrivers.length);
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    let selectedText = intl.formatMessage({
      id: "vehicle.select-driver",
    });
    const count = this.state.selectedDrivers.length;
    if (count) {
      selectedText =
      intl.formatMessage({
        id: "driver.select-assign",
      }) + (count > 1 ? `${count} ${intl.formatMessage({
        id: "driver.drivers",
      })}` : `${intl.formatMessage({
        id: "driver.driver",
      })}`) +intl.formatMessage({
        id: "driver.to-vehicle",
      }) ;
    }

    return (
      <Card>
        <CardBody>
          {count && !this.state.loading ? (
            <span className="float-right">
              auto save in {this.state.autoSaveRemainingSec}s
            </span>
          ) : (
            ""
          )}

          <h6> {selectedText}</h6>
          {count ? (
            <ButtonGroup className="flex-wrap float-right">
              <Button color="warning" size="xs" onClick={this.onCancel}>
              <IntlMessages id="common.cancel" />
              </Button>
              <Button color="primary" size="xs" onClick={this.onAddClick}>
                {this.state.loading ? (
                  <span className="spinner d-inline-block">
                    <span className="bounce1" />
                    <span className="bounce2" />
                    <span className="bounce3" />
                  </span>
                ) : (
                  <IntlMessages id="common.save" />
                )}
              </Button>
            </ButtonGroup>
          ) : (
            ""
          )}
          <DriverSelect
            placeholder={intl.formatMessage({
              id: "driver.select-vehicle-assign",
            })}
            onChange={this.handleOnDriverSelectChange}
            options={this.props.options}
            user={this.props.user}
            value={this.state.selectedDrivers}
          />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};

export default connect(mapStateToProps)(DriverVehiclesAdd);

