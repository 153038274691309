import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { Row } from "reactstrap";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import LiveMap from "../../../containers/dashboards/live-map";


class DashboardAnalytics extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.live" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <LiveMap />
      </Fragment>
    );
  }
}
export default injectIntl(DashboardAnalytics);
