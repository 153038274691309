import React from "react";

const LabelWithIcon = (text, path) => {
  return (
    <div>
      <img className="app-select-icon" src={path} alt="icon"/> {text}
    </div>
  );
};

export default LabelWithIcon;
