import React, { Component } from "react";
import {
  Row,
  Card,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import { NavLink } from "react-router-dom";

import classnames from "classnames";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ListTab from "./group-tab";
import GroupViewTab from "./group-view";
import IntlMessages from "../../helpers/IntlMessages";


class SettingsTab extends Component {
  constructor(props) {
    super(props);

    this.toggleSecondTab = this.toggleSecondTab.bind(this);
    this.state = {
      activeSecondTab: "1"
    };
  }

  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab
      });
    }
  }
  render() {
    return (
      <Row>
        <Colxx xxs="12">
          <Card className="mb-4">
            <CardHeader className="pl-0 pr-0">
              <Nav tabs className=" card-header-tabs  ml-0 mr-0">
                <NavItem className="w-50 text-center">
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "1",
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggleSecondTab("1");
                    }}
                    to="#"
                  >
                    <h6><IntlMessages id="group.tab-group-vehilces" /> </h6>
                  </NavLink>
                </NavItem>
                <NavItem className="w-50 text-center">
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "2",
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggleSecondTab("2");
                    }}
                    to="#"
                  >
                    <h6> <IntlMessages id="group.tab-group-list" /> </h6>
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <Separator  />
            <TabContent activeTab={this.state.activeSecondTab}>
              <TabPane tabId="1">
                
                <GroupViewTab history={this.props.history} />
                
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Colxx xxs="12">
                    <ListTab history={this.props.history} />
                  </Colxx>
                </Row>
              </TabPane>
            </TabContent>
          </Card>
        </Colxx>
      </Row>
    );
  }
}

export default SettingsTab;
