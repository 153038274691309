const data = [
  {
    id:0,
    image: "/assets/img/carousel-1.jpg",
    order: "1",
    title: "Cheesecake",
    rate: 5,
    rateCount: "48"
  },
  {
    id:1,
    image: "/assets/img/carousel-2.jpg",
    order: "2",
    title: "Chocolate Cake",
    rate: 4,
    rateCount: "24"
  },
  {
    id:2,
    image: "/assets/img/carousel-3.jpg",
    order: "3",
    title: "Cremeschnitte",
    rate: 4,
    rateCount: "18"
  }
];
export default data;
