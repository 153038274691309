import React from "react";
import { Button } from "reactstrap";

class LocationName extends React.Component {
  // onClick
  onClickEdit = () => {
    console.log("edit mode ");
  };
  render() {
    const geo = this.props.geo || "";
    const onClick = this.props.onClick;

    let place = this.props.place;
    place = place && place !== ", " ? place : geo;

    if (onClick) {
      return (
        <span className="geo">
          <Button color="link" onClick={onClick} className="ml-1 mr-1 p-0">
            {place}
          </Button>
          {geo ? (
            <a
              title="Show on google map"
              rel="noopener noreferrer"
              target="_blank"
              href={`https://www.google.com/maps/place/${geo}`}
            >
              <i className="simple-icon-social-google"></i>
            </a>
          ) : (
            ""
          )}
        </span>
      );
    } else {
      return (
        <span className="geo">
          {place}
          {geo ? (
            <a
              className={this.props.showGoogle ? "" : "g-link"}
              title="Show on google map"
              rel="noopener noreferrer"
              target="_blank"
              href={`https://www.google.com/maps/place/${geo}`}
            >
              <i className="simple-icon-social-google"></i>
            </a>
          ) : (
            ""
          )}
        </span>
      );
    }
  }
}

export default LocationName;
