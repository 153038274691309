import { STORY_SELECT_MORE_REQUEST, STORY_SELECT_MORE_SUCCESS, STORY_SELECT_MORE_NO_RECORDS } from "../actions";

const INIT_STATE = {
    loading: false,
    noMoreRecords: false,
    ignitionStory: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORY_SELECT_MORE_REQUEST:
      return { ...state, loading: true };
      
    case STORY_SELECT_MORE_NO_RECORDS:
      return { ...state, noMoreRecords: true };

    case STORY_SELECT_MORE_SUCCESS:
      return { ...state, ignitionStory: action.payload.newStory, loading: false, noMoreRecords: false };
      
    default:
      return { ...state };
  }
};
