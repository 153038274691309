import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Button, Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import { authApiAccessCheck } from "../../redux/actions";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";

import { createIntl } from "react-intl";

class VehiclesContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedVehicle: null,
      loading: false,
      filteredRows: [],
      allRows: [],
      searchText: "",
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    this.setState({
      allRows: this.props.vehiclesAll,
      filteredRows: this.props.vehiclesAll,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.setState({
        allRows: this.props.vehiclesAll,
        filteredRows: this.props.vehiclesAll,
        searchText: "",
      });
    }
  }

  searchHandler = (event) => {
    let searcjQery = event.target.value.toLowerCase();
    let arrData = searcjQery.split(" ");
    let tempDataRows = this.state.allRows;
    let filteredDataRows = this.state.allRows;
    console.log("give four ", tempDataRows);
    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        return (
          el.title.toLowerCase().indexOf(element) !== -1 ||
          el.plate_number.toLowerCase().indexOf(element) !== -1 ||
          el.fleet_number.toLowerCase().indexOf(element) !== -1 ||
          el.project.toLowerCase().indexOf(element) !== -1 ||
          el.manufacture_name.toLowerCase().indexOf(element) !== -1 ||
          el.year.toLowerCase().indexOf(element) !== -1 ||
          el.model.toLowerCase().indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredRows: filteredDataRows,
      searchText: searcjQery,
    });
  };

  formatedPushButton = (itemObject, buttonText, path) => {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <Button
            size="xs"
            color="link"
            onClick={() => {
              history.push({
                pathname: path,
                state: { itemObject: itemObject },
              });
            }}
          >
            <IntlMessages id={buttonText} />
          </Button>
        )}
      />
    );
    return <ButtonLive />;
  };

  render() {
    const vehicleList = this.state.filteredRows || [];
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });

    window.localsss = intl;
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <Card>
              <div className="position-absolute card-top-buttons">
                <input
                  type="text"
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: "tracking.search-vehicles",
                  })}
                  value={this.state.searchText}
                  onChange={this.searchHandler}
                />
              </div>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="tracking.vehicle-list" />
                </CardTitle>
                <small>
                  {vehicleList.length} <IntlMessages id="common.records-showing" />
                </small>
                <div className="card-body-min-height">
                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <IntlMessages id="tracking.web-id" />
                        </th>
                        <th>
                          <IntlMessages id="tracking.type" />
                        </th>
                        <th>
                          <IntlMessages id="tracking.plate" />
                        </th>
                        <th>
                          <IntlMessages id="tracking.asset" />
                        </th>
                        <th>
                          <IntlMessages id="tracking.cluster" />
                        </th>
                        <th>
                          <IntlMessages id="tracking.model" />
                        </th>
                        <th>
                          <IntlMessages id="tracking.year" />
                        </th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicleList.map((item, i) => (
                        <tr key={i + 1}>
                          <th scope="row">{i + 1}</th>
                          <td>{item.autoid}</td>
                          <td>
                            <img
                              title={item.manufacture_name}
                              className="app-select-icon"
                              src={item.manufactureIcon}
                              alt="icon"
                            />
                            <img
                              title={item.typeText}
                              className="app-select-icon"
                              src={item.typeIcon}
                              alt="icon"
                            />
                          </td>

                          <td>{item.plate_number}</td>
                          <td>{item.fleet_number}</td>

                          <td>{item.project}</td>
                          <td>{item.model}</td>
                          <td>{item.year}</td>
                          <td>
                            {this.formatedPushButton(
                              item,
                              "common.view",
                              "vehicle-view"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { locale } = settings;
  return { vehiclesAll: all, user, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  VehiclesContainer
);
