import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";

import { storySelectMoreSuccess, storySelectMoreNoRecords } from "./actions";
import { authApiAccessCheck } from "../auth/actions";

import { IgnitionSelectNewBySubscriber } from "../../api-tasks/ignition";
import { STORY_SELECT_MORE_REQUEST } from "../actions";

function* selectMore({ payload }) {
  const state = yield select();
  const { history } = payload;
  const skipRecords = payload.skipRecords || 0;
  const vehicleId = payload.vehicleId || 0;
  try {
    const authUser = state.authUser;
    const user = authUser.user;

    const story = state.story;    
    const ignitionStory = story.ignitionStory || [];

    const resultRow = yield call(
      IgnitionSelectNewBySubscriber,
      user.userToken,
      skipRecords,
      vehicleId
    );

    if (resultRow) {
      if (Array.isArray(resultRow)) {
        if (resultRow.length) {
          const newStory = skipRecords ? ignitionStory.concat(resultRow) : resultRow;
          yield put(storySelectMoreSuccess(newStory));
        } else {
          yield put(storySelectMoreNoRecords());
        }
      } else {
        yield put(authApiAccessCheck(history, resultRow));
      }
    }
  } catch (error) {
    console.log("error story saga 1 :", error);
  }
}

export function* watchSelectAllRequest() {
  yield takeEvery(STORY_SELECT_MORE_REQUEST, selectMore);
}

export default function* rootSaga() {
  yield all([fork(watchSelectAllRequest)]);
}
