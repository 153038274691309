import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Row } from "reactstrap";

import moment from "moment";
import MonthPicker from "../../components/MonthPicker";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectBatch from "../../components/VehicleSelectBatch";

import { IgnitionGetSummaryGroupByDate } from "../../api-tasks/ignition";
import { AppHelperDateToDBString } from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";

const options = {
  chart: {
    zoom: {
      enabled: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    width: [1],
    curve: "straight"
  },
  xaxis: {
    type: "datetime"
  },
  yaxis: {
    min: 0
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: function(y) {
        if (typeof y !== "undefined") {
          return y + " kmph";
        }
        return y;
      }
    }
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5
    }
  }
};

const series = [];

const getSecToHours = seconds => {
  try {
    if (!seconds) {
      return 0;
    }
    const h = Math.floor(seconds / 3600);
    // const m = Math.floor((seconds % 3600) / 60);

    return h;
  } catch (error) {
    return 0;
  }
};

const getDateLabel = theValue => {
  try {
    const a = new Date(theValue);
    return moment(a).format("MMMD");
  } catch (error) {
    return "";
  }
};

const getSpeed = (mileage, enginHours, idleHours) => {
  try {
    const h = mileage / (enginHours - idleHours) || 1;

    if (isFinite(h) && h < 190) {
      return Math.floor(Math.abs(h));
    } else {
      return 40;
    }
  } catch (error) {
    return 0;
  }
};

class ColumnChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      options,
      series,
      selectedVehicles: [],
      startTime: moment().startOf("month"),
      endTime: moment(new Date()),

      pointVehicleUsed: null
    };
  }

  loadMasters = () => {
    this.setState({ loading: true });
    const user = this.props.user;
    const vehicles = this.state.selectedVehicles || [];
    // const vehicles = this.props.vehiclesAll || [];

    if (!vehicles.length) {
      return;
    }

    const vehicleIds = vehicles.map(el => {
      return el.autoid;
    });

    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    IgnitionGetSummaryGroupByDate(
      user.userToken,
      vehicleIds,
      startTime,
      endTime,
      180
    ).then(res => {
      this.setState({ loading: false });
      if (res) {
        if (Array.isArray(res)) {
          const dataKm = res.map(x => {
            return {
              x: getDateLabel(x.dated),
              y: getSpeed(
                parseInt(x.mileage_sum, 10),
                getSecToHours(x.totalduration_sec_sum),
                getSecToHours(x.idl_sec_sum)
              )
            };
          });

           
          const series = [{ name: "Avg.speed", data: dataKm }];

          this.setState({ series });
        } else {
          // this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  componentDidMount() {
    this.setState({ selectedVehicles: this.props.vehiclesAll || [] });

    setTimeout(() => {
      this.loadMasters();
    }, 10);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.setState({ selectedVehicles: this.props.vehiclesAll || [] });

      setTimeout(() => {
        this.loadMasters();
      }, 10);
    }
  }

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime
      // series: []
    });

    setTimeout(() => {
      this.loadMasters();
    }, 10);
  };

  handleOnSelectedVehicleChange = selectedVehicles => {
    this.setState({ selectedVehicles });

    setTimeout(() => {
      this.loadMasters();
    }, 10);
  };

  render() {
    return (
      <Card>
        <CardBody>
          <h5 className="d-inline-block">
          <IntlMessages id="dashboard.avg.speed" />
            <span className="text-muted mr-1">
              <small> {this.state.startTime.format("MMMM YYYY")}</small>
            </span>
            {this.state.loading ? (
              <span className="bg-info">
                <span className="spinner d-inline-block">
                  <span className="bounce1" />
                  <span className="bounce2" />
                  <span className="bounce3" />
                </span>
              </span>
            ) : (
              ""
            )}
          </h5>

          <Row>
            <div className="w-100">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="area"
                height="316"
                id="average-speed-by-day-apex"
                name="average-speed-by-day-apex"
              />

              <div className="clearfix"></div>
            </div>
          </Row>
          <Row>
            <Colxx xxs="12">
              <div className="w-100">
                <MonthPicker
                  onChange={this.handleChangeDateRange}
                  startTime={this.state.startTime}
                  endTime={this.state.endTime}
                />{" "}
                <VehicleSelectBatch
                  name="vehicle Batch"
                  key={999}
                  onChange={this.handleOnSelectedVehicleChange}
                />
              </div>
            </Colxx>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default ColumnChart;
