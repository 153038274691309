import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import dataListPages from './data-list';
import thumbListPages from './thumb-list';
import imageListPages from './image-list';
import detailsPages from './details';
import searchPages from './search';
import mailingPages from './mailing';
import invoicePages from './invoice';

import vehiclesPages from './vehicles';
import vehicleViewPages from './vehicle-view';
import liveMapPages from './live-map';
import historyPages from './history';
import multipleHistoryPages from './multiple-history';

import geofenceHistoryPages from './geofence-history';
import geofenceAnalysisPages from './geofence-analysis';

import tripsPages from './trips';
import errandPages from './errands';
import tripViewPages from './trip-view';
import speedChartPages from './speed-chart';
import fuelChartPages from './fuel-chart';
import speedChartPages2 from './speed-chart-2';
import mapSearchPages from './map-search';

const Pages = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/live-map`} />

            <Route path={`${match.url}/vehicles`} component={vehiclesPages} />
            <Route path={`${match.url}/vehicle-view`} component={vehicleViewPages} />
            <Route path={`${match.url}/live-map`} component={liveMapPages} />
            <Route path={`${match.url}/history`} component={historyPages} />
            <Route path={`${match.url}/multiple-history`} component={multipleHistoryPages} />
            <Route path={`${match.url}/geofence-history`} component={geofenceHistoryPages} />
            <Route path={`${match.url}/geofence-analysis`} component={geofenceAnalysisPages} />

            <Route path={`${match.url}/trips`} component={tripsPages} />
            <Route path={`${match.url}/errands`} component={errandPages} />
            <Route path={`${match.url}/trip-view`} component={tripViewPages} />
            <Route path={`${match.url}/speed-chart`} component={speedChartPages} />
            <Route path={`${match.url}/second-speed-chart`} component={speedChartPages2} />
            <Route path={`${match.url}/fuel-chart`} component={fuelChartPages} />
            <Route path={`${match.url}/map-search`} component={mapSearchPages} />

            <Route path={`${match.url}/data-list`} component={dataListPages} />
            <Route path={`${match.url}/thumb-list`} component={thumbListPages} />
            <Route path={`${match.url}/image-list`} component={imageListPages} />
            <Route path={`${match.url}/details`} component={detailsPages} />
            <Route path={`${match.url}/search`} component={searchPages} />
            <Route path={`${match.url}/mailing`} component={mailingPages} />
            <Route path={`${match.url}/invoice`} component={invoicePages} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Pages;