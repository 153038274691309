import React from "react";
import { CSVLink } from 'react-csv';
import { Button } from "reactstrap";
import IntlMessages from "../helpers/IntlMessages";


class CsvDownloader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            name: this.props.filename || 'data',
            headers: []
        };
        this.csvLink = React.createRef();
    }


    fetchData = () => {

        const data  = this.props.onCallBack ? this.props.onCallBack() : this.props.data ;


        this.setState({
            data: data || [],
            headers: this.props.headers || [],
            filename: this.props.filename || 'data.csv'
        }, () => {

            if (this.props.headers.length) {
                setTimeout(() => {
                    this.csvLink.current.link.click();
                }, 7);
            }

        })
    }

    render() {
        return (
            <React.Fragment>
                <Button title={this.props.filename}
                    onClick={this.fetchData}
                    size="xs" outline color="dark">
                     <IntlMessages id="common.download" />
                      </Button>


                {this.state.headers.length ? (
                    <CSVLink
                        headers={this.state.headers}
                        data={this.state.data}

                        filename={this.state.filename}
                        className="hidden"
                        ref={this.csvLink}
                        target="_blank"
                    />
                ) : ""}


            </React.Fragment>
        )
    }
}
export default CsvDownloader;