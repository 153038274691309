import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row } from "reactstrap";

import { Colxx } from "../../components/common/CustomBootstrap";
import { authApiAccessCheck } from "../../redux/actions";
import AlertSmallLineCharts from "./AlertSmallLineCharts";
import ColumnChart from "../../components/apexcharts/ColumnChart";
import AverageSpeedByDay from "../../components/apexcharts/AverageSpeedByDay";

import { DashboardSelectLast7WeekAlertCount } from "../../api-tasks/dashboard";

import GeofenceSummary from "./GeofenceSummary";
import Top10Alerts from "../../containers/dashboards/Top10Alerts";

class EfficiencyContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertsWeeklyCount: [],
    };
 
  }

  componentWillUnmount() { }

  componentDidMount() {
  

    const user = this.props.user;
    DashboardSelectLast7WeekAlertCount(user.userToken).then(res => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ alertsWeeklyCount: res });
        } else {
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  }

  

  render() {
    return (
      <Fragment>
        
        <Row>
          <Colxx xl="8" lg="8" md="12" className="mb-4">
            <ColumnChart
              user={this.props.user}
              vehiclesAll={this.props.vehiclesAll}
            />
          </Colxx>
          <Colxx xl="4" lg="4" md="12" className="mb-4">
            <AverageSpeedByDay
              user={this.props.user}
              vehiclesAll={this.props.vehiclesAll}
            />
          </Colxx>
        </Row>

        <Row>
          <Colxx xl="4" lg="4" md="12" className="mb-4">
          
             <Top10Alerts user={this.props.user}
              vehiclesAll={this.props.vehiclesAll}
              driversAll={this.props.driversAll} />
              
          </Colxx>

          <Colxx xl="8" lg="8" md="12" className="mb-4">
            <AlertSmallLineCharts
              alertsWeeklyCount={this.state.alertsWeeklyCount}
            />
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12" className="mb-4">
            <GeofenceSummary
              user={this.props.user}
              vehiclesAll={this.props.vehiclesAll}
              geofenceAll={this.props.geofenceAll}
              offsetMinutes={this.props.offsetMinutes}
            />
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, geofence, drivers }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { offsetMinutes } = authUser;
  const { items } = geofence;
  const driversAll = drivers.items || [];
  return { vehiclesAll: all, user, offsetMinutes, geofenceAll: items, driversAll };
};

export default connect(
  mapStateToProps,
  { authApiAccessCheck }
)(EfficiencyContainer);
