import React from "react";

import Select from "react-select";
import { connect } from "react-redux";

import CustomSelectInput from "../components/common/CustomSelectInput";
import IntlMessages from "../helpers/IntlMessages";

const customFilterOption = (option, rawInput) => {
  if (option && rawInput) {
    const words = rawInput.split(" ");
    return words.reduce(
      (acc, cur) =>
        acc &&
        option.data.searchWords.toLowerCase().includes(cur.toLowerCase()),
      true
    );
  } else {
    return true;
  }
};

function connectedStatus(updateTime, noDataTime, reminigDays) {
  if (reminigDays < 0) {
    return "Expired";
  }
  if (!updateTime) return "-";
  if (new Date() - new Date(updateTime) < noDataTime) return "Connected";
  return "No data";
}

const LabelWithIcon = (vehicle) => {
  const noDataMin = vehicle.hasOwnProperty("attributes.liveMap.noDataMin")
    ? vehicle.attributes.liveMap.noDataMin
    : 2880;

  const noDataInMillisecond = noDataMin * 60 * 1000;

  const trackingUpdateTime = vehicle.tracking_last_time;
 
  return (
    <div key={vehicle.key}>
      <img
        title={vehicle.manufacture_name}
        className="app-select-icon"
        src={vehicle.manufactureIcon}
        alt="icon"
      />
      <img
        title={vehicle.typeText}
        className="app-select-icon"
        src={vehicle.typeIcon}
        alt="icon"
      />
      <strong title="Plate">{vehicle.plate_number}</strong>{" "}
      <strong title="Asset No">{vehicle.fleet_number}</strong>{" "}
      <span title="Model">{vehicle.model}</span>{" "}
      <span title="Cluster">{vehicle.project}</span>
       
        <span className="mb-1 mr-1 badge badge-light">
          {connectedStatus(
            trackingUpdateTime,
            noDataInMillisecond,
            vehicle.reminigDays
          )}
        </span>
       


    </div>
  );
};

class VehicleSelect extends React.Component {
  constructor(props) {
    super();
    this.state = {
      options: [],
      selectedVehicle: null,
    };
  }

  componentDidMount() {
    const allData = this.props.vehiclesAll;
    if (allData && allData.length) {
      this.generateOptions();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.generateOptions();
    }
  }

  generateOptions = () => {
     
    const vehiclesAll = this.props.vehiclesAll;
    const options = vehiclesAll.map((item) => ({
      isDisabled:
        item.reminigDays < 0 &&
        item.expiryExtensionDays < 1 &&
        !this.props.allowExpired
          ? true
          : false,
      label: LabelWithIcon(item),
      value: item,
      searchWords:
        item.manufacture_name +
        " " +
        item.plate_number +
        " " +
        item.fleet_number +
        " " +
        item.model +
        " " +
        item.label +
        " " +
        item.year,
      key: item.autoid,
    }));
    this.setState({ options });

    if (!this.props.value) {
      if (
        this.props.defaultSelect &&
        !this.state.selectedVehicle &&
        options &&
        options.length
      ) {
        this.props.onChange(options[0]);
        this.setState({ selectedVehicle: options[0] });
      }
    } else {
      this.props.onChange(this.props.value);
      this.setState({ selectedVehicle: this.props.value });
    }
  };

  handleChange = (selectedVehicle) => {
    this.setState({ selectedVehicle });

    if (this.props.onChange) {
      this.props.onChange(selectedVehicle);
    }
  };

  render() {
    const value = this.props.value || this.state.selectedVehicle;

    return (
      <Select
        filterOption={customFilterOption}
        components={{ Input: CustomSelectInput }}
        className="react-select"
        classNamePrefix="react-select"
        name="allVehicles"
        options={this.state.options}
        onChange={this.handleChange}
        value={value}
        placeholder={<IntlMessages id="common.select-vehicle" />}
      />
    );
  }
}

const mapStateToProps = ({ vehicles }) => {
  const { all } = vehicles;
  return { vehiclesAll: all };
};

export default connect(mapStateToProps)(VehicleSelect);
