import React, { Fragment } from "react";
import { Row, Table } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import CsvDownloader from "../../components/CsvDownloader";
import LocationName from "../../components/LocationName";
import IntlMessages from "../../helpers/IntlMessages";

const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Date", key: "summaryDayText" },
  { label: "Start Time", key: "startTimeText" },
  { label: "End Time", key: "endTimeText" },
  { label: "Duration", key: "duration" },
  { label: "Start Place", key: "startPlace" },
  { label: "Start Geo", key: "startGeo" },
  { label: "End Place", key: "endPlace" },
  { label: "End Geo", key: "endGeo" },
  { label: "Driver", key: "driverName" },
  { label: "First3km on", key: "first3kmText" },
];

class Errands extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theRows: [],
    };
  }

  propagateRows = (allRows) => {
    const theRows = [];

    allRows.forEach((y) => {
      y.errands.forEach((x) => {
        theRows.push({
          vehicleName: x.vehicleName,
          summaryDayText: x.summaryDayText,
          startTimeText: x.startTimeText,
          endTimeText: x.endTimeText,
          dayDiff: x.dayDiff,
          duration: x.duration,
          distance: x.distance,
          startPlace: x.startPlace,
          endPlace: x.endPlace,
          startGeo: x.startGeo,
          endGeo: x.endGeo,
          first3kmText: x.first3kmText,
        });
      });
    });

    this.setState({ theRows });
  };

  componentDidMount() {
    this.propagateRows(this.props.allRows);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allRows !== this.props.allRows) {
      this.propagateRows(this.props.allRows);
    }
  }

  render() {
    const showRows = this.state.theRows || [];
    return (
      <Row>
        <Colxx xxs="12">
          <span className="float-right">
            <CsvDownloader
              headers={headers}
              filename={"errands-ctraac.csv"}
              data={showRows}
            />
          </span>
        </Colxx>

        <Colxx xxs="12">
          <Table responsive hover bordered striped>
            <thead>
              <tr>
                <th>
                  <IntlMessages id="day-summary.vehicle" />
                </th>
                <th>
                  <IntlMessages id="day-summary.date" />
                </th>

                <th>
                  <IntlMessages id="day-summary.start" />
                </th>
                <th>
                  <IntlMessages id="day-summary.end" />
                </th>
                <th>First3km Time</th>
                <th>
                  <IntlMessages id="day-summary.duration" />
                </th>
                <th>
                  <IntlMessages id="day-summary.distance" />
                </th>
                <th>
                  <IntlMessages id="day-summary.driver" />
                </th>
              </tr>
            </thead>
            <tbody>
              {showRows.map((item, i) => (
                <Fragment key={i}>
                  <tr>
                    <td>{item.vehicleName}</td>
                    <td> {item.summaryDayText} </td>

                    <td>
                      {" "}
                      {item.startTimeText}
                      <LocationName
                        place={item.startPlace}
                        geo={item.startGeo}
                        objectItem={item}
                        onClick={() =>
                          this.props.onShowMapModel(
                            item.startGeo,
                            item.startPlace
                          )
                        }
                      />
                    </td>
                    <td>
                      {" "}
                      {item.endTimeText}{" "}
                      <small className="text-muted">
                        {" "}
                        {item.dayDiff ? "+" + item.dayDiff + "d" : ""}{" "}
                      </small>
                      <LocationName
                        place={item.endPlace}
                        geo={item.endGeo}
                        objectItem={item}
                        onClick={() =>
                          this.props.onShowMapModel(item.endGeo, item.endPlace)
                        }
                      />
                    </td>

                    <td> {item.first3kmText} </td>
                    <td> {item.duration} </td>
                    <td> {item.distance}</td>
                    <td> {item.driverName}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        </Colxx>
      </Row>
    );
  }
}

export default Errands;
