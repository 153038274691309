import {
    SUBSCRIBER_SELECT_REQUEST,
    SUBSCRIBER_SELECT_SUCCESS  } from '../actions';

  export const subscriberSelectDetailsRequest = ( history ) => ({
    type: SUBSCRIBER_SELECT_REQUEST,
    payload: { history }
  });

  export const subscriberSelectSuccess = (subscriber ) => ({
    type: SUBSCRIBER_SELECT_SUCCESS,
    payload: { subscriber }
  });
