import React from "react";
import {  Card, CardBody } from "reactstrap";
import Moment from "react-moment";

import LocationName from "./LocationName";

import {
  AppHelperDateAndTimeToString,
  AppHelperDateAndTimeToDay,
} from "../helpers/AppHelper";

class TripsViewTitle extends React.Component {
  render() {
    const title = this.props.title || "";
    const place = this.props.place || "";
    const geo = this.props.geo || "";
    const time = this.props.time || null;
    const item = this.props.item || {};

    return (
      <Card className="progress-banner">
        <CardBody className="justify-content-between d-flex flex-row align-items-center">
          <div>
            <p className="text-white"> {title} </p>
            <div>
              <p className=" lead text-white ">
                <LocationName
                  place={place}
                  geo={geo}
                  objectItem={item}
                  showGoogle
                />
              </p>

              <p className="text-white">
                {AppHelperDateAndTimeToDay(time)}
                {", "}
                <Moment fromNow>{time}</Moment>
                <br />
                {AppHelperDateAndTimeToString(time)}
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default TripsViewTitle;
