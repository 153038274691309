import {
  STORY_SELECT_MORE_REQUEST,
  STORY_SELECT_MORE_SUCCESS,
  STORY_SELECT_MORE_NO_RECORDS  } from '../actions';

  export const storySelectMoreNoRecords = ( ) => ({
    type: STORY_SELECT_MORE_NO_RECORDS,
    payload: { }
  });

  export const storySelectMoreRequest = ( history, skipRecords, vehicleId ) => ({
    type: STORY_SELECT_MORE_REQUEST,
    payload: { history, skipRecords, vehicleId }
  });

  export const storySelectMoreSuccess = ( newStory ) => ({
    type: STORY_SELECT_MORE_SUCCESS,
    payload: { newStory }
  });
