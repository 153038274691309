import React, { Component } from "react";
import {
  Row,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../helpers/IntlMessages";
import classnames from "classnames";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleEdit from "./vehicle-edit";
import VehicleControls from "./vehicle-controls";
import VehicleDrivers from "./vehicle-drivers";
import VehicleSelect from "../../components/VehicleSelect";

class VehicleSettingsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeFirstTab: "1",
      selectedVehicle: null
    };
  }

  handleChangeVehicle = selectedVehicle => {
    this.setState({ selectedVehicle: selectedVehicle.value });
  };

  toggleFirstTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Row className="mb-2">
          <Colxx xl="4" lg="6" md="12">
            <CardBody>
              <h6> <IntlMessages id="vehicle.selected-vehicle" /></h6>
              <VehicleSelect
                onChange={this.handleChangeVehicle}
                defaultSelect
                allowExpired={true}
              />
            </CardBody>
          </Colxx>
        </Row>
        {this.state.selectedVehicle ? (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <CardHeader>
                <Nav tabs className="card-header-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeFirstTab === "1",
                        "nav-link": true
                      })}
                      onClick={() => {
                        this.toggleFirstTab("1");
                      }}
                      to="#"
                    >
                      <IntlMessages id="vehicle.details" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeFirstTab === "2",
                        "nav-link": true
                      })}
                      onClick={() => {
                        this.toggleFirstTab("2");
                      }}
                      to="#"
                    >
                      <IntlMessages id="vehicle.tab-controls" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeFirstTab === "3",
                        "nav-link": true
                      })}
                      onClick={() => {
                        this.toggleFirstTab("3");
                      }}
                      to="#"
                    >
                      <IntlMessages id="vehicle.drivers" />
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>

              <TabContent activeTab={this.state.activeFirstTab}>
                <TabPane tabId="1">
                  <VehicleEdit
                    selectedVehicle={this.state.selectedVehicle}
                    history={this.props.history}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <VehicleControls
                    selectedVehicle={this.state.selectedVehicle}
                    history={this.props.history}
                  />
                </TabPane>
                <TabPane tabId="3">
                  <VehicleDrivers
                    selectedVehicle={this.state.selectedVehicle}
                    history={this.props.history}
                  />
                </TabPane>
              </TabContent>
            </Colxx>
          </Row>
        ) : (
          <center>
            <p className="lead"><IntlMessages id="vehicle.select-continue" /></p>
          </center>
        )}
      </React.Fragment>
    );
  }
}

export default VehicleSettingsTab;
