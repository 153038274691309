import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "live";

export const LiveSelectByToken = userToken => {
  const criterion = { userToken };

  return axios
    .post(proxyServer + "/get-by-token", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
