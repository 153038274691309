import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
export default class LineDemo extends Component {
  constructor(props) {
    super();
    this.state = {
      // label=""
    };
  }
  render() {
    let data = {
      labels: this.props.labelData,
      datasets: [
        {
          label: "Speed",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.props.speedData,
        },
        {
          label: "limit",
          fill: false,
          legend: false,
          lineTension: 0.1,
          backgroundColor: "pink",
          borderColor: "red",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "red",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "pink",
          pointHoverBorderColor: "red",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.props.limit,
        },
      ],
    };
    let selectedValue = this.props.selectedVehicle;
    let label = "Speed Chart";
    if (selectedValue !== {} && selectedValue !== null) {
      label = `Speed Chart -${selectedValue.value.title}  ${
        selectedValue.value.model
      } | ${moment(this.props.startTime).format("Do MMM, h:mm a")} to ${moment(
        this.props.endTime
      ).format("Do MMM , h:mm a")}`;
    }

    return (
      <div>
        <h2>{label}</h2>
        <Line ref="chart" data={data} />
      </div>
    );
  }

  componentDidMount() {
    const { datasets } = this.refs.chart.chartInstance.data;
    console.log(datasets[0].data);
  }
}
