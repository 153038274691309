

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "ignition";

export const IgnitionSelectNewBySubscriber = (userToken, skip, vehicleId) => {
    const criterion = { userToken, skip, vehicleId };
    return axios
      .post(proxyServer + "/get-new-by-subscriber", { criterion })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };
  

export const IgnitionSelectStory = (userToken, vehicleId, tripStart, tripEnd) => {
  const criterion = { userToken, vehicleId, tripStart, tripEnd };
  return axios
    .post(proxyServer + "/get-story", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionGetSuggestionRewind = (userToken, vehicleId, time, limit) => {
  const criterion = { userToken, vehicleId, time, limit };
  return axios
    .post(proxyServer + "/get-suggestion-rewind", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionGetTripsByVehicles = (userToken, vehicleIds, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-trips-by-vehicles", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


export const IgnitionGetTripsByVehicle = (userToken, vehicleId, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleId, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-trips-by-vehicle", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionGetTripsByDriver = (userToken, driverId, startTime, endTime, limit, skip) => {
  const criterion = { userToken, driverId, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-trips-by-driver", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionGetSummaryByVehicles = (userToken, vehicleIds, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-summary-by-vehicles", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionGetSummaryByDrivers = (userToken, driverIds, startTime, endTime, limit, skip) => {
  const criterion = { userToken, driverIds, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-summary-by-drivers", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionGetDatedSummaryByVehicles = (userToken, vehicleIds, startTime, endTime, offsetMinutes, limit, skip) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, offsetMinutes, limit, skip };
  return axios
    .post(proxyServer + "/get-dated-summary-by-vehicles", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionGetSummaryGroupByDate = (userToken, vehicleIds, startTime, endTime, offsetMinutes) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, offsetMinutes };
  return axios
    .post(proxyServer + "/get-summary-group-by-date", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IgnitionSelectByPk = (userToken, vehicleId, timestamp) => {
  const criterion = { userToken, vehicleId, timestamp };
  return axios
    .post(proxyServer + "/get-by-pk", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
