import React from "react";
import { Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";

class ChangePassModel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      executive_email: '',
      new_password: '',
      confirm_password: ''

    };

  }

  componentDidMount() {
    this.loadMasters();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemObject !== this.props.itemObject) {
      this.loadMasters();
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  loadMasters = () => {

    const itemObject = this.props.itemObject;

    // cancle operation if null
    if (!itemObject) return;

    this.setState({ executive_email: itemObject.email });

  };

  render() {

    return (

      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>
          Password Change
        </ModalHeader>
        <ModalBody>

          <Row>

            <Colxx xxs="12" className="mb-1">

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Email</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="email"
                        className="form-control"
                        name="executive_email"
                        readOnly
                        onChange={this.onChange}
                        value={this.state.executive_email}
                      />
                      <div className="form-control-position">
                        <i className="ft-user" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>New Password</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="password"
                        className="form-control"
                        name="new_password"
                        required
                        onChange={this.onChange}
                        value={this.state.new_password}
                      />
                      <div className="form-control-position">
                        <i className="ft-user" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="password"
                        className="form-control"
                        name="confirm_password"
                        required
                        onChange={this.onChange}
                        value={this.state.confirm_password}
                      />
                      <div className="form-control-position">
                        <i className="ft-user" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">

                  <div className="form-actions right">
                    {this.props.closeButton}
                    <button type="button" className="btn btn-primary"
                    onClick={()=>{

                      if(this.state.new_password 
                        && this.state.new_password === this.state.confirm_password){

                          const itemObject = this.props.itemObject;

                          this.props.onUpdatePassword(itemObject.autoid, 
                            itemObject.email,
                            this.state.new_password);

                        }else{
                          alert('New password and confirm password dose not match!')
                        }
                      
                    }}
                    >
                      <i className="fa fa-check-square-o" /> Update
                    </button>
                  </div>

                </div>
              </div>

            </Colxx>

          </Row>

        </ModalBody>
      </Modal>


    );
  }
}

export default ChangePassModel;
