import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { injectIntl } from "react-intl";

// import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import TabContainer from "../../../containers/geofence-settings/tab-container";

class GeofencesMaster extends Component {
  render() {
    // const { messages } = this.props.intl;
    // const messages = [];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb
              heading="menu.geofence-settings"
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
       <TabContainer />
      </Fragment>
    );
  }
}

export default injectIntl(GeofencesMaster);
