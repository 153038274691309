import React, { Component } from "react";
import { Row, Card, CardBody, CardTitle, FormGroup, Label } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { Formik, Form, Field } from "formik";
import moment from "moment";
import "react-quill/dist/quill.bubble.css";
import { OpenTicket } from "../../../api-sc/tickets";
import IntlMessages from "../../../helpers/IntlMessages";
import AppLocale from "../../../lang";
import { createIntl } from "react-intl";

class TicketForm extends Component {
  constructor(props) {
    super();
    this.state = {
      date: moment().format("YYYY-MM-DD"),
      priority: 0,
      subject: "",
      comment: "",
      loading: false,
    };
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.activeSecondTab !== this.props.activeSecondTab) {
      this.setState({
        date: moment().format("YYYY-MM-DD"),
        priority: 0,
        subject: "",
        comment: "",
      });
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onDateChange = (event) => {
    this.setState({ date: event.target.value });
  };
  handleChangeComment = (comment) => {
    this.setState({ comment });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    const input = {
      ticketDate: this.state.date,
      priority: this.state.priority,
      subject: this.state.subject,
      updateText: this.state.comment,
      userToken: this.props.user.userToken,
    };
    const openTicketList = await OpenTicket(input);
    if (openTicketList.status === "success") {
      // GetTicket(this.props.user.userToken);
      this.props.getTickets();
      this.props.toggleSecondTab("1");
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  };
  resetValues = () => {
    this.setState({
      date: moment(),
      priority: 0,
      subject: "",
      comment: "",
    });
  };
  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="ticket.open-ticket" />
                </CardTitle>

                <Formik
                  initialValues={{
                    date: this.state.date,
                    priority: this.state.priority,
                    subject: this.state.subject,
                    message: this.state.message,
                  }}
                  enableReinitialize={true}
                  onSubmit={this.handleSubmit}
                >
                  {() => (
                    <Form className="av-tooltip tooltip-label-right">
                      <Row>
                        <Colxx xl="4" lg="6" md="12">
                          <FormGroup>
                            <Label>
                              <IntlMessages id="ticket.owner" />
                            </Label>
                            <Field
                              className="form-control"
                              name="account"
                              disabled
                              value={`${this.props.userDetails.firstName} ${this.props.userDetails.lastName}`}
                            ></Field>
                          </FormGroup>
                        </Colxx>
                        <Colxx xl="4" lg="6" md="12">
                          <FormGroup>
                            <Label>
                              <IntlMessages id="ticket.date" />
                            </Label>
                            <Field
                              className="form-control"
                              name="date"
                              type="date"
                              onChange={this.onChange}
                              value={this.state.date}
                            />
                          </FormGroup>
                        </Colxx>
                        <Colxx xl="4" lg="6" md="12">
                          <FormGroup required>
                            <Label>
                              <IntlMessages id="ticket.priority" />
                            </Label>
                            <select
                              className="form-control"
                              name="priority"
                              onChange={this.onChange}
                              value={this.state.priority}
                              required
                            >
                              <option value="">
                                {intl.formatMessage({
                                  id: "ticket.select",
                                })}
                              </option>
                              <option value="0">
                                {intl.formatMessage({
                                  id: "ticket.low",
                                })}
                              </option>
                              <option value="1">
                                {intl.formatMessage({
                                  id: "ticket.medium",
                                })}
                              </option>
                              <option value="2">
                                {intl.formatMessage({
                                  id: "ticket.high",
                                })}
                              </option>
                            </select>
                          </FormGroup>
                        </Colxx>

                        <Colxx xl="12" lg="12" md="12">
                          <FormGroup required>
                            <Label>
                              <IntlMessages id="ticket.subject" />
                            </Label>
                            <Field
                              className="form-control"
                              name="subject"
                              value={this.state.subject}
                              onChange={this.onChange}
                              required
                            />
                          </FormGroup>
                        </Colxx>
                        <Colxx xl="12" lg="12" md="12">
                          <FormGroup>
                            <Label>
                              {" "}
                              <IntlMessages id="ticket.add-comment" />
                            </Label>
                            <Row className="mb-4">
                              <Colxx xxs="12">
                                <Card>
                                  <CardBody>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      name="comment"
                                      value={this.state.comment}
                                      onChange={(event) =>
                                        this.handleChangeComment(
                                          event.target.value
                                        )
                                      }
                                      style={{ minHeight: "100px" }}
                                      // required
                                    />
                                  </CardBody>
                                </Card>
                              </Colxx>
                            </Row>
                          </FormGroup>
                        </Colxx>
                      </Row>
                      <Row>
                        <Colxx xl="12" lg="12" md="12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                            disabled={this.state.loading}
                            // style={{ width: "55px" }}
                          >
                            {this.state.loading ? (
                              <span className="spinner d-inline-block">
                                <span className="bounce1" color="primary" />
                                <span className="bounce2" color="primary" />
                                <span className="bounce3" color="primary" />
                                <span className="bounce3" color="primary" />
                              </span>
                            ) : (
                              <IntlMessages id="common.submit" />
                            )}
                          </button>
                          <button
                            type="reset"
                            className="btn btn-primary btn-sm"
                            style={{ marginLeft: "8px" }}
                            onClick={this.resetValues}
                          >
                            <IntlMessages id="common.cancel" />
                          </button>
                        </Colxx>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user } = authUser;
  const { locale } = settings;
  const userDetails = authUser.details;
  return { user, userDetails, locale };
};

export default connect(mapStateToProps)(TicketForm);
