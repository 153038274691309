import React, { Component } from "react";
import {  Row, Card, CardBody } from "reactstrap";

import { Colxx } from "../../components/common/CustomBootstrap";
import AlertSmallLine from "../../components/charts/AlertSmallLine";
import { ThemeColors } from "../../helpers/ThemeColors";
import IntlMessages from "../../helpers/IntlMessages";


const colors = ThemeColors();

const chartData = (alertData, type) => {
  const typeFiltered = alertData.filter(x => x.type === type);

  const labels = typeFiltered.map(x => {
    return x.label;
  });

  const totalCounts = typeFiltered.map(x => {
    return x.avgCount;
  });

  return {
    masterLabel: labels[0],
    masterValue: totalCounts[0],
    masterLastWeekValue: totalCounts[1],
    labels: labels.reverse(),
    datasets: [
      {
        label: "#",
        borderColor: colors.themeColor1,
        pointBorderColor: colors.themeColor1,
        pointHoverBackgroundColor: colors.themeColor1,
        pointHoverBorderColor: colors.themeColor1,
        pointRadius: 2,
        pointBorderWidth: 3,
        pointHoverRadius: 2,
        fill: false,
        borderWidth: 2,
        data: totalCounts.reverse(),
        datalabels: {
          align: "end",
          anchor: "end"
        }
      }
    ]
  };
};

class AlertSmallLineCharts extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const itemClass = "dashboard-small-chart";
    return (
      <Row>
        <Colxx lg="6" sm="12" className="mb-4">
          <div className="w-100">
            
          <h3> <IntlMessages id="dashboard.hard-breaking" /> </h3>

          </div>

          <Card className={itemClass}>
            <CardBody>
              {this.props.alertsWeeklyCount.length ? (
                <AlertSmallLine
                  data={chartData(this.props.alertsWeeklyCount, "breakings")}
                />
              ) : (
                <div className="text-muted"> .... </div>
              )}
            </CardBody>
          </Card>
        </Colxx>
        <Colxx lg="6" sm="12" className="mb-4">
          <h3> <IntlMessages id="dashboard.hard-acc" /> </h3>
          <Card className={itemClass}>
            <CardBody>
              {this.props.alertsWeeklyCount.length ? (
                <AlertSmallLine
                  data={chartData(
                    this.props.alertsWeeklyCount,
                    "accelerations"
                  )}
                />
              ) : (
                <div className="text-muted"> .... </div>
              )}
            </CardBody>
          </Card>
        </Colxx>
        <Colxx lg="6" sm="12" className="mb-4">
          <h3> <IntlMessages id="dashboard.over-speed" /> </h3>
          <Card className={itemClass}>
            <CardBody>
              {this.props.alertsWeeklyCount.length ? (
                <AlertSmallLine
                  data={chartData(this.props.alertsWeeklyCount, "overspeed")}
                />
              ) : (
                <div className="text-muted"> .... </div>
              )}
            </CardBody>
          </Card>
        </Colxx>
        <Colxx lg="6" sm="12" className="mb-4">
          <h3> <IntlMessages id="dashboard.hard-corn" /> </h3>
          <Card className={itemClass}>
            <CardBody>
              {this.props.alertsWeeklyCount.length ? (
                <AlertSmallLine
                  data={chartData(this.props.alertsWeeklyCount, "cornering")}
                />
              ) : (
                <div className="text-muted"> .... </div>
              )}
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    );
  }
}

export default AlertSmallLineCharts;
