import React from "react";
import ReactApexChart from "react-apexcharts";

class GeofenceHeatmapChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
        },
        colors: ["#008FFB"],

        title: {
          text: "Geofence HeatMap",
        },
      },
      series: [],
    };
  }

  componentDidMount() {}

  render() {
    const series = this.props.series || [];
    return (
      <div className="w-100" style={{ direction: "ltr" }}>
        <ReactApexChart
          options={this.state.options}
          series={series}
          type="heatmap"
          height="250"
        />
      </div>
    );
  }
}

export default GeofenceHeatmapChart;
