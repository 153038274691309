import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';


import geofenceAnalytics from './geofence-analytics';
import daySummary from './day-summary';

const Dashboards = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/default`} />
            <Route path={`${match.url}/default`} component={geofenceAnalytics} />
            
            <Route path={`${match.url}/geofence`} component={geofenceAnalytics} />
            <Route path={`${match.url}/day-summary`} component={daySummary} />
            
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Dashboards;