import React from "react";

import ReactSelect from "react-select";
import { connect } from "react-redux";
import { Button, ButtonGroup } from "reactstrap";

import CustomSelectInput from "./common/CustomSelectInput";
import AppLocale from "../lang";
import { createIntl } from "react-intl";

const customFilterOption = (option, rawInput) => {
  if (option && rawInput) {
    const words = rawInput.split(" ");
    return words.reduce(
      (acc, cur) =>
        acc &&
        option.data.searchWords.toLowerCase().includes(cur.toLowerCase()),
      true
    );
  } else {
    return true;
  }
};

const LabelWithIcon = (x) => {
  return (
    <div key={x.autoid}>
      <strong>
        <i className="simple-icon-user mr-1" />
      </strong>
      <strong title="Driver">{x.driver_name}</strong>{" "}
      <strong title="Emp Code">{x.employee_code}</strong>
    </div>
  );
};

class DriverSelectWithBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      nestingDropdownOpen: false,
      options: [],
      batchOptions: [],
      isOpenSizingXs: false,
    };
  }

  componentDidMount() {
    this.loadMasters();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.all !== this.props.all) {
      this.loadMasters();
    }
  }

  loadMasters = () => {
    const options = this.generateOptions(this.props.all);
    this.setState({ options });
  };

  generateOptions = (vehicles) => {
    const options = vehicles.map((item) => ({
      label: LabelWithIcon(item),
      value: item.autoid.toString(),
      searchWords: item.driver_name + " " + item.employee_code,
      key: item.autoid,
      item: item,
    }));
    return options;
  };

  nestingToggle = () => {
    this.setState({
      nestingDropdownOpen: !this.state.nestingDropdownOpen,
    });
  };

  handleChange = (selectedItems) => {
    this.setState({ selectedItems: selectedItems });
    if (this.props.onChange) {
      this.props.onChange(selectedItems);
    }
  };

  handleChangeAppend = (selectedItems) => {
    const selected = [
      ...new Set(this.state.selectedItems.concat(selectedItems)),
    ];

    this.setState({ selectedItems: selected });
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  };

  toggleSizingXs = () => {
    this.setState((prevState) => ({
      isOpenSizingXs: !prevState.isOpenSizingXs,
    }));
  };

  render() {
    const classNamePrefix = this.props.classNamePrefix || "react-select";
    const currentAppLocale = AppLocale[this.props.locale];
    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;

    return (
      <React.Fragment>
        <h6>
          {" "}
          {this.state.selectedItems.length}
          {this.state.selectedItems.length > 1
            ?" "+intl.formatMessage({
                id: "report.drivers",
              })+" "
            : " "+intl.formatMessage({
                id: "report.driver-small",
              })+" "}
          {" "+intl.formatMessage({
            id: "common.selected",
          })}
        </h6>

        <ButtonGroup className="mb-1 flex-wrap">
          <Button outline size="xs" onClick={() => this.handleChange([])}>
            {intl.formatMessage({
              id: "common.clear",
            })}
          </Button>
          <Button
            outline
            size="xs"
            onClick={() => this.handleChange(this.state.options)}
          >
            {intl.formatMessage({
              id: "common.select-all",
            })}
          </Button>
        </ButtonGroup>

        <ReactSelect
          filterOption={customFilterOption}
          components={{ Input: CustomSelectInput }}
          className="react-select"
          classNamePrefix={classNamePrefix}
          maxMenuHeight={240}
          name="allVehicles"
          options={this.state.options}
          placeholder={this.props.placeholder}
          isMulti
          onChange={this.handleChange}
          value={this.state.selectedItems}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ drivers, settings }) => {
  const { items } = drivers;
  const { locale } = settings;
  return { all: items || [], locale };
};

export default connect(mapStateToProps)(DriverSelectWithBatch);
