import React from "react";

import Select from "react-select";
import { connect } from "react-redux";

import CustomSelectInput from "./common/CustomSelectInput";

const customFilterOption = (option, rawInput) => {
  if (option && rawInput) {
    const words = rawInput.split(" ");
    return words.reduce(
      (acc, cur) =>
        acc &&
        option.data.searchWords.toLowerCase().includes(cur.toLowerCase()),
      true
    );
  } else {
    return true;
  }
};

const LabelWithIcon = x => {
  return (
    <div key={x.autoid}>
      <strong>
      <i className="simple-icon-user mr-1" />
      </strong>
      <strong title="Driver">{x.driver_name}</strong>{" "}
      <strong title="Emp Code">{x.employee_code}</strong>
    </div>
  );
};

class DriverSelect2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedItem: null
    };
  }

  componentDidMount() {
    const allData = this.props.driversAll;
    if (allData && allData.length) {
      this.generateOptions();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.driversAll !== this.props.driversAll) {
      this.generateOptions();
    }
  }

  generateOptions = () => {
    const driversAll = this.props.driversAll;
    const options = driversAll.map(item => ({
      label: LabelWithIcon(item),
      value: item,
      searchWords: item.driver_name + " " + item.employee_code,
      key: item.autoid
    }));
    this.setState({ options });

    if (!this.props.value) {
      if (
        this.props.defaultSelect &&
        !this.state.selectedItem &&
        options &&
        options.length
      ) {
        this.props.onChange(options[0]);
        this.setState({ selectedItem: options[0] });
      }
    } else {
      this.props.onChange(this.props.value);
      this.setState({ selectedItem: this.props.value });
    }
  };

  handleChange = selectedItem => {
    this.setState({ selectedItem });

    if (this.props.onChange) {
      this.props.onChange(selectedItem);
    }
  };

  render() {
    const value = this.props.value || this.state.selectedItem;

    return (
      <Select
        filterOption={customFilterOption}
        components={{ Input: CustomSelectInput }}
        className="react-select"
        classNamePrefix="react-select"
        name="allDrivers"
        options={this.state.options}
        onChange={this.handleChange}
        value={value}
      />
    );
  }
}

const mapStateToProps = ({ drivers }) => {
  const { items } = drivers;
  return { driversAll: items };
};

export default connect(mapStateToProps)(DriverSelect2);
