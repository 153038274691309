import { FAVORITE_SELECT_REQUEST, FAVORITE_SELECT_SUCCESS } from "../actions";

  export const favoritePlacesSelectRequest = ( history ) => ({
    type: FAVORITE_SELECT_REQUEST,
    payload: { history }
  });

  export const favoritePlacesSelectSuccess = ( items ) => ({
    type: FAVORITE_SELECT_SUCCESS,
    payload: { items }
  });
