import { GEOFENCE_SELECT_REQUEST, GEOFENCE_SELECT_SUCCESS } from "../actions";

  export const geofenceSelectRequest = ( history ) => ({
    type: GEOFENCE_SELECT_REQUEST,
    payload: { history }
  });

  export const geofenceSelectSuccess = ( items ) => ({
    type: GEOFENCE_SELECT_SUCCESS,
    payload: { items }
  });
