function getOffsetMin(zoneName) {
  switch (zoneName) {
    case 'Asia/Kolkata':
      return 300;

    case 'Asia/Qatar':
      return 300;

    case 'Asia/Dubai':
      return 240;

    case 'Asia/Muscat':
      return 240;

    default:
      return 180; // 'Asia/Qatar'
  }
}


function trim(s, c) {
  // eslint-disable-next-line no-param-reassign
  if (c === ']') c = '\\]';
  // eslint-disable-next-line no-param-reassign
  if (c === '\\') c = '\\\\';
  return s.replace(new RegExp(`^[${c}]+|[${c}]+$`, 'g'), '');
}

function extractFence(fence) {
  const localFence = fence;
  let dataString = trim(localFence, ')');
  dataString = trim(dataString, '(');

  const dataArr = dataString.split(')(');
  const geoArr = [];
  let foo;
  dataArr.forEach((e) => {
    foo = e.split(',');
    geoArr.push([parseFloat(foo[0]), parseFloat(foo[1])]);
  });

  return geoArr;
}

// isPolyInside([lat, log],fenceEx[fenceIndex].boundariesPoints);
function isPolyInside(point, vs) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  const x = point[0];
  const y = point[1];

  let inside = false;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0];
    const yi = vs[i][1];
    const xj = vs[j][0];
    const yj = vs[j][1];

    // eslint-disable-next-line no-mixed-operators
    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) inside = !inside;
  }

  return inside;
}


const theBountryQatar = [
  [24.305756692896612, 51.05724425128171],
  [24.32077458548499, 52.06249327471921],
  [26.213314645006896, 52.08446593096921],
  [26.233025869894046, 50.78258604815671],
  [25.570892211971785, 50.59581847003171],
  [24.835304431224223, 50.71666807940671],
];


const theBountryIndia = [
  [23.254091359161947, 66.56650948438283],
  [38.28357786436966, 73.59775948438283],
  [38.49025293175889, 80.27744698438283],
  [29.85267551528769, 84.32041573438283],
  [29.623729221989155, 96.80088448438283],
  [29.47080854725877, 100.22861885938283],
  [10.501451367658147, 80.71690010938283],
  [6.154346544431151, 76.41025948438283],
];

function isGeoInWhiteListedCountries(lat, lng) {
  if (isPolyInside([lat, lng], theBountryQatar)) {
    return true;
  }

  if (isPolyInside([lat, lng], theBountryIndia)) {
    return true;
  }

  return false;
}

module.exports = {
  getOffsetMin,
  extractFence,
  isPolyInside,
  isGeoInWhiteListedCountries,
};
