import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "log-access";

export const LogAccessSelectByIdAndDay = ( userToken, vehicleId, day) => {

    const criterion = { userToken, vehicleId, day };
  
    return axios
      .post(proxyServer + "/get-by-id-and-day", {
        criterion
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };
