import React from "react";
import moment from "moment";
import { Button, ButtonGroup } from "reactstrap";
import IntlMessages from "../helpers/IntlMessages";
import AppLocale from "../lang";
import { createIntl } from "react-intl";
import { connect } from "react-redux";


class MonthPicker extends React.Component {
  constructor(props) {
    super();
    const startTime =
      props.startTime ||
      moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endTime = props.endTime || moment(new Date());

    this.state = {
      startTime: startTime,
      endTime: endTime,
    };
  }

  componentDidMount() {
    this.validateAndUpdate(this.state.startTime, this.state.endTime);
  }

  handleChangeStart = (time) => {
    this.validateAndUpdate(time, this.state.endTime);
  };

  handleChangeEnd = (time) => {
    this.validateAndUpdate(this.state.startTime, time);
  };

  validateAndUpdate = (startTime, endTime) => {
    this.setState({ startTime, endTime });

    if (this.props.onChange) {
      this.props.onChange(startTime, endTime);
    }
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <ButtonGroup className="flex-wrap">
          <Button
            outline
            title="This Month"
            size="xs"
            onClick={() => {
              this.validateAndUpdate(moment().startOf("month"), moment());
            }}
          >
            <IntlMessages id="dashboard.this-month" />
          </Button>

          <Button
            title={intl.formatMessage({
              id: "dashboard.prev-month",
            })}
            size="xs"
            onClick={() => {
              this.validateAndUpdate(
                this.state.startTime.subtract(1, "months").startOf("month"),
                this.state.endTime.subtract(1, "months").endOf("month")
              );
            }}
          >
            {/* <IntlMessages id="dashboard.prev-month" /> */}
            <i className="iconsminds-arrow-back-3" />
          </Button>

          <Button
            title={intl.formatMessage({
              id: "dashboard.next-month",
            })}
            size="xs"
            onClick={() => {
              this.validateAndUpdate(
                this.state.startTime.add(1, "months").startOf("month"),
                this.state.endTime.add(1, "months").endOf("month")
              );
            }}
          >
            {/* <IntlMessages id="dashboard.next-month" /> */}
            <i className="iconsminds-arrow-forward-2" />
          </Button>
        </ButtonGroup>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;

  return { locale };
};

export default connect(mapStateToProps)(MonthPicker);
