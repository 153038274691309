import React, { Component } from "react";
import { Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import { GetServiceSchedules } from "../../api-sc/service-schedule";
import moment from "moment";
import IntlMessages from "../../helpers/IntlMessages";


class ServiceContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      allRows: [],
    };
  }

  // componentWillUnmount() {}

  componentDidMount() {
    this.getServiceSchedule();
  }
  getServiceSchedule = async () => {
    const user = this.props.user;
    const userToken = user.userToken;

    const serviceSchedules = await GetServiceSchedules(userToken);
    if (serviceSchedules && Array.isArray(serviceSchedules)) {
      this.setState({
        allRows: serviceSchedules,
      });
    } else {
      this.setState({
        allRows: [],
      });
    }
  };
  render() {
    const serviceList = this.state.allRows || [];

    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle><IntlMessages id="service.list" /></CardTitle>
                <small>{serviceList.length} <IntlMessages id="support.records" /></small>
                <div className="card-body-min-height">
                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th><IntlMessages id="service.location" /></th>
                        <th><IntlMessages id="service.ticket" /></th>
                        <th><IntlMessages id="service.date" /></th>
                        <th><IntlMessages id="service.status" /></th>
                        <th><IntlMessages id="service.remarks" /></th>
                        <th><IntlMessages id="service.account" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceList.map((item, i) => (
                        <tr key={i + 1}>
                          <th scope="row">{i + 1}</th>
                          <td>{item.location_name}</td>
                          <td>{"#" + item.ticket_id + " " + item.subject}</td>
                          <td>
                            {moment(item.schedule_date).format(
                              "YYYY-MM-DD, h:mm:ss a"
                            )}
                          </td>
                          <td>{item.status}</td>
                          <td>{item.remarks}</td>
                          <td>{item.company_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return { user };
};

export default connect(mapStateToProps)(ServiceContainer);
