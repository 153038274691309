import React from "react";

class ErrorPopover extends React.Component {
  render() {
    if (this.props.error && this.props.touched) {
      return <div className="invalid-feedback d-block">{this.props.error}</div>;
    } else {
      return <React.Fragment />;
    }
  }
}
export default ErrorPopover;
