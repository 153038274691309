

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "cornering";

export const CorneringGetByVehicle = (userToken, vehicleId, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleId, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-vehicle", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const CorneringGetByVehicles = (userToken, vehicleIds, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-vehicles", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const CorneringGetByDrivers = (userToken, driverIds, startTime, endTime, limit, skip) => {
  const criterion = { userToken, driverIds, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-drivers", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
