import { PASSENGER_SELECT_REQUEST, PASSENGER_SELECT_SUCCESS } from "../actions";

  export const passengerSelectRequest = ( history ) => ({
    type: PASSENGER_SELECT_REQUEST,
    payload: { history }
  });

  export const passengerSelectSuccess = ( items ) => ({
    type: PASSENGER_SELECT_SUCCESS,
    payload: { items }
  });
