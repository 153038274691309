import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import defaultDash from './default';
import contentDash from './content';
import analyticsDash from './analytics';
import ecommerceDash from './ecommerce';
import alertsDash from './alerts';
import storyDash from './story';
import liveDash from './live';
import efficiencyDash from './efficiency';

const Dashboards = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/live`} />
            <Route path={`${match.url}/default`} component={liveDash} />
            <Route path={`${match.url}/live`} component={liveDash} />
            <Route path={`${match.url}/story`} component={storyDash} />
            <Route path={`${match.url}/efficiency`} component={efficiencyDash} />
            
            <Route path={`${match.url}/default2`} component={defaultDash} />
            <Route path={`${match.url}/content`} component={contentDash} />
            <Route path={`${match.url}/ecommerce`} component={ecommerceDash} />
            <Route path={`${match.url}/analytics`} component={analyticsDash} />
            <Route path={`${match.url}/alerts`} component={alertsDash} />
            
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Dashboards;