import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import IntlMessages from "../helpers/IntlMessages";

class PagingBar extends React.Component {
  next = (totalCount, limit, skip) => {
    if (this.props.onChange) {
      let total = this.props.totalCount || 0;
      let s = skip + limit;
      s = s > total ? total : s;

      this.props.onChange(limit, s);
    }
  };

  prev = (totalCount, limit, skip) => {
    if (this.props.onChange) {
      let s = skip - limit;
      s = s < 0 ? 0 : s;
      this.props.onChange(limit, s);
    }
  };

  render() {
    if (!this.props.totalCount) {
      return <React.Fragment />;
    }

    const size = this.props.size || "xs";
    const outline = this.props.outline || false;
    const color = this.props.color || "dark";

    const totalCount = this.props.totalCount || 0;
    const limit = this.props.limit || 0;
    const skip = this.props.skip || 0;

    const showing = skip + limit > totalCount ? totalCount : skip + limit;

    return (
      <div>
        {this.props.loading ? (
          <span className="spinner d-inline-block bg-primary p-1">
            <span className="bounce1" />
            <span className="bounce2" />
            <span className="bounce3" />
          </span>
        ) : (
          <ButtonGroup>
            <Button
              outline={outline}
              color={color}
              size={size}
              onClick={() => this.prev(totalCount, limit, skip)}
              disabled={skip === 0 ? true : false}
            >
              <IntlMessages id="tracking.prev" />
            </Button>

            <Button
              outline={outline}
              color={color}
              size={size}
              onClick={() => this.next(totalCount, limit, skip)}
              disabled={showing === totalCount ? true : false}
            >
              <IntlMessages id="tracking.next" />
            </Button>
          </ButtonGroup>
        )}

        {this.props.loading ? (
          <p> ... </p>
        ) : (
          <p>
            <IntlMessages id="tracking.showing" /> {skip + 1}{" "}
            <IntlMessages id="tracking.to" /> {showing}{" "}
            <IntlMessages id="tracking.from" /> {totalCount}{" "}
            <IntlMessages id="tracking.records" />
          </p>
        )}
      </div>
    );
  }
}

export default PagingBar;
