import React, { Component } from "react";
import { Button, Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelect from "../../components/VehicleSelect";
import { authApiAccessCheck } from "../../redux/actions";
import { TrackingBasicGetByTimeRangeOptimised } from "../../api-tasks/tracking-basic";
import DateRangePicker from "../../components/DateRangePicker";
import CanvasJS from "../../components/CanvasJS";
import { NotificationManager } from "../../components/common/react-notifications";
import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
  AppHelperDurationAsHHmm,
} from "../../helpers/AppHelper";
import moment from "moment";
import IntlMessages from "../../helpers/IntlMessages";
import LocationName from "../../components/LocationName";

class SpeedChartPage extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedVehicle: null,
      loading: false,
      filteredDataRows: [],
      wayPoints: [],
      selected: {},
      topSpeed: {},
      avgSpeed: 0,
    };
  }

  componentWillUnmount() {}

  componentDidMount() {}

  handleChangeVehicle = (selectedVehicle) => {
    this.setState({ selectedVehicle: selectedVehicle, wayPoints: [] });
    setTimeout(() => {
      this.onDateRangeGo();
    }, 5);
  };

  onDateRangeGo = () => {
    if (!this.state.selectedVehicle) {
      NotificationManager.error(
        "please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const vehicleId = this.state.selectedVehicle.key;

    const vehicleValue = this.state.selectedVehicle.value | {};
    const timezone = vehicleValue.timezone || "Asia/Qatar";

    this.setState({
      loading: true,
      selected: { start: this.state.startTime, end: this.state.endTime },
    });

    TrackingBasicGetByTimeRangeOptimised(
      user.userToken,
      vehicleId,
      startTime,
      endTime,
      timezone
    ).then((response) => {
      this.setState({ loading: false });
      if (response && Array.isArray(response)) {
        let avgSpeedSum = 0;
        let avgSpeedCount = 0;
        let avgSpeed = 0;
        const topSpeed = response.length
          ? response.reduce(function (prev, current) {
              avgSpeedSum += current.speed > 9 ? current.speed : 0;
              avgSpeedCount += current.speed > 9 ? 1 : 0;
              return prev.speed > current.speed ? prev : current;
            })
          : {};

        try {
          avgSpeed = Math.floor(avgSpeedSum / avgSpeedCount);
        } catch (err) {}

        this.setState({ wayPoints: response, topSpeed, avgSpeed });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({ startTime, endTime });
    if (this.state.selectedVehicle) {
      setTimeout(() => {
        this.onDateRangeGo();
      }, 5);
    }
  };

  onSuggestionSelect = (item) => {
    this.setState({
      startTime: moment(item.first_start),
      endTime: moment(item.last_end),
    });
    setTimeout(() => {
      this.onDateRangeGo();
    }, 5);
  };

  render() {
    let duration = "";
    let titleLabel = "Speed Chart";
    try {
      duration = AppHelperDurationAsHHmm(
        this.state.startTime.toDate(),
        this.state.endTime.toDate()
      );
    } catch (err) {}
    let selectedVehicle = this.state.selectedVehicle || {};
    let selectedVehicleValue =
      selectedVehicle.value || this.state.selectedVehicle || {};
    if (
      this.state.selectedVehicle !== {} &&
      this.state.selectedVehicle !== null
    ) {
      titleLabel = `Speed Chart - ${selectedVehicleValue.item.title} ${
        selectedVehicleValue.item.model
      } |  ${moment(this.state.startTime).format("Do MMM, h:mm a")} to ${moment(
        this.state.endTime
      ).format("Do MMM , h:mm a")}`;
    }
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="4" lg="4" md="6" sm="12">
                    <VehicleSelect onChange={this.handleChangeVehicle} />
                  </Colxx>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker onChange={this.handleChangeDateRange} />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={this.onDateRangeGo}
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <div className="card-body-min-height">
                  <Row>
                    {this.state.avgSpeed ? (
                      <Colxx xl="3" lg="4" sm="3" className="text-muted">
                        <IntlMessages id="report.avg.speed" />
                        <p className="lead color-theme-1 mb-1 value">
                          {this.state.avgSpeed}
                        </p>
                      </Colxx>
                    ) : (
                      ""
                    )}

                    {this.state.wayPoints.length ? (
                      <Colxx xl="3" lg="4" sm="3" className="text-muted">
                        <IntlMessages id="report.duration" />{" "}
                        <small> HH:mm </small>
                        <p className="lead color-theme-1 mb-1 value">
                          {duration}
                        </p>
                      </Colxx>
                    ) : (
                      ""
                    )}

                    {this.state.topSpeed.speed ? (
                      <Colxx xl="4" lg="6" sm="6" className="text-muted">
                        <IntlMessages id="report.top-speed" />
                        <p className="lead color-theme-1 mb-1 value">
                          {this.state.topSpeed.speed}
                        </p>
                        <span>
                          {AppHelperDateAndTimeToString(
                            this.state.topSpeed.updatetime
                          )}{" "}
                          <LocationName
                            place={""}
                            geo={`${this.state.topSpeed.lat},${this.state.topSpeed.log}`}
                            objectItem={this.state.topSpeed}
                            showGoogle
                          />
                        </span>
                      </Colxx>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row>
                    <Colxx xxs="12">
                      <CanvasJS
                        wayPoints={this.state.wayPoints}
                        title={titleLabel}
                        type={"speed"}
                        avgSpeed={this.state.avgSpeed}
                        selectedVehicle={this.state.selectedVehicle || {}}
                      />
                    </Colxx>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user } = authUser;
  return { vehiclesAll: all, user };
};

export default connect(mapStateToProps, { authApiAccessCheck })(SpeedChartPage);
