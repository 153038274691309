import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
 

// import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import DriverAlerts from "../../../containers/reports/driver-alerts";

class PageLayout extends Component {
  render() {
    
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb
              heading="menu.driver-alerts"
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
       <DriverAlerts />
      </Fragment>
    );
  }
}

export default  PageLayout ;
