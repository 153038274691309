import React, { Component } from 'react';
import { Card, CardTitle, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from '../../components/common/CustomBootstrap';
import CustomSubmitButton from '../../components/form-controls/CustomSubmitButton';
import { subscriberSelectDetailsRequest } from '../../redux/actions';
import { authApiAccessCheck } from '../../redux/actions';
import { SubscriberAttributes } from '../../api-tasks/subscriber';
import { NotificationManager } from '../../components/common/react-notifications';
import AppLocale from "../../lang";
import { createIntl } from "react-intl";
import subscriberConst from '../../data-const/subscriber-const';

class CompanyAttributes extends Component {
  constructor(props) {
    super();

    this.state = {
      updating: false,
      liveMapNoDataMin: subscriberConst.liveMap.noDataMin,
      menuSettingsType: subscriberConst.menuSettings.type
    };
  }

  loadInitalValues = () => {
    if (
      this.props.subscriberDetails &&
      this.props.subscriberDetails.attributes
    ) {
      const attributes = this.props.subscriberDetails.attributes;

      this.setState({ liveMapNoDataMin: attributes.liveMap.noDataMin });

      if (attributes.menuSettings) {
        this.setState({ menuSettingsType: attributes.menuSettings.type });
      }

    }
  };

  componentDidMount() {
    this.loadInitalValues();
  }

  componentDidUpdate(preProps) {
    if (preProps.subscriberDetails !== this.props.subscriberDetails) {
      this.loadInitalValues();
    }
  }

  handleSubmit = () => {
    this.setState({ updating: true });

    const user = this.props.user;

    const params = subscriberConst;

    params.liveMap.noDataMin = this.state.liveMapNoDataMin;
    params.menuSettings.type = this.state.menuSettingsType;

    SubscriberAttributes(user.userToken, params).then((res) => {
      this.setState({ updating: false });
      if (res) {
        this.showMessage(res);
        this.props.authApiAccessCheck(this.props.history, res);
        this.props.subscriberSelectDetailsRequest(this.props.history);
      }
    });
  };

  showMessage = (response) => {
    if (response.status === 'success') {
      NotificationManager.success(
        response.message,
        response.status.toUpperCase(),
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.error(
        response.message,
        response.status.toUpperCase(),
        3000,
        null,
        null,
        ''
      );
    }
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    // const subscriberDetails = this.props.subscriberDetails || {};
    return (
      <Colxx xl="6" lg="6" md="12" className="mb-4">
        <Card>
          <CardBody>
            <CardTitle><IntlMessages id="profile.attribute-edit" /></CardTitle>

            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <th> # </th>
                  <th> <IntlMessages id="profile.section" /> </th>
                  <th> <IntlMessages id="profile.attribute" /> </th>
                  <th> <IntlMessages id="profile.value" /> </th>
                </thead>
                <tbody>
                  <tr>
                    <td> 1 </td>
                    <td> <IntlMessages id="profile.live-map" /> </td>
                    <td> <IntlMessages id="profile.no-data" /> </td>
                    <td>
                      <select
                        className="form-control"
                        value={this.state.liveMapNoDataMin}
                        onChange={(e) => {
                          this.setState({ liveMapNoDataMin: e.target.value });
                        }}
                      >
                        <option value="14400">10d</option>
                        <option value="2880">48h</option>
                        <option value="120">2h</option>
                        <option value="15">15m</option>
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <td> 2 </td>
                    <td> Menu Settings </td>
                    <td> Type </td>
                    <td>
                      <select
                        className="form-control"
                        value={this.state.menuSettingsType}
                        onChange={(e) => {
                          this.setState({ menuSettingsType: e.target.value });
                        }}
                      >
                        <option value="ordinary">Ordinary</option>
                        <option value="basic">Basic</option>

                      </select>
                    </td>
                  </tr>


                  <tr>
                    <td colSpan="4">
                      <CustomSubmitButton
                        text={intl.formatMessage({
                          id: "common.update",
                        })}
                        validating={false}
                        updating={this.state.updating}
                        onClick={this.handleSubmit}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </CardBody>
        </Card>
      </Colxx>
    );
  }
}

const mapStateToProps = ({ authUser, subscriber, settings }) => {
  const { user } = authUser;
  const { details } = subscriber;
  const { locale } = settings;
  return { user, subscriberDetails: details, locale };
};

export default connect(mapStateToProps, {
  subscriberSelectDetailsRequest,
  authApiAccessCheck,
})(injectIntl(CompanyAttributes));
