// const FIFTEEN_MIN_IN_MILLISECOND = 15 * 60 * 1000;

// move marker from position current to moveto in t seconds
function animatedMove(marker, t, current, moveto) {
  var lat = current.lat;
  var lng = current.lng;

  var movetolat = moveto.lat;
  var movetolng = moveto.lng;

  var deltalat = (movetolat - lat) / 100;
  var deltalng = (movetolng - lng) / 100;

  var delay = 10 * t;
  for (var i = 0; i < 100; i++) {
    // eslint-disable-next-line no-loop-func
    (function(ind) {
      setTimeout(function() {
        var lat = marker.position.lat();
        var lng = marker.position.lng();
        lat += deltalat;
        lng += deltalng;

        marker.setPosition({ lat, lng });
      }, delay * ind);
    })(i);
  }
}

const MegaMapMarker = function getMarker(
  masterContainer,
  info,
  vehicle,
  mapMarker,
  geoPosition
) {
  let _newMarker = {
    id: info.vehicle_id,
    mapContainer: masterContainer,
    gpstime: info.gpstime,
    showMore: true,
    position: geoPosition,
    path: [],
    polyline: null,
    vehicle: vehicle,
    visible: true,
    mapMarker: mapMarker,
    show(flag, showInfo) {
      if (this.visible === flag) {
        return;
      }
      this.visible = flag;

      if (flag) {
        this.mapMarker.setVisible(true);
       
        if (this.polyline) {
          this.polyline.setVisible(true);
        }
      } else {
        
        this.mapMarker.setVisible(false);
        if (this.polyline) {
          this.polyline.setVisible(false);
        }
      }
    },

    updateInfo: function(newInfo, noDataTime) {
      let megaMarker = this;

      const timeNow = new Date();

      if (this.path > 2 && this.gpstime === newInfo.gpstime) {
        return;
      }

      let _position = {
        lat: parseFloat(newInfo.lat),
        lng: parseFloat(newInfo.lng)
      };
      let ing = newInfo.ignition ? "On" : "Off";

      this.gpstime = newInfo.gpstime;
      if (newInfo.speed > 0 && ing === "On" ) {
        animatedMove(this.mapMarker, 13, this.position, _position);
      } else {
        this.mapMarker.setPosition(_position);
        this.path = [];

      }
      this.position = _position;
      // this.mapMarker.setPosition(_position);

      if (this.path.length > 2) {
        if (this.path[this.path.length - 1] !== _position) {
          this.path.push(_position);
        }
      } else {
        this.path.push(_position);
      }

      if (this.path.length > 2) {
        if (this.polyline === null) {
          const newPolyline = new this.mapContainer.maps.Polyline({
            path: this.path,
            geodesic: true,
            strokeColor: "#44b6ae",
            strokeOpacity: 0.6,
            strokeWeight: 2
          });

          newPolyline.setMap(this.mapContainer.map);

          this.polyline = newPolyline;
        } else {
          if (this.path.length > 30) {
            this.polyline.setPath(
              this.path.slice(this.path.length - 30, this.path.length)
            );
          } else {
            this.polyline.setPath(this.path);
          }
        }
      }

      if (ing === "Off") {
        const isNoData =  newInfo.gpstime ? ( timeNow - new Date(newInfo.updatetime) < noDataTime ? false : true ) : true ;

        if(isNoData){
          megaMarker.mapMarker.setIcon(masterContainer.mapIcons.NoData);
        }else{
          megaMarker.mapMarker.setIcon(masterContainer.mapIcons.Stopped);
        }

      } else {
        if (newInfo.speed === 0) {
          megaMarker.mapMarker.setIcon(masterContainer.mapIcons.Idle);
        } else {
          megaMarker.mapMarker.setIcon(masterContainer.mapIcons.Running);
        }
      }
    },
    setZoomAndCenter(self) {
      self.mapContainer.map.setCenter(self.position);
      self.mapContainer.map.setZoom(18);
    },
    
    setShowMore(show) {
      this.showMore = show;
    }
  };

 

  return _newMarker;
};

module.exports = MegaMapMarker;
