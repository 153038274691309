import React from "react";
import { createIntl } from "react-intl";

import { Colxx } from "../components/common/CustomBootstrap";
import AppLocale from "../lang";

class MegaMapSideList extends React.Component {
  showTime(info) {
    if (info.vehicleState === "no-data") {
      return (
        <small title="Time">{info.timeHBMoment.format("DD-MMM-hh:mma")}</small>
      );
    }

    return (
      <small title="Time">
        {info.vehicleState === "idle" && info.theIdleTime
          ? "Since " + info.theIdleTime
          : info.theTime}
      </small>
    );
  }

  render() {
    const vehicles = this.props.vehicles || [];
    const searchText = this.props.searchText || "";
    const onSearchChange = this.props.onSearchChange || {};
    const onVehicleClick = this.props.onVehicleClick || {};

    const currentAppLocale = AppLocale[this.props.locale];
    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });

    window.localsss = intl;

    return (
      <Colxx xl="2" lg="2" md="12">
        <div className="dashboard-map overflow-h">
          <input
            type="text"
            className="form-control"
            placeholder={intl.formatMessage({
              id: "tracking.search-vehicles",
            })}
            value={searchText}
            onChange={onSearchChange}
          />
        </div>
        <div className="dashboard-map scroll2 w-100 ">
          {vehicles.map((x, index) => {
            const class1 = x.vehicleState;

            if (x.reminigDays < 0 && x.expiryExtensionDays < 1) {
              return (
                <div key={index} className={`d-block bg-yellow`}>
                  <span className="d-inline-block">
                    <h6 className="m-0 p-0">
                      {x.plate_number} {x.fleet_number}
                    </h6>
                  </span>

                  <span className="d-inline-block">
                    <small>Expired since {Math.abs(x.reminigDays)} days </small>
                  </span>
                </div>
              );
            }

            if (x.noData && !x.gpstime) {
              return (
                <div key={index} className={`d-block bl-data`}>
                  <span className="d-inline-block">
                    <h6 className="m-0 p-0">
                      {x.plate_number} {x.fleet_number}
                    </h6>
                  </span>

                  <span className="d-inline-block">
                    <small> No data </small>
                  </span>
                  {x.reminigDays < 0 && x.expiryExtensionDays > 0 ? (
                  <small className="bg-yellow">
                    Expiry extension {x.expiryExtensionDays} days
                  </small>
                ) : null}
                </div>
              );
            }

            return (
              <div
                key={index}
                className={`d-block bl-${class1}`}
                onClick={() => {
                  onVehicleClick(x.autoid);
                }}
              >
                <span className="d-inline-block">
                  <h6 className="m-0 p-0">
                    {x.plate_number} {x.fleet_number}
                  </h6>
                </span>

                {x.reminigDays < 0 && x.expiryExtensionDays > 0 ? (
                  <small className="bg-yellow">
                    Expiry extension {x.expiryExtensionDays} days
                  </small>
                ) : null}

                {class1 !== "off" ? (
                  <span className="d-inline-block ml-1" title="kmph">
                    <i className="simple-icon-speedometer" /> {x.epic.speed}
                  </span>
                ) : (
                  ""
                )}

                <span className="d-inline-block ml-1">
                  <a
                    title="Show on google map"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://www.google.com/maps/place/${x.epic.lat},${x.epic.lng}`}
                  >
                    <i className="simple-icon-social-google"></i>
                  </a>
                </span>

                {x.epic.fuel > 0 ? (
                  <span className="d-inline-block ml-1" title="ltr">
                    <i className="iconsminds-gas-pump" /> {x.epic.fuel}
                  </span>
                ) : (
                  ""
                )}
                <span className="d-inline-block ml-1">
                  <small title="Driver"> {x.driverName}</small>
                </span>
                <span className="d-inline-block ml-1">
                  <small title="Model"> {x.model} </small>
                </span>

                <span className="d-inline-block ml-1">{this.showTime(x)}</span>
              </div>
            );
          })}
        </div>
      </Colxx>
    );
  }
}

export default MegaMapSideList;
