import axios from "axios";
import config from "./config";

const proxyServer = config.supportApi + "routes-app/service-schedule";

export const GetServiceSchedules = (userToken) => {
  const criterion = { userToken };

  return axios
    .post(proxyServer + "/get-service-schedule-list", { criterion })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
