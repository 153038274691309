function isNumber(evt) {
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

function isInfoWindowOpen(infoWindow) {
  var map = infoWindow.getMap();
  return (map !== null && typeof map !== "undefined");
}

const mapDrawing = {
  map: null,
  maps: null,
  drawingManager: null,
  infowindow: null,
  infowindowFavoriteForm: null,
  newPolygon: null,
  newMarker: null,
  addNewCallback: null,
  addNewFavCallback: null,
  pathPoints: "",
  favGeoPoints: "",
  init(map, maps, callBackOnPolygonComplete, addNewCallback, addNewFavCallback) {
    let self = this;
    self.addNewCallback = addNewCallback;
    self.addNewFavCallback = addNewFavCallback;
    const dm = new maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        // drawingModes: ['marker', 'circle', 'polygon', 'polyline', 'rectangle']
        drawingModes: [maps.drawing.OverlayType.POLYGON,
        maps.drawing.OverlayType.MARKER]
      },
      polygonOptions: {
        clickable: false,
        editable: true,
        zIndex: 1
      },
      markerOptions: {
        draggable: true,
      },
    });

    dm.setMap(map);

    this.infowindowFavoriteForm = new maps.InfoWindow({
      content: this.getFavoriteForm(this)
    });

    maps.event.addListener(dm, "markercomplete", function (marker) {
     // console.log('markercomplete event at', marker.getPosition().lat(), marker.getPosition().lng());

      marker.addListener("click", () => {

        if (!isInfoWindowOpen(self.infowindowFavoriteForm)) {
          self.infowindowFavoriteForm.open(map, marker);
        }

      });

      const geoPosition = marker.getPosition() ;

      self.infowindowFavoriteForm.open(map, marker);
      self.newMarker = marker;
      self.favGeoPoints = `${geoPosition.lat().toFixed(6)},${geoPosition.lng().toFixed(6)}`

      dm.setDrawingMode(null);

    });

    maps.event.addListener(dm, "circlecomplete", function (circle) {
      console.log('circlecomplete event', circle);
    });

    maps.event.addListener(dm, "polygoncomplete", function (polygon) {
      let coordinates = polygon.getPath().getArray();
      let pathPoints = [];
      coordinates.forEach(point => {
        pathPoints.push(point.toString());
      });

      self.pathPoints = pathPoints;
      self.showForm(coordinates);

      if (callBackOnPolygonComplete) {
        callBackOnPolygonComplete(pathPoints);
      }

      if (self.newPolygon) {
        self.newPolygon.setMap(null);
      }

      self.newPolygon = polygon;
    });

    this.infowindow = new maps.InfoWindow({
      content: this.getForm(this)
    });

    this.map = map;
    this.maps = maps;
    this.drawingManager = dm;

    return this;
  },
  showForm(coordinates) {
    if (coordinates && coordinates.length) {
      this.infowindow.setPosition(coordinates[0]);
      this.infowindow.open(this.map);
    }
    return;
  },
  dismiss: function (self) {
    self.infowindow.setMap(null);
    self.newPolygon.setMap(null);
    self.poinameInput.value = "";
    self.radiuInput.value = "0";
  },
  dismissFav: function (self) {
    self.infowindowFavoriteForm.setMap(null);
    self.newMarker.setMap(null);

    self.favNameInput.value = "";
    self.favType.value = "";
    self.favLabelError.innerText = "";
 

  },
  addNew: function (self) {
    if (self.poinameInput.value.length < 3) {
      self.labelError.innerText = "Please enter a valid name";
      return;
    }
    if (self.addNewCallback) {
      self.addNewCallback(
        self.poinameInput.value,
        self.radiuInput.value,
        self.pathPoints
      );

    }
    self.dismiss(self);
    self.drawingManager.setDrawingMode(null);
  },

  addNewFavoritePlace : function (self) {
    if (self.favNameInput.value.length < 3) {
      self.favLabelError.innerText = "Please enter a valid name";
      return;
    }
    if (self.addNewFavCallback) {
      self.addNewFavCallback(
        self.favNameInput.value,
        self.favGeoPoints,
         0, //radius
        self.favType.value,
         1 // type
       
      );

    }
    self.dismissFav(self);
    self.drawingManager.setDrawingMode(null);
  },
  getForm(self) {
    let htmlObject = document.createElement("div");
    htmlObject.setAttribute("style", "width: 200px");

    let h3 = document.createElement("h4");
    h3.innerText = "Add Geofence";

    let div2 = document.createElement("div");

    let labelName = document.createElement("label");
    labelName.innerText = "Geofence Name";
    let inputName = document.createElement("input");
    inputName.setAttribute("type", "text");
    inputName.setAttribute("class", "form-control");
    inputName.setAttribute("name", "poi_name");

    let labelRadius = document.createElement("label");
    labelRadius.innerText = "Speed Limit(kmph)";
    let inputRadius = document.createElement("input");
    inputRadius.setAttribute("type", "text");
    inputRadius.setAttribute("class", "form-control");
    inputRadius.setAttribute("name", "radius");
    inputRadius.onkeypress = function (event) { return isNumber(event) };


    let buttonCancel = document.createElement("button");
    buttonCancel.setAttribute("type", "button");
    buttonCancel.setAttribute("class", "mr-1 btn btn-secondary btn-xs");
    buttonCancel.innerText = "Cancel";

    buttonCancel.addEventListener("click", function () {
      self.dismiss(self);
    });

    let buttonAdd = document.createElement("button");
    buttonAdd.setAttribute("type", "button");
    buttonAdd.setAttribute("class", "mr-1 btn btn-primary btn-xs");
    buttonAdd.innerText = "Add ";

    buttonAdd.addEventListener("click", function () {
      self.addNew(self);
    });

    let br = document.createElement("br");
    let labelError = document.createElement("label");

    div2.appendChild(labelName);
    div2.appendChild(inputName);
    div2.appendChild(labelRadius);
    div2.appendChild(inputRadius);

    div2.appendChild(br);
    div2.appendChild(buttonAdd);
    div2.appendChild(buttonCancel);
    div2.appendChild(labelError);

    htmlObject.appendChild(h3);
    htmlObject.appendChild(div2);

    this.poinameInput = inputName;
    this.radiuInput = inputRadius;
    this.labelError = labelError;

    inputRadius.value = "0";

    return htmlObject;
  },
  getFavoriteForm(self) {

    let htmlObject = document.createElement("div");
    htmlObject.setAttribute("style", "width: 200px");

    let h3 = document.createElement("h4");
    h3.innerText = "Add Favorite";

    let div2 = document.createElement("div");

    let labelName = document.createElement("label");
    labelName.innerText = "Name";
    let inputName = document.createElement("input");
    inputName.setAttribute("type", "text");
    inputName.setAttribute("class", "form-control");
    inputName.setAttribute("name", "poi_name");

    let labelRadius = document.createElement("label");
    labelRadius.innerText = "Label";
    let inputRadius = document.createElement("SELECT");
    inputRadius.setAttribute("class", "form-control");
    inputRadius.innerHTML = `
    <option value=""> None </option>
    <option value="A">A</option>
    <option value="B">B</option>
    <option value="C">C</option>
    <option value="D">D</option>
    <option value="E">E</option>
    <option value="F">F</option>
    <option value="G">G</option>
    <option value="H">H</option>
    <option value="I">I</option>
    <option value="J">J</option>
    <option value="K">K</option>
    <option value="L">L</option>
    <option value="M">M</option>
    <option value="N">N</option>
    <option value="O">O</option>
    <option value="P">P</option>
    <option value="Q">Q</option>
    <option value="R">R</option>
    <option value="S">S</option>
    <option value="T">T</option>
    <option value="U">U</option>
    <option value="V">V</option>
    <option value="W">W</option>
    <option value="X">X</option>
    <option value="Y">Y</option>
    <option value="Z">Z</option>  `;



    let buttonCancel = document.createElement("button");
    buttonCancel.setAttribute("type", "button");
    buttonCancel.setAttribute("class", "mr-1 btn btn-secondary btn-xs");
    buttonCancel.innerText = "Cancel";

    buttonCancel.addEventListener("click", function () {
      self.dismissFav(self);
    });

    let buttonAdd = document.createElement("button");
    buttonAdd.setAttribute("type", "button");
    buttonAdd.setAttribute("class", "mr-1 btn btn-primary btn-xs");
    buttonAdd.innerText = "Add ";

    buttonAdd.addEventListener("click", function () {
      self.addNewFavoritePlace(self);
    });

    let br = document.createElement("br");
    let labelError = document.createElement("label");

    div2.appendChild(labelName);
    div2.appendChild(inputName);
    div2.appendChild(labelRadius);
    div2.appendChild(inputRadius);

    div2.appendChild(br);
    div2.appendChild(buttonAdd);
    div2.appendChild(buttonCancel);
    div2.appendChild(labelError);

    htmlObject.appendChild(h3);
    htmlObject.appendChild(div2);

    inputRadius.value = "";

    this.favNameInput = inputName;
    this.favType = inputRadius;
    this.favLabelError = labelError;

    return htmlObject;
  }
};

module.exports = mapDrawing;
