import React from "react";
import { ButtonGroup } from "reactstrap";

import XButton from "./common/XButton";

class GroupRowItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateBtn: {
        cssClass: "btn btn-default btn-xs d-none",
        btnText: "Delete"
      },
      editBtn: { cssClass: "btn btn-default btn-xs", btnText: "Edit" },
      editMode: false
    };
  }

  componentDidMount() {
    const item = this.props.item || {};
    this.setState({
      groupName: item.group_name,
      groupDescription: item.group_details
    });
  }

  componentDidUpdate() {}

  componentWillUnmount(){}

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOnRemove = () => {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.item);
    }
  };

  handleOnUpdate = () => {
    
    this.setState({
      editMode: false,
      editBtn: { cssClass: "btn btn-default btn-xs", btnText: "Edit" },
      updateBtn: { cssClass: "btn btn-default btn-xs d-none", btnText: "Delete" },
    });
    if (this.props.onUpdate) {
      this.props.onUpdate(this.props.item.autoid, this.state.groupName, this.state.groupDescription);
    }
  };

  onEdit = () => {
    if (this.state.editBtn.btnText === "Cancel") {
        const item = this.props.item || {};
      this.setState({
        editMode: false,
        updateBtn: {
          cssClass: "btn btn-default btn-xs d-none",
          btnText: "Delete"
        },
        groupName: item.group_name,
      groupDescription: item.group_details
      });

      this.setState({
        editBtn: { cssClass: "btn btn-default btn-xs", btnText: "Edit" }
      });
    } else if (this.state.editBtn.btnText === "Edit") {
      this.setState({ editMode: true });
      this.setState({
        updateBtn: { cssClass: "btn btn-primary btn-xs", btnText: "Update" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-warning btn-xs", btnText: "Cancel" }
      });

      // this.setState({ Executive_name: this.props.itemObject.name });
      // this.setState({ short_code: this.props.itemObject.short_code });
    }
  };

  render() {
    const item = this.props.item || {};
    const number = this.props.number || 0;

    if (this.state.editMode) {
      return (
        <tr>
          <th scope="row">{number + 1}</th>

          <td>
            <input
              type="text"
              className="form-control"
              name="groupName"
              
              onChange={this.handleChange}
              value={this.state.groupName}
            />
          </td>
          <td>
            <input
              type="text"
              className="form-control"
              name="groupDescription"
              onChange={this.handleChange}
              value={this.state.groupDescription}
            />
          </td>

          <td>
            <ButtonGroup className="flex-wrap float-right">
              <button
                type="button"
                onClick={this.onEdit}
                className={this.state.editBtn.cssClass}
              >
                {this.state.editBtn.btnText}
              </button>

              <button
                type="button"
                className={this.state.updateBtn.cssClass}
                onClick={this.handleOnUpdate}
              >
                {this.state.updateBtn.btnText}
              </button>
            </ButtonGroup>
          </td>
        </tr>
      );
    }

    return (
      <tr>
        <th scope="row">{number + 1}</th>

        <td>{this.state.groupName} </td>
        <td>{this.state.groupDescription}</td>

        <td>
          <ButtonGroup className="flex-wrap float-right">
            <button
              type="button"
              onClick={this.onEdit}
              className={this.state.editBtn.cssClass}
            >
              {this.state.editBtn.btnText}
            </button>

            <button
              type="button"
              className={this.state.updateBtn.cssClass}
              onClick={this.handleOnUpdate}
            >
              {this.state.updateBtn.btnText}
            </button>

            <XButton
              onAction={this.handleOnRemove}
              value={item}
              title="Remove group"
            />
          </ButtonGroup>
        </td>
      </tr>
    );
  }
}

export default GroupRowItem;
