import React from "react";

import Select from "react-select";
import { connect } from "react-redux";
import {  groupSelectRequest } from "../redux/actions";
import CustomSelectInput from "./common/CustomSelectInput";
import AppLocale from "../lang";
import { createIntl } from "react-intl";

const customFilterOption = (option, rawInput) => {
  if (option && rawInput) {
    const words = rawInput.split(" ");
    return words.reduce(
      (acc, cur) =>
        acc &&
        option.data.searchWords.toLowerCase().includes(cur.toLowerCase()),
      true
    );
  } else {
    return true;
  }
};

const LabelWithIcon = item => {
  return (
    <div key={item.autoid}>
      <strong>{item.group_name}</strong>
      <small>{item.group_details}</small>
    </div>
  );
};

class GroupSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedGroup: null
    };
  }

  componentDidMount() {
    const allData = this.props.groupAll;
    if (allData && allData.length) {
      this.generateOptions();
    }

    if(!this.props.groupAll){
      this.props.groupSelectRequest(this.props.history);
    }

    

  }

  componentDidUpdate(prevProps) {
    if (prevProps.groupAll !== this.props.groupAll) {
      this.generateOptions();
      
    }
  }

  generateOptions = () => {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    const groupAll = this.props.groupAll;
    const options = groupAll.map(item => ({
      label: LabelWithIcon(item),
      value: item.autoid,
      searchWords:
        item.group_name +
        " " +
        item.group_details,
      key: item.autoid,
      ...item
    }));

    const optionsWithDefault = [
      {
        label: LabelWithIcon({ autoid: -1, group_name: intl.formatMessage({
          id: "group.group-not-selected",
        }),
        group_details: "", }),
        value: -1,
        searchWords: "",
        key: -1,
        autoid: -1,
        group_name: "",
        group_details: "",
      }
    , ...options ]

    this.setState({ options : optionsWithDefault });

    if (this.props.initialValue) {
        
      const result = optionsWithDefault.filter(el => el.autoid === this.props.initialValue); 
 
        if(result && Array.isArray(result) && result[0]){
          this.setState({ selectedGroup: result[0] });
          if (this.props.onChange) {
            this.props.onChange(result[0]);
          }
        }
    }
  };

  handleChange = selectedGroup => {
    this.setState({ selectedGroup });

    if (this.props.onChange) {
      this.props.onChange(selectedGroup);
    }
  };

  render() {
    const value = this.state.selectedGroup || null;
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <div className="w-100">
      <Select
        filterOption={customFilterOption}
        components={{ Input: CustomSelectInput }}
        className="react-select"
        classNamePrefix="react-select"
        name="allGroups"
        placeholder={intl.formatMessage({
          id: "group.group-select",
        })}
        options={this.state.options}
        onChange={this.handleChange}
        value={value}
      />
      </div>
    );
  }
}

const mapStateToProps = ({ groups,settings }) => {
  const { items, loading } = groups;
  const {locale}=settings;
  return { groupAll: items, groupLoading: loading,locale };
};

export default connect(mapStateToProps, {
  groupSelectRequest
})(GroupSelect);
