import React from "react";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  CustomInput,
} from "reactstrap"; 
import {  ExcelRenderer } from "react-excel-renderer";
import * as XLSX from "xlsx"; 
import IntlMessages from "../helpers/IntlMessages";
import { Colxx } from "../components/common/CustomBootstrap";
import { NotificationManager } from "../components/common/react-notifications";

function getJsDateFromExcel(excelDate) {
  // JavaScript dates can be constructed by passing milliseconds
  // since the Unix epoch (January 1, 1970) example: new Date(12312512312);

  // 1. Subtract number of days between Jan 1, 1900 and Jan 1, 1970, plus 1  (Google "excel leap year bug")
  // 2. Convert to milliseconds.

  let theDate = new Date((excelDate - (25567 + 1)) * 86400 * 1000);

  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let day = theDate.getDate();

  let monthIndex = theDate.getMonth();
  let monthName = monthNames[monthIndex];

  let year = theDate.getFullYear();

  return `${day}-${monthName}-${year}`;
}

class LiveStatusCustom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allRows: [],
      fileName: "Ctraac - live_status_cu.csv",
      dataLoaded: false,
      isFormInvalid: false,
      rows: null,
      cols: null,
      excelRows: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.allRows !== this.props.allRows) {
      this.setState({
        allRows: this.props.allRows,
      });
    }
  }

  fileHandler = (event) => {
    let fileObj = event.target.files[0];
    let fileName = fileObj.name;

    if (this.props.allRows && !this.props.allRows.length) {
      NotificationManager.error(
        "Please select all vehicles",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    if (fileName && fileName.slice(fileName.lastIndexOf(".") + 1) !== "xlsx") {
      // TODO: Error notification
      NotificationManager.error(
        "please select a xlsx file",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let plateNumberIndex = -1;
        let headdress = resp.rows[0];

        let dateIndexs = [];

        for (let index = 0; index < headdress.length; index++) {
          const element = headdress[index];

          if (
            element.toLowerCase().includes("plate number") ||
            element.includes("رقم اللوحة")
          ) {
            plateNumberIndex = index;
          }

          if (
            element.toLowerCase().includes("date") ||
            element.includes("تاريخ")
          ) {
            dateIndexs.push(index);
          }
        }

        headdress.splice(plateNumberIndex + 1, 0, "CTRAACStatus");
        headdress.splice(plateNumberIndex + 2, 0, "CTRAACReminingDays");
 

        const excelRows = resp.rows;
        excelRows[0] = headdress;

        let rowVehicleNumber = "";
        let rowCTRAACStatus = "";
        let rowCTRAACReminingDays = "";
        for (let i = 1; i < resp.rows.length; i++) {
          const elementCol = resp.rows[i];

          rowVehicleNumber = "";
          rowCTRAACStatus = "";
          rowCTRAACReminingDays = "";

          dateIndexs.forEach((di) => {
            elementCol[di] = elementCol[di]
              ? getJsDateFromExcel(elementCol[di])
              : "";
          });

          rowVehicleNumber = elementCol[plateNumberIndex] || "";
          rowVehicleNumber = rowVehicleNumber.toString();

          for (let k = 0; k < this.props.allRows.length; k++) {
            const elVehicle = this.props.allRows[k];

            if (elVehicle.plateNumber === rowVehicleNumber) {
              rowCTRAACStatus = elVehicle.status;
              rowCTRAACReminingDays = elVehicle.reminigDays;
               
              k = this.props.allRows.length; // to exit from loop
            }
          }

          if (rowVehicleNumber) {
            elementCol.splice(plateNumberIndex + 1, 0, rowCTRAACStatus);
            elementCol.splice(plateNumberIndex + 2, 0, rowCTRAACReminingDays);
          }

          excelRows[i] = elementCol;
        }

        this.setState({
          fileName: fileName.replace(".xlsx", ""),
          dataLoaded: true,
          cols: resp.cols,
          rows: resp.rows,
          excelRows: excelRows,
        });

        // console.log("mehabooba rows", resp.rows);
      }
    });
  };

  downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, this.state.fileName + "-cu.xlsx");
  };

  render() {
    return (
      <React.Fragment>
        <Colxx xxs="6">
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              Custom Template
            </InputGroupAddon>
            <CustomInput
              type="file"
              color="secondary"
              id="exampleCustomFileBrowser1"
              name="customFile"
              onChange={this.fileHandler.bind(this)}
            />
          </InputGroup>
        </Colxx>
        <ul>
          <li> Only .xlsx are permitted </li>
          <li>The document should contain column رقم اللوحة or plate number</li>
          <li>
            If any column contain date the title should contain تاريخ or date
          </li>
          <li> Column title must be zeroth position of row </li>
        </ul>

        {this.state.dataLoaded && (
          <div>
            <Button
              title={this.state.fileName}
              size="xs"
              outline
              color="dark"
              onClick={() => this.downloadExcel(this.state.excelRows)}
            >
              <IntlMessages id="common.download" /> {this.state.fileName}
            </Button>

             
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default LiveStatusCustom;
