import React, { Component } from "react";
import { Row, Button, ButtonGroup, CardBody, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { authApiAccessCheck } from "../../redux/actions";

import {
  VehicleControlSelectByVehicle,
  VehicleControlUpdateControl,
  VehicleControlUpdateMultipleControl
} from "../../api-tasks/vehicles";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleControlItem from "../../components/vehicle/VehicleControlItem";
import { NotificationManager } from "../../components/common/react-notifications";
import DropStateButton from "../../components/DropStateButton";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

class VehicleControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      allDataRows: []
    };
  }

  componentDidMount() {
    this.loadControls();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedVehicle !== this.props.selectedVehicle) {
      this.loadControls();
    }
  }

  loadControls = () => {
    const user = this.props.user;
    const vehicle = this.props.selectedVehicle;

    const fnInput = {
      userToken: user.userToken,
      vehicle_id: vehicle.autoid
    };

    this.setState({ loading: true });

    VehicleControlSelectByVehicle(fnInput).then(res => {
      this.setState({ loading: false });
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ allDataRows: res });
        } else {
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  showMessage = response => {
    if (response.status === "success") {
      NotificationManager.success(
        response.message,
        response.status.toUpperCase(),
        3000,
        null,
        null,
        ""
      );
    } else {
      NotificationManager.error(
        response.message,
        response.status.toUpperCase(),
        3000,
        null,
        null,
        ""
      );
    }
  };

  updateControls = params => {
    const user = this.props.user;

    const fnInput = {
      ...params,
      userToken: user.userToken
    };

    VehicleControlUpdateControl(fnInput).then(res => {
      if (res) {
        this.showMessage(res);
        this.props.authApiAccessCheck(this.props.history, res);
        this.loadControls();
      }
    });
  };

  Refresh = () => {
    this.loadControls();
  };

  handleOnClickReadControls = () => {
    return new Promise((success, fail) => {
      try {
        const readCommands = [
          { typeCode: 202, command: "$GET,OV,#" },
          { typeCode: 204, command: "$GET,FV,#" },
          { typeCode: 206, command: "$GET,CA,#" },
          { typeCode: 207, command: "$GET,CS,#" },
          { typeCode: 208, command: "$GET,HB,#" },
          { typeCode: 209, command: "$GET,HA,#" },
          { typeCode: 210, command: "$GET,IL,#" },
          { typeCode: 212, command: "$GET,BZ,#" },
          { typeCode: 213, command: "$GET,IMM,#" },
          { typeCode: 213, command: "$GET,PH1,#" },
          { typeCode: 214, command: "$GET,PH2,#" },
          { typeCode: 215, command: "$GET,EX,#" },
          { typeCode: 216, command: "$GET,APN,#" },
          { typeCode: 217, command: "$GET,ODO,#" },
          { typeCode: 218, command: "$GET,OPH,#" }
        ];

        const vehicle = this.props.selectedVehicle;
        const items = readCommands.map(element => ({
          ...element,
          newValue: 0,
          vehicleId: vehicle.autoid,
          deviceType: vehicle.device_type,
          command: element.command
        }));

        VehicleControlUpdateMultipleControl(
          this.props.user.userToken,
          items
        ).then(response => {
          this.setState({ loading: false });
          if (response) {
            if (response.status === "success") {
              success(response.message);
            } else {
              fail(response.message);
              this.props.authApiAccessCheck(this.props.history, response);
            }
          }
        });
      } catch (error) {
        fail("Ooh, unexpected error");
        console.log('error ', error);
      }
    });
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    const vehicle = this.props.selectedVehicle;

    return (
      <Row className="mb-4">
        <Colxx xxs="12">
          <CardBody>
            <ButtonGroup className="flex-wrap float-right">
              <Button
                color="secondary"
                size="sm"
                onClick={this.Refresh}
              >
                {this.state.loading ? (
                  <span className="spinner d-inline-block">
                    <span className="bounce1" />
                    <span className="bounce2" />
                    <span className="bounce3" />
                  </span>
                ) : (
                  <IntlMessages id="common.refresh" />
                )}
              </Button>

              <DropStateButton
                id={`btn-read-vehicle-controls`}
                color="secondary"
                className="btn"
                size="sm"
                title={intl.formatMessage({
                  id: "vehicle.read-controls-device",
                })}
                onClick={this.handleOnClickReadControls}
              >
                <IntlMessages id="vehicle.read-controls" />
              </DropStateButton>
            </ButtonGroup>
            <CardTitle>
            <IntlMessages id="vehicle.controls" /> - {vehicle.title} #{vehicle.autoid}
            </CardTitle>
            <React.Fragment>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col"><IntlMessages id="vehicle.type" /></th>
                      <th scope="col"><IntlMessages id="vehicle.value" /></th>
                      <th scope="col"><IntlMessages id="vehicle.status" /> </th>
                      <th scope="col"><IntlMessages id="vehicle.action" /> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.allDataRows.map((Item, i) => (
                      <React.Fragment key={i}>
                        <VehicleControlItem
                          itemObject={Item}
                          index={i}
                          actionUpdate={this.updateControls}
                        />
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          </CardBody>
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ authUser,settings }) => {
  const { user } = authUser;
  const { locale } = settings;
  return { user, locale };
};

export default connect(
  mapStateToProps,
  { authApiAccessCheck }
)(injectIntl(VehicleControls));
