import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "user";
const proxyServerSubscriber = config.serverApi + "subscriber";

export const SubscribersAuth = async (email, password) => {
  const criterion = {
    loginEmail: email,
    loginPassword: password
  };
  return new Promise((resolve, reject) => {
    axios
      .post(proxyServer + "/subscriber-auth", { criterion })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};


export const SubscribersAuthCheck = async (userToken) => {
    const criterion = { userToken };
    return new Promise((resolve, reject) => {
      axios
        .post(proxyServer + "/token-auth", { criterion })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  export const SubscriberGetByID = async (userToken) => {
    const criterion = { userToken };
    return new Promise((resolve, reject) => {
      axios
        .post(proxyServerSubscriber + "/get-by-id", { criterion })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  export const SubscriberUpdate = async (userToken, items) => {
    const criterion = { userToken, items };
    return new Promise((resolve, reject) => {
      axios
        .post(proxyServerSubscriber + "/update-details", { criterion })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  export const SubscriberAttributes = async (userToken, attributes) => {
    const criterion = { userToken, attributes };
    return new Promise((resolve, reject) => {
      axios
        .post(proxyServerSubscriber + "/update-attributes", { criterion })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
