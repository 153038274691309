import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Row } from "reactstrap";

import moment from "moment";
import MonthPicker from "../../components/MonthPicker";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectBatch from "../../components/VehicleSelectBatch";

import { IgnitionGetSummaryGroupByDate } from "../../api-tasks/ignition";
import { AppHelperDateToDBString } from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";

const options = {
  chart: {
    stacked: false,
  },
  stroke: {
    width: [1, 3, 3, 3],
    curve: "smooth",
  },
  fill: {
    opacity: [0.15, 0.25, 1, 1],
    gradient: {
      inverseColors: false,
      shade: "light",
      type: "vertical",
      opacityFrom: 0.85,
      opacityTo: 0.55,
      stops: [0, 100, 100, 100],
    },
  },

  markers: {
    size: 0,
  },
  xaxis: {
    type: "datetime",
  },
  yaxis: {
    title: {
      text: "Points",
    },
    min: 0,
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: function (y) {
        if (typeof y !== "undefined") {
          return y.toFixed(0) + " points";
        }
        return y;
      },
    },
  },
};

const series = [];

const getSecToHours = (seconds) => {
  try {
    if (!seconds) {
      return 0;
    }
    const h = Math.floor(seconds / 3600);
    // const m = Math.floor((seconds % 3600) / 60);

    return h;
  } catch (error) {
    return 0;
  }
};

const getDateLabel = (theValue) => {
  try {
    const a = new Date(theValue);
    return moment(a).format("MMMD");
  } catch (error) {
    return "";
  }
};

const getInHundreds = (theValue) => {
  try {
    const h = (theValue / 100).toFixed(1);
    // const m = Math.floor((seconds % 3600) / 60);

    return h;
  } catch (error) {
    return "";
  }
};

const getInThousands = (theValue) => {
  try {
    const h = (theValue / 1000).toFixed(1);
    // const m = Math.floor((seconds % 3600) / 60);

    return h;
  } catch (error) {
    return "";
  }
};

const getInTen = (theValue) => {
  try {
    const h = (theValue / 10).toFixed(1);
    // const m = Math.floor((seconds % 3600) / 60);

    return h;
  } catch (error) {
    return "";
  }
};

class ColumnChart extends React.Component {
  constructor(props) {
    super(props);

    const cuOptions = {
      ...options,
      tooltip: {
        custom: this.tooltipCustom,
      },
    };

    this.state = {
      loading: false,
      options: cuOptions,
      series,
      selectedVehicles: [],
      startTime: moment().startOf("month"),
      endTime: moment(new Date()),
      pointDate: "-",
      pointMileage: "-",
      pointVehicleUsed: "-",
      pointEnginHours: "-",
      pointIdleHours: "-",
    };
  }

  tooltipCustom = ({ series, seriesIndex, dataPointIndex, w }) => {
    this.setState({
      pointDate: this.state.series[0].data[dataPointIndex].x,
      pointVehicleUsed: this.state.series[0].data[dataPointIndex].dataValue,
      pointMileage: this.state.series[1].data[dataPointIndex].dataValue,
      pointEnginHours: this.state.series[2].data[dataPointIndex].dataValue,
      pointIdleHours: this.state.series[3].data[dataPointIndex].dataValue,
    });

    return "";
  };

  loadMasters = () => {
    this.setState({ loading: true });
    const user = this.props.user;
    const vehicles = this.state.selectedVehicles || [];

    if (!vehicles.length) {
      return;
    }

    const vehicleIds = vehicles.map((el) => {
      return el.autoid;
    });

    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    this.setState({
      pointDate: "-",
      pointVehicleUsed: "-",
      pointMileage: "-",
      pointEnginHours: "-",
      pointIdleHours: "-",
    });

    IgnitionGetSummaryGroupByDate(
      user.userToken,
      vehicleIds,
      startTime,
      endTime,
      180
    ).then((res) => {
      this.setState({ loading: false });
      if (res) {
        if (Array.isArray(res)) {
          const dataKm = res.map((x) => {
            return {
              x: getDateLabel(x.dated),
              y: getInThousands(x.mileage_sum),
              dataValue: x.mileage_sum,
            };
          });

          const dataEnginHours = res.map((x) => {
            return {
              x: getDateLabel(x.dated),
              y: getInHundreds(getSecToHours(x.totalduration_sec_sum)),
              dataValue: getSecToHours(x.totalduration_sec_sum),
            };
          });

          const dataVehicles = res.map((x) => {
            return {
              x: getDateLabel(x.dated),
              y: getInTen(x.vehicle_count),
              dataValue: x.vehicle_count,
            };
          });

          const dataIdleHours = res.map((x) => {
            return {
              x: getDateLabel(x.dated),
              y: getInHundreds(getSecToHours(x.idl_sec_sum)),
              dataValue: getSecToHours(x.idl_sec_sum),
            };
          });

          const series = [
            { name: "Vehicles used", data: dataVehicles, type: "bar" },
            { name: "Mileage", data: dataKm, type: "area" },
            { name: "Engin hours", data: dataEnginHours, type: "line" },
            { name: "Idle hours", data: dataIdleHours, type: "line" },
          ];

          try {
            this.setState({
              pointDate: series[0].data[0].x,
              pointVehicleUsed: series[0].data[0].dataValue,
              pointMileage: series[1].data[0].dataValue,
              pointEnginHours: series[2].data[0].dataValue,
              pointIdleHours: series[3].data[0].dataValue,
            });
          } catch (e) {}

          this.setState({ series });
        } else {
          // this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  componentDidMount() {
    this.setState({ selectedVehicles: this.props.vehiclesAll || [] });

    setTimeout(() => {
      this.loadMasters();
    }, 10);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.setState({ selectedVehicles: this.props.vehiclesAll || [] });

      setTimeout(() => {
        this.loadMasters();
      }, 10);
    }
  }

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      // series: []
    });

    setTimeout(() => {
      this.loadMasters();
    }, 10);
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    setTimeout(() => {
      this.loadMasters();
    }, 10);
  };

  render() {
    return (
      <Card>
        <CardBody className="w-100">
          <div className="float-right">
            <MonthPicker
              onChange={this.handleChangeDateRange}
              startTime={this.state.startTime}
              endTime={this.state.endTime}
            />{" "}
            <VehicleSelectBatch onChange={this.handleOnSelectedVehicleChange} />
          </div>

          <h5 className="d-inline-block">
            <IntlMessages id="dashboard.vehicle-utlzn" />{" "}
            <span className="text-muted">
              {" "}
              {this.state.startTime.format("MMMM YYYY")}{" "}
            </span>
            {this.state.loading ? (
              <span className="bg-info">
                <span className="spinner d-inline-block">
                  <span className="bounce1" />
                  <span className="bounce2" />
                  <span className="bounce3" />
                </span>
              </span>
            ) : (
              ""
            )}{" "}
          </h5>

          <Row>
            <Colxx xxs="12">
              <span className="d-inline-block mr-4">
                <small className="text-muted">
                  {" "}
                  <IntlMessages id="dashboard.day" />{" "}
                </small>
                <p className="lead color-theme-1 m-0">
                  {this.state.pointDate || ""}
                </p>
              </span>

              <span className="d-inline-block mr-4">
                <small className="text-muted">
                  {" "}
                  <IntlMessages id="dashboard.vehicle-used" />{" "}
                </small>
                <p className="lead color-theme-1 m-0">
                  {this.state.pointVehicleUsed || ""}
                </p>
              </span>

              <span className="d-inline-block mr-4">
                <small className="text-muted">
                  {" "}
                  <IntlMessages id="dashboard.milage" />{" "}
                </small>
                <p className="lead color-theme-1 m-0">
                  {this.state.pointMileage || ""}
                </p>
              </span>

              <span className="d-inline-block mr-4">
                <small className="text-muted">
                  {" "}
                  <IntlMessages id="dashboard.engn-hrs" />{" "}
                </small>
                <p className="lead color-theme-1 m-0">
                  {this.state.pointEnginHours || ""}
                </p>
              </span>

              <span className="d-inline-block mr-4">
                <small className="text-muted">
                  {" "}
                  <IntlMessages id="dashboard.idle-hrs" />{" "}
                </small>
                <p className="lead color-theme-1 m-0">
                  {this.state.pointIdleHours || ""}
                </p>
              </span>
            </Colxx>
          </Row>
          <Row>
            <Colxx xxs="12" style={{direction:"ltr"}}>
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="line"
                height="290"
              />
            </Colxx>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default ColumnChart;
