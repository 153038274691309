import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  Row,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import queryString from "query-string";
import { authApiAccessCheck } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleCard from "../../components/VehicleCard";

import LiveVehicleTabPage from "./live-vehicle-tab-page";
import TripsVehicleTabPage from "./trips-vehicle-tab-page";
import HistoryVehicleTabPage from "./history-vehicle-tab-page";
import GeofenceDrillDownTabPage from "./geofence-drill-down-tab-page";
import IntlMessages from "../../helpers/IntlMessages";

class ViewContainer extends Component {
  constructor(props) {
    super(props);

    const locationState = this.props.location.state || {};

    this.state = {
      activeFirstTab: "2",
      selectedVehicle: locationState.itemObject || {},
    };
  }

  componentDidMount() {
    const queryValue = queryString.parse(this.props.location.search);
    if (!queryValue && !this.props.location.state) {
      this.props.history.push("/app/tracking/live-map");
    }

    this.doSelectVehicle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.doSelectVehicle();
    }
  }

  doSelectVehicle = () => {
    if (!this.state.selectedVehicle.autoid) {
      try {
        const queryValue = queryString.parse(this.props.location.search);
        if (queryValue && queryValue.param1) {
          const selectedVehicle = this.props.vehiclesAll.filter(
            (x) => x.autoid === parseInt(queryValue.param1)
          )[0];
          this.setState({ selectedVehicle: selectedVehicle || {} });
        }
      } catch (error) {}
    }
  };

  toggleFirstTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card>
                  <CardBody>
                    <VehicleCard vehicle={this.state.selectedVehicle} />
                    <CardHeader>
                      <Nav tabs className="card-header-tabs">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeFirstTab === "1",
                              "nav-link": true,
                            })}
                            onClick={() => {
                              this.toggleFirstTab("1");
                            }}
                            to="#"
                          >
                            <IntlMessages id="tracking.trips" />
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeFirstTab === "2",
                              "nav-link": true,
                            })}
                            onClick={() => {
                              this.toggleFirstTab("2");
                            }}
                            to="#"
                          >
                            <IntlMessages id="tracking.live" />
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeFirstTab === "3",
                              "nav-link": true,
                            })}
                            onClick={() => {
                              this.toggleFirstTab("3");
                            }}
                            to="#"
                          >
                            <IntlMessages id="tracking.history" />
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeFirstTab === "4",
                              "nav-link": true,
                            })}
                            onClick={() => {
                              this.toggleFirstTab("4");
                            }}
                            to="#"
                          >
                            <IntlMessages id="common.geofence" />
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </CardHeader>

                    <TabContent activeTab={this.state.activeFirstTab}>
                      <TabPane tabId="1">
                        <TripsVehicleTabPage
                          selectedVehicle={this.state.selectedVehicle}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <LiveVehicleTabPage
                          selectedVehicle={this.state.selectedVehicle}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <HistoryVehicleTabPage
                          selectedVehicle={this.state.selectedVehicle}
                        />
                      </TabPane>

                      <TabPane tabId="4">
                        <GeofenceDrillDownTabPage
                          selectedVehicle={this.state.selectedVehicle}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user } = authUser;
  return { vehiclesAll: all, user };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  withRouter(ViewContainer)
);
