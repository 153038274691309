import React, { Component } from "react";
import { Button } from "reactstrap";

class XButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnText: "Remove",
      btnColor: "secondary",
      btnCancelCss: "d-none"
    };
  }

  componentWillUnmount(){}

  handleChange = selectedOption => {
    if (this.state.btnText === "Remove") {
      this.setState({
        btnText: "Confirm ?",
        btnColor: "danger",
        btnCancelCss: ""
      });
    } else {
      this.setState({ loading: true, btnCancelCss: "d-none" });
      if (this.props.onAction) {
        this.props.onAction(this.props.value);
      }
      setTimeout(() => {
        this.setState({ loading: false });
      }, 3000);
    }
  };

  handleCancel = selectedOption => {
    this.setState({
      btnText: "Remove",
      btnColor: "secondary",
      btnCancelCss: "d-none"
    });
  };

  render() {
    const btnText = this.state.btnText;
    return (
      <React.Fragment>
         <Button
          color="warning"
          outline
          size="xs"
          onClick={this.handleCancel}
          className={this.state.btnCancelCss}
        >
          Cancel
        </Button>
        <Button
          title={this.props.title}
          color={this.state.btnColor}
          size="xs"
          onClick={this.handleChange}
        >
          {this.state.loading ? (
            <span className="spinner d-inline-block">
              <span className="bounce1" />
              <span className="bounce2" />
              <span className="bounce3" />
            </span>
          ) : (
            <React.Fragment> {btnText} </React.Fragment>
          )}
        </Button>
       
      </React.Fragment>
    );
  }
}

export default XButton;
