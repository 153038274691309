
function geo2LatLng(geo) {
    const splits = geo.split(',');
    return {
      lat: parseFloat(splits[0]),
      lng: parseFloat(splits[1]),
    };
  }

var POIItem = {
  map: null,
  maps: null,
  marker: null,
  infowindow: null,
  location: null,
  circle: null,
  onChangeCallback: null,
  dismissCallback: null,
  radius: 500,

  poinameInput: null,
  labelLatLng: null,
  LinkLatLng: null,
  labelRadius: null,
  init: function (map, maps, onChangeCallback, dismissCallback) {
    let self = this;
    self.map = map;
    self.maps = maps;
    self.dismissCallback = dismissCallback;
    self.onChangeCallback = onChangeCallback;

    self.location = map.getCenter();
    self.marker = new maps.Marker({
      map: map,
      draggable: true,
      animation: maps.Animation.DROP,
      position: self.location,
    });

    self.circle = new maps.Circle({
      strokeColor: '#0069d9',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#0069d9',
      fillOpacity: 0.3,
      editable: true,
      map: map,
      center: { lat: self.location.lat(), lng: self.location.lng() },
      radius: self.radius,
    });

    self.infowindow = new maps.InfoWindow({
      content: self.getInfoWindowDom(self),
    });

    // self.infowindow.open(map, self.marker);

    maps.event.addListener(self.marker, 'dragend', function (e) {
      self.locationUpdate(e.latLng);
    });

    maps.event.addListener(self.circle, 'radius_changed', function () {
      if (self.circle.getRadius() <= 3000) {
        self.radiusChanged(self.circle.getRadius());
      } else {
        self.circle.setRadius(3000);
        // self.radiusChanged(3000);
        
      }
    });

    maps.event.addListener(map, 'click', function (e) {
      self.locationUpdate(e.latLng);
    });

    self.marker.addListener('click', function () {
      self.infowindow.open(map, self.marker);
    });

    self.labelLatLng.innerText =
      Math.round(self.location.lat() * 1000000) / 1000000 +
      ',' +
      Math.round(self.location.lng() * 1000000) / 1000000;

      if(this.onChangeCallback){
        this.onChangeCallback(this.labelLatLng.innerText, this.radius);
    }

    self.LinkLatLng.href = 'https://www.google.com/maps/place/' + self.labelLatLng.innerText;

    return self;
  },
  findLocation(geo){
      try {
          
        const points = geo2LatLng(geo);
        if(points.lat && points.lng){
            const latLng = new this.maps.LatLng(points.lat, points.lng );
            this.locationUpdate(latLng);
            this.map.setCenter(latLng);
            // this.map.setZoom(14);
            return true;
        }

      } catch (err) { }

      return false;
   
  },
  radiusChanged: function (changeValue) {
    this.radius = changeValue;
    this.labelRadius.innerText = 'Radius(in meters):' + Math.round(changeValue);
    this.locationUpdate(this.location);

    if(this.onChangeCallback){
        this.onChangeCallback(this.labelLatLng.innerText, this.radius);
    }

  },
  locationUpdate: function (currentPosition) {
    let self = this;
    if (self.location !== currentPosition) {
      self.location = currentPosition;
      self.marker.setPosition(currentPosition);
      self.circle.setCenter(currentPosition);
      // console.log('this.location ', this.location.lat(),this.location.lng()  );
      self.labelLatLng.innerText =
        Math.round(currentPosition.lat() * 1000000) / 1000000 +
        ',' +
        Math.round(currentPosition.lng() * 1000000) / 1000000;

      self.LinkLatLng.href = 'https://www.google.com/maps/place/' + self.labelLatLng.innerText;

        if(self.onChangeCallback){
            this.onChangeCallback(self.labelLatLng.innerText, this.radius);
        }
    }
  },
  dismiss: function () {
    let self = this;
    self.marker.setMap(null);
    self.circle.setMap(null);
    if (self.dismissCallback) {
      self.dismissCallback(self);
    }
  },

  getInfoWindowDom: function (self) {
    let htmlObject = document.createElement('div');
    htmlObject.setAttribute('style', 'width: 200px');

    let div2 = document.createElement('div');

    let labelRadius = document.createElement('label');
    labelRadius.innerText = 'Radius(in meters):' + this.radius;

    let labelLatLng = document.createElement('label');

    let buttonCancel = document.createElement('button');
    buttonCancel.setAttribute('type', 'button');
    buttonCancel.setAttribute('class', 'btn btn-link btn-xs');
    buttonCancel.innerText = 'Dismiss';

    buttonCancel.addEventListener('click', function () {
      self.dismiss();
    });

    let divGoogleMap = document.createElement('div');
    divGoogleMap.innerHTML = '<a'
     + ' title="Show on google map" '
     + ' rel="noopener noreferrer"'
     + ' target="_blank"'
     + ' href="https://www.google.com/maps/place/" >'
     + ' '
    +  ' </a>';

    let linkGoogleMap = divGoogleMap.children[0];

    linkGoogleMap.appendChild(labelLatLng);

    div2.appendChild(labelRadius);

    div2.appendChild(linkGoogleMap);
    div2.appendChild(divGoogleMap);
    div2.appendChild(buttonCancel);

    htmlObject.appendChild(div2);

    this.labelLatLng = labelLatLng;
    this.labelRadius = labelRadius;

    this.LinkLatLng = linkGoogleMap;

     

    return htmlObject;
  },
};

module.exports = POIItem;
