import React from "react";
import { Modal, ModalHeader, ModalBody, Button, Row } from "reactstrap";
import { Colxx } from "../components/common/CustomBootstrap";
import IntlMessages from '../helpers/IntlMessages';

class SuspensionModel extends React.Component {



  render() {


    return (
      <Modal isOpen={this.props.isOpen} size="lg" id="suspention.notice" toggle={this.props.toggle} >
        <ModalHeader toggle={this.props.toggle}>
          Suspention Notice
          </ModalHeader>
        <ModalBody className="bg-danger text-white">


          <Row  >

            <Colxx xxs="12" className="mb-1">

              <h3 >
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              </h3>

            </Colxx>

          </Row>

        </ModalBody>
      </Modal>
    );
  }
}


class SuspensionModelBase extends React.Component {

  constructor(props) {
    super(props);

    this.state = { mapModelOpen: false, isNoticeActive: false }
    this.timer = null;

  }

  componentDidMount(){
    this.startTimer();
  }

  componentWillUnmount(){
    if(this.timer){
      clearInterval(this.timer);
    }
  }

  startTimer = () =>{
    if(this.state.isNoticeActive){
      this.timer = setInterval(this.openPopup, 10000);
    }
  }

  openPopup = () =>{
     
    if(this.state.mapModelOpen){ return; } // if model is open

    this.toggleLarge();
    
  }



  toggleLarge = () => {
    this.setState((prevState) => ({
      mapModelOpen: !prevState.mapModelOpen,
    }));
  };

  render() {


    return (
      <React.Fragment>

        <SuspensionModel

          isOpen={this.state.mapModelOpen}
          closeButton={
            <Button
              color="primary"
              outline
              size="xs"
              className="mb-2"
              onClick={this.toggleLarge}
            >
              <IntlMessages id="common.close" />
            </Button>
          }
          toggle={this.toggleLarge}
        />



      </React.Fragment>
    );
  }
}



export default SuspensionModelBase;
