

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "driver-log";

export const DriverLogGetByVehicles = (userToken, vehicleIds, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-vehicles", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
 