import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";

import { groupSelectSuccess } from "./actions";
import { authApiAccessCheck } from "../auth/actions";

import { GroupSelectBySubscriber } from "../../api-tasks/groups";
import { GROUP_SELECT_REQUEST } from "../actions";

function* selectAll({ payload }) {

  const state = yield select();
  const { history } = payload;
  try {
    const authUser = state.authUser;
    const user = authUser.user;

    const resultSet = yield call(GroupSelectBySubscriber, user.userToken);

    if (resultSet) {
      if (Array.isArray(resultSet)) {

        const aList = resultSet.map((x) => {
          return {
            ...x,
            vehicleData: x.vehicles ? x.vehicles.split(',') : []
          }

        });

        yield put(groupSelectSuccess(aList));
      } else {
        yield put(authApiAccessCheck(history, resultSet));
      }
    }
  } catch (error) {
    console.log("error vehicle saga 1 :", error);
  }
}

export function* watchSelectAllRequest() {
  yield takeEvery(GROUP_SELECT_REQUEST, selectAll);
}

export default function* rootSaga() {
  yield all([fork(watchSelectAllRequest)]);
}
