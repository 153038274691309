import {
  LOGIN_USER,
  LOGIN_USER_AUTO,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_DETAILS,
  LOGIN_USER_REJECTED,
  LOGOUT_USER,
  AUTH_API_ACCESS_CHECK,
  REGISTER_USER,
  REGISTER_USER_SUCCESS
} from '../actions';

export const loginUser = (user, history, failedCallback) => ({
  type: LOGIN_USER,
  payload: { user, history, failedCallback }
});
export const loginUserAuto = (user, history) => ({
  type: LOGIN_USER_AUTO,
  payload: { user, history }
});
export const loginUserSuccess = (userToken) => ({
  type: LOGIN_USER_SUCCESS,
  payload: {userToken}
});

export const loginUserDetails = (user, details) => ({
  type: LOGIN_USER_DETAILS,
  payload: {user, details}
});

export const loginUserRejected = (error) => ({
  type: LOGIN_USER_REJECTED,
  payload: error
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload : {history}
});

export const authApiAccessCheck = (history, apiResponse) => ({
  type: AUTH_API_ACCESS_CHECK,
  payload : {history, apiResponse}
});
