import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Card, Button, Row, CardTitle, CardBody, Table } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { Colxx } from "../../components/common/CustomBootstrap";
import {
  authApiAccessCheck,
  passengerSelectRequest,
} from "../../redux/actions";
import CustomSubmitButton from "../../components/form-controls/CustomSubmitButton";
import PassengerClusters from "../../data-const/passenger-types";
import { NotificationManager } from "../../components/common/react-notifications";
import { PassengerAdd, PassengerVehicleAdd } from "../../api-tasks/passenger";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

class ListTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      selectedPassengers: null,
      filteredDataRows: [],
      allDataRows: [],
      passengerCluster: "0",
      passengerName: "",
      passengerCode: "",
      passengerRFID: "",
      searchTex: "",
    };
  }

  componentDidMount() {
    this.generatePassengers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.passengersAll !== this.props.passengersAll) {
      this.generatePassengers();
    }
  }

  generatePassengers() {
    const passengersAll = this.props.passengersAll || [];
    const foo = passengersAll.map((x) => {
      return {
        ...x,
        clusterText: PassengerClusters.filter((d) => d.id === x.cluster)[0]
          .title,
      };
    });

    this.setState({ allDataRows: foo, filteredDataRows: foo });
  }

  handleRefresh = () => {
    this.props.passengerSelectRequest(this.props.history);
    this.setState({ searchTex: "" });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ updating: true });

    const {
      passengerCluster,
      passengerName,
      passengerCode,
      passengerRFID,
    } = this.state;

    if (
      !passengerCluster ||
      !passengerName ||
      !passengerCode ||
      !passengerRFID
    ) {
      NotificationManager.warning(
        "All fields are required!",
        "Error",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    const rfidCodeWithLimit = "00000000" + passengerRFID;
    let newRfidCode = rfidCodeWithLimit.substring(rfidCodeWithLimit.length - 8);

    const user = this.props.user;
    if (passengerName && passengerCode && passengerRFID) {
      PassengerAdd(
        user.userToken,
        passengerCluster,
        passengerName,
        passengerCode,
        newRfidCode
      ).then((response) => {
        console.log("the res ", response);
        if (response) {
          this.setState({ updating: false });
          if (response) {
            if (response.status === "success") {
              this.setState({
                passengerName: "",
                passengerCode: "",
                passengerRFID: "",
              });

              const newPassengerId = response.row.new_id;
              const assignedItems = this.props.vehiclesAll.map((vItem) => ({
                passengerId: newPassengerId || -1,
                vehicleId: vItem.autoid,
                deviceType: vItem.device_type,
              }));

              PassengerVehicleAdd(user.userToken, assignedItems)
                .then((response) => {})
                .catch();

              NotificationManager.success(
                response.message,
                "Success",
                3000,
                null,
                null,
                ""
              );
              this.props.passengerSelectRequest(this.props.history);
            } else {
              console.error("passenger.add", response);
              NotificationManager.warning(
                response.message,
                response.status,
                5000,
                null,
                null,
                ""
              );
              this.props.authApiAccessCheck(this.props.history, response);
            }
          }
        }
      });
    }
  };

  onSearch = (event) => {
    let searchQuery = event.target.value.toLowerCase();
    let arrData = searchQuery.split(" ");
    let tempDataRows = this.state.allDataRows;
    let filteredDataRows = this.state.allDataRows;
    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        const item = {
          ...el,
        };

        return (
          item.passenger_name.toLowerCase().indexOf(element) !== -1 ||
          item.employee_code.toLowerCase().indexOf(element) !== -1 ||
          item.rfid_code.toLowerCase().indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredDataRows: filteredDataRows,
      searchTex: searchQuery || "",
    });
  };

  render() {
    const loading = this.state.updating || this.props.passengerLoading || false;
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <Fragment>
        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Row>
                  <Colxx xxs="12">
                    <form className="form" onSubmit={this.handleSubmit}>
                      <div className="form-body">
                        <Row>
                          <Colxx xl="3" lg="6" md="12">
                            <div className="form-group">
                              <label className="title">
                                <IntlMessages id="passenger.tab-cluster" />
                              </label>
                              <select
                                className="form-control"
                                name="passengerCluster"
                                onChange={this.handleChange}
                                value={this.state.passengerCluster}
                              >
                                {PassengerClusters.map((item, i) => (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    label={item.title}
                                  />
                                ))}
                              </select>
                            </div>
                          </Colxx>
                          <Colxx xl="5" lg="6" md="12">
                            <div className="form-group">
                              <label className="title">
                                <IntlMessages id="passenger.passenger-name" />
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="passengerName"
                                required
                                onChange={this.handleChange}
                                value={this.state.passengerName}
                              />
                            </div>
                          </Colxx>
                          <Colxx xl="2" lg="6" md="12">
                            <div className="form-group">
                              <label className="title">
                                <IntlMessages id="passenger.passenger-code" />
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="passengerCode"
                                required
                                onChange={this.handleChange}
                                value={this.state.passengerCode}
                              />
                            </div>
                          </Colxx>
                          <Colxx xl="2" lg="6" md="12">
                            <div className="form-group">
                              <label className="title">
                                <IntlMessages id="passenger.passenger-rfid" />
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="passengerRFID"
                                required
                                onChange={this.handleChange}
                                value={this.state.passengerRFID}
                              />
                            </div>
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xl="4" lg="6" md="12">
                            <CustomSubmitButton
                              updating={this.state.updating}
                            />
                          </Colxx>
                        </Row>
                      </div>
                    </form>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="passenger.passengers" />
                </CardTitle>
                <Row className="mt-2">
                  <Colxx xxs="12">
                    <div className="search">
                      <div className="float-right">
                        <Button
                          color="primary"
                          size="sm"
                          className="mr-1"
                          onClick={this.handleRefresh}
                        >
                          <IntlMessages id="common.refresh" />
                          {loading && (
                            <span className="spinner d-inline-block">
                              <span className="bounce1" />
                              <span className="bounce2" />
                              <span className="bounce3" />
                            </span>
                          )}
                        </Button>

                        <input
                          className="input2 driver-td-action"
                          type="text"
                          onChange={this.onSearch}
                          value={this.state.searchTex}
                          placeholder={intl.formatMessage({
                            id: "passenger.search-passenger",
                          })}
                        />
                      </div>
                    </div>
                  </Colxx>
                </Row>
                <Row>
                  <Colxx xxs="12">
                    <div className="card-body-min-height">
                      <Table responsive hover bordered striped>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <IntlMessages id="passenger.cluster" />
                            </th>
                            <th>
                              <IntlMessages id="passenger.name" />
                            </th>
                            <th title="Employee Code">
                              <IntlMessages id="passenger.emp" />
                            </th>
                            <th>
                              <IntlMessages id="passenger.rfid" />
                            </th>

                            <th>
                              <IntlMessages id="passenger.Action" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filteredDataRows.map((item, i) => (
                            <tr key={item.autoid}>
                              <th scope="row">{i + 1}</th>
                              <td>{item.clusterText}</td>
                              <td>{item.passenger_name}</td>
                              <td>{item.employee_code}</td>
                              <td>{item.rfid_code}</td>

                              <td>
                                <div className="float-right">
                                  <Link
                                    to={`passenger/passenger-view?param1=${item.autoid}`}
                                    className="btn btn-link btn-xs"
                                  >
                                    <IntlMessages id="passenger.view" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, passengers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items, loading } = passengers;
  const { locale } = settings;
  return {
    vehiclesAll: all,
    user,
    passengersAll: items,
    passengerLoading: loading,
    locale,
  };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
  passengerSelectRequest,
})(withRouter(ListTab));
