import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import InvoiceLayout from "./invoices";
import JobcardLayout from "./jobcards";
import ServiceScheduleLayout from "./services";
import TicketsLayout from "./tickets";
import VisitLayout from "./visits";

const Pages = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/support`} />
      <Route path={`${match.url}/support`} component={InvoiceLayout} />
      <Route path={`${match.url}/invoices`} component={InvoiceLayout} />
      <Route path={`${match.url}/jobcards`} component={JobcardLayout} />
      <Route path={`${match.url}/services`} component={ServiceScheduleLayout} />
      <Route path={`${match.url}/tickets`} component={TicketsLayout} />
      <Route path={`${match.url}/visits`} component={VisitLayout} />
      <Redirect to="/error" />
    </Switch>
  </div>
);

export default Pages;
