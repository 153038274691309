import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import SpeedChart from "../../../containers/pages/speed-chart";
class PagesSpeedChart extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.speed-chart" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <SpeedChart />
      </Fragment>
    );
  }
}

export default PagesSpeedChart;
