import React from "react";

import Select from "react-select";
import { connect } from "react-redux";

import { geofenceSelectRequest } from "../redux/actions";
import CustomSelectInput from "./common/CustomSelectInput";
import AppLocale from "../lang";
import { createIntl } from "react-intl";

const customFilterOption = (option, rawInput) => {
  if (option && rawInput) {
    const words = rawInput.split(" ");
    return words.reduce(
      (acc, cur) =>
        acc &&
        option.data.searchWords.toLowerCase().includes(cur.toLowerCase()),
      true
    );
  } else {
    return true;
  }
};

const LabelWithIcon = item => {
  return (
    <div key={item.autoid}>
      <strong>{item.title}</strong>
    </div>
  );
};

class GroupSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedGroup: null
    };
  }

  componentDidMount() {
    const allData = this.props.dataAll;
    if (allData && allData.length) {
      this.generateOptions();
    }

    if (!this.props.dataAll) {
      this.props.geofenceSelectRequest(this.props.history);
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataAll !== this.props.dataAll) {
      this.generateOptions();

    }
  }

  generateOptions = () => {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    const dataAll = this.props.dataAll;
    const options = dataAll.map(item => ({
      label: LabelWithIcon(item),
      value: item.autoid,
      searchWords:
        item.title,
      key: item.autoid,
      ...item
    }));

    const optionsWithDefault = [
      {
        label: LabelWithIcon({
          autoid: -1, 
          title: intl.formatMessage({
            id: "geofence.geofence-not-selected",
          }),
          
        }),
        value: -1,
        searchWords: "",
        key: -1,
        autoid: -1,
        title: "",
      }
      , ...options]

    this.setState({ options: optionsWithDefault });

    if (this.props.initialValue) {

      const result = optionsWithDefault.filter(el => el.autoid === this.props.initialValue);

      if (result && Array.isArray(result) && result[0]) {
        this.setState({ selectedGroup: result[0] });
        if (this.props.onChange) {
          this.props.onChange(result[0]);
        }
      }
    }
  };

  handleChange = selectedGroup => {
    this.setState({ selectedGroup });

    if (this.props.onChange) {
      this.props.onChange(selectedGroup);
    }
  };

  render() {

    const isMulti = this.props.singleSelect ? false : true ;

    const value = this.state.selectedGroup || null;
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <div className="w-100">
        <Select
          filterOption={customFilterOption}
          components={{ Input: CustomSelectInput }}
          className="react-select"
          classNamePrefix="react-select"
          name="allGeofences"
          placeholder={intl.formatMessage({
            id: "geofence.geofence-select",
          })}
          options={this.state.options}
          onChange={this.handleChange}
          value={value}
          isMulti={isMulti}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ geofence, settings }) => {
  const { items, loading } = geofence;
  const { locale } = settings;

  return { dataAll: items, dataLoading: loading, locale };
};

export default connect(mapStateToProps, {
  geofenceSelectRequest
})(GroupSelect);
