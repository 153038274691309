import React from "react";
import { Button, Tooltip } from "reactstrap";

class TooltipButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false
    };
  }
  toggle = () => {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Button
          onClick={this.props.onClick}
          size="sm"
          outline
          color="secondary"
          id={"Tooltip-" + this.props.id}
        >
          {this.state.tooltipOpen && this.props.confirm
            ? "Confirm ?"
            : this.props.children}
        </Button>
        <Tooltip
          placement="top"
          isOpen={this.state.tooltipOpen}
          target={"Tooltip-" + this.props.id}
          toggle={this.toggle}
        >
          {this.props.tooltipBody}
        </Tooltip>
      </React.Fragment>
    );
  }
}
export default TooltipButton;
