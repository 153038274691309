const proxyServer =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_SERVER_SUPPORT_PROD
    : process.env.REACT_APP_SERVER_SUPPORT_LOCAL;
const config = {};
config.serverApi = proxyServer;
config.supportApi = "https://sc-api.ctraac.com/";


module.exports = config;
