 

export const AuthHelperLogout = param1 => {
  try {
    localStorage.removeItem("apiAuthAccessKey");
    localStorage.removeItem("apiAuthAccessId");
  } catch (e) {}

  /*
  if (param1 === "layoutsubscriber") {
    Router.push("/login");
  } else {
    Router.push("/loginsupportcenter");
  }
  */
  // Router.push("/login");
  //this.context.history.push('/login');
};

export const AuthHelperAccessCheck = res => {
  // console.log("handiling error on AuthHelperAccessCheck ", res);
  if (res.error_code && res.error_code === 5001) {
    AuthHelperLogout(res);
  }
};

export const AuthHelperValidateOverAPI = param1 => {
  // console.log("handiling on AuthHelperValidateOverAPI ");
  AuthHelperLogout(param1);
};

export const AuthHelperValidateOverToken = param1 => {
  try {
    const token = localStorage.apiAuthAccessKey;

    if (token) {
      return true;
    } else {
      AuthHelperLogout(param1);
      return false;
    }
  } catch (e) {
    AuthHelperLogout(param1);
    return false;
  }
};

export const AuthHelperGerSubscriberRoleText = param1 => {
  switch (param1) {
    case 1:
      return "Owner";
    case 2:
      return "Admin";
    default:
      return "User";
  }
};

export const AuthHelperGerSubscriberTypeText = param1 => {
  switch (param1) {
    case 1:
      return "Basic";
    case 2:
      return "Premium";
    default:
      return "Basic";
  }
};

export const AuthHelperGerSubscriberLocationTypeText = param1 => {
  switch (param1) {
    case 1:
      return "Shipping Address";
    case 2:
      return "Collection Point";
    default:
      return "Unknown";
  }
};
