import React, { Component } from "react";
import {
  Row,
  Card,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import { NavLink } from "react-router-dom";

import classnames from "classnames";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Summary from "./Summary";
import Errands from "./Errands";
import Idlings from "./Idlings";
import OverSpeed from "./OverSpeed";
import IntlMessages from "../../helpers/IntlMessages";


/*
import VehicleSettings from "./vehicle-settings";

*/

class DetailsTab extends Component {
  constructor(props) {
    super(props);

    this.toggleSecondTab = this.toggleSecondTab.bind(this);
    this.state = {
      activeSecondTab: "1",
    };
  }

  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab,
      });
    }
  }
  render() {
    return (
      <Row>
        <Colxx xxs="12">
          <Card className="mb-4">
            <CardHeader className="pl-0 pr-0">
              <Nav tabs className=" card-header-tabs  ml-0 mr-0">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "1",
                      "nav-link": true,
                    })}
                    onClick={() => {
                      this.toggleSecondTab("1");
                    }}
                    to="#"
                  >
                    <h6>
                      <IntlMessages id="day-summary.summary" />
                    </h6>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "2",
                      "nav-link": true,
                    })}
                    onClick={() => {
                      this.toggleSecondTab("2");
                    }}
                    to="#"
                  >
                    <h6>
                      Trips
                    </h6>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "3",
                      "nav-link": true,
                    })}
                    onClick={() => {
                      this.toggleSecondTab("3");
                    }}
                    to="#"
                  >
                    <h6>
                      {" "}
                      <IntlMessages id="day-summary.idle" />{" "}
                    </h6>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "4",
                      "nav-link": true,
                    })}
                    onClick={() => {
                      this.toggleSecondTab("4");
                    }}
                    to="#"
                  >
                    <h6>
                      {" "}
                      <IntlMessages id="day-summary.over-speed" />{" "}
                    </h6>
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <Separator />
            <TabContent activeTab={this.state.activeSecondTab}>
              <TabPane tabId="1">
                <Summary
                  allRows={this.props.allRows}
                  onShowMapModel={this.props.onShowMapModel}
                />
              </TabPane>
              <TabPane tabId="2">
                <Errands
                  allRows={this.props.allRows}
                  onShowMapModel={this.props.onShowMapModel}
                />
              </TabPane>
              <TabPane tabId="3">
                <Idlings
                  allRows={this.props.allRows}
                  onShowMapModel={this.props.onShowMapModel}
                />
              </TabPane>
              <TabPane tabId="4">
                <OverSpeed
                  allRows={this.props.allRows}
                  onShowMapModel={this.props.onShowMapModel}
                />
              </TabPane>
            </TabContent>
          </Card>
        </Colxx>
      </Row>
    );
  }
}

export default DetailsTab;
