import React, { Component, Fragment } from "react";

import { Card, Row, CardBody, Table, ButtonGroup, Button } from "reactstrap";

import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { Colxx } from "../../components/common/CustomBootstrap";
import { NotificationManager } from "../../components/common/react-notifications";
import XButton from "../../components/common/XButton";
import { authApiAccessCheck, driverSelectRequest, groupSelectRequest } from "../../redux/actions";
import GroupVehiclesAddByGroup from "../../components/GroupVehiclesAddByGroup";
import GroupSelect from "../../components/GroupSelect";

import {
  GroupSelectVehiclesByGroup,
  GroupVehicleRemove,
} from "../../api-tasks/groups";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

class DriverList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDriver: null,
      filteredDataRows: [],
      allDataRows: [],
      driverId: 0,
      driverName: "",
      empCode: "",
      rfidCode: "",
      searchTex: "",
      selectedGroup: null,
    };
  }

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return {
            title: `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`,
            device_type: foo[0].device_type,
          };
        }
      }
    }
    return {};
  };

  onSearch = (event) => {
    let searchQuery = event.target.value.toLowerCase();
    let arrData = searchQuery.split(" ");
    let tempDataRows = this.state.allDataRows;
    let filteredDataRows = this.state.allDataRows;
    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        const item = {
          ...el,
          statusText: el.statuscode === 2 ? "active" : "pending",
        };

        return (
          item.vehicleName.toLowerCase().indexOf(element) !== -1 ||
          item.statusText.indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredDataRows: filteredDataRows,
      searchTex: searchQuery,
    });
  };

  loadTable = (searchTex) => {
    const user = this.props.user;
    const selectedGroup = this.state.selectedGroup || {};
    const groupId = selectedGroup.autoid || 0;

    this.setState({ loading: true, searchTex: searchTex || "" });

    GroupSelectVehiclesByGroup(user.userToken, groupId).then((res) => {
      
      this.setState({ loading: false });
      if (res) {
        if (Array.isArray(res)) {
          const theRows = res.map((x) => {
            const vItem = this.findVehicleName(x);
             
            return {
              ...x,
              ...vItem,
              vehicleName:    vItem.title ,
            };
          });

          

          this.setState({ allDataRows: theRows, filteredDataRows: theRows });

          let allVehicles = this.props.vehiclesAll || [];
          let vehicleDrivers = res;
          let vehiclesNotInDriver = [];

          if (allVehicles.length !== vehicleDrivers.length) {
            vehiclesNotInDriver = allVehicles;
            vehicleDrivers.forEach((vd) => {
              vehiclesNotInDriver = vehiclesNotInDriver.filter(function (
                value
              ) {
                return value.autoid !== vd.vehicle_id;
              });
            });
          } else {
            vehiclesNotInDriver = [];
          }

          this.setState({ vehiclesNotInDriver });
        } else {
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  handleChangeGroup = (selectedGroup) => {
    this.setState({ selectedGroup });

    setTimeout(() => {
      this.loadTable();
    }, 10);
  };

  handleChangeDriver = (selectedDriver) => {
    this.setState({ selectedDriver });
    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  handleOnRefresh = () => {
    this.props.groupSelectRequest(this.props.history);
    this.loadTable();
  };

  handleOnRemove = (item) => {
    const user = this.props.user;

    GroupVehicleRemove(user.userToken, item.autoid).then((response) => {
      if (response) {
        this.setState({ loading: false, selectedDrivers: [] });
        if (response) {
          if (response.status === "success") {
            this.loadTable();
            this.props.groupSelectRequest(this.props.history);
          } else {
            NotificationManager.error(
              response.message,
              "ERROR",
              3000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  render() {
    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    if (userRole === 3) {
      return (
        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Row>
                  <Colxx xxs="12">
                    <div className="alert alert-info" role="alert">
                    <IntlMessages id="group.user-access" />
                    </div>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      );
    }

    return (
      <Fragment>
        <Row>
          <Colxx xl="4" lg="4" md="12" sm="12">
            <Card>
              <CardBody>
                <Row>
                  <GroupSelect
                    onChange={this.handleChangeGroup}
                    group={this.state.selectedGroup}
                  />
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Row>
                  <div className="w-100">
                    <Colxx xl="6" lg="6" md="12" sm="12">
                      <GroupVehiclesAddByGroup
                        options={this.state.vehiclesNotInDriver}
                        onRefresh={this.handleOnRefresh}
                        group={this.state.selectedGroup}
                        user={this.props.user}
                      />
                    </Colxx>
                  </div>
                </Row>
                <Row className="mt-2">
                  <Colxx xxs="12">
                    <div className="search">
                      <div className="float-right">
                        <ButtonGroup className="flex-wrap">
                          <input
                            className="input2 w-50"
                            type="text"
                            onChange={this.onSearch}
                            value={this.state.searchTex}
                            placeholder={intl.formatMessage({
                              id: "group.search-vehicles",
                            })}
                          />
                          <Button
                            color="secondary"
                            size="sm"
                            onClick={this.loadTable}
                          >
                            {this.state.loading ? (
                              <span className="spinner d-inline-block">
                                <span className="bounce1" />
                                <span className="bounce2" />
                                <span className="bounce3" />
                              </span>
                            ) : (
                              <IntlMessages id="common.refresh" />
                            )}
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Colxx>
                </Row>
                <div className="card-body-min-height">
                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th><IntlMessages id="group.vehicles" /></th>

                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredDataRows.map((item, i) => (
                        
                        <tr key={item.autoid}>
                          <th scope="row">{i + 1}</th>
                          <td>{item.vehicleName}</td>

                          <td>
                            <div className="driver-td-action float-right">
                              <ButtonGroup className="float-right">
                                <XButton
                                  onAction={this.handleOnRemove}
                                  value={item}
                                  title={intl.formatMessage({
                                    id: "group.remove-driver",
                                  })}
                                  
                                />
                              </ButtonGroup>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user, details } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, userDetails: details, driversAll: items,locale};
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
  driverSelectRequest,
  groupSelectRequest,
})(withRouter(DriverList));
