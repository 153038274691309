import { GROUP_SELECT_REQUEST, GROUP_SELECT_SUCCESS } from "../actions";

  export const groupSelectRequest = ( history ) => ({
    type: GROUP_SELECT_REQUEST,
    payload: { history }
  });

  export const groupSelectSuccess = ( items ) => ({
    type: GROUP_SELECT_SUCCESS,
    payload: { items }
  });
