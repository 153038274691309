import React, { Fragment } from "react";
import {
  Row,
  Table,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import LocationName from "../../components/LocationName";
import CsvDownloader from "../../components/CsvDownloader";
import IntlMessages from "../../helpers/IntlMessages";

const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Date", key: "summaryDayText" },
  { label: "Time", key: "startTimeText" },
  { label: "Duration", key: "duration" },
  { label: "Place", key: "place" },
  { label: "Geo", key: "geo" },
  { label: "driver", key: "driverName" },
];

class Idlings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theRows: [],
      theOrginalRows: [],
      selectSecondLimit: "300",
      selectedText: "Sort by",
      isOpenSizingXs: false,
    };
  }

  propagateRows = (allRows) => {
    const rows = [];

    allRows.forEach((x) => {
      x.idles.forEach((elidle) => {
        rows.push({
          durationSec: elidle.durationSec,
          geo: elidle.geoPoints || elidle.geo,
          place: elidle.place,
          vehicleName: elidle.vehicleName,
          summaryDayText: elidle.summaryDayText,
          startTimeText: elidle.startTimeText,
          duration: elidle.duration,
          // driverName: elidle.driverName,
          driverName: elidle.driverName ? elidle.driverName : elidle.driverItem? `Driver ${elidle.driverItem.rfid_code}` : '',
        });
      });
    });

    const selectSecondLimit = this.state.selectSecondLimit || 300;
    const filteredRows = rows.filter(
      (x) => x.durationSec >= parseInt(selectSecondLimit, 10)
    );

    this.setState({ theRows: filteredRows, theOrginalRows: rows });
  };

  componentDidMount() {
    this.propagateRows(this.props.allRows);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allRows !== this.props.allRows) {
      this.propagateRows(this.props.allRows);
    }
  }

  handleSecondsLimitOnChaneg = (event) => {
    const filteredRows = this.state.theOrginalRows.filter(
      (x) => x.durationSec >= parseInt(event.target.value, 10)
    );
    this.setState({
      selectSecondLimit: event.target.value,
      theRows: filteredRows,
    });
  };

  handleSortChange = (label) => {
    this.setState({ selectedText: `${label}` });

    const allRows = this.state.theRows || [];
    let sortedRows = allRows;

    if (!allRows.length) {
      return;
    }

    switch (label) {
      case "DriverAsc":
        sortedRows = allRows.sort((a, b) =>
          a.driverName.localeCompare(b.driverName)
        );
        break;

      case "DriverDesc":
        sortedRows = allRows.sort((a, b) =>
          b.driverName.localeCompare(a.driverName)
        );
        break;

      case "VehicleAsc":
        sortedRows = allRows.sort((a, b) =>
          a.vehicleName.localeCompare(b.vehicleName)
        );
        break;

      case "VehicleDesc":
        sortedRows = allRows.sort((a, b) =>
          b.vehicleName.localeCompare(a.vehicleName)
        );
        break;

      case "TimeAsc":
        sortedRows = allRows.sort((a, b) => a.time - b.time);
        break;

      case "TimeDesc":
        sortedRows = allRows.sort((a, b) => b.time - a.time);
        break;

      case "DurationAsc":
        sortedRows = allRows.sort((a, b) => a.durationSec - b.durationSec);
        break;

      case "DurationDesc":
        sortedRows = allRows.sort((a, b) => b.durationSec - a.durationSec);
        break;

      default:
        sortedRows = allRows || [];
        break;
    }

    this.setState({ theRows: sortedRows });

    /*
    if (this.props.onChange) {
      this.props.onChange(selectedVehicle);
    }*/
  };

  toggleSizingXs = () => {
    this.setState((prevState) => ({
      isOpenSizingXs: !prevState.isOpenSizingXs,
    }));
  };

  render() {
    const showRows = this.state.theRows || [];
    return (
      <Row>
        <Colxx xxs="12">
          <span className="float-right">
            <ButtonGroup className="flex-wrap">
              <ButtonDropdown
                className="bd-container"
                isOpen={this.state.isOpenSizingXs}
                toggle={this.toggleSizingXs}
              >
                <DropdownToggle caret size="xs" outline color="dark">
                  {this.state.selectedText}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: (data) => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: 221,
                          },
                        };
                      },
                    },
                  }}
                >
                  <DropdownItem
                    onClick={() => this.handleSortChange("DurationAsc")}
                  >
                    Duration Asc
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => this.handleSortChange("DurationDesc")}
                  >
                    Duration Desc
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => this.handleSortChange("TimeAsc")}
                  >
                    Time Asc
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => this.handleSortChange("TimeDesc")}
                  >
                    Time Desc
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => this.handleSortChange("VehicleAsc")}
                  >
                    Vehicle Asc
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => this.handleSortChange("VehicleDesc")}
                  >
                    Vehicle Desc
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => this.handleSortChange("DriverAsc")}
                  >
                    Driver Asc
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => this.handleSortChange("DriverDesc")}
                  >
                    Driver Desc
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
              
              <CsvDownloader
                headers={headers}
                filename={"idle-ctraac.csv"}
                data={this.state.theRows}
              />
            </ButtonGroup>
          </span>

          <select
            className="btn btn-outline-primary btn-xs"
            onChange={this.handleSecondsLimitOnChaneg}
            value={this.state.selectSecondLimit}
          >
            <option value="300">5min or above </option>
            <option value="600">10min or above </option>
            <option value="900">15min or above </option>
            <option value="1800">30min or above </option>
            <option value="2700">45min or above </option>
            <option value="3600">1hrs or above </option>
          </select>
        </Colxx>
        <Colxx xxs="12">
          <Table responsive hover bordered striped>
            <thead>
              <tr>
                <th>
                  <IntlMessages id="day-summary.vehicle" />
                </th>
                <th>
                  <IntlMessages id="day-summary.day" />
                </th>
                <th>
                  <IntlMessages id="day-summary.time" />
                </th>
                <th>
                  <IntlMessages id="day-summary.place" />
                </th>
                <th>
                  <IntlMessages id="day-summary.duration" />
                </th>
                <th>
                  <IntlMessages id="day-summary.driver" />
                </th>
              </tr>
            </thead>
            <tbody>
              {showRows.map((item, i) => (
                <Fragment key={i}>
                  <tr>
                    <td>{item.vehicleName}</td>
                    <td> {item.summaryDayText} </td>
                    <td> {item.startTimeText} </td>
                    <td>
                      <LocationName
                        place={item.place}
                        geo={item.geo}
                        objectItem={item}
                        showGoogle
                        onClick={() =>
                          this.props.onShowMapModel(item.geo, item.place)
                        }
                      />
                    </td>
                    <td> {item.duration}</td>
                    <td> {item.driverName}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        </Colxx>
      </Row>
    );
  }
}

export default Idlings;
