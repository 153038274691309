import React from "react";

class VehicleCard extends React.Component {
  render() {
    const vehicle = this.props.vehicle || {};
    if(vehicle)
    return (
      <div className="mb-4">
        <img
          title={vehicle.manufacture_name}
          className="app-select-icon2"
          src={vehicle.manufactureIcon}
          alt="icon"
        />
        <img
          title={vehicle.typeText}
          className="app-select-icon2"
          src={vehicle.typeIcon}
          alt="icon"
        />
        <strong title="Plate">{vehicle.plate_number}</strong>{" "}
        <strong title="Asset No">{vehicle.fleet_number}</strong>{" "}
        <span title="Model">{vehicle.model}</span>{" "}
        <span title="year">{vehicle.year}</span>{" "}
        <span title="Cluster">{vehicle.project}</span>
      </div>
    );
  }
}

export default VehicleCard;
