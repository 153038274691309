import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Row, Card, CardTitle, Label, Input, Button } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { VerifyMail } from "../../api-sc/forgot-password";
import { NotificationManager } from "../../components/common/react-notifications";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmitMail = async (values) => {
    const input = {
      mailId: values.email,
    };
    if (values.email !== "") {
      let verifyResult = await VerifyMail(input);
      if (verifyResult.status !== "failed") {
        verifyResult.message =
        "Please check your email for OTP from bot@cloudtel.qa. If you do not find the email in your inbox, please check your spam filter or bulk email folder";
        this.onSubmitFailed(verifyResult.status, verifyResult.message);
        setTimeout(() => {
          this.props.history.push({
            pathname: `/user/send-otp`,
            state: {
              email: values.email,
            },
          });
        }, 8000);
      }
      else{
        this.onSubmitFailed(verifyResult.status, verifyResult.message)
      }
    }
  };
  onSubmitFailed = (status, message) => {
    // alert(status+", "+ message);
    if (status) {
      NotificationManager.warning(
        message,
        status.toUpperCase(),
        8000,
        null,
        null,
        ""
      );
    }
  };
  render() {
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side ">
              <p className="text-white h2">
                ctraac.com - Cloud Telematics Remote Access Analytics Console
              </p>
              <p className="white mb-0">
                The tracking system of cloud telematics. <br />
                {/* If you are not a member, please{" "}
                <NavLink to={`/register`} className="white">
                  register
                </NavLink>
                . */}
              </p>
            </div>
            <div className="form-side">
              <CardTitle className="mb-4">
                <span style={{ fontWeight: "bold" }}>
                  <IntlMessages id="user.forgot-password" />
                </span>
              </CardTitle>
              <Formik
                initialValues={{
                  email: "",
                }}
                enableReinitialize={true}
                // validationSchema={VehicleEditValidationSchema}
                onSubmit={async (values) => {
                  try {
                    await this.onSubmitMail(values);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <Label className="form-group has-float-label mb-4">
                      <p style={{ fontWeight: "bold", fontSize: "0.850rem" }}>
                        <IntlMessages id="user.please-enter-email-ctraac" />
                      </p>
                    </Label>
                    <Label className="form-group has-float-label mb-4">
                      <span
                        style={{ fontWeight: "bold", fontSize: "0.813rem" }}
                      >
                        <IntlMessages id="user.enter-mail" />
                      </span>
                      <Input
                        type="email"
                        name="email"
                        required
                        value={values.email}
                        onChange={handleChange}
                      />
                      {/* <IntlMessages id="user.email" /> */}
                    </Label>

                    <div className="d-flex justify-content-end align-items-center">
                      {/* <NavLink to={`/`}> */}
                      <Button
                        // href="/user/send-otp"
                        color="primary"
                        className="btn-shadow"
                        size="lg"
                        type="submit"
                        onClick={this.verifyEmail}
                      >
                        <IntlMessages id="common.submit" />
                      </Button>
                      {/* </NavLink> */}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
    // }
  }
}
