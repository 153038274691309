import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Button, Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelect from "../../components/VehicleSelect";
import { authApiAccessCheck, pagesTripsUpdate } from "../../redux/actions";
import { IgnitionGetTripsByVehicle } from "../../api-tasks/ignition";
import DateRangePicker from "../../components/DateRangePicker";
import LocationName from "../../components/LocationName";
import PagingBar from "../../components/PagingBar";
import { NotificationManager } from "../../components/common/react-notifications";

import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
  AppHelperDurationAsHHmm
} from "../../helpers/AppHelper";
import moment from "moment";

const pagingLimit = 20;

class Trips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: null,
      loading: false,
      allRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    const tripsRedux = this.props.tripsRedux || {};

    this.setState({
      selectedVehicle: tripsRedux.selectedVehicle || null,
      allRows: tripsRedux.allRows || [],
      limit: tripsRedux.limit || pagingLimit,
      skip: tripsRedux.skip || 0,
      totalCount: tripsRedux.totalCount || 0,
      startTime: tripsRedux.startTime || moment().startOf("month"),
      endTime: tripsRedux.endTime || moment(new Date())
    });
  }

  handleChangeVehicle = selectedVehicle => {
    this.setState({
      selectedVehicle: selectedVehicle,
      allRows: [],
      limit: pagingLimit,
      skip: 0
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip, selectedVehicle);
    }, 500);
  };

  onDateRangeGo = (limit, skip, vehicle) => {
    const selectedVehicle = vehicle || this.state.selectedVehicle || null;
    if (!selectedVehicle) {
      NotificationManager.error(
        "please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const state = this.state;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const vehicleId = selectedVehicle.key;

    this.setState({ loading: true });

    IgnitionGetTripsByVehicle(
      user.userToken,
      vehicleId,
      startTime,
      endTime,
      limit,
      skip
    ).then(response => {
      this.setState({ loading: false, limit, skip });
      console.log("the respo trips ", response);
      if (response && Array.isArray(response.rows)) {
        this.setState({
          allRows: response.rows,
          totalCount: response.count,
          touched: true
        });

        if (this.props.pagesTripsUpdate) {
          this.props.pagesTripsUpdate({
            selectedVehicle: state.selectedVehicle,
            startTime: state.startTime,
            endTime: state.endTime,
            limit,
            skip,
            allRows: response.rows,
            totalCount: response.count
          });
        }
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      limit: pagingLimit,
      skip: 0,
      allRows: []
    });
    if (this.state.selectedVehicle) {
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);
    }
  };

  handlePagingChange = (limit, skip) => {
    this.onDateRangeGo(limit, skip);
  };

  formatedPushButton = (itemObject, buttonText, path) => {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <Button
            size="xs"
            color="link"
            onClick={() => {
              history.push({
                pathname: path,
                state: { itemObject: itemObject }
              });
            }}
          >
            {buttonText}
          </Button>
        )}
      />
    );
    return <ButtonLive />;
  };

  findDriverName = item => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function(value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  render() {
    const selectedVehicle = this.state.selectedVehicle || {};
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="4" lg="4" md="6" sm="12">
                    <VehicleSelect
                      onChange={this.handleChangeVehicle}
                      defaultSelect
                      value={this.state.selectedVehicle}
                    />
                  </Colxx>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      suggestions="months"
                      startTime={this.state.startTime}
                      endTime={this.state.endTime}
                    />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        `Refresh`
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {selectedVehicle.value && this.state.touched ? (
                  <CardTitle>
                    Trips of vehicle : {selectedVehicle.value.plate_number}{" "}
                    {selectedVehicle.value.fleet_number}
                    {" from "}
                    {AppHelperDateAndTimeToString(
                      this.state.startTime.toDate()
                    )}
                    {" to "}
                    {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
                  </CardTitle>
                ) : (
                  <CardTitle> Try search filter or select vehicle </CardTitle>
                )}

                {this.state.touched && !this.state.totalCount ? (
                  <p> Nothing to show</p>
                ) : (
                  ""
                )}
                <div className="card-body-min-height">
                  {this.state.totalCount > this.state.limit ? (
                    <PagingBar
                      totalCount={this.state.totalCount}
                      limit={this.state.limit}
                      skip={this.state.skip}
                      onChange={this.handlePagingChange}
                      outline
                      loading={this.state.loading}
                    />
                  ) : (
                    ""
                  )}

                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>KM</th>
                        <th>HH:mm</th>
                        <th>Driver</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.allRows.map((item, i) => (
                        <tr key={this.state.skip + i + 1}>
                          <th scope="row">{this.state.skip + i + 1}</th>
                          <td>
                            {AppHelperDateAndTimeToString(item.start_time)}
                            <br />
                            <LocationName
                              place={item.start_place}
                              geo={item.start_geo}
                              objectItem={item}
                              showGoogle
                            />
                          </td>
                          <td>
                            {AppHelperDateAndTimeToString(item.end_time)}
                            <br />
                            <LocationName
                              place={item.end_place}
                              geo={item.end_geo}
                              objectItem={item}
                              showGoogle
                            />
                          </td>

                          <td>{item.mileage}</td>
                          <td>
                            {AppHelperDurationAsHHmm(
                              item.start_time,
                              item.end_time
                            )}
                          </td>
                          <td>{this.findDriverName(item)}</td>
                          <td>
                            {this.formatedPushButton(item, "View", "trip-view")}
                            
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <PagingBar
                    totalCount={this.state.totalCount}
                    limit={this.state.limit}
                    skip={this.state.skip}
                    onChange={this.handlePagingChange}
                    outline
                    loading={this.state.loading}
                  />
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, pages, drivers }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { trips } = pages;
  const { items } = drivers;
  return { vehiclesAll: all, user, tripsRedux: trips, driversAll: items };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
  pagesTripsUpdate
})(Trips);
