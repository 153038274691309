import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Button, Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";

import { Colxx } from "../../components/common/CustomBootstrap";
import DriverSelectWithBatch from "../../components/DriverSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
import { IgnitionGetSummaryByDrivers } from "../../api-tasks/ignition";
import DateRangePicker from "../../components/DateRangePicker";

import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
  AppHelperSecondsAsHHmm,
} from "../../helpers/AppHelper";
import moment from "moment";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

const pagingLimit = -1;

const headers = [
  { label: "Driver", key: "driverName" },
  { label: "first Start", key: "firstStart" },
  { label: "LastEnd", key: "lastEnd" },
  { label: "Trips", key: "trip_count" },
  { label: "Km", key: "mileage_sum" },
  { label: "HH:mm", key: "totalDuration" },
  { label: "Idling", key: "idl_count_sum" },
  { label: "Idling HH:mm", key: "IdleDuration" },

  { label: "Vehicles", key: "vehicleNames" },
];

class Idle extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedDrivers: [],
      loading: false,
      allRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
      fileName: "Ctraac - Driver Utilization.csv",
    };
  }

  componentWillUnmount() {}

  componentDidMount() {}

  handleOnSelectedVehicleChange = (selectedDrivers) => {
    this.setState({ selectedDrivers });

    this.setState({
      selectedDrivers,
      allRows: [],
      limit: pagingLimit,
      skip: 0,
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  onDateRangeGo = (limit, skip) => {
    if (!this.state.selectedDrivers.length) {
      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    const driverIds = this.state.selectedDrivers.map((el) => {
      return el.key;
    });

    this.setState({ loading: true });

    IgnitionGetSummaryByDrivers(
      user.userToken,
      driverIds,
      startTime,
      endTime,
      limit,
      skip
    ).then((response) => {
      this.setState({
        loading: false,
        limit,
        skip,
        fileName: `Ctraac - Driver Utilization, ${this.state.startTime.format(
          "YYY-MM-DD hh:mm A"
        )} to ${this.state.endTime.format("YYY-MM-DD hh:mm A")}.csv`,
      });

      if (response && Array.isArray(response.rows)) {
        const a = response.rows.map((x) => {
          return {
            ...x,
            firstStart: AppHelperDateAndTimeToString(x.first_start),
            lastEnd: AppHelperDateAndTimeToString(x.last_end),

            driverName: this.findDriverName(x),
            totalDuration: AppHelperSecondsAsHHmm(x.totalduration_sec_sum),
            vehicleNames: this.getVehiclesNames(x.vehicles),
            IdleDuration: AppHelperSecondsAsHHmm(x.idl_sec_sum),
          };
        });

        this.setState({
          allRows: a,
          totalCount: response.count,
          touched: true,
        });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      limit: pagingLimit,
      skip: 0,
      allRows: [],
    });
    if (this.state.selectedDrivers.length) {
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);
    }
  };

  handlePagingChange = (limit, skip) => {
    this.onDateRangeGo(limit, skip);
  };

  formatedPushButton = (itemObject, buttonText, path) => {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <Button
            size="xs"
            color="link"
            onClick={() => {
              history.push({
                pathname: path,
                state: { itemObject: itemObject },
              });
            }}
          >
            {buttonText}
          </Button>
        )}
      />
    );
    return <ButtonLive />;
  };

  getVehiclesNames(vehicles) {
    try {
      const arr = vehicles.split(",");
      let driverNames = "";
      const arrPrev = [];
      arr.forEach((el) => {
        if (el && el > 0) {
          if (arrPrev.indexOf(el) === -1) {
            driverNames =
              driverNames +
              (arrPrev.length ? ", " : "") +
              this.findVehicleName({ vehicle_id: parseInt(el, 10) });

            arrPrev.push(el);
          }
        }
      });

      return driverNames;
    } catch (error) {}

    return "";
  }

  findDriverName = (item) => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number}_${foo[0].fleet_number}_${foo[0].model}`;
        }
      }
    }
    return "";
  };

  renderDriverNames(drivers) {
    try {
      const names = drivers.split(", ") || [];

      return names.map((item, i) => (
        <p className="mb-0" key={i}>
          {" "}
          {item}{" "}
        </p>
      ));
    } catch (error) {}
    return "";
  }

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row className="mb-1">
                  <Colxx xxs="12">
                    <DriverSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                    />
                  </Colxx>
                </Row>

                <Row>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      suggestions="months"
                      startTime={moment().startOf("month")}
                    />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {this.state.selectedDrivers.length && this.state.touched ? (
                  <CardTitle>
                   {intl.formatMessage({
                        id: "report.driver-utlzn",
                      })} :{" from "}
                    {AppHelperDateAndTimeToString(
                      this.state.startTime.toDate()
                    )}
                    {" to "}
                    {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
                  </CardTitle>
                ) : (
                  <CardTitle>
                    {" "}
                    <IntlMessages id="report.try-search-driver" />
                  </CardTitle>
                )}

                {this.state.touched && !this.state.totalCount ? (
                  <p>
                    {+" " +
                      intl.formatMessage({
                        id: "report.nothing-show",
                      })}
                  </p>
                ) : (
                  ""
                )}
                <div className="card-body-min-height">
                  <div className="float-right">
                    <CSVLink
                      headers={headers}
                      filename={this.state.fileName}
                      data={this.state.allRows}
                    >
                      <Button
                        title={this.state.fileName}
                        size="xs"
                        outline
                        color="dark"
                      >
                        <IntlMessages id="common.download" />
                      </Button>
                    </CSVLink>
                  </div>
                  {this.state.allRows.length +
                    " " +
                    intl.formatMessage({
                      id: "report.records",
                    })}

                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th><IntlMessages id="report.driver" /></th>
                        <th><IntlMessages id="report.first-start" /></th>
                        <th><IntlMessages id="report.last-end" /></th>
                        <th><IntlMessages id="report.trips" /></th>
                        <th>Km</th>
                        <th>HH:mm </th>
                        <th><IntlMessages id="report.idling" /> </th>
                        <th><IntlMessages id="report.idling" /> HH:mm</th>
                        <th><IntlMessages id="report.vehicles" /> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.allRows.map((item, i) => (
                        <tr key={this.state.skip + i + 1}>
                          <th scope="row">{this.state.skip + i + 1}</th>
                          <td>{item.driverName}</td>
                          <td>{item.firstStart}</td>
                          <td>{item.lastEnd}</td>
                          <td>{item.trip_count}</td>
                          <td>{item.mileage_sum}</td>
                          <td>{item.totalDuration}</td>
                          <td>{item.idl_count_sum}</td>
                          <td>{item.IdleDuration}</td>
                          <td>{this.renderDriverNames(item.vehicleNames)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Idle);
