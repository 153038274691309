import React, { Component } from "react";
import { Button, Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelect from "../../components/VehicleSelect";
import { authApiAccessCheck } from "../../redux/actions";
import { TrackingBasicGetByTimeRangeOptimised } from "../../api-tasks/tracking-basic";
import DateRangePicker from "../../components/DateRangePicker";
import CanvasJS from "../../components/CanvasJS";
import { NotificationManager } from "../../components/common/react-notifications";
import { AppHelperDateToDBString } from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";
import moment from "moment";

class FuelChartPage extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedVehicle: null,
      loading: false,
      filteredDataRows: [],
      wayPoints: [],
      selected: {},
    };
  }

  componentWillUnmount() {}

  componentDidMount() {}

  handleChangeVehicle = (selectedVehicle) => {
    this.setState({ selectedVehicle: selectedVehicle, wayPoints: [] });
    setTimeout(() => {
      this.onDateRangeGo();
    }, 5);
  };

  onDateRangeGo = () => {
    if (!this.state.selectedVehicle) {
      NotificationManager.error(
        "please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const vehicleId = this.state.selectedVehicle.key;

    const vehicleValue = this.state.selectedVehicle.value | {};
    const timezone = vehicleValue.timezone || "Asia/Qatar";

    this.setState({
      loading: true,
      selected: { start: this.state.startTime, end: this.state.endTime },
    });

    TrackingBasicGetByTimeRangeOptimised(
      user.userToken,
      vehicleId,
      startTime,
      endTime,
      timezone
    ).then((response) => {
      this.setState({ loading: false });
      if (response && Array.isArray(response)) {
        this.setState({ wayPoints: response });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({ startTime, endTime });
    if (this.state.selectedVehicle) {
      setTimeout(() => {
        this.onDateRangeGo();
      }, 5);
    }
  };

  render() {
    let selectedVehicleValue = {};
    let label = "Fuel Chart";
    if (this.state.selectedVehicle !== null) {
      // console.log("selected date", this.state.selectedVehicle.value.item);
      selectedVehicleValue = this.state.selectedVehicle.value.item;

      if (selectedVehicleValue !== undefined) {
        label = `Fuel Chart -${selectedVehicleValue.title}  ${
          selectedVehicleValue.model
        } | ${moment(this.state.startTime).format(
          "Do MMM, h:mm a"
        )} to ${moment(this.state.endTime).format("Do MMM , h:mm a")}`;
      }
    }
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="4" lg="4" md="6" sm="12">
                    <VehicleSelect onChange={this.handleChangeVehicle} />
                  </Colxx>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker onChange={this.handleChangeDateRange} />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={this.onDateRangeGo}
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <div className="card-body-min-height">
                  <Row>
                    <Colxx xxs="12" style={{ marginTop: "1em" }}>
                      <CanvasJS
                        wayPoints={this.state.wayPoints}
                        title={label}
                        type={"fuel"}
                      />
                    </Colxx>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { locale } = settings;
  return { vehiclesAll: all, user, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(FuelChartPage);
