import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import SpeedChart2 from "../../../containers/pages/speed-chart-2";
class PagesSpeedChart2 extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.speed-chart-2" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <SpeedChart2 />
      </Fragment>
    );
  }
}

export default PagesSpeedChart2;
