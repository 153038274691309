import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
 
import trip from './trip'; 
import idle from './idle'; 
import overspeed from './overspeed';
import summary from './day-summary'; 
import geofenceAnalytics from './geofence-analytics'; 
import restrictedGeofence from './restricted-geofence'; 

const Pages = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/trip`} />
            <Route path={`${match.url}/trip`} component={trip} />
            <Route path={`${match.url}/idle`} component={idle} />
            <Route path={`${match.url}/overspeed`} component={overspeed} />
            <Route path={`${match.url}/summary`} component={summary} />
            <Route path={`${match.url}/geofence`} component={geofenceAnalytics} />
            <Route path={`${match.url}/restricted-geofence`} component={restrictedGeofence} />
             
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Pages;