import React, { Component } from "react";
import { Button, Row, Card, CardBody, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../../components/common/CustomBootstrap";
import VehicleSelect from "../../../components/VehicleSelect";
import { authApiAccessCheck, pagesTripsUpdate } from "../../../redux/actions";
import { IgnitionGetTripsByVehicle } from "../../../api-tasks/ignition";
import DateRangePicker from "../../../components/DateRangePicker";
import { NotificationManager } from "../../../components/common/react-notifications";
import IntlMessages from "../../../helpers/IntlMessages";
import { VehiclesGetDaySummary } from "../../../api-tasks-analytics/vehicles";
import { IgnitionGetSuggestionRewind } from "../../../api-tasks/ignition";
import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
  AppHelperDurationAsHHmm,
} from "../../../helpers/AppHelper";
import moment from "moment";
import ErrandsTabView from "./ErrandsTabView";

const pagingLimit = 20;
class ErrandsView extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedVehicle: null,
      loading: false,
      allRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
      errandsRows: [],
      totalCountErrand: 0,
      wayPoints: [],
      itemObject:{},
      viewingObject:{}
    };
  }

  componentWillUnmount() {}
  componentDidMount() {}
 
  handleChangeVehicle = (selectedVehicle) => {
    this.setState({
      selectedVehicle: selectedVehicle,
      allRows: [],
      limit: pagingLimit,
      skip: 0,
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip, selectedVehicle);
    }, 500);
  };

  onDateRangeGo = (limit, skip, vehicle) => {
    const selectedVehicle = vehicle || this.state.selectedVehicle || null;
    if (!selectedVehicle) {
      NotificationManager.error(
        "please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const state = this.state;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const vehicleId = selectedVehicle.key;

    this.setState({ loading: true });

    IgnitionGetTripsByVehicle(
      user.userToken,
      vehicleId,
      startTime,
      endTime,
      limit,
      skip
    ).then((response) => {
      this.setState({ loading: false, limit, skip });
      if (response && Array.isArray(response.rows)) {
        this.setState({
          allRows: response.rows,
          totalCount: response.count,
          touched: true,
        });

        if (this.props.pagesTripsUpdate) {
          this.props.pagesTripsUpdate({
            selectedVehicle: state.selectedVehicle,
            startTime: state.startTime,
            endTime: state.endTime,
            limit,
            skip,
            allRows: response.rows,
            totalCount: response.count,
          });
        }
      }
    });

    VehiclesGetDaySummary(user.userToken, [vehicleId], startTime, endTime).then(
      (response) => {
        this.setState({
          loading: false,
          errandsRows: [],
          totalCountErrand: 0,

          fileName: `Ctraac - day summary, ${this.state.startTime.format(
            "YYY-MM-DD hh:mm A"
          )} to ${this.state.endTime.format("YYY-MM-DD hh:mm A")}.csv`,
        });
        let count = 0;
        if (response && Array.isArray(response) && !response.error) {
          const a = response.map((x) => {
            // const summaryDay = moment(x.summaryDay);
            const summaryDayText = moment(x.summaryDay).format("ddd MMM-DD");
            const vehicleName = this.findVehicleName(x);
            const errands = x.errands.map((y) => {
              const startTime = moment(y.startTime);
              const endTime = moment(y.endTime);
              count = x.errands.length;
              return {
                ...y,
                summaryDayText,
                vehicleName,
                startTimeText: startTime.format("hh:mm A"),
                endTimeText: endTime.format("hh:mm A"),
                dayDiff: endTime.diff(x.summaryDay, "days") || 0,
                distance: y.distance.toFixed(1),
                duration: AppHelperDurationAsHHmm(
                  startTime.toDate(),
                  endTime.toDate()
                ),
                driverName: this.findDriverName(y.driverId),
              };
            });

            return errands;
          });

          this.setState({
            errandsRows: a,
            totalCountErrand: count,
            touched: true,
          });
        }
      }
    );
  };

  getSuggestion = () => {
    const user = this.props.user;
    const vehicleId = this.state.selectedVehicle.key;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const limit = 7;

    IgnitionGetSuggestionRewind(
      user.userToken,
      vehicleId,
      startTime,
      limit
    ).then((response) => {
      if (response && Array.isArray(response)) {
        this.setState({ suggestions: response });
      }
    });
  };
  onSuggestionSelect = (item) => {
    this.setState({
      startTime: moment(item.first_start),
      endTime: moment(item.last_end),
    });
    setTimeout(() => {
      this.onDateRangeGo();
    }, 5);
  };
  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      limit: pagingLimit,
      skip: 0,
      allRows: [],
    });
    if (this.state.selectedVehicle) {
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);
    }
  };

  handlePagingChange = (limit, skip) => {
    this.onDateRangeGo(limit, skip);
  };

  findDriverName = (item) => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };
  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicleId && ignition.vehicleId > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicleId;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };
  render() {
    const selectedVehicle = this.state.selectedVehicle || {};
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="4" lg="4" md="6" sm="12">
                    <VehicleSelect
                      onChange={this.handleChangeVehicle}
                      defaultSelect
                      value={this.state.selectedVehicle}
                    />
                  </Colxx>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      suggestions="months"
                      startTime={this.state.startTime}
                      endTime={this.state.endTime}
                    />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="tracking.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {selectedVehicle.value && this.state.touched ? (
                  <CardTitle>
                    <IntlMessages id="tracking.trips-of-vehicle" /> :{" "}
                    {selectedVehicle.value.plate_number}{" "}
                    {selectedVehicle.value.fleet_number}{" "}
                    <IntlMessages id="tracking.from" />{" "}
                    {AppHelperDateAndTimeToString(
                      this.state.startTime.toDate()
                    )}{" "}
                    <IntlMessages id="tracking.to" />{" "}
                    {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
                  </CardTitle>
                ) : (
                  <CardTitle>
                    <IntlMessages id="tracking.try-search-filter-or-select-vehicle" />
                  </CardTitle>
                )}
                <div className="card-body-min-height">
                  {this.state.totalCountErrand > 0 ? (
                    <ErrandsTabView
                      allRows={this.state.errandsRows}
                      findDriverName={this.findDriverName}
                      totalCount={this.state.totalCount}
                      loading={this.state.loading}
                      wayPoints={this.state.wayPoints}
                      vehicle={this.state.selectedVehicle}
                      start={this.state.startTime}
                      end={this.state.endTime}
                      getItemDetails={this.getItemDetails}
                    />
                  ) : (
                    <p>
                      <IntlMessages id="tracking.nothing-to-show" />
                    </p>
                  )}
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  return { vehiclesAll: all, user, driversAll: items };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
  pagesTripsUpdate,
})(ErrandsView);
