

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "idle";

export const IdleGetByVehicles = (userToken, vehicleIds, startTime, endTime, secondsOrAbove, limit, skip) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, secondsOrAbove, limit, skip };
  return axios
    .post(proxyServer + "/get-by-vehicles", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


export const IdleGetByVehicle = (userToken, vehicleId, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleId, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-vehicle", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const IdleGetByDriver = (userToken, driverId, startTime, endTime, limit, skip) => {
  const criterion = { userToken, driverId, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-driver", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
