import React from "react";
import { Badge, Card, CardBody } from "reactstrap";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  AppHelperDateToDBString,
  AppHelperFindVehicleName,
  AppHelperGetDriverNames,
} from "../../helpers/AppHelper";
import { DashboardSelectTop10ByVehicles } from "../../api-tasks/dashboard";
import IntlMessages from "../../helpers/IntlMessages";

class Top10Alerts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allRows: [],
      loading: false,
      selectedVehicles: [],
      startTime: moment().startOf("month"),
      endTime: moment(new Date()),
    };
  }

  componentDidMount() {
    this.setState({ selectedVehicles: this.props.vehiclesAll || [] });

    setTimeout(() => {
      this.loadMasters();
    }, 10);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.setState({ selectedVehicles: this.props.vehiclesAll || [] });

      setTimeout(() => {
        this.loadMasters();
      }, 10);
    }
  }

  loadMasters = () => {
    const user = this.props.user;
    const vehicles = this.state.selectedVehicles || [];

    if (!vehicles.length) {
      return;
    }

    const vehicleIds = vehicles.map((el) => {
      return el.autoid;
    });

    this.setState({ loading: true });
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    DashboardSelectTop10ByVehicles(
      user.userToken,
      vehicleIds,
      startTime,
      endTime
    ).then((res) => {
      this.setState({ loading: false });
      if (res) {
        if (Array.isArray(res)) {
          const a = res.map((x) => {
            return {
              ...x,
              vehicleName: AppHelperFindVehicleName(x, this.props.vehiclesAll),
              driverNames: AppHelperGetDriverNames(
                x.drivers,
                this.props.driversAll
              ),
            };
          });

          this.setState({ allRows: a });
        }
      }
    });
  };

  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <h5 className="d-inline-block">
              <IntlMessages id="dashboard.top-10" />
              {this.state.loading ? (
                <span className="bg-info">
                  <span className="spinner d-inline-block">
                    <span className="bounce1" />
                    <span className="bounce2" />
                    <span className="bounce3" />
                  </span>
                </span>
              ) : (
                ""
              )}
            </h5>

            <div className="dashboard-top10">
              <PerfectScrollbar
                options={{ suppressScrollX: true, wheelPropagation: false }}
              >
                {!this.state.allRows.length ? (
                  <p>
                    {" "}
                    <IntlMessages id="dashboard.noting-show" />{" "}
                  </p>
                ) : (
                  ""
                )}
                <table className="table table-sm table-borderless">
                  <tbody>
                    {this.state.allRows.map((log, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span
                              className={`log-indicator align-middle border-theme-1`}
                            />
                          </td>
                          <td>
                            <div className="font-weight-medium">
                              <Badge
                                color="secondary"
                                className="mb-1 float-right"
                              >
                                {log.type_text}
                              </Badge>

                              {log.vehicleName}
                            </div>
                            <small>
                              {log.driverNames ? log.driverNames : ""}
                            </small>
                          </td>
                          <td className="text-right">
                            <span className="text-muted">
                              {log.alert_count}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </PerfectScrollbar>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Top10Alerts;
