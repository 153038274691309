import React, { Component } from "react";
import {
  Button,
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Nav,
  CardHeader,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";
import classnames from "classnames";

import GeofenceSelect from "../../components/GeofenceSelect";
import { Colxx } from "../../components/common/CustomBootstrap";
import { authApiAccessCheck } from "../../redux/actions";

import { GeofenceGetInAndOutByDateAndVehicle } from "../../api-tasks-analytics/geofence";

import DateRangePicker from "../../components/DateRangePicker";
import LocationName from "../../components/LocationName";

import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
  AppHelperDateToString,
} from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Time", key: "time" },
  { label: "Place", key: "place" },
  { label: "Geo", key: "geo" },
  { label: "Entry", key: "typeText" },
  { label: "Driver", key: "driverName" },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];

    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

    return objectsByKeyValue;
  }, {});
const gTimeDay = groupBy("timeDay");

class GeofenceDrillDown extends Component {
  constructor(props) {
    super(props);
    this.toggleSecondTab = this.toggleSecondTab.bind(this);
    this.state = {
      activeSecondTab: "1",
      selectedVehicle: null,
      selectedGeofences: [],
      loading: false,
      filteredRows: [],
      allRows: [],
      allRowsSummaryByDay: [],
      totalTrips: 0,
      totalCount: 0,
      touched: false,
    };

    // const vehicleId = this.state.selectedVehicle.autoid;
  }

  componentWillUnmount() {}

  componentDidMount() {
    if (this.props.selectedVehicle) {
      this.handleChangeVehicle(this.props.selectedVehicle);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedVehicle !== this.props.selectedVehicle) {
      this.handleChangeVehicle(this.props.selectedVehicle);
    }
  }

  handleChangeVehicle = (selectedVehicle) => {
    this.setState({
      selectedVehicle: selectedVehicle,
      allRows: [],
      allRowsSummaryByDay: [],
      filteredRows: [],
    });

    setTimeout(() => {
      this.onDateRangeGo();
    }, 10);
  };

  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab,
      });
    }
  }

  findDriverName = (item) => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };

  onDateRangeGo = () => {
    if (
      !this.state.selectedVehicle ||
      !this.state.selectedGeofences ||
      !this.state.selectedGeofences.length
    ) {
      // NotificationManager.error("Please select geofence", "ERROR", 3000, null, null, "");

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const vehicleId = this.state.selectedVehicle.autoid;

    const geofenceIds = this.state.selectedGeofences.map((el) => {
      return el.autoid;
    });

    this.setState({ loading: true });

    GeofenceGetInAndOutByDateAndVehicle(
      user.userToken,
      [vehicleId],
      geofenceIds,
      startTime,
      endTime
    ).then((response) => {
      this.setState({ loading: false });

      if (response && Array.isArray(response)) {
        // console.log("the go ", response);

        const a = response.map((x) => {
          return {
            ...x,
            typeText: x.type ? "In" : "Out",
            time: AppHelperDateAndTimeToString(x.event_time),
            timeDay: AppHelperDateToString(x.event_time),
            driverName: this.findDriverName(x),
            vehicleName: this.findVehicleName(x),
          };
        });

        const gAll = gTimeDay(a);

        const gDayBySummary = Object.keys(gAll).map(function (key) {
          const data = gAll[key] || [];
          const timeDay = data[0].timeDay || "";

          return {
            timeDay,
            totalCount: data.length,
          };
        });

        const totalTrips = gDayBySummary.reduce((a, b) => a + b.totalCount, 0);

        this.setState({
          allRowsSummaryByDay: gDayBySummary,
          allRows: a,
          filteredRows: a,
          totalCount: a.length,
          totalTrips,
          touched: true,
        });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      allRows: [],
      totalTrips: 0,
      allRowsSummaryByDay: [],
      filteredRows: [],
    });
    if (this.state.selectedVehicle) {
      setTimeout(() => {
        this.onDateRangeGo();
      }, 10);
    }
  };

  handleOnSelectedGeofenceChange = (selectedGeofences) => {
    const fences =
      selectedGeofences && selectedGeofences.length
        ? selectedGeofences
        : this.props.geofenceAll;
    this.setState({ selectedGeofences: fences || [] });

    setTimeout(() => {
      this.onDateRangeGo();
    }, 10);
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];

    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });

    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xxs="12" className="mb-1">
                    <GeofenceSelect
                      onChange={this.handleOnSelectedGeofenceChange}
                    />
                  </Colxx>

                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      suggestions="months"
                      startTime={moment().startOf("month")}
                    />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => this.onDateRangeGo()}
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="tracking.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {this.state.selectedVehicle && this.state.touched ? (
                  <CardTitle>
                    <IntlMessages id="tracking.geofence-alerts" /> :{" "}
                    <IntlMessages id="tracking.from" />{" "}
                    {AppHelperDateAndTimeToString(
                      this.state.startTime.toDate()
                    )}{" "}
                    <IntlMessages id="tracking.to" />{" "}
                    {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
                  </CardTitle>
                ) : (
                  <CardTitle>
                    {" "}
                    <IntlMessages id="tracking.try-search-filter-or-select-vehicle" />{" "}
                  </CardTitle>
                )}

                {!this.state.allRows ? (
                  <p>
                    {" "}
                    <IntlMessages id="tracking.nothing-to-show" />
                  </p>
                ) : (
                  ""
                )}

                <div className="card-body-min-height">
                  <CardHeader className="pl-0 pr-0">
                    <Nav tabs className=" card-header-tabs  ml-0 mr-0">
                      <NavItem className="text-center">
                        <NavLink
                          className={classnames({
                            active: this.state.activeSecondTab === "1",
                            "nav-link": true,
                          })}
                          onClick={() => {
                            this.toggleSecondTab("1");
                          }}
                          to="#"
                        >
                          <h6>
                            {" "}
                            <IntlMessages id="tracking.day-summary" />{" "}
                          </h6>
                        </NavLink>
                      </NavItem>
                      <NavItem className="text-center">
                        <NavLink
                          className={classnames({
                            active: this.state.activeSecondTab === "2",
                            "nav-link": true,
                          })}
                          onClick={() => {
                            this.toggleSecondTab("2");
                          }}
                          to="#"
                        >
                          <h6>
                            {" "}
                            <IntlMessages id="tracking.table" />{" "}
                          </h6>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>

                  <TabContent activeTab={this.state.activeSecondTab}>
                    <TabPane tabId="1">
                      <Row className="p-2">
                        <Colxx xxs="12">
                          <p>
                            {" "}
                            <IntlMessages id="tracking.total-trips" />{" "}
                            {this.state.totalTrips / 2}{" "}
                          </p>
                          <Table responsive hover bordered striped>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>
                                  <IntlMessages id="tracking.day" />
                                </th>
                                <th>
                                  <IntlMessages id="tracking.trips" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.allRowsSummaryByDay.map((item, i) => (
                                <tr key={i + 1}>
                                  <th scope="row">{i + 1}</th>

                                  <td>{item.timeDay}</td>
                                  <td>{item.totalCount / 2}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Colxx>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="p-4">
                        <Colxx xxs="12">
                          <div className="float-right">
                            <CSVLink
                              headers={headers}
                              filename={this.state.fileName}
                              data={this.state.allRows}
                            >
                              <Button
                                title={this.state.fileName}
                                size="xs"
                                outline
                                color="dark"
                              >
                                <IntlMessages id="common.download" />
                              </Button>
                            </CSVLink>
                          </div>
                          {this.state.allRows.length +
                            " " +
                            intl.formatMessage({
                              id: "tracking.records",
                            })}

                          <Table responsive hover bordered striped>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>
                                  <IntlMessages id="common.vehicle" />
                                </th>
                                <th>
                                  <IntlMessages id="tracking.place" />
                                </th>

                                <th>
                                  <IntlMessages id="tracking.entry" />
                                </th>
                                <th>
                                  <IntlMessages id="tracking.driver" />{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.allRows.map((item, i) => (
                                <tr key={i + 1}>
                                  <th scope="row">{i + 1}</th>
                                  <td>{item.vehicleName}</td>
                                  <td>
                                    {item.time}
                                    <br />
                                    <LocationName
                                      place={item.place}
                                      geo={item.geo}
                                      objectItem={item}
                                      showGoogle
                                    />
                                  </td>

                                  <td>{item.typeText}</td>
                                  <td>{item.driverName}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Colxx>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  GeofenceDrillDown
);
