
const alertsArray = [];

function addItem(vehicle, type) {
    const item = { vehicle, type };

    alertsArray.filter((f) => {return f.vehicle === vehicle && f.type === type});

    if(alertsArray.length) { return false; }

    alertsArray.push(item);

    setTimeout(() => {
        const index = alertsArray.indexOf(item);
        if (index > -1) { alertsArray.splice(index, 1) }

    }, 60 * 1000);

    return true;
}

const addAlertManagerItem = addItem;

export {
    addAlertManagerItem,
};

