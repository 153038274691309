import React, { Component } from "react";
import { Button, Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
import { GeofenceGetInAndOutByDateAndVehicle } from "../../api-tasks-analytics/geofence";
import DateRangePicker from "../../components/DateRangePicker";
import GeofenceSelect from "../../components/GeofenceSelect";
import { NotificationManager } from "../../components/common/react-notifications";
import { AppHelperDateToDBString } from "../../helpers/AppHelper";
import moment from "moment";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";
import GeofenceTable from "./geofence-table";

class Idle extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedVehicles: [],
      selectedGeofences: [],
      loading: false,
      displayVehicles: [],
      isDataLoaded: false,
    };
  }

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  handleOnSelectedGeofenceChange = (selectedGeofences) => {
    const fences =
      selectedGeofences && selectedGeofences.length
        ? selectedGeofences
        : this.props.geofenceAll;
    this.setState({ selectedGeofences: fences || [] });

    setTimeout(() => {
      //  this.loadMasters(this.state.limit, this.state.skip);
    }, 10);
  };

  onDateRangeGo = () => {
    if (
      !this.state.selectedVehicles ||
      !this.state.selectedGeofences ||
      !this.state.selectedVehicles.length ||
      !this.state.selectedGeofences.length
    ) {
      NotificationManager.error(
        "Please select vehicle and geofence",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    const geofenceIds = this.state.selectedGeofences.map((el) => {
      return el.autoid;
    });

    const displayVehicles = this.state.selectedVehicles.map((el) => {
      return {
        item: el,
        call: GeofenceGetInAndOutByDateAndVehicle(
          user.userToken,
          [el.key],
          geofenceIds,
          startTime,
          endTime
        ),
        callStatus: "pending",
        callResponse: null,
      };
    });

    this.setState({ displayVehicles, isDataLoaded: true });
  };

  // onDateRangeGo = (limit, skip) => {
  //   if (
  //     !this.state.selectedVehicles ||
  //     !this.state.selectedGeofences ||
  //     !this.state.selectedVehicles.length ||
  //     !this.state.selectedGeofences.length
  //   ) {
  //     NotificationManager.error(
  //       "Please select vehicle and geofence",
  //       "ERROR",
  //       3000,
  //       null,
  //       null,
  //       ""
  //     );

  //     return;
  //   }

  //   const user = this.props.user;
  //   const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
  //   const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

  //   const vehicleIds = this.state.selectedVehicles.map((el) => {
  //     return el.key;
  //   });

  //   const geofenceIds = this.state.selectedGeofences.map((el) => {
  //     return el.autoid;
  //   });

  //   this.setState({ loading: true });

  //   GeofenceGetInAndOutByDateAndVehicle(
  //     user.userToken,
  //     vehicleIds,
  //     geofenceIds,
  //     startTime,
  //     endTime
  //   ).then((response) => {
  //     this.setState({
  //       loading: false,
  //       fileName: `Ctraac - Geofence Alerts, ${this.state.startTime.format(
  //         "YYY-MM-DD hh:mm A"
  //       )} to ${this.state.endTime.format("YYY-MM-DD hh:mm A")}.csv`,
  //     });

  //     if (response && Array.isArray(response)) {
  //       const a = response.map((x) => {
  //         return {
  //           ...x,
  //           typeText: x.type ? "In" : "Out",
  //           time: AppHelperDateAndTimeToString(x.event_time),
  //           driverName: this.findDriverName(x),
  //           vehicleName: this.findVehicleName(x),
  //           plateNumber: this.findPlateNumber(x),
  //         };
  //       });

  //       this.setState({
  //         allRows: a,
  //         totalCount: response.count,
  //         touched: true,
  //       });
  //     }
  //   });
  // };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
    });
    if (this.state.selectedVehicles.length) {
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);
    }
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xxs="12" className="mb-1">
                    <GeofenceSelect
                      onChange={this.handleOnSelectedGeofenceChange}
                    />
                  </Colxx>

                  <Colxx xxs="12" className="mb-1">
                    <VehicleSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                    />
                  </Colxx>
                </Row>

                <Row>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      startTime={moment().startOf("day")}
                    />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : ( <IntlMessages id="common.refresh" /> )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <GeofenceTable
                  displayVehicles={this.state.displayVehicles}
                  startTime={this.state.startTime}
                  endTime={this.state.endTime}
                  selectedGeofence={this.state.selectedGeofences}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { locale } = settings;
  return { vehiclesAll: all, user, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Idle);
