import React, { Component } from "react";
import {
  Badge,
  Row,
  CardBody,
  CardTitle,
  ButtonGroup,
  Button,
  Table
} from "reactstrap";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Moment from "react-moment";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import XButton from "../../components/common/XButton";
import DriverVehiclesAdd from "../../components/DriverVehiclesAdd";
import { NotificationManager } from "../../components/common/react-notifications";
import StateButton from "../../components/StateButton";
import DropStateButton from "../../components/DropStateButton";
import {
  DriverSelectByVehicle,
  DriverSelectBySubscriber,
  DriverVehicleRemove,
  DriverVehicleRedo
} from "../../api-tasks/drivers";
import { authApiAccessCheck } from "../../redux/actions";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

class VehicleDrivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      allDataRows: [],
      filteredDataRows: [],
      allDrivers: [],
      driversNotInVehicle: [],
      searchTex: ""
    };
  }

  componentDidMount() {
    this.loadMasterData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedVehicle !== this.props.selectedVehicle) {
      this.loadTable();
    }

    if (
      prevState.allDataRows !== this.state.allDataRows &&
      this.state.searchTex
    ) {
      this.onSearch({ target: { value: this.state.searchTex } });
    }
  }

  loadMasterData = () => {
    this.setState({ loading: true });
    const user = this.props.user;
    DriverSelectBySubscriber(user.userToken).then(res => {
      if (res) {
        if (Array.isArray(res)) {
           
          this.setState({ allDrivers: res });
          this.loadTable();
        } else {
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  loadTable = searchTex => {
    const user = this.props.user;
    const vehicle = this.props.selectedVehicle;

    this.setState({ loading: true, searchTex: searchTex || "" });

    DriverSelectByVehicle(vehicle.autoid, user.userToken).then(res => {
      this.setState({ loading: false });
      if (res) {
        if (Array.isArray(res)) {
          
          this.setState({ allDataRows: res, filteredDataRows: res });

          let allDriver = this.state.allDrivers;
          let vehicleDrivers = res;
          let driversNotInVehicle = [];

          if (allDriver.length !== vehicleDrivers.length) {
            driversNotInVehicle = allDriver;
            vehicleDrivers.forEach(vd => {
              driversNotInVehicle = driversNotInVehicle.filter(function(value) {
                return value.autoid !== vd.driver_id;
              });
            });
          } else {
            driversNotInVehicle = [];
          }

          this.setState({ driversNotInVehicle });
        } else {
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  onSearch = event => {
    let searchQuery = event.target.value.toLowerCase();
    let arrData = searchQuery.split(" ");
    let tempDataRows = this.state.allDataRows;
    let filteredDataRows = this.state.allDataRows;
    arrData.forEach(element => {
      filteredDataRows = tempDataRows.filter(el => {
        const item = {
          ...el,
          statusText: el.statuscode === 2 ? "active" : "pending"
        };

        return (
          item.driver_name.toLowerCase().indexOf(element) !== -1 ||
          item.employee_code.toLowerCase().indexOf(element) !== -1 ||
          item.rfid_code.toLowerCase().indexOf(element) !== -1 ||
          item.statusText.indexOf(element) !== -1
        );
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredDataRows: filteredDataRows,
      searchTex: searchQuery
    });
  };

  handleOnRefresh = () => {
    this.loadTable();
  };

  handleOnRemove = item => {
    const user = this.props.user;
    const input = {
      autoid: item.autoid,
      driver_id: item.driver_id,
      vehicle_id: item.vehicle_id,
      device_type: item.device_type,
      cancel_only: item.statuscode === 1 ? 1 : 0
    };

    DriverVehicleRemove(user.userToken, input).then(response => {
      if (response) {
        this.setState({ loading: false, selectedDrivers: [] });
        if (response) {
          if (response.status === "success") {
            this.loadTable();
          } else {
            NotificationManager.error(
              response.message,
              "ERROR",
              3000,
              null,
              null,
              ""
            );
          }
        }
      }
    });
  };

  handleOnRedoListedClick = item => {
    return new Promise((success, fail) => {
      if (!this.state.filteredDataRows.length) {
        fail("Hi, nothing to redo");
        return;
      }

      try {
        const vehicle = this.props.selectedVehicle;
        const drivers = this.state.filteredDataRows;

        const assignedItems = drivers.map(item => ({
          autoid: item.autoid,
          driverId: item.driver_id,
          vehicleId: item.vehicle_id,
          deviceType: vehicle.device_type
        }));

        const user = this.props.user;
        DriverVehicleRedo(user.userToken, assignedItems).then(response => {
          if (response) {
            if (response.status === "success") {
              success(response.message);
              this.loadTable(this.state.searchTex);
            } else {
              fail(response.message);
            }
          }
        });
      } catch (error) {
        fail("Ooh, unexpected error");
      }
    });
  };

  handleOnClickRedoItem = item => {
    return new Promise((success, fail) => {
      try {
        const vehicle = this.props.selectedVehicle;

        const assignedItems = [
          {
            autoid: item.autoid,
            driverId: item.driver_id,
            vehicleId: item.vehicle_id,
            deviceType: vehicle.device_type
          }
        ];

        const user = this.props.user;
        DriverVehicleRedo(user.userToken, assignedItems).then(response => {
          if (response) {
            if (response.status === "success") {
              success(response.message);
              this.loadTable(this.state.searchTex);
            } else {
              fail(response.message);
            }
          }
        });
      } catch (error) {
        fail("Ooh, unexpected error");
      }
    });
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    const vehicle = this.props.selectedVehicle;
    return (
      <Row className="mb-4">
        <Colxx xxs="12">
          <CardBody>
            <CardTitle>
            <IntlMessages id="vehicle.drivers-of" /> {vehicle.title} #{vehicle.autoid}{" "}
            </CardTitle>
            <Row>
              <div className="w-100">
                <Colxx xl="6" lg="6" md="12" sm="12">
                  <DriverVehiclesAdd
                    options={this.state.driversNotInVehicle}
                    onRefresh={this.handleOnRefresh}
                    vehicle={vehicle}
                    user={this.props.user}
                  />
                </Colxx>
              </div>
            </Row>

            <Row className="mt-2">
              <Colxx xxs="12">
                <div className="search">
                  <div className="float-right">
                    <ButtonGroup className="flex-wrap">
                      <input
                        className="input2 w-50"
                        type="text"
                        onChange={this.onSearch}
                        value={this.state.searchTex}
                        placeholder={intl.formatMessage({
                          id: "driver.search-drivers",
                        })}
                      />
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={this.loadMasterData}
                      >
                        {this.state.loading ? (
                          <span className="spinner d-inline-block">
                            <span className="bounce1" />
                            <span className="bounce2" />
                            <span className="bounce3" />
                          </span>
                        ) : (
                          <IntlMessages id="common.refresh" />
                        )}
                      </Button>
                      <DropStateButton
                        id={`redo_listed_driver_vehicles`}
                        color="secondary"
                        className="btn"
                        size="sm"
                        title={intl.formatMessage({
                          id: "vehicle.re-apply",
                        })}
                        onClick={this.handleOnRedoListedClick}
                        confirmText={`${intl.formatMessage({
                          id: "vehicle.confirm",
                        })} ${
                          this.state.filteredDataRows.length
                        } ${intl.formatMessage({
                          id: "vehicle.drivers-?",
                        })}`}
                      >
                         <IntlMessages id="vehicle.redo-list" />
                      </DropStateButton>
                    </ButtonGroup>
                  </div>
                </div>
              </Colxx>
            </Row>
            <div className="card-body-min-height">
              <Table responsive hover bordered striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th><IntlMessages id="vehicle.name" /></th>
                    <th title="Employee Code"><IntlMessages id="vehicle.emp" /></th>
                    <th>RFID</th>
                    <th><IntlMessages id="vehicle.status" /></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.filteredDataRows.map((item, i) => (
                    <tr key={item.autoid}>
                      <th scope="row">{i + 1}</th>
                      <td>{item.driver_name}</td>
                      <td>{item.employee_code}</td>
                      <td>{item.rfid_code}</td>
                      <td>
                        <h6 className="d-inline pb-1">
                          <Badge
                            color={
                              item.statuscode === 2 ? "success" : "secondary"
                            }
                            className="mb-1 mr-1"
                          >
                            {item.statuscode === 2 ? "Active" : "Pending"}
                          </Badge>

                          <Badge
                            color="info"
                            className="mb-1 mr-1"
                            title={item.updatetime}
                          >
                            <Moment fromNow>{item.updatetime}</Moment>
                          </Badge>
                        </h6>
                        <div className="driver-td-action float-right">
                          <ButtonGroup className="float-right">
                            <StateButton
                              key={item.autoid}
                              id={`redo${item.autoid}`}
                              size="xs"
                              color="secondary"
                              title={intl.formatMessage({
                                id: "vehicle.re-apply",
                              })}
                              onClick={this.handleOnClickRedoItem}
                              value={item}
                            >
                              <IntlMessages id="vehicle.redo" />
                            </StateButton>

                            <XButton
                              onAction={this.handleOnRemove}
                              value={item}
                              title={intl.formatMessage({
                                id: "vehicle.remove",
                              })}
                            />
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ authUser,settings }) => {
  const { user } = authUser;
  const { locale } = settings;
  return { user, locale };
};

export default connect(
  mapStateToProps,
  { authApiAccessCheck }
)(injectIntl(VehicleDrivers));
