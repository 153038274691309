import React from "react";
import Select from "react-select";
import { Button, ButtonGroup } from "reactstrap";
import IntlMessages from "../helpers/IntlMessages";
import CustomSelectInput from "../components/common/CustomSelectInput";

const customFilterOption = (option, rawInput) => {
  if (option && rawInput) {
    const words = rawInput.split(" ");
    return words.reduce(
      (acc, cur) =>
        acc &&
        option.data.searchWords.toLowerCase().includes(cur.toLowerCase()),
      true
    );
  } else {
    return true;
  }
};

class VehicleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: null,
    };
  }

  handleChange = (selectedItems) => {
    this.setState({ selectedItems });
    if (this.props.onChange) {
      this.props.onChange(selectedItems);
    }
  };

  render() {
    const options = this.props.options.map((item) => ({
      label: `${item.driver_name} - ${item.employee_code}`,
      value: item.autoid,
      searchWords:
        item.driver_name + " " + item.employee_code + " " + item.rfid_code,
      key: item.autoid,
      item: item,
    }));
    return (
      <div>
        <ButtonGroup className="flex-wrap">
          <Button
            outline
            color="secondary"
            size="xs"
            onClick={() => this.handleChange([])}
          >
            <IntlMessages id="common.clear" />
          </Button>
          <Button
            outline
            color="secondary"
            size="xs"
            onClick={() => this.handleChange(options)}
          >
            <IntlMessages id="common.select-all" />
          </Button>
        </ButtonGroup>
        <Select
          filterOption={customFilterOption}
          components={{ Input: CustomSelectInput }}
          className="react-select mt-1"
          classNamePrefix="react-select"
          options={options}
          isMulti={true}
          onChange={this.handleChange}
          value={this.props.value}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default VehicleSelect;
