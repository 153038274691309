import React, { Component } from "react";
import { Button, Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";

import { Colxx } from "../../components/common/CustomBootstrap";
import { authApiAccessCheck } from "../../redux/actions";
import DateRangePicker from "../../components/DateRangePicker";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { NotificationManager } from "../../components/common/react-notifications";
import { AppHelperDateToDBString } from "../../helpers/AppHelper";
import { VehiclesGetDaySummary } from "../../api-tasks-analytics/vehicles";
import IntlMessages from "../../helpers/IntlMessages";
import MultipleHistoryMap from "./multiple-history-map";

class MapSearchContainer extends Component {
  constructor(props) {
    super();

    this.state = {
      selectedVehicles: [],
      loading: false,
      displayVehicles: [],
      allRows: [],
    };
  }

  componentWillUnmount() {}

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicleId && ignition.vehicleId > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicleId;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };

  onDateRangeGo = (limit, skip) => {
    if (!this.state.selectedVehicles || !this.state.selectedVehicles.length) {
      NotificationManager.error(
        "Please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    const offsetMinutes = null;
    const pathWay = true;

    const displayVehicles = this.state.selectedVehicles.map((el) => {
      return {
        item: el,
        historyCall: VehiclesGetDaySummary(
          user.userToken,
          [el.key],
          startTime,
          endTime,
          offsetMinutes,
          pathWay
        ),
        historyCallStatus: "pending",
        historyCallResponse: null,
      };
    });

    this.setState({ displayVehicles });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      allRows: [],
    });
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
      allRows: [],
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="rounded alert alert-info fade show" role="alert">
          This is an experimental features, Choose maximum one week and 10
          vehicles for best perfomance.{" "}
        </div>

        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row className="mb-1">
                  <Colxx xxs="12">
                    <VehicleSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                      dateOnly={true}
                    />
                  </Colxx>
                </Row>
                <Row className="mb-1">
                  <Colxx xl="8" lg="8" md="8" sm="12" className="pl-3">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      startTime={moment().startOf("week")}
                    />
                  </Colxx>
                  <Colxx xl="2" lg="2" md="2" sm="12">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="tracking.go" />
                      )}
                    </Button>
                  </Colxx>
                </Row>

                <MultipleHistoryMap
                  displayVehicles={this.state.displayVehicles}
                  startTime={this.state.startTime}
                  endTime={this.state.endTime}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { locale } = settings;

  return {
    vehiclesAll: all,
    user,
    locale,
  };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
})(MapSearchContainer);
