import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import {
  UncontrolledAlert,
  Row,
  CardTitle,
  CardBody,
  FormGroup,
  Label
} from "reactstrap";
import Select from "react-select";
import { injectIntl } from "react-intl";

import { connect } from "react-redux";
import { NotificationManager } from "../../components/common/react-notifications";
import IntlMessages from "../../helpers/IntlMessages";
import { vehicleUpdateDetails } from "../../redux/actions";
import ReactAutoSuggest from "../../components/common/ReactAutoSuggest";
import { Colxx } from "../../components/common/CustomBootstrap";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import ErrorPopover from "../../components/form-controls/ErrorPopover";
import CustomSubmitButton from "../../components/form-controls/CustomSubmitButton";
import VehicleTypesData from "../../data-const/vehicle-types";
import FuelTypesData from "../../data-const/fuel-types";
import ManufacturesData from "../../data-const/manufactures";
import VehicleModelsData from "../../data-const/vehicle-models";
// import IntlMessages from "../../helpers/IntlMessages";
import * as Yup from "yup";

const VehicleEditValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required"),
  plateNumber: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
  fleetNumber: Yup.string().max(20, "Too Long!"),
  model: Yup.string()
    .min(2, "Too Short!")
    .max(10, "Too Long!"),
  tyre: Yup.string().max(10, "Too Long!"),
  engine: Yup.string().max(10, "Too Long!"),
  year: Yup.string().max(10, "Too Long!"),
  sim: Yup.string()
    .min(4, "Too Short!")
    .max(25, "Too Long!"),
  simProvider: Yup.string().max(25, "Too Long!")
});

class VehicleEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      edited: false,
      selectedFuelType: "",
      selectedVehicleType: "",
      selectedManufacture: "",
      selectedModel: ""
    };
  }

  handleChangeModel = selectedModel => {
    this.setState({ selectedModel });
  };

  handleChangeFuelType = selectedFuelType => {
    this.setState({ selectedFuelType });
  };

  handleChangeVehicleType = selectedVehicleType => {
    this.setState({ selectedVehicleType });
  };

  handleChangeManufactures = selectedManufacture => {
    this.setState({ selectedManufacture });
  };

  handleSubmit = values => {

    this.setState({ updating: true });
    const {
      selectedFuelType,
      selectedVehicleType,
      selectedManufacture,
      selectedModel
    } = this.state;

    this.props.vehicleUpdateDetails(
      this.props.history,
      {
        ...values,
        selectedFuelType,
        selectedVehicleType,
        selectedManufacture,
        selectedModel
      },
      this.props.selectedVehicle,
      this.submitCallBack
    );
  };

  submitCallBack = response => {
    this.setState({ updating: false });
    if (response) {
      if (response.status === "success") {
        NotificationManager.success(
          response.message,
          response.status.toUpperCase(),
          3000,
          null,
          null,
          ""
        );
      } else {
        NotificationManager.warning(
          response.message,
          response.status.toUpperCase(),
          3000,
          null,
          null,
          ""
        );
      }
    }
  };

  componentDidMount() {
    this.changeFormState(this.props.selectedVehicle);
  }

  componentDidUpdate(prevProps) {
    
    if (prevProps.selectedVehicle !== this.props.selectedVehicle) {
      this.changeFormState(this.props.selectedVehicle);
    }
  }

  changeFormState(selectedVehicle) {
    const { vehicle_type, manufacture_name, fuel_type } = selectedVehicle;

    const filteredVehicleTypes = VehicleTypesData.filter(
      row => row.key === vehicle_type
    );

    const filteredManufacture = ManufacturesData.filter(
      row => row.value === manufacture_name
    );

    const filteredFuelType = FuelTypesData.filter(
      row => row.value === fuel_type
    );

    this.setState({
      selectedVehicleType: filteredVehicleTypes[0],
      selectedManufacture: filteredManufacture[0],
      selectedFuelType: filteredFuelType[0],
      selectedModel: selectedVehicle.model
    });
  }

  render() {
    const selectedVehicle = this.props.selectedVehicle;

    if (!selectedVehicle) {
      return <div><IntlMessages id="vehicle.not-select" /></div>;
    }

    return (
      <Row className="mb-4">
        <Colxx xxs="12">
          <CardBody>
          <CardTitle>
          <IntlMessages id="vehicle.edit" /> - {selectedVehicle.title} #{selectedVehicle.autoid} 
             </CardTitle>
            <Formik
              initialValues={{
                title: selectedVehicle.title,
                plateNumber: selectedVehicle.plate_number,
                fleetNumber: selectedVehicle.fleet_number || "",
                tyre: selectedVehicle.tyre || "",
                engine: selectedVehicle.engine || "",
                year: selectedVehicle.year || "",
                sim: selectedVehicle.sim || "",
                simProvider: selectedVehicle.sim_provider || "",
                clusterProject: selectedVehicle.project || ""
              }}
              enableReinitialize={true}
              validationSchema={VehicleEditValidationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ errors, touched, isValidating }) => (
                <Form className="av-tooltip tooltip-label-right">
                  <Row>
                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.title" /></Label>
                        <Field className="form-control" name="title" />
                        <ErrorPopover
                          error={errors.title}
                          touched={touched.title}
                        />
                      </FormGroup>
                    </Colxx>
                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.plate-no" /></Label>
                        <Field className="form-control" name="plateNumber" />
                        <ErrorPopover
                          error={errors.plateNumber}
                          touched={touched.plateNumber}
                        />
                      </FormGroup>
                    </Colxx>
                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.asset-no" /></Label>
                        <Field className="form-control" name="fleetNumber" />
                        <ErrorPopover
                          error={errors.fleetNumber}
                          touched={touched.fleetNumber}
                        />
                      </FormGroup>
                    </Colxx>

                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.vehicle-type" /></Label>
                        <Select
                          components={{ Input: CustomSelectInput }}
                          className="react-select"
                          classNamePrefix="react-select"
                          name="vehicleType"
                          value={this.state.selectedVehicleType}
                          onChange={this.handleChangeVehicleType}
                          options={VehicleTypesData}
                        />
                      </FormGroup>
                    </Colxx>

                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.manfr-name" /></Label>

                        <Select
                          components={{ Input: CustomSelectInput }}
                          className="react-select"
                          classNamePrefix="react-select"
                          name="manufactureName"
                          value={this.state.selectedManufacture}
                          onChange={this.handleChangeManufactures}
                          options={ManufacturesData}
                        />
                      </FormGroup>
                    </Colxx>

                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.modal" /></Label>
                        <ReactAutoSuggest
                          placeholder="Model"
                          data={VehicleModelsData}
                          onChange={this.handleChangeModel}
                          name="model"
                          initialValue={ selectedVehicle.model? selectedVehicle.model : "." }
                        />

                        <ErrorPopover
                          error={errors.model}
                          touched={touched.model}
                        />
                      </FormGroup>
                    </Colxx>
                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.tyre" /></Label>
                        <Field className="form-control" name="tyre" />
                        <ErrorPopover
                          error={errors.tyre}
                          touched={touched.tyre}
                        />
                      </FormGroup>
                    </Colxx>
                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.fuel-type" /></Label>
                        <Select
                          components={{ Input: CustomSelectInput }}
                          className="react-select"
                          classNamePrefix="react-select"
                          name="fuelType"
                          value={this.state.selectedFuelType}
                          onChange={this.handleChangeFuelType}
                          options={FuelTypesData}
                        />
                      </FormGroup>
                    </Colxx>
                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.engine" /></Label>
                        <Field className="form-control" name="engine" />
                        <ErrorPopover
                          error={errors.engine}
                          touched={touched.engine}
                        />
                      </FormGroup>
                    </Colxx>
                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.year" /></Label>
                        <Field className="form-control" name="year" />
                        <ErrorPopover
                          error={errors.year}
                          touched={touched.year}
                        />
                      </FormGroup>
                    </Colxx>
                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.sim-no" /></Label>
                        <Field className="form-control" name="sim" />
                        <ErrorPopover
                          error={errors.sim}
                          touched={touched.sim}
                        />
                      </FormGroup>
                    </Colxx>
                    <Colxx xl="4" lg="6" md="12">
                      <FormGroup>
                        <Label><IntlMessages id="vehicle.sim-provider" /></Label>
                        <Field className="form-control" name="simProvider" />
                        <ErrorPopover
                          error={errors.simProvider}
                          touched={touched.simProvider}
                        />
                      </FormGroup>
                    </Colxx>
                    <Colxx xl="4" lg="6" md="12">
                    <FormGroup>
                    <Label><IntlMessages id="vehicle.cluster" /></Label>
                        <Field className="form-control" name="clusterProject" />
                        <ErrorPopover
                          error={errors.clusterProject}
                          touched={touched.clusterProject}
                        />
                      </FormGroup>
                    </Colxx>
                    <Colxx xl="4" lg="6" md="12">
                    <FormGroup>
                        <Label><IntlMessages id="vehicle.device-id" /></Label>
                        <input type="text" className="form-control" disabled value={selectedVehicle.device_id} />
                         
                      </FormGroup>
                    </Colxx>
                  </Row>

                  <CustomSubmitButton
                    validating={isValidating}
                    updating={this.state.updating}
                  />

                  {Object.keys(errors).length && Object.keys(touched).length ? (
                    <UncontrolledAlert
                      color="danger"
                      fade={false}
                      className="d-inline rounded ml-2"
                    >
                      {Object.keys(errors).length}{" "}
                      {Object.keys(errors).length > 1 ? "errors" : "error"}
                    </UncontrolledAlert>
                  ) : (
                    ""
                  )}
                </Form>
              )}
            </Formik>
          </CardBody>
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ vehicles }) => {
  return {};
};

export default connect(
  mapStateToProps,
  {
    vehicleUpdateDetails
  }
)(injectIntl(VehicleEdit));
