import React, { Component } from "react";
import { Row, CardBody, Button, Jumbotron } from "reactstrap";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { authApiAccessCheck } from "../../redux/actions";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Spinner from "../../components/common/Spinner";
import IntlMessages from "../../helpers/IntlMessages";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import TooltipButton from "../../components/common/TooltipButton";
import { VehicleControlUpdateMultipleControl } from "../../api-tasks/vehicles";
import { NotificationManager } from "../../components/common/react-notifications";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

class CommonSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedVehicles: [],
      txtOverSpeed: 120,
      txtImmobilization: "OFF",
      txtPeripheral: 0,
      txtTripHistoryData: 0,
      txtEventHistoryData: 0,
      txtIdlingBuzzer: "OFF",
      txtIdlingExitKmph: 10,
      txtCorneringAngleThreshold: 7,
      txtCorneringSpeedThreshold: 35,
      txtHardBreakingThreshold: 10,
      txtHardAcceleratingThreshold: 9,
      txtIdlingThreshold: 600,
      txtAlertBuzzer: "OFF",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  ChangeOverSpeed = (e) => {
    e.preventDefault();
    let newValue = this.state.txtOverSpeed;

    const inputs = {
      typeCode: 2, // over speed
      newValue: newValue,
      command: "$SET,OV," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  ChangeIdlingExitKmph = (e) => {
    e.preventDefault();
    let newValue = this.state.txtIdlingExitKmph;

    const inputs = {
      typeCode: 15, // Idling Exit Kmph
      newValue: newValue,
      command: "",
    };

    this.updateControls(inputs);
  };

  ChangeCorneringAngleThreshold = (e) => {
    e.preventDefault();
    let newValue = this.state.txtCorneringAngleThreshold;

    const inputs = {
      typeCode: 6, // CorneringAngleThreshold
      newValue: newValue,
      command: "$SET,CA," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  ChangeCorneringSpeedThreshold = (e) => {
    e.preventDefault();
    let newValue = this.state.txtCorneringSpeedThreshold;

    const inputs = {
      typeCode: 7, // CorneringSpeedThreshold
      newValue: newValue,
      command: "$SET,CS," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  ChangeHardBreakingThreshold = (e) => {
    e.preventDefault();
    let newValue = this.state.txtHardBreakingThreshold;

    const inputs = {
      typeCode: 8, // HardBreakingThreshold
      newValue: newValue,
      command: "$SET,HB," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  ChangeHardAcceleratingThreshold = (e) => {
    e.preventDefault();
    let newValue = this.state.txtHardBreakingThreshold;

    const inputs = {
      typeCode: 9, // HardAcceleratingThreshold
      newValue: newValue,
      command: "$SET,HA," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  ChangeIdlingThreshold = (e) => {
    e.preventDefault();
    let newValue = this.state.txtIdlingThreshold;

    const inputs = {
      typeCode: 10, // IdlingThreshold
      newValue: newValue,
      command: "$SET,IDL," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  GetTripHistoryData = (e) => {
    e.preventDefault();
    let newValue = this.state.txtTripHistoryData;

    const inputs = {
      typeCode: 200, //
      newValue: newValue,
      command: "$TRP,DT," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  GetEventHistoryData = (e) => {
    e.preventDefault();
    let newValue = this.state.txtEventHistoryData;

    const inputs = {
      typeCode: 201, //
      newValue: newValue,
      command: "$TRP,EV," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  ChangeImmobilization = (e) => {
    e.preventDefault();
    let newValue = this.state.txtImmobilization;

    const inputs = {
      typeCode: 1, // Immobilization
      newValue: newValue,
      command: "$SET,IMM," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  ChangeImmobilizationTemporary = (e) => {
    e.preventDefault();

    const inputs = {
      typeCode: 401, // Immobilization
      newValue: "ON",
      command: "$SET,MB,ON,#",
    };

    this.updateControls(inputs);
  };

  ChangeIdlingBuzzer = (e) => {
    e.preventDefault();
    let newValue = this.state.txtIdlingBuzzer;

    const inputs = {
      typeCode: 11, // Idling Buzzer
      newValue: newValue,
      command: "$SET,BZID," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  ChangeAlertBuzzer = (e) => {
    e.preventDefault();
    let newValue = this.state.txtAlertBuzzer;

    const inputs = {
      typeCode: 11, // Idling Buzzer
      newValue: newValue,
      command: "$SET,BZ," + newValue + ",#",
    };

    this.updateControls(inputs);
  };

  ChangePeripheral = (e) => {
    e.preventDefault();
    let newValue = this.state.txtPeripheral;

    const inputs = {
      typeCode: 13, // Immobilization
      newValue: newValue === "1" ? "RFID Driver" : "NONE",
      command: "$SET,PORT,1," + newValue + ",9600,#",
      command2: "$SET,PORT,2," + newValue + ",9600,#",
    };

    this.updateControls(inputs);
  };

  updateControls = (params) => {
    if (this.state.selectedVehicles && this.state.selectedVehicles.length) {
      this.setState({ loading: true });
      const items = this.state.selectedVehicles.map((element) => ({
        ...params,
        vehicleId: element.key,
        deviceType: element.item.device_type,
        command:
          element.item.device_type === 3 && params.command2
            ? params.command2
            : params.command,
      }));

      VehicleControlUpdateMultipleControl(
        this.props.user.userToken,
        items
      ).then((response) => {
        this.setState({ loading: false });
        if (response) {
          this.showMessage(response);
          this.props.authApiAccessCheck(this.props.history, response);
        }
      });
    } else {
      NotificationManager.warning(
        "Vehicles not selected",
        "Failed",
        3000,
        null,
        null,
        ""
      );
    }
  };

  showMessage = (response) => {
    if (response.status === "success") {
      NotificationManager.success(
        response.message + " and the action scheduled",
        response.status.toUpperCase(),
        3000,
        null,
        null,
        ""
      );
    } else {
      NotificationManager.error(
        response.message,
        response.status.toUpperCase(),
        3000,
        null,
        null,
        ""
      );
    }
  };

  componentDidMount() {}

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    const tooltipText = this.state.selectedVehicles.length
      ? `${intl.formatMessage({
          id: "vehicle.you-are-update",
        })} ${this.state.selectedVehicles.length} ${intl.formatMessage({
          id: "driver.vehicle",
        })}`
      : intl.formatMessage({
          id: "vehicle.please-select-vehicle",
        });
    return (
      <Row className="mb-4">
        <Colxx xl="6" lg="6" md="12">
          <CardBody>
            <VehicleSelectWithBatch
              onChange={this.handleOnSelectedVehicleChange}
            />
            <Row className="d-none d-lg-block">
              <CardBody>
                <Jumbotron>
                  <p className="lead">
                    <IntlMessages id="vehicles.info" />
                  </p>
                  <hr className="my-4" />
                  <p>
                    <IntlMessages id="vehicle.info-text" />
                  </p>
                </Jumbotron>
              </CardBody>
            </Row>
          </CardBody>
        </Colxx>
        <Colxx xl="6" lg="6" md="12">
          <CardBody>
            {this.state.loading ? (
              <Spinner className="float-right bg-info" />
            ) : (
              ""
            )}

            <h5>
              <IntlMessages id="vehicle.general" />
            </h5>

            <Separator />
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td title="2">
                      <IntlMessages id="vehicle.over-speed" />
                    </td>

                    <td>
                      <div className="btn-group">
                        <input
                          type="text"
                          className="input2"
                          name="txtOverSpeed"
                          onChange={this.onChange}
                          value={this.state.txtOverSpeed}
                          style={{ width: "70px" }}
                        />

                        <TooltipButton
                          tooltipBody={tooltipText}
                          id={1}
                          key={1}
                          size="sm"
                          onClick={this.ChangeOverSpeed}
                        >
                          <IntlMessages id="common.update" />
                        </TooltipButton>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">2</th>
                    <td title="6">
                      <IntlMessages id="vehicle.angle-threshold" />
                    </td>

                    <td>
                      <div className="btn-group">
                        <input
                          type="text"
                          className="input2"
                          name="txtCorneringAngleThreshold"
                          onChange={this.onChange}
                          value={this.state.txtCorneringAngleThreshold}
                          style={{ width: "70px" }}
                        />

                        <TooltipButton
                          tooltipBody={intl.formatMessage({
                            id: "vehicle.tooltip-best-value-7",
                          })}
                          id={6}
                          key={6}
                          size="sm"
                          onClick={this.ChangeCorneringAngleThreshold}
                        >
                          <IntlMessages id="common.update" />
                        </TooltipButton>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">3</th>
                    <td title="7">
                      <IntlMessages id="vehicle.speed-threshold" />
                    </td>

                    <td>
                      <div className="btn-group">
                        <input
                          type="text"
                          className="input2"
                          name="txtCorneringSpeedThreshold"
                          onChange={this.onChange}
                          value={this.state.txtCorneringSpeedThreshold}
                          style={{ width: "70px" }}
                        />

                        <TooltipButton
                          tooltipBody={intl.formatMessage({
                            id: "vehicle.tooltip-best-value-35",
                          })}
                          id={7}
                          key={7}
                          size="sm"
                          onClick={this.ChangeCorneringAngleThreshold}
                        >
                          <IntlMessages id="common.update" />
                        </TooltipButton>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">4</th>
                    <td title="8">
                      <IntlMessages id="vehicle.break-threshold" />
                    </td>

                    <td>
                      <div className="btn-group">
                        <input
                          type="text"
                          className="input2"
                          name="txtHardBreakingThreshold"
                          onChange={this.onChange}
                          value={this.state.txtHardBreakingThreshold}
                          style={{ width: "70px" }}
                        />

                        <TooltipButton
                          tooltipBody={intl.formatMessage({
                            id: "vehicle.tooltip-best-value-10",
                          })}
                          id={8}
                          key={8}
                          size="sm"
                          onClick={this.ChangeHardBreakingThreshold}
                        >
                          <IntlMessages id="common.update" />
                        </TooltipButton>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">5</th>
                    <td title="9">
                      <IntlMessages id="vehicle.acc-threshold" />
                    </td>

                    <td>
                      <div className="btn-group">
                        <input
                          type="text"
                          className="input2"
                          name="txtHardAcceleratingThreshold"
                          onChange={this.onChange}
                          value={this.state.txtHardAcceleratingThreshold}
                          style={{ width: "70px" }}
                        />

                        <TooltipButton
                          tooltipBody={intl.formatMessage({
                            id: "vehicle.tooltip-best-value-9",
                          })}
                          id={9}
                          key={9}
                          size="sm"
                          onClick={this.ChangeHardAcceleratingThreshold}
                        >
                          <IntlMessages id="common.update" />
                        </TooltipButton>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">6</th>
                    <td title="10">
                      <IntlMessages id="vehicle.idle-sec" />
                    </td>

                    <td>
                      <div className="btn-group">
                        <input
                          type="text"
                          className="input2"
                          name="txtIdlingThreshold"
                          onChange={this.onChange}
                          value={this.state.txtIdlingThreshold}
                          style={{ width: "70px" }}
                        />

                        <TooltipButton
                          tooltipBody={intl.formatMessage({
                            id: "vehicle.tooltip-best-value-600",
                          })}
                          id={10}
                          key={10}
                          size="sm"
                          onClick={this.ChangeIdlingThreshold}
                        >
                          <IntlMessages id="common.update" />
                        </TooltipButton>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">7</th>
                    <td title="4">
                      {" "}
                      <IntlMessages id="vehicle.idle-kmph" />{" "}
                    </td>

                    <td>
                      <div className="btn-group">
                        <input
                          type="text"
                          className="input2"
                          name="txtIdlingExitKmph"
                          onChange={this.onChange}
                          value={this.state.txtIdlingExitKmph}
                          style={{ width: "70px" }}
                        />

                        <TooltipButton
                          tooltipBody={tooltipText}
                          id={4}
                          key={4}
                          size="sm"
                          onClick={this.ChangeIdlingExitKmph}
                        >
                          <IntlMessages id="common.update" />
                        </TooltipButton>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {this.state.loading ? (
              <Spinner className="float-right bg-info" />
            ) : (
              ""
            )}
            <h5 className="mt-2">
              <IntlMessages id="vehicle.read-command" />
            </h5>

            <Separator />
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td colSpan="2">
                      <Button
                        size="sm"
                        color="info"
                        className="m-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 202,
                            newValue: 0,
                            command: "$GET,OV,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-over" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 204,
                            newValue: 0,
                            command: "$GET,FV,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.fuel-volume" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 206,
                            newValue: 0,
                            command: "$GET,CA,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-angle" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 207,
                            newValue: 0,
                            command: "$GET,CS,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-speed" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 208,
                            newValue: 0,
                            command: "$GET,HB,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-break" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 209,
                            newValue: 0,
                            command: "$GET,HA,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-acc" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 210,
                            newValue: 0,
                            command: "$GET,IL,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-idle" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 212,
                            newValue: 0,
                            command: "$GET,BZ,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-buz-status" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 213,
                            newValue: 0,
                            command: "$GET,IMM,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-imob-status" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 213,
                            newValue: 0,
                            command: "$GET,PH1,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-peripheral-1" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 214,
                            newValue: 0,
                            command: "$GET,PH2,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-peripheral-2" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 215,
                            newValue: 0,
                            command: "$GET,EX,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-expiry" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 216,
                            newValue: 0,
                            command: "$GET,APN,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-apn" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 217,
                            newValue: 0,
                            command: "$GET,ODO,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-odo" />
                      </Button>

                      <Button
                        size="sm"
                        color="info"
                        className="ml-1 mb-1"
                        onClick={() =>
                          this.updateControls({
                            typeCode: 218,
                            newValue: 0,
                            command: "$GET,OPH,#",
                          })
                        }
                      >
                        <IntlMessages id="vehicle.read-opt-hrs" />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <IntlMessages id="vehicle.get-trip" />
                    </td>

                    <td>
                      <div className="btn-group">
                        <input
                          type="text"
                          className="input2"
                          name="txtTripHistoryData"
                          onChange={this.onChange}
                          value={this.state.txtTripHistoryData}
                          style={{ width: "70px" }}
                        />

                        <Button
                          size="sm"
                          color="info"
                          onClick={this.GetTripHistoryData}
                        >
                          <IntlMessages id="vehicle.read" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <IntlMessages id="vehicle.get-event" />
                    </td>

                    <td>
                      <div className="btn-group">
                        <input
                          type="text"
                          className="input2"
                          name="txtEventHistoryData"
                          onChange={this.onChange}
                          value={this.state.txtEventHistoryData}
                          style={{ width: "70px" }}
                        />

                        <Button
                          size="sm"
                          color="info"
                          onClick={this.GetEventHistoryData}
                        >
                          <IntlMessages id="vehicle.read" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {this.state.loading ? (
              <Spinner className="float-right bg-info" />
            ) : (
              ""
            )}
            <h5 className="mt-2">
              <IntlMessages id="vehicle.sensitive-commands" />
            </h5>
            <Separator />
            <div className="bl-danger">
              <div className="table-responsive">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>
                        <IntlMessages id="vehicle.mob-temp" />{" "}
                      </td>

                      <td>
                        <div className="btn-group">
                          <select
                            name="txtIdlingBuzzer"
                            className="btn-outline-secondary btn-sm"
                          >
                            <option value="Disable">
                              {intl.formatMessage({
                                id: "vehicle.disable",
                              })}
                            </option>
                          </select>
                          <button
                            onClick={this.ChangeImmobilizationTemporary}
                            className="btn btn-outline-secondary btn-sm"
                          >
                            <IntlMessages id="common.update" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>
                        <IntlMessages id="vehicle.idle-buzz" />
                      </td>

                      <td>
                        <div className="btn-group">
                          <select
                            name="txtIdlingBuzzer"
                            className="btn-outline-secondary btn-sm"
                            onChange={this.onChange}
                            value={this.state.txtIdlingBuzzer}
                          >
                            <option value="OFF">
                              {intl.formatMessage({
                                id: "vehicle.off",
                              })}
                            </option>
                            <option value="ON">
                              {intl.formatMessage({
                                id: "vehicle.on",
                              })}
                            </option>
                          </select>
                          <button
                            onClick={this.ChangeIdlingBuzzer}
                            className="btn btn-outline-secondary btn-sm"
                          >
                            <IntlMessages id="common.update" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">3</th>
                      <td>
                        <IntlMessages id="vehicle.alert-buzz" />
                      </td>

                      <td>
                        <div className="btn-group">
                          <select
                            name="txtAlertBuzzer"
                            className="btn-outline-secondary btn-sm"
                            onChange={this.onChange}
                            value={this.state.txtAlertBuzzer}
                          >
                            <option value="OFF">
                              {intl.formatMessage({
                                id: "vehicle.off",
                              })}
                            </option>
                            <option value="ON">
                              {intl.formatMessage({
                                id: "vehicle.on",
                              })}
                            </option>
                          </select>
                          <button
                            onClick={this.ChangeAlertBuzzer}
                            className="btn btn-outline-secondary btn-sm"
                          >
                            <IntlMessages id="common.update" />
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">4</th>
                      <td>
                        <IntlMessages id="vehicle.imob" />
                      </td>

                      <td>
                        <div className="btn-group">
                          <select
                            name="txtImmobilization"
                            className="btn-outline-secondary btn-sm"
                            onChange={this.onChange}
                            value={this.state.txtImmobilization}
                          >
                            <option value="OFF">
                              {intl.formatMessage({
                                id: "vehicle.off",
                              })}
                            </option>
                            <option value="ON">
                              {intl.formatMessage({
                                id: "vehicle.on",
                              })}
                            </option>
                          </select>
                          <button
                            onClick={this.ChangeImmobilization}
                            className="btn btn-outline-secondary btn-sm"
                          >
                            <IntlMessages id="common.update" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>
                        <IntlMessages id="vehicle.assign-aux" />
                      </td>

                      <td>
                        <div className="btn-group">
                          <select
                            name="txtPeripheral"
                            className="btn-outline-secondary btn-sm"
                            onChange={this.onChange}
                            value={this.state.txtPeripheral}
                          >
                            <option value="0">
                              {intl.formatMessage({
                                id: "vehicle.none",
                              })}
                            </option>
                            <option value="1">
                              {intl.formatMessage({
                                id: "vehicle.driver-rfid",
                              })}
                            </option>
                          </select>
                          <button
                            onClick={this.ChangePeripheral}
                            className="btn btn-outline-secondary btn-sm"
                          >
                            <IntlMessages id="common.update" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </CardBody>
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user } = authUser;
  const { locale } = settings;
  return { user, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  injectIntl(CommonSettings)
);
