import React, { Fragment } from "react";
import { Row, Table, Button } from "reactstrap";
import { Colxx } from "../../../components/common/CustomBootstrap";
import CsvDownloader from "../../../components/CsvDownloader";
import LocationName from "../../../components/LocationName";
import IntlMessages from "../../../helpers/IntlMessages";
import MapModel from "../../../components/MapModel";

const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Date", key: "summaryDayText" },
  { label: "Start Time", key: "startTimeText" },
  { label: "End Time", key: "endTimeText" },
  { label: "Duration", key: "duration" },
  { label: "Start Place", key: "startPlace" },
  { label: "Start Geo", key: "startGeo" },
  { label: "End Place", key: "endPlace" },
  { label: "End Geo", key: "endGeo" },
  { label: "driver", key: "driverName" },
];

class Errands extends React.Component {
  constructor(props) {
    super();

    this.state = {
      theRows: [],
      mapModleGeo: "",
      mapModlePlace: "",
      mapModelOpen: false,
    };
  }

  propagateRows = (allRows) => {
    const theRows = [];
    allRows.forEach((y) => {
      y.forEach((x) => {
        theRows.push({
          vehicleName: x.vehicleName,
          summaryDayText: x.summaryDayText,
          startTimeText: x.startTimeText,
          endTimeText: x.endTimeText,
          dayDiff: x.dayDiff,
          duration: x.duration,
          distance: x.distance,
          startPlace: x.startPlace,
          endPlace: x.endPlace,
          startGeo: x.startGeo,
          endGeo: x.endGeo,
          startTime:x.startTime,
          endTime:x.endTime
        });
      });
    });

    this.setState({ theRows });
  };
  componentDidMount() {
    this.propagateRows(this.props.allRows);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.allRows !== this.props.allRows) {
      this.propagateRows(this.props.allRows);
    }
  }
  
  toggleLarge = () => {
    this.setState((prevState) => ({
      mapModelOpen: !prevState.mapModelOpen,
    }));
  };

  onShowMapModel = (geo, place) => {
    this.setState({
      mapModleGeo: geo,
      mapModlePlace: place,
      mapModelOpen: true,
    });
  };
  onOpenHistoryView = (item) => {
    this.props.toggle("2")
    this.props.onClickView(item)
  };
  render() {
    const showRows = this.state.theRows || [];
    return (
      <Row>
        <MapModel
          geo={this.state.mapModleGeo}
          place={this.state.mapModlePlace}
          isOpen={this.state.mapModelOpen}
          closeButton={
            <Button
              color="primary"
              outline
              size="xs"
              className="mb-2"
              onClick={this.toggleLarge}
            >
              <IntlMessages id="common.close" />
            </Button>
          }
          toggle={this.toggleLarge}
        />

        <Colxx xxs="12" className={showRows.length === 0 ? "d-none" : ""}>
          <span className="float-right">
            <CsvDownloader
              headers={headers}
              filename={"errands-ctraac.csv"}
              data={showRows}
            />
          </span>
        </Colxx>

        <Colxx xxs="12">
          {showRows.length > 0 ? (
            <Table responsive hover bordered striped>
              <thead>
                <tr>
                  <th>
                    <IntlMessages id="day-summary.vehicle" />
                  </th>
                  <th>
                    <IntlMessages id="day-summary.date" />
                  </th>

                  <th>
                    <IntlMessages id="day-summary.start" />
                  </th>
                  <th>
                    <IntlMessages id="day-summary.end" />
                  </th>
                  <th>
                    <IntlMessages id="day-summary.duration" />
                  </th>
                  <th>
                    <IntlMessages id="day-summary.distance" />
                  </th>
                  <th>
                    <IntlMessages id="day-summary.driver" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {showRows.map((item, i) => (
                  <Fragment key={i}>
                    <tr>
                      <td>{item.vehicleName}</td>
                      <td> {item.summaryDayText} </td>
                      <td>
                        {" "}
                        {item.startTimeText}
                        <LocationName
                          place={item.startPlace}
                          geo={item.startGeo}
                          objectItem={item}
                          onClick={() =>
                            this.onShowMapModel(item.startGeo, item.startPlace)
                          }
                        />
                      </td>
                      <td>
                        {" "}
                        {item.endTimeText}{" "}
                        <small className="text-muted">
                          {" "}
                          {item.dayDiff ? "+" + item.dayDiff + "d" : ""}{" "}
                        </small>
                        <LocationName
                          place={item.endPlace}
                          geo={item.endGeo}
                          objectItem={item}
                          onClick={() =>
                            this.onShowMapModel(item.endGeo, item.endPlace)
                          }
                        />
                      </td>
                      <td> {item.duration} </td>
                      <td> {item.distance}</td>
                      <td> {item.driverName}</td>
                      <td>
                        {" "}
                        <Button
                          size="xs"
                          color="link"
                          onClick={()=>this.onOpenHistoryView(item)}
                        >
                          {" "}
                          View
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>
              <IntlMessages id="tracking.nothing-to-show" />
            </p>
          )}
        </Colxx>
      </Row>
    );
  }
}

export default Errands;
