
import config from "../api-config/config";

const proxyServer = config.serverApi;

const address = {
    CDN_IMAGE_URL: "https://files.ctraac.com/app-images/company-logos/",
    CDN_URL_COMPANY_LOGO: "https://files.ctraac.com/app-images/company-logos/",
    IMAGE_HANDLING_COMPANY_IMAGE : proxyServer +"image-handling/company-image" ,
    CDN_OVER_SPEED_AUDIO_URL: "https://files.ctraac.com/app-audios/over-speeds/",
  };

  export default address;
