import React, { Component } from "react";
import { Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import { GetJobcard } from "../../api-sc/jobcard";
import IntlMessages from "../../helpers/IntlMessages";


class JobcardContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      allRows: [],
    };
  }

  // componentWillUnmount() {}

  componentDidMount() {
    this.getJobcard();
  }
  getJobcard = async () => {
    const user = this.props.user;
    const userToken = user.userToken;

    const jobcards = await GetJobcard(userToken);
    if (jobcards && Array.isArray(jobcards)) {
      this.setState({
        allRows: jobcards,
        filteredRows: jobcards,
      });
    }
  };
  render() {
    const jobcardList = this.state.allRows || [];

    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle><IntlMessages id="jobcard.list" /></CardTitle>
                <small>{jobcardList.length} <IntlMessages id="support.records" /> </small>
                <div className="card-body-min-height">
                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th><IntlMessages id="jobcard.job" /></th>
                        <th><IntlMessages id="jobcard.ticket" /></th>
                        <th><IntlMessages id="jobcard.location" /></th>
                        <th><IntlMessages id="jobcard.date" /></th>
                        <th><IntlMessages id="jobcard.start-time" /></th>
                        <th><IntlMessages id="joacard.end-time" /></th>
                        <th><IntlMessages id="jobcard.status" /></th>
                        <th><IntlMessages id="jobcard.chargeable" /></th>
                        <th><IntlMessages id="jobcard.type" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobcardList.map((item, i) => (
                        <tr key={i + 1}>
                          <td>{item.title}</td>

                          <td>#{item.ticket_id + " " + item.ticketSubject}</td>
                          <td>{item.location_name}</td>
                          <td>{item.job_date}</td>
                          <td>{item.start_time}</td>
                          <td>{item.end_time}</td>
                          <td>{item.status}</td>
                          <td>{item.ischargeable}</td>
                          <td>{item.type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return { user };
};

export default connect(mapStateToProps)(JobcardContainer);
