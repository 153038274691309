/*
*HQ200862006228058537,AD1
&A2341162509974505124284970000210620
&B0000000000&C00000210&F0000&R2200&V0127&X(J01E0)(k8997401002154242903F)&K00100&Z000#
--------------------------------------------------------------------------------------

*HQ20               Headder
0                   Response properties 1byte: 1 need response ; 0 :No need response ;
862006228058537     DEVICE ID
,                   Simple ,
A                   Setting command(Code is A)
D1                  transmission status-D, ‘1’:enter GPRS/CDMA transmission, 0 exit
&                   Identifier befor each command
A2341162509974505124284970000210620      GPS Postion data(34 BYTES)
&                   Identifier befor each command
B                   B indicates additional info is the vehicle status code,
0000000000          SSSSSAAAAA
&                   Identifier befor each command
C00000210           is mileage data
&F0000              indicates the additional info is speed data
&R2200              is the GSM signal strength and the number of GPS satellites
&V0127              is the main power voltage
&X(J01E0)(k8997401002154242903F)    is terminal parameters
&K00100             is the extended status info
&Z000               driving behavoir status
#                   Tail


*/

const appHelper = require('./helper');

const CommandTypes = {
  heartBeat: 'heartbeat',
  GPSPostion: 'GPSPostion',
};

const CommandModels = {
  GPSPostion: {
    type: CommandTypes.GPSPostion,
    lat: 0.0,
    lng: 0.0,
    speed: 0.0,
    time: new Date(),
    safe: true,
    fuel: 0,
    ignition: false,
  },
  HeartBeat: {
    type: CommandTypes.heartBeat,
    time: new Date(),
  },
};
const GPSPostionObject = CommandModels.GPSPostion;
const HeartBeatObject = CommandModels.HeartBeat;

function bit2decimal(point) {
  const degree = parseFloat(point.substr(0, point.length === 8 ? 2 : 3));
  const minutes = parseFloat(point.substr(point.length === 8 ? 2 : 3, 6)) / 10000;

  return parseFloat((degree + (minutes / 60)).toFixed(6));
}

// nautical mile per hour
// to
// kilometer per hour
function nmph2kmpk(speed) {
  return parseFloat((speed * 2 * 1.852).toFixed(1));
}

function bit2Time(date, time) {
  // new Date(year, month, day, hours, minutes, seconds, milliseconds)

  const t = {
    year: (parseInt(date.substr(4, 2), 10) + 2000).toString(),
    month: (parseInt(date.substr(2, 2), 10) - 1).toString(),
    day: date.substr(0, 2),
    hour: time.substr(0, 2),
    minute: time.substr(2, 2),
    second: time.substr(4, 2),

  };

  return new Date(t.year, t.month, t.day, t.hour, t.minute, t.second, 0);
}

function getIgnition(splits) {
  for (let index = 0; index < splits.length; index += 1) {
    const el = splits[index];
    if (el[0] === 'B') {
      return el[2] === '1';
    }
  }

  return false;
}

function getBitArray(value) {
  return `${value.toString(2).split('').reverse().join('')}0000`;
}

function isolate(message) {
  if (message.length > 20
      && message.substr(0, 5) === '*HQ20'
      && message[message.length - 1] === '#') {
    const theSplits = message.split('&');
    const responseNeeded = theSplits[0].substr(5, 1) !== '0';
    const deviceId = theSplits[0].substr(6, 15);
    const messages = [];
    let time = 0;
    let flag = [];
    let ignition = false;
    let speed = 0;
    let unprecised = true;
    let lat = 0.0;
    let lng = 0.0;

    if (theSplits.length === 1) {
      messages.push({
        ...HeartBeatObject,
        time: new Date(),
      });
    }

    theSplits.forEach((el) => {
      switch (el[0]) {
        case 'A':

          // A2341162509974505124284970000210620
          // A
          // 234116 -- time
          // 25099745 -- lat
          // 051242849 -- lng
          // 7  -- status
          // 00  -- speed
          // 00  -- direction
          // 210620 -- date

          time = bit2Time(el.substr(29, 6), el.substr(1, 6));
          flag = getBitArray(parseInt(el.substr(24, 1), 10));
          ignition = getIgnition(theSplits);
          speed = nmph2kmpk(parseFloat(el.substr(25, 2)));
          lat = bit2decimal(el.substr(7, 8));
          lng = bit2decimal(el.substr(15, 9));
          unprecised = flag[0] === '1';

           

          if (!appHelper.isGeoInWhiteListedCountries(lat, lng)) {
            unprecised = true;
          }

          messages.push({
            ...GPSPostionObject,
            lat,
            lng,
            flag: el.substr(24, 1),
            unprecised,
            northLat: flag[1] === '1',
            northLng: flag[2] === '1',
            speed,
            direction: el.substr(27, 2),
            time,
            ignition,
          });

          break;

        case 'B':
          // B0000000000 B0100000000 B0000040000
          // S2 {1: ignitionOn}


          break;
        default:
          break;
      }
    });

    return {
      time,
      deviceId,
      responseNeeded,
      messages,

    };
  }

  return null;
}
 

export default isolate ;
