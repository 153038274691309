import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
// import { auth } from '../../helpers/Firebase';
import { SubscribersAuth, SubscribersAuthCheck } from "../../api-tasks/subscriber";

import {
  LOGIN_USER,
  LOGIN_USER_AUTO,
  LOGOUT_USER,
  AUTH_API_ACCESS_CHECK
} from "../actions";

import { loginUserSuccess, loginUserRejected, logoutUser } from "./actions";

import {
  vehicleSelectAllRequest,
  vehicleStateDisposal
} from "../vehicles/actions";


import { subscriberSelectDetailsRequest
} from "../subscriber/actions";

import { driverSelectRequest } from "../drivers/actions";
import { geofenceSelectRequest } from "../geofence/actions";
import { geofenceVehicleSelectRequest } from "../geofence-vehicles/actions";
import { storySelectMoreRequest } from "../story/actions";
import { passengerSelectRequest } from "../passengers/actions";
import { groupSelectRequest } from "../groups/actions";
import { favoritePlacesSelectRequest } from "../favorite-places/actions";

const loginWithEmailPasswordAsync = async (email, password) =>
  await SubscribersAuth(email, password)
    .then(authUser => authUser)
    .catch(error => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  const { failedCallback } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);

    if (loginUser && loginUser.status === "success") {
      localStorage.setItem("user_id", loginUser.accessToken);

      yield put( loginUserSuccess(loginUser.accessToken)  );

      // loading initial states
      yield put(vehicleSelectAllRequest(true, history));
      yield put(subscriberSelectDetailsRequest(history));
      yield put(driverSelectRequest(history));
      yield put(geofenceSelectRequest(history));
      yield put(geofenceVehicleSelectRequest(history));
      yield put(groupSelectRequest(history));
      yield put(storySelectMoreRequest(history,0));
      yield put(passengerSelectRequest(history));
      yield put(favoritePlacesSelectRequest(history));

      history.push("/");
    } else {
      // console.log('login failed :', loginUser.message);
      yield put(loginUserRejected(loginUser));
      if (failedCallback) {
        failedCallback(loginUser.status, loginUser.message);
      }
    }
  } catch (error) {
    console.log("login error : ", error);
  }
}

const loginWithUserKeyAsync = async userToken =>
  await SubscribersAuthCheck(userToken)
    .then(resultSet => resultSet)
    .catch(error => error);

function* loginWithUserKey({ payload }) {
  const { userToken } = payload.user;
  const { history } = payload;
  const { location } = history;

  try {
    if (userToken) {
      const resultSet = yield call(loginWithUserKeyAsync, userToken);

      if (resultSet && resultSet.status === "success") {
        // checking re-authenticated token
        if(resultSet.accessToken){
          localStorage.setItem("user_id", resultSet.accessToken);
          yield put( loginUserSuccess(resultSet.accessToken)  );
        }

        // loading initial states
        const state = yield select();
        const vehicles = state.vehicles;
        const subscriber = state.subscriber;
        const drivers = state.drivers;
        const geofence = state.geofence;
        const favoritePlaces = state.favoritePlaces;
        const geofenceVehicles = state.geofenceVehicles;
        const groups = state.groups;
        const passengers = state.passengers;
        const story = state.story;

        if (!vehicles.all.length) {
           yield put(vehicleSelectAllRequest(true, history));
        }
        if(!subscriber.details){
          yield put(subscriberSelectDetailsRequest(history));
        }
        if(!drivers.items){
          yield put(driverSelectRequest(history));
        }

        if(!groups.items){
          yield put(groupSelectRequest(history));
        }

        if(!geofence.items){
          yield put( geofenceSelectRequest(history));
        }

        if(!favoritePlaces.items){
          yield put( favoritePlacesSelectRequest(history));
        }

        if(!geofenceVehicles.items){
          yield put( geofenceVehicleSelectRequest(history));
        }

        if(!passengers.items){
          yield put(passengerSelectRequest(history));
        }

        if(!story.ignitionStory){
          yield put(storySelectMoreRequest(history,0))
        }

        // proceeding to auto login
        if (location.pathname === "/user/login") {
          history.push("/");
        }
      } else {
        if (location.pathname !== "/user/login") {
          yield put(logoutUser(history));
        }
      }
    } else {
      if (location.pathname !== "/user/login") {
        yield put(logoutUser(history));
      }
    }
  } catch (error) {}
}

/*
const registerWithEmailPasswordAsync = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push('/')
        } else {
            console.log('register failed :', registerUser.message)
        }
    } catch (error) {
        console.log('register error : ', error)
    }
}

const logoutAsync = async (history) => {
     await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
}
*/

function* apiAccessCheck({ payload }) {
  const { history, apiResponse } = payload;
  try {
    if (
      apiResponse.error_code &&
      parseInt(apiResponse.error_code, 10) === 5001
    ) {
      yield put(logoutUser(history));
    }
  } catch (error) {}
}

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield put(vehicleStateDisposal(history));

    localStorage.removeItem("user_id");
    history.push("/user/login");

    // setTimeout(require('../../AppRenderer'),200);
    // window.location.reload(false);
    setTimeout(function () {
      window.location.reload(false);
    }, 10)

  } catch (error) {
    console.log("error auth saga logout ", error);
  }
}

/*
export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}*/

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLoginUserAuto() {
  yield takeEvery(LOGIN_USER_AUTO, loginWithUserKey);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchAuthApiAccessCheck() {
  yield takeEvery(AUTH_API_ACCESS_CHECK, apiAccessCheck);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginUserAuto),
    fork(watchLogoutUser),
    fork(watchAuthApiAccessCheck)
  ]);
}
