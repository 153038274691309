import SelectLabelWithIcon from "../components/common/SelectLabelWithIcon";

const root = "/assets/img/manufacture/";

export const optionsManufactures = [
  { icon: `${root}noimage.png`, value: "", key: 0 },
  { icon: `${root}nissan-logo.png`, value: "Nissan", key: 1 },
  { icon: `${root}tata-logo.png`, value: "Tata", key: 2 },
  { icon: `${root}mercedes-benz-logo.png`, value: "Mercedes-Benz", key: 3 },
  { icon: `${root}man-logo.png`, value: "Man", key: 4 },
  { icon: `${root}hyundai-logo.png`, value: "Hyundai", key: 5 },
  { icon: `${root}toyota-logo.png`, value: "Toyota", key: 6 },
  { icon: `${root}suzuki-logo.png`, value: "Suzuki", key: 7 },
  { icon: `${root}marcopolo-logo.png`, value: "Marcopolo", key: 8 },
  { icon: `${root}mitsubishi-logo.png`, value: "Mitsubishi", key: 9 },
  { icon: `${root}renault-logo.png`, value: "Renault", key: 10 },
  { icon: `${root}volare-logo.png`, value: "Volare", key: 11 },
  { icon: `${root}scania-logo.png`, value: "Scania", key: 12 },

  { icon: `${root}volvo-logo.png`, value: "Volvo", key: 13 },
  { icon: `${root}volkswagen-logo.png`, value: "Volkswagen", key: 14 },
  { icon: `${root}ud-trucks-logo.png`, value: "UD Trucks", key: 15 },
  { icon: `${root}tesla-logo.png`, value: "Tesla", key: 16 },
  { icon: `${root}mg-logo.png`, value: "MG", key: 17 },
  { icon: `${root}mazda-logo.png`, value: "Mazda", key: 18 },
  { icon: `${root}mahindra-logo.png`, value: "Mahindra", key: 19 },
  { icon: `${root}kia-logo.png`, value: "KIA", key: 20 },
  { icon: `${root}jac-motors-logo.png`, value: "JAC Motors", key: 21 },
  { icon: `${root}iveco-logo.png`, value: "Iveco", key: 22 },
  { icon: `${root}isuzu-logo.png`, value: "Isuzu", key: 23 },
  { icon: `${root}infiniti-logo.png`, value: "Infiniti", key: 24 },
  { icon: `${root}honda-logo.png`, value: "Honda", key: 25 },
  { icon: `${root}hino-logo.png`, value: "Hino", key: 26 },
  { icon: `${root}great-wall-logo.png`, value: "Great wall", key: 27 },
  { icon: `${root}gmc-logo.png`, value: "GMC", key: 28 },
  { icon: `${root}gac-logo.png`, value: "GAC", key: 29 },
  { icon: `${root}foton-logo.png`, value: "Foton", key: 30 },
  { icon: `${root}Force-Motors-logo.png`, value: "Isuzu", key: 31 },
  { icon: `${root}fiat-logo.png`, value: "Fiat", key: 32 },
  { icon: `${root}datsun-logo.png`, value: "Datsun", key: 33 },
  { icon: `${root}dacia-logo.png`, value: "dacia", key: 34 },
  { icon: `${root}citroen-logo.png`, value: "Citroen", key: 35 },

  { icon: `${root}alfa-romeo-logo.png`, value: "Alfa Romeo", key: 36 },
  { icon: `${root}amg-logo.png`, value: "AMG", key: 37 },
  { icon: `${root}aston-martin-logo.png`, value: "Aston Martin", key: 38 },
  { icon: `${root}audi-logo.png`, value: "Audi", key: 39 },
  { icon: `${root}bentley-logo.png`, value: "Bentley", key: 40 },
  { icon: `${root}bharatbenz-logo.png`, value: "BharatBenz", key: 41 },
  { icon: `${root}bmw-logo.png`, value: "BMW", key: 42 },
  { icon: `${root}cadillac-logo.png`, value: "Cadillac", key: 43 },
  { icon: `${root}chery-logo.png`, value: "Chery", key: 44 },
  { icon: `${root}chevrolet-logo.png`, value: "Chevrolet", key: 45 },
  { icon: `${root}daihatsu-logo.png`, value: "Daihatsu", key: 46 },
  { icon: `${root}dodge-logo.png`, value: "Dodge", key: 47 },
  { icon: `${root}ford-logo.png`, value: "Ford", key: 48 },
  { icon: `${root}jaguar-logo.png`, value: "Jaguar", key: 49 },
  { icon: `${root}jeep-logo.png`, value: "Jeep", key: 50 },
  { icon: `${root}lamborghini-logo.png`, value: "Lamborghini", key: 51 },
  { icon: `${root}land-rover-logo.png`, value: "Land Rover", key: 52 },
  { icon: `${root}lexus-logo.png`, value: "Lexus", key: 53 },
  { icon: `${root}lincoln-logo.png`, value: "Lincoln", key: 54 },
  { icon: `${root}maserati-logo.png`, value: "Maserati", key: 55 },
  { icon: `${root}mini-logo.png`, value: "Mini", key: 56 },
  { icon: `${root}mustang-logo.png`, value: "Mustang", key: 57 },
  { icon: `${root}peugeot-logo.png`, value: "Peugeot", key: 58 },
  { icon: `${root}saab-logo.png`, value: "Saab", key: 59 },
  { icon: `${root}scion-logo.png`, value: "Scion", key: 60 },
  { icon: `${root}seat-logo.png`, value: "Seat", key: 61 },
  { icon: `${root}skoda-logo.png`, value: "Skoda", key: 62 },
  { icon: `${root}subaru-logo.png`, value: "Subaru", key: 63 }
];

const selectOptions = optionsManufactures.map(item => ({
  label: SelectLabelWithIcon(item.value, item.icon),
  value: item.value,
  key: item.key
}));

export default selectOptions;