import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import vehicleSagas from './vehicles/saga';
import subscriberSagas from './subscriber/saga';
import storySagas from './story/saga';
import driverSagas from './drivers/saga';
import geofenceSagas from './geofence/saga';
import geofenceVehiclesSagas from './geofence-vehicles/saga';
import passengerSagas from './passengers/saga';
import groupSagas from './groups/saga';
import favoritePlacesSagas from './favorite-places/saga';

import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    vehicleSagas(),
    subscriberSagas(),
    storySagas(),
    driverSagas(),
    geofenceSagas(),
    geofenceVehiclesSagas(),
    passengerSagas(),
    groupSagas(),
    favoritePlacesSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas()
  ]);
}
