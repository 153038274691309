import React, { Component } from "react";
import { Button, Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
import { VehiclesGetDaySummary } from "../../api-tasks-analytics/vehicles";
import DateRangePicker from "../../components/DateRangePicker";
import { NotificationManager } from "../../components/common/react-notifications";

import AppLocale from "../../lang";
import { createIntl } from "react-intl";

import {
  AppHelperDateToDBString,
   
} from "../../helpers/AppHelper";
import DaySummaryTable from "./day-summary-table";

class DaySummary extends Component {
  constructor() {
    super();
    this.state = {
      selectedVehicles: [],
      loading: false,
      allRows: [],
      totalCount: 0,
      touched: false,
      skip: 0,
      fileName: "Ctraac - alerts.csv",
      displayVehicles: [],
    };
  }

  findDriverName = (driverId) => {
    if (driverId && driverId > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === driverId;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicleId && ignition.vehicleId > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicleId;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
      allRows: [],
    });

    setTimeout(() => {
      this.onDateRangeGo();
    }, 10);
  };

  onDateRangeGo = () => {
    if (
      !this.state.selectedVehicles ||
      !this.state.selectedVehicles.length
    ) {
      NotificationManager.error(
        "Please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    const displayVehicles = this.state.selectedVehicles.map((el) => {
      return {
        item: el,
        call:  VehiclesGetDaySummary(user.userToken, [el.key], startTime, endTime),
        callStatus: "pending",
        callResponse: null,
      };
    });

    this.setState({ displayVehicles, isDataLoaded: true });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      allRows: [],
    });
    if (this.state.selectedVehicles.length) {
      setTimeout(() => {
        this.onDateRangeGo();
      }, 10);
    }
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="6" lg="6" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      startTime={moment().startOf("month")}
                      suggestions="months"
                      dateOnly={true}
                    />
                  </Colxx>
                </Row>

                <Row>
                  <Colxx xxs="12" className="mb-1">
                    <VehicleSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                      dateOnly={true}
                    />
                  </Colxx>
                </Row>

                <Row>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => this.onDateRangeGo()}
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <DaySummaryTable
                   

                  displayVehicles={this.state.displayVehicles}
                  startTime={this.state.startTime}
                  endTime={this.state.endTime}

                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(DaySummary);
