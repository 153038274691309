import React, { Component } from "react";
// import { Formik, Form } from "formik";
import { Row, Card, CardTitle } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
// import IntlMessages from "../../helpers/IntlMessages";
import { VerifyMail } from "../../api-sc/forgot-password";
import { NotificationManager } from "../../components/common/react-notifications";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmitMail = async (values) => {
    const input = {
      mailId: values.email,
    };
    if (values.email !== "") {
      let verifyResult = await VerifyMail(input);
      if (verifyResult.status !== "failed") {
        verifyResult.message =
        "Please check your email for OTP from bot@cloudtel.qa. If you do not find the email in your inbox, please check your spam filter or bulk email folder";
        this.onSubmitFailed(verifyResult.status, verifyResult.message);
        setTimeout(() => {
          this.props.history.push({
            pathname: `/user/send-otp`,
            state: {
              email: values.email,
            },
          });
        }, 8000);
      }
      else{
        this.onSubmitFailed(verifyResult.status, verifyResult.message)
      }
    }
  };
  onSubmitFailed = (status, message) => {
    // alert(status+", "+ message);
    if (status) {
      NotificationManager.warning(
        message,
        status.toUpperCase(),
        8000,
        null,
        null,
        ""
      );
    }
  };
  render() {
    return (
      <Row className="" >
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side ">
              <p className="text-white h2">
                Cloud telematics & ctraac.com - Privacy policy
              </p>
               
            </div>
            <div className="form-side" >
              <CardTitle className="mb-4">
                <span style={{ fontWeight: "bold" }}>
                Privacy and cookie policy
                </span>
              </CardTitle>
              
              <h1 className="hclass" >
  
</h1>
<h1 className="hclass">&nbsp;</h1>
<h1 className="hclass"><strong>Privacy policy</strong></h1>
<p className="sf-font">
  At Cloud Telematics, we respect your privacy. We inform our users on how we
  collect, use, share, and protect your data. Every time you interact with Cloud
  Telematics through either our physical stores or e-commerce websites and
  engage our services, you agree to the use of information that is collected or
  submitted as stated in this Privacy Policy. Looking after your personal
  information is important to us, which is why we would like you to be confident
  that your data is kept safe and secure with us.&nbsp;
</p>
<p className="sf-font">
  This policy describes how Cloud Telematics collects, processes, uses and
  discloses your personal information as a data controller, when you contact us,
  use our services or interact with our platforms including websites and mobile
  apps (together&nbsp;"Platforms").&nbsp;
</p>
<h1 className="hclass">What does this policy cover</h1>
<p className="sf-font">
  We are committed to adopting the highest standards when it comes to how we
  collect, use and protect your personal information, and have accordingly
  developed this privacy policy (the “Policy”), which:
</p>
<ul className="sf-font">
  <li className="sf-font-li">
    1.sets out the types of personal information we collect;
  </li>
  <li className="sf-font-li">
    2.explains how and why we collect and use your personal information;
  </li>
  <li className="sf-font-li">
    3.explains when and why we may share personal information within the Cloud
    Telematics and with other organizations.
  </li>
  <li className="sf-font-li">
    4.explains how we protect the personal information we collect; and
  </li>
  <li className="sf-font-li">
    5.explains the rights and choices you have concerning your personal
    information.
  </li>
</ul>
<p className="sf-font">
  This Policy applies if you use our services (referred to in this Policy as
  “our Services”).&nbsp;
</p>
<p className="sf-font">Using our Services means:</p>
<ul className="sf-font">
  <li className="sf-font-li">
    1.Buying / Ordering services or products from any of our subsidiaries over
    the phone, in-store, or online
  </li>
  <li className="sf-font-li">
    2.Using any of our platforms, including websites (“our Websites”)
  </li>
  <li className="sf-font-li">3.Being a member of any of our loyalty schemes</li>
  <li className="sf-font-li">
    4.Taking part in our promotions &amp; competitions and registering to
    receive our newsletters and offers
  </li>
</ul>
<p className="sf-font">
  This Policy also applies if you contact us or we contact you about our
  Services.&nbsp;
</p>
<p className="sf-font">Personal information we collect</p>
<p className="sf-font">When you register to our services, we may collect:</p>
<ul className="sf-font">
  <li className="sf-font-li">
    1.Your details, including your postal and billing addresses, email
    address(es), phone number(s), date of birth, gender, and passport or ID
    number
  </li>
  <li className="sf-font-li">
    2.Your account login details, such as your username and the password
    (encrypted) that you have chosen; and
  </li>
  <li className="sf-font-li">
    3.Your interests, preferences, income, and other profiling
    information.&nbsp;
  </li>
</ul>
<p className="sf-font">
  When you shop with us online, browse our Websites, use our Mobile Apps, or
  connect to Wi-Fi networks we provide, we may collect:
</p>
<ul className="sf-font">
  <li className="sf-font-li">
    1.Information about your online purchases (for example: what, when, where
    you ordered bought and how you paid);&nbsp;
  </li>
  <li className="sf-font-li">
    2.Information about your online browsing behavior on our Websites, Mobile
    Apps and public networks and information about when you click on one of our
    adverts (including those shown on other organizations’ websites);
  </li>
  <li className="sf-font-li">
    3.Information about devices you have used to access our Services (including
    the make, model and operating system, IP address, browser type, and mobile
    device identifiers);&nbsp;
  </li>
  <li className="sf-font-li">
    4.Information about your precise geolocation.&nbsp;
  </li>
</ul>
<p className="sf-font">
  When you use any of our loyalty program(s) to shop with us, we may collect:
</p>
<ul className="sf-font">
  <li className="sf-font-li">
    1.Transaction information, including the in-store and online purchases you
    earn points for and how you use your points, coupons, and vouchers within
    our subsidiaries or with other partners.&nbsp;
  </li>
</ul>
<p className="sf-font">
  When you contact us or we contact you or you take part in promotions,
  competitions, surveys or questionnaires about our Services, we may collect:
</p>
<ul className="sf-font">
  <li className="sf-font-li">1.Information you provide about yourself.&nbsp;</li>
  <li className="sf-font-li">
    2.Details of emails and other digital communications we send to you
    including information about the emails you open and links in them that you
    click on.&nbsp;
  </li>
  <li className="sf-font-li">
    3.Your feedback and contributions to customer surveys and questionnaires.
  </li>
</ul>
<p className="sf-font">Other sources of personal information</p>
<p className="sf-font">
  We may also use personal information from other sources, such as specialist
  companies that supply information, online media channels, our partners and
  public registers. We may collect the following types of personal information
  about you from other sources: contact details, credit history, purchases,
  interests, preferences, or other types of publicly available information. This
  other personal information helps us to:
</p>
<ul className="sf-font">
  <li className="sf-font-li">
    1.review and improve the accuracy of the data we hold; and
  </li>
  <li className="sf-font-li">
    2.improve and measure the effectiveness of our marketing communications,
    including online advertising.&nbsp;
  </li>
</ul>
<p className="sf-font">
  We may be required by law to collect personal information about you or as a
  consequence of any contractual relationship we have with you.&nbsp;
</p>
<p className="sf-font">
  If you choose not to provide your personal information to be used as per this
  Policy, you will still be able to visit our Platforms but you may not be able
  to access certain options, products or services, and in some cases we may not
  be able to fulfill a service you request.&nbsp;
</p>
<h1 className="hclass">Why and how we use your personal information</h1>
<p className="sf-font">Make our Services available to you</p>
<p className="sf-font">
  We use your personal information to provide the services you have requested,
  including:
</p>
<ul className="sf-font">
  <li className="sf-font-li">1.Processing your orders and refunds</li>
  <li className="sf-font-li">2.Managing the accounts you hold with us.&nbsp;</li>
</ul>
<p className="sf-font">
  This is so we can provide the services in line with our contractual
  obligations to you.
</p>
<h2>Personalize your experience</h2>
<p className="sf-font">
  We may use your personal information to personalize the marketing messages we
  send to you and to make them more relevant and interesting, as this is in our
  legitimate business interests. Where necessary we will obtain your consent
  first. We may use your personal information for this purpose in the following
  ways:
</p>
<ol>
  <li className="sf-font-li">
    1.Using your online browsing behavior as well as your in-store and online
    purchases to help us better understand you as a customer and provide you
    with personalized offers and services.
  </li>
  <li className="sf-font-li">
    2.Providing you with relevant marketing communications (including by email,
    post, telephone, SMS, or online advertising), relating to products and
    services we offer, and those of our suppliers and partners. As part of this,
    online advertising may be displayed on our Websites and other organizations’
    websites and online media channels. We may also measure the effectiveness of
    our marketing communications and those of our suppliers and partners.
  </li>
  <li className="sf-font-li">
    3.To help us to better understand you and provide you with services and
    marketing communications that are relevant to your interests, we combine
    personal information we collect when you make purchases in-store with
    personal information collected from our Websites, Mobile Apps and other
    sources including the different subsidiaries under Cloud Telematics.
  </li>
</ol>
<h2 className="hclass">Contact and interact with you</h2>
<p className="sf-font">
  We may use your personal information when we communicate with you, to:
</p>
<ul className="sf-font">
  <li className="sf-font-li">
    1.Contact you about our Services, for example by phone, email or post or by
    responding to social media posts that you have directed at us
  </li>
  <li className="sf-font-li">
    2.Manage promotions and competitions you take part in, including those we
    run with our suppliers and partners
  </li>
  <li className="sf-font-li">
    3.Invite you to take part in and manage customer surveys, questionnaires and
    other market research activities carried out by Cloud Telematics and by
    other organizations on our behalf
  </li>
</ul>
<p className="sf-font">
  It is in our legitimate business interests that we can provide you with
  appropriate responses and provide you with a notice about our services.&nbsp;
</p>
<p className="sf-font">Manage and improve our day-to-day operations</p>
<p className="sf-font">
  We analyze information about how you use our services.&nbsp;
</p>
<ul className="sf-font">
  <li className="sf-font-li">1.Manage and improve our Websites and Mobile Apps.</li>
  <li className="sf-font-li">
    2.Help to develop and improve our product range, services, stores,
    information technology systems, know-how and the way we communicate with
    you.
  </li>
  <li className="sf-font-li">3.Detect and prevent fraud or other crimes.</li>
</ul>
<p className="sf-font">
  It is in our legitimate business interests to use the information provided to
  us for this purpose, so we can understand any issues with our services to
  improve it.&nbsp;
</p>
<h2 className="hclass">Social media</h2>
<p className="sf-font">
  We operate channels, pages, and accounts on some social media sites to inform,
  assist and engage with customers. We monitor and record comments and posts
  made on these channels about us so that we can improve our services.&nbsp;
</p>
<p className="sf-font">
  Cloud Telematics is not responsible for any information posted on those sites
  other than the information we have posted ourselves. We do not endorse the
  social media sites themselves or any information posted on them by third
  parties.&nbsp;
</p>
<p className="sf-font">
  The following companies, Facebook, Twitter, Instagram, YouTube, Google and
  Linkedin, may use data for their purposes, specifically to market to you
  through their social media platforms and allow engagement on our websites.
  This includes profiling and targeting you with other advertising.&nbsp;
</p>
<h2 className="hclass">Your rights</h2>
<p className="sf-font">
  All the information that we have collected is available in our database of
  contacts. For users who registered on Cloud Telematics, the e-commerce
  platform for a big variety of products, all information is available online in
  your account and you may access such information at any time by logging in to
  your account through our website.&nbsp;
</p>
<p className="sf-font">
  About your data, you have the followings rights, as mentioned below:
</p>
<p className="sf-font">
  1.Request correction or updation of the personal data that we have with you
  about you.&nbsp;
</p>
<p className="sf-font">
  2.This enables us to correct or update any incomplete or inaccurate data we
  have about you. However, we may need to verify the accuracy of the corrected
  or updated data provided to us by you, to secure the data and comply with our
  data accuracy policies·
</p>
<p className="sf-font">
  3.Request to delete the personal data. You may request to delete or remove
  personal data where there are no reasons for us processing that data. However,
  in special cases, we may not be able to comply with your request of erasure
  for specific legal reasons which will be notified to you, if applicable, at
  the time of your request.&nbsp;
</p>
<p className="sf-font">
  4.The objection regarding processing your data, where we are relying on a
  legitimate interest and there is something about your particular situation
  that makes you want to object processing on this ground if you feel it impacts
  on your fundamental rights and freedom.&nbsp;
</p>
<p className="sf-font">
  5.Request restriction of processing your data. In such circumstances that you
  have successfully requested restriction of your data, we will suspend the
  processing of your data—where you wish to cancel all services and close your
  accounts; or where you are challenging the accuracy of the personal data we
  hold, and where you may want to exercise or defend legal claims when we would
  normally delete or anonymity the data.&nbsp;
</p>
<h1 className="sf-font">Data retention</h1>
<p className="sf-font">
  When you place an order through the Site, we will maintain your Order
  Information for our records unless and until you ask us to delete this
  information.&nbsp;
</p>
<h1 className="sf-font">Opt-out policy</h1>
<p className="sf-font">
  Where you have opted-in to receive such communications, we may send you
  information about our products and services. To opt-out of marketing or
  promotional emails, please click the unsubscribe link in the footer of the
  email communication to be removed from future communications. To stop
  receiving SMS, please reply with the four-digit opt out number stated in the
  SMS.&nbsp;
</p>
<h1 className="sf-font">Notification of changes to this privacy policy&nbsp;</h1>
<p className="sf-font">
  We may update or amend this privacy policy from time to time, for example,
  changes to our practices or for other operational, legal or regulatory
  reasons.
</p>
<h1 className="sf-font">Minors</h1>
<p className="sf-font">
  The Site is not intended for individuals under the age of 18.&nbsp;
</p>
<h1 className="sf-font">Contacting us and complaints</h1>
<p className="sf-font">
  If you require further information regarding our Privacy Policy, or if you
  have a complaint about our handling of your personal information, please email
  us at the following address:
  <a  href="mailto:hello@ctraac.com">hello@ctraac.com</a> .If you have a
  complaint about our handling of personal information, we will be in contact,
  promptly and work with you to resolve the issue.&nbsp;
</p>

            </div>
          </Card>
        </Colxx>
      </Row>
    );
    // }
  }
}
