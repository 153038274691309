import React, { Component } from "react";
import { connect } from "react-redux";
import { Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { authApiAccessCheck } from "../../../redux/actions";
import { Colxx } from "../../../components/common/CustomBootstrap";
import moment from 'moment';
import History from "../history";

class ErrandsViewContainer extends Component {
  constructor() {
    super();

    this.clickHistoryGo = null;
    this.state = {
      loading: false,
      wayPoints: [],
      startTime: null,
      startPlace: "",
      startGeo: "",
      endTime: null,
      endPlace: "",
      endGeo: "",
      viewingObject: null,
      topSpeed: {},
      avgSpeed: 0,
    };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

    if (prevProps.activeTab !== this.props.activeTab) {
      if (this.props.activeTab === "2") {
        // this.getHistoryState();
        if (this.clickHistoryGo) {

          this.clickHistoryGo({
            selectedVehicle: this.props.vehicle,
            startTime: moment(this.props.itemObject.startTime),
            endTime: moment(this.props.itemObject.endTime)
          });
        }
      }
    }
  }


  render() {

    return (
      <React.Fragment>
        <Row >


          <Colxx xxs="12">


            <History
              clickGo={c => this.clickHistoryGo = c}
              hideSearch={true}
            />

          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user } = authUser;
  return { vehiclesAll: all, user };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  withRouter(ErrandsViewContainer)
);