import React from "react";
import Moment from "react-moment";
import { Badge } from "reactstrap";
import TooltipButton from "../common/TooltipButton";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";
import { connect } from "react-redux";

class VehicleControlItem extends React.Component {
  constructor(props) {
    super();

    this.state = {
      txtNewValue: ''
    };
  }

  componentDidMount() {

    let newValue = this.props.itemObject.new_value;
    if (this.props.itemObject.type_code === 13) {
      switch (newValue) {
        case "Driver RFID":
          newValue = "1";
          break;
        default:
          newValue = "0";
          break;
      }
    }

    this.setState({ txtNewValue: newValue });
 
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.itemObject !== this.props.itemObject) {
      let newValue = this.props.itemObject.new_value;

      if (this.props.itemObject.type_code === 13) {
        switch (newValue) {
          case "Driver RFID":
            newValue = "1";
            break;
          default:
            newValue = "0";
            break;
        }
      }

      this.setState({ txtNewValue: newValue });
    }

  }

  ChangeImmobilization = (e) => {
    e.preventDefault();
    let itemObject = this.props.itemObject;

    let newValue = itemObject.new_value;

    if (itemObject.type_code === 1) {
      if (itemObject.new_value === "OFF") {
        newValue = "ON";
      } else {
        newValue = "OFF";
      }
    }

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,IMM," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeOverSpeed = (e) => {
    e.preventDefault();
    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,OV," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeExpiry = (e) => {
    e.preventDefault();
    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,EXP," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeFuelTankSize = (e) => {
    e.preventDefault();
    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,FUL," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeAPN = (e) => {
    e.preventDefault();

    if (this.state.txtNewValue.length > 30) {
      console.log("Error len");
      return;
    }

    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue.trim();

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,APN," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    //console.log('apn ', regFormInputes);
    this.props.actionUpdate(regFormInputes);
  };

  ChangeCorneringAngle = (e) => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,CA," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeCorneringSpeed = (e) => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,CS," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeHardBreaking = (e) => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,HB," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeHardAcceleration = (e) => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,HA," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeIdlingMinutes = (e) => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,IDL," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeIdlingBuzzer = (e) => {
    e.preventDefault();
    let itemObject = this.props.itemObject;

    let newValue = itemObject.new_value;

    if (itemObject.type_code === 11) {
      if (itemObject.new_value === "OFF") {
        newValue = "ON";
      } else {
        newValue = "OFF";
      }
    }

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,BZID," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeAlertBuzzer = (e) => {
    e.preventDefault();
    let itemObject = this.props.itemObject;

    let newValue = itemObject.new_value;

    if (itemObject.type_code === 12) {
      if (itemObject.new_value === "OFF") {
        newValue = "ON";
      } else {
        newValue = "OFF";
      }
    }

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "$SET,BZ," + newValue + ",#",
      updatefrom: 3, // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangePeripheral = (e) => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue === "1" ? "RFID Driver" : "NONE",
      command: "$SET,PORT,1," + newValue + ",9600,#",
      updatefrom: 3, // 1:system, 2:supportmembers, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  ChangeFuelSensor = (e) => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "",
      updatefrom: 3, // 1:system, 2:supportmembers, 3:executives
    };

    console.log("on ChangeFuelSensor", regFormInputes);
    this.props.actionUpdate(regFormInputes);
  };

  ChangeAssistedGPS = e => {
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "",
      updatefrom: 3 // 1:system, 2:supportmembers, 3:executives
    };

    console.log('on ChangeAssistedGPS', regFormInputes);
    this.props.actionUpdate(regFormInputes);
  };

  ChangeIdlingExitKmph = e => {
 
    e.preventDefault();

    let itemObject = this.props.itemObject;
    let newValue = this.state.txtNewValue;

    const regFormInputes = {
      autoid: itemObject.autoid,
      vehicle_id: itemObject.vehicle_id,
      type_code: itemObject.type_code,
      new_value: newValue,
      command: "",
      updatefrom: 3, // 1:system, 2:support members, 3:executives
    };

    this.props.actionUpdate(regFormInputes);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toDateString = (baseString) => {
    baseString = "20" + baseString;
    baseString = baseString.substr(0, 4) + "-" + baseString.substr(4);
    baseString = baseString.substr(0, 7) + "-" + baseString.substr(7);
    baseString = baseString.substr(0, 10) + " " + baseString.substr(10);
    baseString = baseString.substr(0, 13) + ":" + baseString.substr(13);

    return baseString;
    //    return baseString.substr(0, index) + string + baseString.substr(index);
  };

  statusText(statuscode) {
    if (statuscode === 1) {
      return "Pending";
    } else if (statuscode === 2) {
      return "Active";
    } else {
      return "Cancelled";
    }
  }

  formateButton(item) {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    if (item.type_code === 1) {
      return (
        <TooltipButton
          tooltipBody={`${intl.formatMessage({
            id: "vehicle.current",
          })} ${item.new_value}, ${intl.formatMessage({
            id: "vehicle.default",
          })}`}
          id="vehicle-control1"
          key="vehicle-control1"
          size="sm"
          onClick={this.ChangeImmobilization}
        >
          {item.new_value === "OFF" ? "ON" : "OFF"}
        </TooltipButton>
      );
    } else if (item.type_code === 2) {
      return (
        <div className="btn-group">
          <input
            type="text"
            className="input2"
            name="txtNewValue"
            onChange={this.onChange}
            value={this.state.txtNewValue}
            style={{ width: "70px" }}
          />

          <TooltipButton
            tooltipBody={intl.formatMessage({
              id: "vehicle.tooltip-best-value-120",
            })}
            id="vehicle-control2"
            key="vehicle-control2"
            size="sm"
            onClick={this.ChangeOverSpeed}
          >
            <IntlMessages id="common.update" />
          </TooltipButton>
        </div>
      );
    } else if (item.type_code === 3) {
      return (
        <div className="btn-group">
          {this.toDateString(this.state.txtNewValue)}
        </div>
      );
    } else if (item.type_code === 4) {
      return (
        <div className="btn-group">
          <input
            className="input2"
            type="text"
            name="txtNewValue"
            onChange={this.onChange}
            value={this.state.txtNewValue}
            style={{ width: "70px" }}
          />
          <button
            onClick={this.ChangeFuelTankSize}
            className="btn btn-outline-secondary btn-sm"
          >
            <IntlMessages id="common.update" />
          </button>
        </div>
      );
    } else if (item.type_code === 5) {
      return (
        <div className="btn-group">
          <input
            className="input2"
            type="text"
            name="txtNewValue"
            onChange={this.onChange}
            value={this.state.txtNewValue}
            style={{ width: "70px" }}
            disabled
          />
          <button
            onClick={this.ChangeCorneringAngle}
            className="btn btn-outline-secondary btn-sm"
            disabled
          >
            <IntlMessages id="common.update" />
          </button>
        </div>
      );
    } else if (item.type_code === 6) {
      return (
        <div className="btn-group">
          <input
            className="input2"
            type="text"
            name="txtNewValue"
            onChange={this.onChange}
            value={this.state.txtNewValue}
            style={{ width: "70px" }}
          />

          <TooltipButton
            tooltipBody={intl.formatMessage({
              id: "vehicle.tooltip-best-value-7",
            })}
            id="vehicle-control6"
            key="vehicle-control6"
            size="sm"
            onClick={this.ChangeCorneringAngle}
          >
            <IntlMessages id="common.update" />
          </TooltipButton>
        </div>
      );
    } else if (item.type_code === 7) {
      return (
        <div className="btn-group">
          <input
            className="input2"
            type="text"
            name="txtNewValue"
            onChange={this.onChange}
            value={this.state.txtNewValue}
            style={{ width: "70px" }}
          />
          <TooltipButton
            tooltipBody={intl.formatMessage({
              id: "vehicle.tooltip-best-value-35",
            })}
            id="vehicle-control7"
            key="vehicle-control7"
            size="sm"
            onClick={this.ChangeCorneringSpeed}
          >
            <IntlMessages id="common.update" />
          </TooltipButton>
        </div>
      );
    } else if (item.type_code === 8) {
      return (
        <div className="btn-group">
          <input
            className="input2"
            type="text"
            name="txtNewValue"
            onChange={this.onChange}
            value={this.state.txtNewValue}
            style={{ width: "70px" }}
          />

          <TooltipButton
            tooltipBody={intl.formatMessage({
              id: "vehicle.tooltip-best-value-10",
            })}
            id="vehicle-control8"
            key="vehicle-control8"
            size="sm"
            onClick={this.ChangeHardBreaking}
          >
            <IntlMessages id="common.update" />
          </TooltipButton>
        </div>
      );
    } else if (item.type_code === 9) {
      return (
        <div className="btn-group">
          <input
            className="input2"
            type="text"
            name="txtNewValue"
            onChange={this.onChange}
            value={this.state.txtNewValue}
            style={{ width: "70px" }}
          />

          <TooltipButton
            tooltipBody={intl.formatMessage({
              id: "vehicle.tooltip-best-value-9",
            })}
            id="vehicle-control9"
            key="vehicle-control9"
            size="sm"
            onClick={this.ChangeHardAcceleration}
          >
            <IntlMessages id="common.update" />
          </TooltipButton>
        </div>
      );
    } else if (item.type_code === 10) {
      return (
        <div className="btn-group">
          <input
            className="input2"
            type="text"
            name="txtNewValue"
            onChange={this.onChange}
            value={this.state.txtNewValue}
            style={{ width: "70px" }}
          />

          <TooltipButton
            tooltipBody={intl.formatMessage({
              id: "vehicle.tooltip-best-value-600",
            })}
            id="vehicle-control10"
            key="vehicle-control10"
            size="sm"
            onClick={this.ChangeIdlingMinutes}
          >
            <IntlMessages id="common.update" />
          </TooltipButton>
        </div>
      );
    } else if (item.type_code === 11) {
      return (
        <TooltipButton
          tooltipBody={`${intl.formatMessage({
            id: "vehicle.current",
          })} ${item.new_value}, ${intl.formatMessage({
            id: "vehicle.default",
          })}`}
          id="vehicle-control11"
          key="vehicle-control11"
          size="sm"
          onClick={this.ChangeIdlingBuzzer}
        >
          {item.new_value === "OFF" ? "ON" : "OFF"}
        </TooltipButton>
      );
    } else if (item.type_code === 12) {
      return (
        <TooltipButton
          tooltipBody={`${intl.formatMessage({
            id: "vehicle.current",
          })} ${item.new_value}, ${intl.formatMessage({
            id: "vehicle.default",
          })}`}
          id="vehicle-control12"
          key="vehicle-control12"
          size="sm"
          onClick={this.ChangeAlertBuzzer}
        >
          {item.new_value === "OFF" ? "ON" : "OFF"}
        </TooltipButton>
      );
    } else if (item.type_code === 13) {
      return (
        <div className="btn-group">
          <select
            name="txtNewValue"
            className="btn-outline-secondary btn-sm"
            onChange={this.onChange}
            value={this.state.txtNewValue}
          >
            <option value="0">
              {intl.formatMessage({
                id: "vehicle.none",
              })}
            </option>
            <option value="1">
              {intl.formatMessage({
                id: "vehicle.driver-rfid",
              })}
            </option>
          </select>
          <button
            onClick={this.ChangePeripheral}
            className="btn btn-outline-secondary btn-sm"
          >
            <IntlMessages id="common.update" />
          </button>
        </div>
      );
    } else if (item.type_code === 14) {
      return (
        <div className="btn-group">
          <select
            name="txtNewValue"
            className="btn-outline-secondary btn-sm"
            onChange={this.onChange}
            value={this.state.txtNewValue}
          >
            <option value="NO">
              {intl.formatMessage({
                id: "vehicle.no",
              })}
            </option>
            <option value="JT">
              {intl.formatMessage({
                id: "vehicle.jt",
              })}
            </option>
            <option value="RCS">
              {intl.formatMessage({
                id: "vehicle.rcs",
              })}
            </option>
          </select>
          <button
            onClick={this.ChangeFuelSensor}
            className="btn btn-outline-secondary btn-sm"
          >
            <IntlMessages id="common.update" />
          </button>
        </div>
      );
    } else if (item.type_code === 15) {
      return (
        <div className="btn-group">
          <input
            className="input2"
            type="text"
            name="txtNewValue"
            onChange={this.onChange}
            value={this.state.txtNewValue}
            style={{ width: "70px" }}
          />

          <TooltipButton
            tooltipBody={intl.formatMessage({
              id: "vehicle.tooltip-best-value-10kmph",
            })}
            id="vehicle-control15"
            key="vehicle-control15"
            size="sm"
            onClick={this.ChangeIdlingExitKmph}
          >
            <IntlMessages id="common.update" />
          </TooltipButton>
        </div>
      );
    } else if (item.type_code === 16) {
      return (
        <div className="btn-group">
          <select
            name="txtNewValue"
            className="btn-outline-secondary btn-sm"
            onChange={this.onChange}
            value={this.state.txtNewValue}
          >
            <option value="NO">NO</option>
            <option value="YES">YES</option>

          </select>
          <button
            onClick={this.ChangeAssistedGPS}
            className="btn btn-outline-secondary btn-sm"
          >
            Update
        </button>
        </div>
      );
    }
  }

  render() {
    let item = this.props.itemObject;
    let i = this.props.index;
    return (
      <tr>
        <th scope="row">{i + 1}</th>
        <td>{item.type_text} </td>

        <td>{item.new_value}</td>

        <td>
          <Badge
            color={item.statuscode === 2 ? "success" : "secondary"}
            className="mb-1 mr-1"
          >
            {this.statusText(item.statuscode)}
          </Badge>

          <Badge color="info" className="mb-1 mr-1" title={item.updatetime}>
            <Moment fromNow>{item.updatetime}</Moment>
          </Badge>
        </td>
        <td>{this.formateButton(item)}</td>
      </tr>
    );
  }
}
const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};

export default connect(mapStateToProps)(VehicleControlItem);
