import React, { Component } from "react";
import {
  Button,
  Row,
  Card,
  CardBody,
  TabContent,
  TabPane,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { connect } from "react-redux"; 
import moment from "moment";
import { createIntl } from "react-intl"; 
import classnames from "classnames";

import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
import { LiveSelectByToken } from "../../api-tasks/live";
import { NotificationManager } from "../../components/common/react-notifications";
import { AppHelperDateAndTimeToString } from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import LiveStatusData from "../../components/LiveStatusData";
import LiveStatusCustom from "../../components/LiveStatusCustom";

const pagingLimit = -1;
const THREE_MIN = 3 * 60 * 1000;
// const FIFTEEN_MIN = 15 * 60 * 1000;
// const TWO_DAYS = 56 * 60 * 60 * 1000;

function connectedStatus(updateTime, noDataTime, reminigDays) {
  if (reminigDays < 0) {
    return "Expired";
  }
  if (!updateTime) return "-";
  if (new Date() - new Date(updateTime) < noDataTime) return "Connected";
  return "No data";
}

function liveStatus(ignition, updateTime) {
  if (!updateTime) return "-";
  if (ignition && new Date() - new Date(updateTime) < THREE_MIN) return "On";
  return "Off";
}

class Trips extends Component {
  constructor(props) {
    super();
    this.liveMapNoDataInMillisecond = 2880 * 60 * 1000;

    this.state = {
      activeFirstTab: "1",
      selectedVehicles: [],
      loading: false,
      allRows: [],
      filteredRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
      fileName: "Ctraac - live_status.csv",

      
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    if (this.props.subscriberDetails) {
      const attributes = this.props.subscriberDetails.attributes;
      const noDataMin = parseInt(attributes.liveMap.noDataMin, 10);
      this.liveMapNoDataInMillisecond = noDataMin * 60 * 1000;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.subscriberDetails !== prevProps.subscriberDetails) {
      const attributes = this.props.subscriberDetails.attributes;
      const noDataMin = parseInt(attributes.liveMap.noDataMin, 10);
      this.liveMapNoDataInMillisecond = noDataMin * 60 * 1000;
    }
  }

  toggleFirstTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab,
      });
    }
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
      allRows: [],
      filteredRows: [],
      limit: pagingLimit,
      skip: 0,
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  findLiveStatus = (vehicleId, response) => {
    const foo = response.filter(function (x) {
      return x.vehicle_id === vehicleId;
    });

    return foo[0] || {};
  };

  onDateRangeGo = () => {
    if (!this.state.selectedVehicles.length) {
      NotificationManager.error(
        "please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const vehicles = this.state.selectedVehicles;

    this.setState({ loading: true });

    LiveSelectByToken(user.userToken).then((response) => {
      this.setState({
        loading: false,
        fileName: `Ctraac - Live status ${moment().format(
          "YYY-MM-DD hh:mm A"
        )}.csv`,
      });
      const groupList = this.props.groups || [];
      if (response && Array.isArray(response)) {
        const allRows = vehicles.map((x) => {
          const foo = this.findLiveStatus(x.key, response);

          const gList = [];
          groupList.forEach((gItem) => {
            if (gItem.vehicleData.indexOf(x.key.toString()) !== -1) {
              gList.push(gItem);
              // console.log('a lot of ', gItem);
            }
          });

          return {
            webId: x.key,
            plateNumber: x.item.plate_number,
            assetNumber: x.item.fleet_number,
            cluster: x.item.project,
            model: x.item.model,
            gpsTime: AppHelperDateAndTimeToString(foo.gpstime),
            geoPoint: foo.lat ? `${foo.lat},${foo.lng}` : "",
            fuel: `${foo.fuel > 0 ? foo.fuel : "-"}`,
            updateTime: AppHelperDateAndTimeToString(foo.updatetime),
            timeStamp: new Date(foo.updatetime),
            driverName: this.findDriverName(foo),
            ignition: liveStatus(foo.ignition, foo.gpstime),
            status: connectedStatus(
              foo.updatetime,
              this.liveMapNoDataInMillisecond,
              x.item.reminigDays
            ),
            manufacture_name: x.item.manufacture_name,
            manufactureIcon: x.item.manufactureIcon,
            typeText: x.typeText,
            typeIcon: x.typeIcon,
            deviceId: x.item.device_id,
            simProvider: x.item.sim_provider,
            sim: x.item.sim,
            sim2: `${x.item.sim} #`,
            installation: moment(x.item.created_date).format("DD MMM yyyy"),
            expiry: moment(x.item.expiry_date).format("DD MMM yyyy"),
            renewed: x.item.renewed
              ? moment(x.item.renewed).format("DD MMM yyyy")
              : null,
            reminigDays: x.item.reminigDays,
            renewalIn: x.item.re_newal_interval,
            groupList: gList,
            groupNames: gList.map((g) => g.group_name).join(", "),
            isLastUpdateToday: moment(foo.updatetime).isSame(new Date(), "day")
              ? moment(foo.updatetime).format("hh:mm A")
              : "",
          };
        });

        const sortedRows = allRows.sort((a, b) => b.timeStamp - a.timeStamp);

        this.setState({
          allRows: sortedRows,
          filteredRows: sortedRows,
          totalCount: response.length,
          touched: true,
        });
      }
    });
  };

  findDriverName = (item) => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row className="mb-1">
                  <Colxx xxs="12">
                    <VehicleSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                      allowExpired={true}
                    />
                  </Colxx>
                </Row>
                <Row>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12"></Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardHeader>
                <Nav tabs className="card-header-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeFirstTab === "1",
                        "nav-link": true,
                      })}
                      onClick={() => {
                        this.toggleFirstTab("1");
                      }}
                      to="#"
                    >
                      <IntlMessages id="vehicle.details" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeFirstTab === "2",
                        "nav-link": true,
                      })}
                      onClick={() => {
                        this.toggleFirstTab("2");
                      }}
                      to="#"
                    >
                      Custom Template
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>

              <CardBody>
                <div className="card-body-min-height">
                  <TabContent activeTab={this.state.activeFirstTab}>
                    <TabPane tabId="1">
                      <LiveStatusData
                        allRows={this.state.allRows}
                        selectedVehicles={this.state.selectedVehicles}
                        touched={this.state.touched}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      
                    <LiveStatusCustom
                        allRows={this.state.allRows}
                        selectedVehicles={this.state.selectedVehicles}
                        touched={this.state.touched}
                      />

                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  vehicles,
  authUser,
  drivers,
  subscriber,
  settings,
  groups,
}) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { details } = subscriber;
  const { locale } = settings;
  return {
    vehiclesAll: all,
    user,
    driversAll: items,
    subscriberDetails: details,
    locale,
    groups: groups.items,
  };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Trips);
