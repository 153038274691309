const data = [
  {
    title: "Gender",
    data: [
      { title: "Male", count: 105, quota: 125 },
      { title: "Female", count: 90, quota: 125 }
    ]
  },
  {
    title: "Education",
    data: [
      { title: "College", count: 300, quota: 125 },
      { title: "High School", count: 95, quota: 125 }
    ]
  },
  {
    title: "Age",
    data: [
      { title: "18-24", count: 50, quota: 75 },
      { title: "24-30", count: 40, quota: 75 },
      { title: "30-40", count: 60, quota: 75 },
      
    ]
  }

];

export default data;
