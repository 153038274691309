import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Row, Card, CardTitle, Label, Input, Button, Col } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { VerifyOtp, VerifyMail } from "../../api-sc/forgot-password";
import { NotificationManager } from "../../components/common/react-notifications";

export default class SendPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.location.state.email,
      otp: "",
      time: {},
      seconds: 60,
      resendDisabled: true,
    };
    this.timer = 0;
  }
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };
  componentDidMount = () => {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar, resendDisabled: true });
    this.startTimer(60);
  };

  startTimer = (seconds) => {
    if (this.timer === 0 && seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = async () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);

      this.setState({ resendDisabled: false });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onResendButton = async () => {
    this.timer = 0;
    const input = { mailId: this.state.email };
    const verifyResult = await VerifyMail(input);
    this.onSubmitFailed(verifyResult.status, verifyResult.message);
    this.setState({ seconds: 60, resendDisabled: true });
    this.startTimer(60);
  };
  onSubmitOtp = async (values) => {
    const input = {
      otp: values.otp,
    };
    const verifyResult = await VerifyOtp(input);
    this.onSubmitFailed(verifyResult.status, verifyResult.message);
    if (verifyResult.status === "success") {
      setTimeout(() => {
        this.props.history.push({
          pathname: `/user/reset-password`,
          state: {
            email: this.state.email,
            otp: values.otp,
          },
        });
      }, 3000);
    }
  };
  onSubmitFailed = (status, message) => {
    // alert(status+", "+ message);
    if (status) {
      NotificationManager.warning(
        message,
        status.toUpperCase(),
        3000,
        null,
        null,
        ""
      );
    }
  };
  render() {
    const { resendDisabled } = this.state;
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side ">
              <p className="text-white h2">
              ctraac.com - Cloud Telematics Remote Access Analytics Console
              </p>
              <p className="white mb-0">
                The tracking system of cloud telematics. <br />
                {/* If you are not a member, please{" "}
                <NavLink to={`/register`} className="white">
                  register
                </NavLink>
                . */}
              </p>
            </div>
            <div className="form-side">
              <CardTitle className="mb-4">
                <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  <IntlMessages id="user.verify-otp" />
                </span>
              </CardTitle>
              <Formik
                initialValues={{
                  otp: "",
                }}
                enableReinitialize={true}
                // validationSchema={VehicleEditValidationSchema}
                onSubmit={async (values) => {
                  try {
                    await this.onSubmitOtp(values);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <Label className="form-group has-float-label mb-4">
                      <p style={{ fontWeight: "bold", fontSize: "0.850rem" }}>
                        <IntlMessages id="user.email-token" />
                        <br />
                        <IntlMessages id="user.email-10min-token" />
                      </p>
                    </Label>
                    <Label className="form-group has-float-label mb-4">
                      <span
                        style={{ fontWeight: "bold", fontSize: "0.813rem" }}
                      >
                        <IntlMessages id="user.enter-otp" />
                      </span>
                      <Input
                        type="text"
                        name="otp"
                        value={values.otp}
                        onChange={handleChange}
                        required
                      />
                      {/* <IntlMessages id="user.email" /> */}
                    </Label>

                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        // href="/user/reset-password"
                        color="primary"
                        className="btn-shadow"
                        size="lg"
                        onClick={this.verifyOtp}
                        type="submit"
                      >
                        <IntlMessages id="common.submit" />
                      </Button>
                    </div>

                    {/* <div className="d-inline-flex">
                      <div>Resend</div>
                      <div>Resend</div> */}
                    {/* <div className="d-inline-flex  flex-1"> */}
                    {/* <Label style={{float:"left"}}>Resend OTP in </Label> */}
                    {/* </div> */}
                    {/* <div className="d-inline-flex  flex-1"> */}
                    {/* <Label onClick={() => VerifyMail(this.state.email)} style={{float:"right"}}>
                          Resend
                        </Label> */}
                    {/* </div> */}
                    {/* </div> */}
                  </Form>
                )}
              </Formik>
              <Row style={{ marginTop: "1em" }}>
                <Col sm={4} md={6}>
                  <div>
                    <IntlMessages id="user.resend-otp-in" />
                    {this.state.time.m} : {this.state.time.s}
                  </div>
                </Col>
                <Col sm={4}>
                  <div>
                    <Button
                      className="btn-shadow"
                      size="xs"
                      disabled={resendDisabled}
                      onClick={this.onResendButton}
                    >
                      Resend
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
