import React from "react";
import { Route } from "react-router-dom";

// import { SendMail } from "../businessjs/CommunicationFn";
/*
import {
  AuthHelperGerSubscriberExecutiveTypeText,
  CommonStatusCodeText
} from "../businessjs/AppHelperFn";
import { EmailVerificationTokenAdd } from "../businessjs/EmailVerifyFn";
*/

import GroupSelect from "../../components/GroupSelect";

const AuthHelperGerSubscriberExecutiveTypeText = param1 => {
  switch (param1) {
    case 1:
      return "Owner";
    case 2:
      return "Admin";
    default:
      return "User";
  }
};

const CommonStatusCodeText = param1 => {
  switch (param1) {
    case 0:
      return "Deleted";
    case 1:
      return "Active";
    case 2:
      return "Suspended";
    default:
      return "Unknown";
  }
};

class ExecutiveItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      changePasswordBtn: { cssClass: "btn btn-default btn-sm", btnText: "Change Password" },

      deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" },
      editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" },
      resendBtn: { cssClass: "btn btn-default btn-sm", btnText: "Resend" },
      editMode: false,
      subscriber_id: props.itemObject.subscriber_id,
      edituser_role: props.itemObject.role,
      first_name: props.itemObject.first_name,
      last_name: props.itemObject.last_name,
      nick_name: props.itemObject.nick_name,
      statuscode: props.itemObject.statuscode,
      errorText: "",
      is_email_verified: props.itemObject.is_email_verified,
      selectedGroup: null
    };

    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onResend = this.onResend.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {}

  onDelete(e) {
    e.preventDefault();

    switch (this.state.deleteBtn.btnText) {
      case "Delete":
        this.setState({
          deleteBtn: { cssClass: "btn btn-danger btn-sm", btnText: "Confirm ?" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });
        break;
      case "Update":
        if (this.state.first_name === "") {
          this.setState({ errorText: "The name is mandatory " });
          return;
        }

        let temObj = this.props.itemObject;
        temObj.subscriber_id = this.state.subscriber_id;
        temObj.role = this.state.edituser_role;
        temObj.first_name = this.state.first_name;
        temObj.last_name = this.state.last_name;
        temObj.nick_name = this.state.nick_name;
        temObj.statuscode = this.state.statuscode;
        temObj.user_token = localStorage.apiAuthAccessKey;
        temObj.user_token_id = localStorage.apiAuthAccessId;

        this.props.onUpdate(temObj);

        this.setState({ editMode: false });
        this.setState({
          deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
        });

        break;
      case "Confirm ?":
        this.props.onDelete(this.props.itemObject);

        break;
      default:
        this.setState({
          deleteBtn: { cssClass: "btn btn-danger btn-sm", btnText: "Confirm ?" }
        });

        this.setState({
          editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
        });
        break;
    }
  }

  onEdit(e) {
    if (this.state.editBtn.btnText === "Cancel") {
      this.setState({ editMode: false });
      this.setState({
        deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
      });
    } else if (this.state.editBtn.btnText === "Edit") {
      this.setState({ editMode: true });
      this.setState({
        deleteBtn: { cssClass: "btn btn-primary btn-sm", btnText: "Update" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
      });

      this.setState({ Executive_name: this.props.itemObject.name });
      this.setState({ short_code: this.props.itemObject.short_code });
    }
  }

  onChangePassword = (e) => {
    if (this.state.editBtn.btnText === "Cancel") {
      this.setState({ editMode: false });
      this.setState({
        deleteBtn: { cssClass: "btn btn-default btn-sm", btnText: "Delete" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-default btn-sm", btnText: "Edit" }
      });
    } else if (this.state.editBtn.btnText === "Edit") {
      this.setState({ editMode: true });
      this.setState({
        deleteBtn: { cssClass: "btn btn-primary btn-sm", btnText: "Update" }
      });

      this.setState({
        editBtn: { cssClass: "btn btn-warning btn-sm", btnText: "Cancel" }
      });

      this.setState({ Executive_name: this.props.itemObject.name });
      this.setState({ short_code: this.props.itemObject.short_code });
    }
  }

  onResend() {
    /*
    let params = {
      user_token: localStorage.apiAuthAccessKey,
      user_token_id: localStorage.apiAuthAccessId,
      toAddress: this.props.itemObject.email,
      mail_type: 1,
      executive_id: this.props.itemObject.autoid
    };
    
    EmailVerificationTokenAdd({ params }).then(res => {
      if (res) {
        AuthHelperAccessCheck(res);
        if (res.status === "success") {
          //this.send(params);
          this.onToastItemAdd(res.status, res.message);
        } else this.onToastItemAdd(res.status, res.message);
      }
    });
    */
  }

  send = params => {
    /*
    SendMail(params).then(res => {
      if (res) {
        if (res.status === "success") {
          this.onToastItemAdd(res.status, res.message);
        } else {
          AuthHelperAccessCheck(res);
        }
      }
    }); */
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeGroup = selectedGroup => {

    if(this.props.limitedAccess){
      return;
    }
    this.setState({ selectedGroup });

    const userId = this.props.itemObject ? this.props.itemObject.autoid : -1;
    const oldDefaultGroup = this.props.itemObject ? this.props.itemObject.default_group : -1;
    const groupId = selectedGroup ? selectedGroup.autoid : -1;

    if (this.props.onUpdateGroup && oldDefaultGroup !== groupId) {
      this.props.onUpdateGroup(userId, groupId);
    }
  };

  formatResetPasswordButton(itemObject) {
    //if (this.state.is_email_verified === 1) {
    const ButtonReset = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            className="btn btn-default btn-sm"
            onClick={() => {
              history.push({
                pathname: "/change-password-admin",
                state: {
                  itemObject: { executive_id: this.props.itemObject.autoid }
                }
              });
            }}
          >
            Reset Password
          </button>
        )}
      />
    );
    return <ButtonReset />;
    //}
  }

  formateBody(mode) {
    
    if (mode) {
      const defaultGroup = this.props.itemObject.default_group || -1 ;

      return (
        <React.Fragment>
          <div className="form-body">
            <div className="col-12">
              <div className="row ">
                <div className="col-6">
                  <div className="form-group">
                    <label>Status</label>
                    <div className="position-relative has-icon-left">
                      <select
                        name="statuscode"
                        className="form-control"
                        onChange={this.onChange}
                        value={this.state.statuscode}
                      >
                        <option value="1"> Active </option>
                        <option value="2">Suspend </option>
                      </select>

                      <div className="form-control-position">
                        <i className="ft-user" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="first_name"
                        required
                        onChange={this.onChange}
                        value={this.state.first_name}
                      />
                      <div className="form-control-position">
                        <i className="ft-user" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        onChange={this.onChange}
                        required
                        value={this.state.last_name}
                      />
                      <div className="form-control-position">
                        <i className="ft-user" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Nick Name </label>
                    <div className="position-relative has-icon-left">
                      <input
                        type="text"
                        className="form-control"
                        name="nick_name"
                        onChange={this.onChange}
                        value={this.state.nick_name}
                      />
                      <div className="form-control-position">
                        <i className="ft-user" />
                      </div>
                    </div>
                  </div>
                </div>

                {!this.props.limitedAccess && (
                  <React.Fragment>
                   <div className="col-6">
                   <div className="form-group">
                     <label>User Role</label>
                     <div className="position-relative has-icon-left">
                       <select
                         name="edituser_role"
                         className="form-control"
                         onChange={this.onChange}
                         value={this.state.edituser_role}
                       >
                         <option value="1"> Owner </option>
                         <option value="2"> Admin </option>
                         <option value="3">User</option>
                       </select>
 
                       <div className="form-control-position">
                         <i className="ft-user" />
                       </div>
                     </div>
                   </div>
                 </div>

                 <div className="col-6">
                  <div className="form-group">
                    <label>Group </label>
                    <div className="position-relative has-icon-left">
                      <GroupSelect
                        onChange={this.handleChangeGroup}
                        group={this.state.selectedGroup}
                        initialValue={defaultGroup}
                      />
                    </div>
                  </div>
                </div>
                
                 </React.Fragment>
                )}
               

               
              </div>
            </div>
          </div>

          <label className="errorlabel"> {this.state.errorText}</label>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="rowstatus float-right">
            <span className="badge">
              Role:
              {AuthHelperGerSubscriberExecutiveTypeText(
                this.props.itemObject.role
              )}
            </span>

            <span className="badge">
              Status: {CommonStatusCodeText(this.props.itemObject.statuscode)}
            </span>
          </div>

          <span>
            {this.props.itemObject.first_name} {this.props.itemObject.last_name}
          </span>
          <span className="phone d-block">
            {this.props.itemObject.nick_name}
          </span>

          <span className="phone d-block"> {this.props.itemObject.email}</span>

          <span className="phone d-block">
            {this.props.itemObject.contact_number}
          </span>
        </React.Fragment>
      );
    }
  }

  checkIsEmailVerified = () => {
    if (this.state.is_email_verified === 0) {
      return (
        <button
          type="button"
          onClick={this.onResend}
          className={this.state.resendBtn.cssClass}
        >
          {this.state.resendBtn.btnText}
        </button>
      );
    }
  };

  checkIsEmailVerifiedForBadge = () => {
    if (this.state.is_email_verified === 0) {
      return <span className="badge badge-warning">E-mail not Verified</span>;
    }
  };

  onToastItemAdd = (status, message) => {
    this.props.onToastItemAdd(status, message);
  };

  render() {
    // console.log("number",Math.random())
    return (
      <li>
        {this.formateBody(this.state.editMode)}

        <div className="btn-group float-right">
        <button
            type="button"
            onClick={()=>{
              this.props.onChangePassword(this.props.itemObject)
            }}
            className={this.state.changePasswordBtn.cssClass}
          >
            {this.state.changePasswordBtn.btnText}
          </button>

          <button
            type="button"
            onClick={this.onEdit}
            className={this.state.editBtn.cssClass}
          >
            {this.state.editBtn.btnText}
          </button>
          <button
            type="button"
            onClick={this.onDelete}
            className={this.state.deleteBtn.cssClass}
          >
            {this.state.deleteBtn.btnText}
          </button>

         
        </div>
      </li>
    );
  }
}

export default ExecutiveItem;
