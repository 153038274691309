import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import GoogleMap from "google-map-react";

class MapModel extends React.Component {
  onMapLoaded = (map, maps) => {
    this.setState({
      mapsLoaded: true,
      map: map,
      maps: maps,
    });

    const place = this.props.place || "";
    const geo = this.props.geo || "";
    const splits = geo.split(",");
    const lat = parseFloat(splits[0]);
    const lng = parseFloat(splits[1]);

    new maps.Marker({
      position: { lat, lng },
      map,
      title: place,
    });

    map.setZoom(14);
    map.panTo({ lat, lng });
  };

  render() {
    const geo = this.props.geo;
    const place = this.props.place;

    return (
      <Modal isOpen={this.props.isOpen} size="lg" toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>
          {place || geo}
          <a
            title="Show on google map"
            rel="noopener noreferrer"
            target="_blank"
            href={`https://www.google.com/maps/place/${geo}`}
          >
            <i className="simple-icon-social-google"></i>
          </a>
        </ModalHeader>
        <ModalBody>
          <div style={{ height: "518px" }} className="w-100">
            <GoogleMap
              yesIWantToUseGoogleMapApiInternals={true}
              bootstrapURLKeys={{
                key: "AIzaSyAKjw_wuep7sJmjM6tKylxLr6Yo_bHUxnw",
              }}
              defaultCenter={[25.273217, 51.414257]}
              defaultZoom={8}
              options={{ mapTypeControl: true }}
              onGoogleApiLoaded={({ map, maps }) => {
                 this.onMapLoaded(map, maps);
              }}
            ></GoogleMap>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default MapModel;
