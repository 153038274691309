// import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
import esLang from './entries/es-ES';
import enRtlLang from './entries/en-US-rtl';
import arLang from './entries/ar-QA-rtl';

const AppLocale = {
    en: enLang,
    es: esLang,
    enrtl:enRtlLang,
    ar: arLang,
    "sf":{
        locale:"sf",
        messages:{
            "wen":"worked"
        }
    }
};
// addLocaleData(AppLocale.en.data);
// addLocaleData(AppLocale.es.data);
// addLocaleData(AppLocale.enrtl.data);
// addLocaleData(AppLocale.ar.data);

export default AppLocale;
