import React from "react";
import ReactApexChart from "react-apexcharts";

import moment from "moment";

const options = {
  chart: {
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: [1],
    curve: "straight",
  },
  xaxis: {
    type: "datetime",
  },
  yaxis: {
    min: 0,
  },
  tooltip: {
    shared: true,
    intersect: false,
    x: {
        format: 'dd hh:mmtt'
      },
    y: {
      formatter: function (y) {
        if (typeof y !== "undefined") {
          return y + " ltr";
        }
        return y;
      },
    },
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
};

const series = [];

class ColumnChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      options,
      series,
      selectedVehicles: [],
      startTime: moment().startOf("month"),
      endTime: moment(new Date()),

      pointVehicleUsed: null,
    };
  }

  loadMasters = () => {
    const dataPoints = this.props.wayPoints || [];
    const wayPointsData = dataPoints.map((x) => {
      return {
        x: x.updatetime, // getDateLabel(x.updatetime),
        y: x.fuel,
      };
    });

    const series = [
      { name: "Fuel", data: wayPointsData || [{ x: "a", y: 0 }] },
    ];

    this.setState({ series });
  };

  componentDidMount() {
    setTimeout(() => {
      this.loadMasters();
    }, 10);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wayPoints !== this.props.wayPoints) {
      setTimeout(() => {
        this.loadMasters();
      }, 10);
    }
  }

  render() {
    return (
      <div>
        <h5 className="d-inline-block">Fuel Chart</h5>

        <div className="w-100">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="area"
            height="316"
            id="fuel-chart-by-day-apex"
            name="fuel-chart-by-day-apex"
          />

          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}

export default ColumnChart;
