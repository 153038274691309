/* eslint-disable no-loop-func */
import React from "react";
import {
  CardTitle,
  Button,
  Table,
  Modal,
  ModalBody,
  Row,
  Progress,
  Badge,
} from "reactstrap";
import { createIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { Colxx } from "../../components/common/CustomBootstrap";
import AppLocale from "../../lang";
import { AppHelperDateAndTimeToString } from "../../helpers/AppHelper";
import { CSVLink } from "react-csv";
import IntlMessages from "../../helpers/IntlMessages";
import LocationName from "../../components/LocationName";
import { authApiAccessCheck } from "../../redux/actions";

const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Plate", key: "plateNumber" },
  { label: "Time", key: "time" },
  { label: "Place", key: "place" },
  { label: "Geo", key: "geo" },
  { label: "Entry", key: "typeText" },
  { label: "Driver", key: "driverName" },
];

const LIMIT_PER_CALL = 80;

class GeofenceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayVehicles: [],
      startTime: moment(),
      endTime: moment(),
      loadingModalIsOpen: false,
      fetchDataProgress: 0,
      allRows: [],
      fileName: "Ctraac - geofence-alerts.csv",
      countOfProgressing: 0,
      countOfPending: 0,
      countOfDone: 0,
      countOfFailed: 0,
    };
  }
  componantDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.displayVehicles !== this.props.displayVehicles) {
      //   const duration = moment.duration(
      //     this.props.endTime.diff(this.props.startTime)
      //   );

      this.setState({
        displayVehicles: this.props.displayVehicles,
        startTime: this.props.startTime,
        endTime: this.props.endTime,
        fetchDataProgress: 0,
        allRows: [],
        countOfProgressing: 0,
        countOfPending: 0,
        countOfDone: 0,
        countOfFailed: 0,
      });

      setTimeout(() => {
        this.fetchData();
      }, 100);
    }
  }

  findDriverName = (item) => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };

  findPlateNumber = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number}`;
        }
      }
    }
    return "";
  };

  progressCalc = () => {
    const displayVehicles = this.state.displayVehicles;

    let countOfProgressing = 0;
    let countOfPending = 0;
    let countOfDone = 0;
    let countOfFailed = 0;
    for (const v of displayVehicles) {
      countOfProgressing += v.callStatus === "progressing" ? 1 : 0;
      countOfPending += v.callStatus === "pending" ? 1 : 0;
      countOfDone += v.callStatus === "done" ? 1 : 0;
      countOfFailed += v.callStatus === "failed" ? 1 : 0;
    }

    this.setState({
      countOfPending,
      countOfDone,
      countOfFailed,
    });

    const countOfTotal =
      countOfProgressing + countOfPending + countOfDone + countOfFailed;
    const progressingPercentage =
      ((countOfDone + countOfFailed) / countOfTotal) * 100;

    if (progressingPercentage === 100) {
      this.setState({ loadingModalIsOpen: false });

      const allRecords = this.state.displayVehicles.map((v) => {
        return v.callResponse ? v.callResponse.allRecords : [];
      });
      const allRows = allRecords.flat();

      // console.log("final allRows", { allRows, allRecords });
      this.setState({ allRows: allRows });
    }

    this.setState({ fetchDataProgress: progressingPercentage });
  };

  fetchData = () => {
    const displayVehicles = this.state.displayVehicles;
    // const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    // const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    let callCounter = 0;
    let pendingCounter = 0;
    let progressingCounter = 0;

    this.setState({ loadingModalIsOpen: true });

    for (let index = 0; index < displayVehicles.length; index++) {
      const v = displayVehicles[index];

      if (v.callStatus === "progressing") {
        progressingCounter += 1;
        if (!(progressingCounter % LIMIT_PER_CALL)) {
          break;
        }
      } else if (v.callStatus === "pending") {
        pendingCounter += 1;
        if (!(pendingCounter % LIMIT_PER_CALL)) {
          break;
        }

        v.callStatus = "progressing";
        displayVehicles[index] = v;

        v.call
          .then((response) => {
            // console.log("the responce ", response);
            let allRecords = [];

            if (response && Array.isArray(response)) {
              allRecords = response.map((x) => {
                return {
                  ...x,
                  typeText: x.type ? "In" : "Out",
                  time: AppHelperDateAndTimeToString(x.event_time),
                  driverName: this.findDriverName(x),
                  vehicleName: this.findVehicleName(x),
                  plateNumber: this.findPlateNumber(x),
                };
              });
            }

            v.callResponse = {
              allRecords: allRecords,
              loading: false,
            };

            v.callStatus = "done";

            const vehicleList = this.state.displayVehicles;
            vehicleList[index] = v;
            this.setState({ displayVehicles: vehicleList });

            setTimeout(() => {
              this.progressCalc();
            }, 100);
          })
          .catch((error) => {
            v.callStatus = "failed";
            displayVehicles[index] = v;

            const vehicleList = this.state.displayVehicles;
            vehicleList[index] = v;
            this.setState({ displayVehicles: vehicleList });

            console.error(error);
            setTimeout(() => {
              this.progressCalc();
            }, 100);
          });
      } else {
        callCounter += 1;
      }
    }

    this.setState({ displayVehicles, countOfProgressing: progressingCounter });

    setTimeout(() => {
      this.progressCalc();
    }, 100);

    if (callCounter !== displayVehicles.length) {
      setTimeout(() => {
        this.fetchData();
      }, 1000 * 2);
    }
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;

    const displayVehicles = this.state.displayVehicles || [];

    return (
      <>
        <Modal isOpen={this.state.loadingModalIsOpen} size="lg">
          <ModalBody>
            <Row>
              <Colxx xxs="12">
                <div className="d-block">
                  <h3 className="d-inline pr-1">
                    Loading {Math.round(this.state.fetchDataProgress)}%
                  </h3>
                  <p className="d-inline">
                    Pending: {this.state.countOfPending} | Done:{" "}
                    {this.state.countOfDone}
                  </p>
                </div>
                <Progress
                  value={this.state.fetchDataProgress}
                  className="mb-2"
                />

                <div className="d-block" style={{maxHeight: '300px', overflow: 'auto' }} >
                  {displayVehicles.map((v, i) => (
                    <Badge
                      color={v.callStatus === "done" ? "light" : "warning"}
                      key={v.item.key}
                      className={"mb-1 mr-1"}
                    >
                      <span>
                        {v.item.plate_number} {v.item.fleet_number}{" "}
                        {v.historyCallStatus === "done"
                          ? null
                          : v.historyCallStatus}
                      </span>
                    </Badge>
                  ))}

                    
                </div>
              </Colxx>
            </Row>
          </ModalBody>
        </Modal>

        {this.state.displayVehicles.length ? (
          <CardTitle>
            {intl.formatMessage({
              id: "geofence-alert",
            })}
            :{" from "}
            {AppHelperDateAndTimeToString(this.state.startTime.toDate())}
            {" to "}
            {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
          </CardTitle>
        ) : (
          <CardTitle>
            <IntlMessages id="day-summary.search" />
          </CardTitle>
        )}

        {!this.state.allRows ? (
          <p>
            <IntlMessages id="day-summary.nothing" />
          </p>
        ) : (
          ""
        )}
        <div className="card-body-min-height">
          <div className="float-right">
            <CSVLink
              headers={headers}
              filename={this.state.fileName}
              data={this.state.allRows}
            >
              <Button
                title={this.state.fileName}
                size="xs"
                outline
                color="dark"
              >
                <IntlMessages id="common.download" />
              </Button>
            </CSVLink>
          </div>
          {this.state.allRows.length +
            intl.formatMessage({
              id: "geofence.records",
            })}

          <Table responsive hover bordered striped>
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <IntlMessages id="day-summary.vehicle" />
                </th>
                <th>
                  <IntlMessages id="day-summary.place" />
                </th>

                <th>
                  <IntlMessages id="day-summary.entry" />
                </th>
                <th>
                  <IntlMessages id="day-summary.driver" />
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.allRows.map((item, i) => (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>{item.vehicleName}</td>
                  <td>
                    {item.time}
                    <br />
                    <LocationName
                      place={item.place}
                      geo={item.geo}
                      objectItem={item}
                      showGoogle
                    />
                  </td>

                  <td>{item.typeText}</td>
                  <td>{item.driverName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(GeofenceTable);
