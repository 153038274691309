import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";

import { Colxx } from "../../components/common/CustomBootstrap";
import { LiveSelectByToken } from "../../api-tasks/live";

import MegaMap from "../../components/MegaMap";
import { authApiAccessCheck } from "../../redux/actions";

const LabelWithIcon = vehicle => {
  return (
    <div key={vehicle.key}>
      <img
        title={vehicle.manufacture_name}
        className="app-select-icon"
        src={vehicle.manufactureIcon}
        alt="icon"
      />
      <img
        title={vehicle.typeText}
        className="app-select-icon"
        src={vehicle.typeIcon}
        alt="icon"
      />
      <strong title="Plate">{vehicle.plate_number}</strong>{" "}
      <strong title="Asset No">{vehicle.fleet_number}</strong>{" "}
      <span title="Model">{vehicle.model}</span>{" "}
      <span title="year">{vehicle.year}</span>
    </div>
  );
};

class LiveVehicleTabPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      liveUpdates: [],
      selectedVehicles: []
    };

    this.intervalId = null;
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidMount() {
    if(this.props.selectedVehicle.autoid){
      this.handleChangeVehicle();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedVehicle !== this.props.selectedVehicle) {
      this.handleChangeVehicle();
    }
  }

  handleChangeVehicle = () => {

    const locationState = this.props.location.state || {};

    const item = locationState.itemObject || {autoid : -1 } ;

    const option= {
      
      label: LabelWithIcon(item),
      value: item.autoid.toString(),
      key: item.autoid,
      model: item.model,
      typeText: item.typeText,
      typeIcon: item.typeIcon,
      item: item
    };

    this.setState({
      selectedVehicles : [this.props.selectedVehicle || option]
    });
    setTimeout(() => {
      this.getLastUpdate();
      this.intervalId = setInterval(this.getLastUpdate, 2000);
    }, 10);
  };

  getLastUpdate = () => {
    const user = this.props.user;

    LiveSelectByToken(user.userToken).then(res => {
      if (res) {
        if (Array.isArray(res)) {
         
          this.setState({ liveUpdates: res });
        } else {
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };
 

  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Card className="mb-4">
              <CardBody>
             
                <MegaMap
                  selectedVehicles={this.state.selectedVehicles}
                  vehiclesAll={this.props.vehiclesAll}
                  liveUpdates={this.state.liveUpdates}
                  mapOnly={true}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user } = authUser;
  return { vehiclesAll: all, user };
};

export default connect(
  mapStateToProps,
  { authApiAccessCheck }
)( withRouter( LiveVehicleTabPage));
