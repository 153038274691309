import React from "react";
import { connect } from "react-redux";
import {
  
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown
} from "reactstrap";
 

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];

    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

    return objectsByKeyValue;
  }, {});
 

class VehicleSelectWithBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: [],
      nestingDropdownOpen: false,
      options: [],
      batchOptions: [],
      isOpenSizingXs: false,
      selectedText: "Select by"
    };
  }

  componentDidMount() {
    this.loadMasters();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.loadMasters();
    }
  }

  loadMasters = () => {
    const options = this.generateOptions(this.props.vehiclesAll);
    this.setState({ options });
    this.generateBatch(options);
  };

  generateBatch = options => {
    const all = options;

    if (!all) {
      this.setState({ batchOptions: [] });
      return;
    }

    const gModel = groupBy("model");
    const resultModel = gModel(all);
    const itemModels = Object.keys(resultModel).map(function(key, index) {
      return {
        label: key,
        data: resultModel[key] ,
        icons: "",
        value: `m${index}`
      };
    });

    const gType = groupBy("typeText");
    const resultType = gType(all);
    const itemType = Object.keys(resultType).map(function(key, index) {
      return {
        label: key,
        data: resultType[key],
        icon: resultType[key][0].typeIcon,
        value: `t${index}`
      };
    });

    const gProject = groupBy("project");
    const resultProject = gProject(all);


    const itemProject = Object.keys(resultProject).map(function(key, index) {
      const el = !key || key === "null" ? "No Cluster" : key;
      return {
        label: ` ${el}` ,
        data: resultProject[key],
        icon: '',
        value: `l${index}`
      };
    });
 

    const resultA = itemProject.concat( itemModels ) || [];
    const result =  resultA.concat(itemType);

    result.sort(function(a, b) {
      return a.label.localeCompare(b.label);
    });

    if (result[0] && !result[0].label  ) {
      result[0].label = "Others";
    }


    this.setState({
      batchOptions: [
        {
          label: "All",
          data: all,
          icons: "",
          value: "s0"
        },
        ...result
      ]
    });
  };

  generateOptions = vehicles => {
    const options = vehicles.map(item => ({
      value: item.autoid.toString(),
      key: item.autoid,
      autoid: item.autoid,
      model: item.model,
      typeText: item.typeText,
      typeIcon: item.typeIcon,
      item: item,
      project: item.project,
    }));
    return options;
  };

  nestingToggle = () => {
    this.setState({
      nestingDropdownOpen: !this.state.nestingDropdownOpen
    });
  };

  handleChange = (selectedVehicle, label) => {
    this.setState({ selectedVehicle: selectedVehicle,
      selectedText: `${label}(${selectedVehicle.length})` });
    if (this.props.onChange) {
      this.props.onChange(selectedVehicle);
    }
  };
 

  toggleSizingXs = () => {
    this.setState(prevState => ({
      isOpenSizingXs: !prevState.isOpenSizingXs
    }));
  };

  render() {
    return (
      <React.Fragment>
        
          <ButtonGroup  className="flex-wrap">
            <ButtonDropdown
              className="bd-container"
              isOpen={this.state.isOpenSizingXs}
              toggle={this.toggleSizingXs}
            >
              <DropdownToggle caret size="xs" outline color="secondary">
                { this.state.selectedText}
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: data => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: "auto",
                          maxHeight: 221
                        }
                      };
                    }
                  }
                }}
              >
                {this.state.batchOptions.map((el, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => this.handleChange(el.data, el.label)}
                  >
                    {el.icon ? (
                      <img
                        className="app-select-icon"
                        src={el.icon}
                        alt="icon"
                      />
                    ) : (
                      ""
                    )}
                    {`${el.label} (${el.data.length})`}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </ButtonGroup>
        
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles }) => {
  const { all } = vehicles;
  return { vehiclesAll: all };
};

export default connect(mapStateToProps)(VehicleSelectWithBatch);
