import React, { Component } from "react";
import { Row, Card, CardBody, CardTitle, Label, Button } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { GetCommentList } from "../../../api-sc/tickets";
import { AddCommentList } from "../../../api-sc/tickets";
import moment from "moment";
import "react-quill/dist/quill.bubble.css";
import IntlMessages from "../../../helpers/IntlMessages";
import AppLocale from "../../../lang";
import { createIntl } from "react-intl";

class Comment extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      allRows: [],
      isClicked: false,
      comment: "",
      status: "",
    };
  }

  // componentWillUnmount() {}

  componentDidMount() {
    const ticket = this.props.ticketData;
    const ticketId = ticket.id;
    this.getComments(ticketId);
  }

  getComments = async (ticketId) => {
    const user = this.props.user;
    const userToken = user.userToken;
    const comments = await GetCommentList(userToken, ticketId);
    if (comments && Array.isArray(comments)) {
      this.setState({
        allRows: comments,
        loading: false,
      });
    } else {
      this.setState({
        allRows: [],
        loading: false,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.ticketData !== this.props.ticketData) {
      const ticketId = this.props.ticketData.id;
      const ticketStatus = this.props.ticketData.status;
      this.getComments(ticketId);
      this.setState({ status: ticketStatus });
    }
  }
  onStatusChange = (event) => {
    this.setState({ status: event.target.value });
  };
  onAddButtonClick = () => {
    this.setState({ isClicked: !this.state.isClicked });
  };
  handleChangeComment = (comment) => {
    this.setState({ comment });
  };
  resetValues = () => {
    this.setState({ comment: "", subject: "" });
  };
  onAddComment = async () => {
    this.setState({ loading: true });
    const user = this.props.user;
    const userToken = user.userToken;
    const input = {
      userToken: userToken,
      ticketId: this.props.ticketData.id,
      updateText: this.state.comment,
      status: this.state.status,
    };
    const addResult = await AddCommentList(input);
    if (addResult.status === "success") {
      this.setState({ comment: "", subject: "" });
      this.getComments(this.props.ticketData.id);
    } else {
      this.setState({ loading: false });
    }
  };
  render() {
    const { allRows, isClicked, loading } = this.state;
    const ticketDetails = this.props.ticketData;
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="ticket.view" />
                </CardTitle>
                <Card style={{ margin: "8px" }}>
                  <CardBody>
                    <Row>
                      <Colxx xl="6" lg="6" md="12">
                        <Label>
                          {" "}
                          <IntlMessages id="ticket.ticket-id" /> :{" "}
                          <label
                            style={{ fontSize: "14px", fontWeight: "bolder" }}
                          >
                            #{ticketDetails.id}
                          </label>
                        </Label>
                      </Colxx>
                      <Colxx xl="6" lg="6" md="12">
                        <Label>
                          <span>
                            <IntlMessages id="ticket.subject" /> :{" "}
                            <label
                              style={{ fontSize: "14px", fontWeight: "bolder" }}
                            >
                              {ticketDetails.subject}
                            </label>
                          </span>
                        </Label>
                      </Colxx>
                      <Colxx xl="12" lg="12" md="12">
                        <Row>
                          <span style={{ margin: "8px" }}>
                            <h6>
                              <IntlMessages id="ticket.add-reply" />{" "}
                            </h6>
                          </span>
                          <Button
                            className="icon-button"
                            onClick={this.onAddButtonClick}
                          >
                            {isClicked ? (
                              <i
                                className="simple-icon-minus btn-group-icon"
                                // aria-hidden="true"
                              />
                            ) : (
                              <i
                                className="simple-icon-plus btn-group-icon"
                                // aria-hidden="true"
                              />
                            )}
                          </Button>
                        </Row>
                      </Colxx>
                      <Colxx
                        xl="4"
                        lg="6"
                        md="12"
                        style={{
                          display: isClicked ? "" : "none",
                          margin: "8px",
                        }}
                      >
                        <Label>
                          <IntlMessages id="ticket.status" />
                        </Label>
                        <select
                          className="form-control"
                          name="priority"
                          onChange={(event) => this.onStatusChange(event)}
                          value={this.state.status}
                          required
                        >
                          <option value="1">
                            {intl.formatMessage({
                              id: "ticket.open",
                            })}
                          </option>
                          <option value="2">
                            {intl.formatMessage({
                              id: "ticket.assigned",
                            })}
                          </option>
                          <option value="3">
                            {intl.formatMessage({
                              id: "ticket.answered",
                            })}
                          </option>
                          <option value="4">
                            {intl.formatMessage({
                              id: "ticket.pending",
                            })}
                          </option>
                          <option value="5">
                            {intl.formatMessage({
                              id: "ticket.closed",
                            })}
                          </option>
                        </select>
                      </Colxx>
                      <Colxx
                        xl="12"
                        lg="12"
                        md="12"
                        style={{ display: isClicked ? "" : "none" }}
                      >
                        <Label>
                          {" "}
                          <IntlMessages id="ticket.add-comment" />
                        </Label>
                        <Row className="mb-4">
                          <Colxx xxs="12">
                            <Card>
                              <CardBody>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name="comment"
                                  value={this.state.comment}
                                  onChange={(event) =>
                                    this.handleChangeComment(event.target.value)
                                  }
                                  style={{ minHeight: "100px" }}
                                  // required
                                />
                              </CardBody>
                            </Card>
                          </Colxx>
                        </Row>
                      </Colxx>
                      <Colxx
                        xl="12"
                        lg="12"
                        md="12"
                        style={{ display: isClicked ? "" : "none" }}
                      >
                        <button
                          //   type="submit"
                          className="btn btn-primary btn-sm"
                          onClick={this.onAddComment}
                          // style={{ width: "55px" }}
                          disabled={loading}
                        >
                          {loading ? (
                            <span className="spinner d-inline-block">
                              <span className="bounce1" color="primary" />
                              <span className="bounce2" color="primary" />
                              <span className="bounce3" color="primary" />
                              <span className="bounce3" color="primary" />
                            </span>
                          ) : (
                            <IntlMessages id="common.submit" />
                          )}
                        </button>
                        <button
                          type="reset"
                          className="btn btn-primary btn-sm"
                          style={{ marginLeft: "8px" }}
                          onClick={this.resetValues}
                        >
                          <IntlMessages id="common.cancel" />
                        </button>
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
                <Card style={{ margin: "8px" }}>
                  <CardBody>
                    <CardTitle>
                      <IntlMessages id="ticket.comment-list" />
                    </CardTitle>

                    {allRows.map((item, i) => (
                      <Card style={{ marginBottom: "8px" }} key={i + 1}>
                        <CardBody>
                          <Row>
                            <Colxx xl="6" lg="6" md="12">
                              <i
                                className="simple-icon-user btn-group-icon"
                                // aria-hidden="true"
                              />
                              <Label style={{ marginLeft: "8px" }}>
                                {item.UserName}
                              </Label>
                            </Colxx>
                            <Colxx xl="6" lg="6" md="12">
                              <i
                                className="simple-icon-clock btn-group-icon"
                                // aria-hidden="true"
                              />
                              <Label style={{ marginLeft: "8px" }}>
                                {moment(item.updatetime).format(
                                  "YYYY/MM/DD, h:mm:ss a"
                                )}
                              </Label>
                            </Colxx>
                            <Colxx xl="12" lg="6" md="12">
                              <Label
                                style={{
                                  fontWeight: "bold",
                                  fontStyle: "italic",
                                  fontSize: "14px",
                                }}
                              >
                                {item.update_text}
                              </Label>
                            </Colxx>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user } = authUser;
  const { locale } = settings;
  return { user, locale };
};

export default connect(mapStateToProps)(Comment);
