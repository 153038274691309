const data = [
  {
    title: 'Basic Information',
    total: 18,
    status: 12
  },
  {
    title: 'Portfolio',
    total: 8,
    status: 1
  },
  {
    title: 'Billing Details',
    total: 6,
    status: 2
  },
  {
    title: 'Interests',
    total: 10,
    status: 0
  },
  {
    title: 'Legal Documents',
    total: 2,
    status: 1
  }
]
export default data
