import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

import { subscriberSelectSuccess } from './actions';
import { authApiAccessCheck } from '../auth/actions';

import { SubscriberGetByID } from '../../api-tasks/subscriber';
import { SUBSCRIBER_SELECT_REQUEST } from '../actions';
import SubscriberConst from '../../data-const/subscriber-const';

function* selectById({ payload }) {
  const state = yield select();
  const { history } = payload;
  try {
    const authUser = state.authUser;
    const user = authUser.user;

    const subscriberDetails = yield call(SubscriberGetByID, user.userToken);

    if (subscriberDetails) {
      if (Array.isArray(subscriberDetails)) {
        const details = subscriberDetails[0];

        if (!details.attributes) {
          details.attributes = SubscriberConst;
        }

        yield put(subscriberSelectSuccess(details));
      } else {
        yield put(authApiAccessCheck(history, subscriberDetails));
      }
    }
  } catch (error) {
    console.log('error vehicle saga 1 :', error);
  }
}

export function* watchSelectAllRequest() {
  yield takeEvery(SUBSCRIBER_SELECT_REQUEST, selectById);
}

export default function* rootSaga() {
  yield all([fork(watchSelectAllRequest)]);
}
