import React, { Component } from "react";
import { Row, Card } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import CommonSettings from "./common-settings";

class SettingsTab extends Component {
  constructor(props) {
    super();

    this.toggleSecondTab = this.toggleSecondTab.bind(this);
    this.state = {
      activeSecondTab: "1"
    };
  }

  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab
      });
    }
  }
  render() {
    
    return (
      <Row>
        <Colxx xxs="12">
          <Card className="mb-4">
            
          <CommonSettings />
            
          </Card>
        </Colxx>
      </Row>
    );
  }
}
 

const mapStateToProps = ({ authUser }) => {
  
  const { user, details } = authUser;

  return {  user, userDetails: details };
};

export default connect(mapStateToProps, {})(SettingsTab);

