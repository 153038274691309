import React, { Component } from "react";
import { Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import { GetInvoice } from "../../api-sc/invoice";
import IntlMessages from "../../helpers/IntlMessages";


class InvoiceContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      allRows: [],
    };
  }

  // componentWillUnmount() {}

  componentDidMount() {
    this.getInvoices();
  }

  getInvoices = async () => {
    const user = this.props.user;
    const userToken = user.userToken;

    const invoices = await GetInvoice(userToken);
    if (invoices && Array.isArray(invoices)) {
      this.setState({
        allRows: invoices,
      });
    }
  };

  render() {
    const invoiceList = this.state.allRows || [];
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle><IntlMessages id="invoice.list" /></CardTitle>
                {/* <Button onClick={this.getInvoices}>Invoice</Button> */}

                <small>{invoiceList.length} <IntlMessages id="support.records" /> </small>
                <div className="card-body-min-height">
                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th><IntlMessages id="invoice.no" /></th>
                        <th><IntlMessages id="invoice.total" /></th>
                        <th><IntlMessages id="invoice.discount" /></th>
                        <th><IntlMessages id="invoice.grand-total" /></th>
                        <th><IntlMessages id="invoice.date" /></th>
                        <th><IntlMessages id="invoice-due-date" /></th>
                        <th><IntlMessages id="invoice.status" /></th>
                        <th><IntlMessages id="invoice.type" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceList.map((item, i) => (
                        <tr key={i + 1}>
                          <th scope="row">{i + 1}</th>
                          <td>{item.autoid}</td>
                          <td>{item.total_amount}</td>
                          <td>{item.discount}</td>
                          <td>{item.grand_total}</td>
                          <td>{item.invoice_date}</td>
                          <td>{item.invoice_due_date}</td>
                          <td>{item.status}</td>
                          <td>{item.invoice_type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return { user };
};

export default connect(mapStateToProps)(InvoiceContainer);
