// import appLocaleData from 'react-intl/locale-data/ar';
import enMessages from '../locales/ar_QA';

const ArLangRtl = {
    messages: {
        ...enMessages
    },
    locale: 'ar',
    // data: appLocaleData
};
export default ArLangRtl;