import React, { Component } from "react";
import {  Row, Card } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import GeofenceView from "./geofence-view";

class SettingsTab extends Component {
  constructor(props) {
    super(props);

    this.toggleSecondTab = this.toggleSecondTab.bind(this);
    this.state = {
      activeSecondTab: "1"
    };
  }

  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab
      });
    }
  }
  render() {
    return (
      <Row>
        <Colxx xxs="12">
          <Card className="mb-4">
            <GeofenceView history={this.props.history} />
          </Card>
        </Colxx>
      </Row>
    );
  }
}

export default SettingsTab;
