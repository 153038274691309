import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Card, Button, Row, CardTitle, CardBody, Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Colxx } from "../../components/common/CustomBootstrap";
import { authApiAccessCheck, groupSelectRequest } from "../../redux/actions";
import CustomSubmitButton from "../../components/form-controls/CustomSubmitButton";
import { NotificationManager } from "../../components/common/react-notifications";
import { GroupAdd, GroupUpdate, GroupRemove } from "../../api-tasks/groups";
import GroupRowItem from "../../components/GroupRowItem";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

class ListTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      selectedGroup: null,
      filteredDataRows: [],
      allDataRows: [],
      groupName: "",
      groupDescription: "",
      searchTex: "",
    };
  }

  componentDidMount() {
    this.generateGroup();

    if (!this.props.groupAll) {
      this.props.groupSelectRequest(this.props.history);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groupAll !== this.props.groupAll) {
      this.generateGroup();
    }
  }

  generateGroup() {
    const groupAll = this.props.groupAll || [];
    this.setState({ allDataRows: groupAll, filteredDataRows: groupAll });
  }

  handleRefresh = () => {
    this.props.groupSelectRequest(this.props.history);
    this.setState({ searchTex: "" });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ updating: true });
    const { groupName, groupDescription } = this.state;

    if (!groupName) {
      NotificationManager.warning(
        "Group name fields are required!",
        "Error",
        5000,
        null,
        null,
        ""
      );
      return;
    }

    const user = this.props.user;
    if (groupName) {
      GroupAdd(user.userToken, groupName, groupDescription || "").then(
        (response) => {
          if (response) {
            this.setState({ updating: false });
            if (response) {
              if (response.status === "success") {
                this.setState({
                  groupName: "",
                  groupDescription: "",
                });

                NotificationManager.success(
                  response.message,
                  "Success",
                  3000,
                  null,
                  null,
                  ""
                );
                this.props.groupSelectRequest(this.props.history);
              } else {
                console.error("group.add", response);
                NotificationManager.warning(
                  response.message,
                  response.status,
                  5000,
                  null,
                  null,
                  ""
                );
                this.props.authApiAccessCheck(this.props.history, response);
              }
            }
          }
        }
      );
    }
  };

  onSearch = (event) => {
    let searchQuery = event.target.value.toLowerCase();
    let arrData = searchQuery.split(" ");
    let tempDataRows = this.state.allDataRows;
    let filteredDataRows = this.state.allDataRows;
    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        const item = {
          ...el,
        };

        return item.group_name.toLowerCase().indexOf(element) !== -1;
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      filteredDataRows: filteredDataRows,
      searchTex: searchQuery || "",
    });
  };

  handleOnUpdate = (autoid, name, details) => {
    const user = this.props.user;

    GroupUpdate(user.userToken, autoid, name, details).then((response) => {
      if (response) {
        this.setState({ loading: false });

        if (response.status === "success") {
          this.handleRefresh();
        } else {
          NotificationManager.error(
            response.message,
            "ERROR",
            3000,
            null,
            null,
            ""
          );
        }
      }
    });
  };

  handleOnRemove = (item) => {
    const newFilledItems = this.state.filteredDataRows.filter(
      (c) => c.autoid !== item.autoid
    );

    const newAllItems = this.state.allDataRows.filter(
      (c) => c.autoid !== item.autoid
    );

    this.setState({
      loading: true,
      filteredDataRows: newFilledItems,
      allDataRows: newAllItems,
    });

    const user = this.props.user;

    GroupRemove(user.userToken, item.autoid).then((response) => {
      if (response) {
        this.setState({ loading: false });

        if (response.status === "success") {
          this.handleRefresh();
        } else {
          NotificationManager.error(
            response.message,
            "ERROR",
            3000,
            null,
            null,
            ""
          );
        }
      }
    });
  };

  render() {
    const loading = this.state.updating || this.props.groupLoading || false;

    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    if (userRole === 3) {
      return (
        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Row>
                  <Colxx xxs="12">
                    <div className="alert alert-info" role="alert">
                      <IntlMessages id="group.user-access" />
                    </div>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      );
    }

    return (
      <Fragment>
        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Row>
                  <Colxx xxs="12">
                    <form className="form" onSubmit={this.handleSubmit}>
                      <div className="form-body">
                        <Row>
                          <Colxx xl="3" lg="6" md="12">
                            <div className="form-group">
                              <label className="title">
                                <IntlMessages id="group.group-name" />
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="groupName"
                                required
                                onChange={this.handleChange}
                                value={this.state.groupName}
                              />
                            </div>
                          </Colxx>
                          <Colxx xl="5" lg="6" md="12">
                            <div className="form-group">
                              <label className="title">
                                <IntlMessages id="group.group-description" />
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="groupDescription"
                                onChange={this.handleChange}
                                value={this.state.groupDescription}
                              />
                            </div>
                          </Colxx>
                        </Row>
                        <Row>
                          <Colxx xl="4" lg="6" md="12">
                            <CustomSubmitButton
                              updating={this.state.updating}
                            />
                          </Colxx>
                        </Row>
                      </div>
                    </form>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle>
                  <IntlMessages id="group.groups" />
                </CardTitle>
                <Row className="mt-2">
                  <Colxx xxs="12">
                    <div className="search">
                      <div className="float-right">
                        <Button
                          color="primary"
                          size="sm"
                          className="mr-1"
                          onClick={this.handleRefresh}
                        >
                          <IntlMessages id="common.refresh" />
                          {loading && (
                            <span className="spinner d-inline-block">
                              <span className="bounce1" />
                              <span className="bounce2" />
                              <span className="bounce3" />
                            </span>
                          )}
                        </Button>

                        <input
                          className="input2 driver-td-action"
                          type="text"
                          onChange={this.onSearch}
                          value={this.state.searchTex}
                          placeholder={intl.formatMessage({
                            id: "common.search",
                          })}
                        />
                      </div>
                    </div>
                  </Colxx>
                </Row>
                <Row>
                  <Colxx xxs="12">
                    <div className="card-body-min-height">
                      <Table responsive hover bordered striped>
                        <thead>
                          <tr>
                            <th>#</th>

                            <th>
                              <IntlMessages id="group.name" />
                            </th>
                            <th>
                              <IntlMessages id="group.details" />
                            </th>

                            <th>
                              <IntlMessages id="group.action" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filteredDataRows.map((item, i) => (
                            <GroupRowItem
                              key={item.autoid}
                              number={i}
                              item={item}
                              onRemove={this.handleOnRemove}
                              onUpdate={this.handleOnUpdate}
                            />
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, groups, settings }) => {
  const { all } = vehicles;
  const { user, details } = authUser;
  const { items, loading } = groups;
  const { locale } = settings;
  return {
    vehiclesAll: all,
    user,
    userDetails: details,
    groupAll: items,
    groupLoading: loading,
    locale,
  };
};

export default connect(mapStateToProps, {
  authApiAccessCheck,
  groupSelectRequest,
})(withRouter(ListTab));
