import axios from "axios";
import config from "./config";

const proxyServer = config.supportApi +"routes-app/ticket";

export const GetTicket = (userToken) => {
  const criterion = { userToken };

  return axios
    .post(proxyServer + "/get-ticket-lists", { criterion })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const OpenTicket = (values) => {
  const criterion = values;
  return axios
    .post(proxyServer + "/ticket-add", { criterion })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const GetCommentList = (userToken, ticketId) => {
  const criterion = { userToken, ticketId };

  return axios
    .post(proxyServer + "/get-ticket-comments", { criterion })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const AddCommentList = (input) => {
  const criterion = input;

  return axios
    .post(proxyServer + "/comment-add", { criterion })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
