import React, { Component, Fragment } from "react";
import { Row, Card, CardBody } from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import CompanyEdit from "../../../containers/profile-master/company-edit";
import CompanyImage from "../../../containers/profile-master/company-image";
import CompanyAttributes from "../../../containers/profile-master/company-attributes";

class ProfileMaster extends Component {
  render() {
    // const { messages } = this.props.intl;
    // const messages = [];

    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;

    if (userRole === 3) {
      return (
        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Row>
                  <Colxx xxs="12">
                    <div className="alert alert-info" role="alert">
                      <IntlMessages id="group.user-access" />
                    </div>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      );
    }

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb
              heading="menu.profile-master"
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
         
          <CompanyEdit history={this.history} />
          <CompanyImage history={this.history} />
          <CompanyAttributes history={this.history}/>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user, details } = authUser;

  return { vehiclesAll: all, user, userDetails: details };
};

export default connect(mapStateToProps)(injectIntl(ProfileMaster));
