import React, { Fragment } from "react";
import ChartComponent, { Chart } from "react-chartjs-2";

import { smallLineChartOptions } from "./config";

const addCommas = nStr => {
  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  return x1 + x2;
};

export default class AlertSmallLine extends React.Component {
  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);
    this.state = {
      currentValue: "",
      currentLabel: ""
    };
  }

  UNSAFE_componentWillMount() {
    Chart.defaults.lineWithLine = Chart.defaults.line;
    Chart.controllers.lineWithLine = Chart.controllers.line.extend({
      draw: function(ease) {
        Chart.controllers.line.prototype.draw.call(this, ease);

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
          var activePoint = this.chart.tooltip._active[0];
          var ctx = this.chart.ctx;
          var x = activePoint.tooltipPosition().x;
          var topY = this.chart.scales["y-axis-0"].top;
          var bottomY = this.chart.scales["y-axis-0"].bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "rgba(0,0,0,0.1)";
          ctx.stroke();
          ctx.restore();
        }
      }
    });
  }

  changeState(yLabel, xLabel) {
    this.setState({ currentValue: yLabel, currentLabel: xLabel });
  }

  atGlance(masterValue, compareTo) {
    try {
      if (masterValue && compareTo) {
        let x = ((compareTo - masterValue) / compareTo) * 100;
        x = Math.floor(x);

        if (x > 0) {
          return (
            <h1 className="bad">
              {" "}
              {x}% <i className="simple-icon-arrow-up" />{" "}
            </h1>
          );
        } else {
          if (!isFinite(x)) {
            return (
              <h1 className="good">
                -% <i className="simple-icon-arrow-down" />{" "}
              </h1>
            );
          }

          return (
            <h1 className="good">
              {" "}
              {Math.abs(x)}% <i className="simple-icon-arrow-down" />{" "}
            </h1>
          );
        }
      } else {
        return "-";
      }
    } catch (error) {}
    return "-";
  }

  render() {
    const changeState = this.changeState;
    const { data } = this.props;

    // const masterValue = data.masterValue || 0 ;
    const masterLastWeekValue = data.masterLastWeekValue || 0;
    const masterLabel = data.masterLabel || "";

    return (
      <Fragment>
        <div>
          <div className="float-right">
            <small className="text-muted d-block">Vs last week</small>
            {this.atGlance(masterLastWeekValue, this.state.currentValue)}
          </div>
          <p className="lead color-theme-1 mb-1 value">
            {this.state.currentValue}{" "}
            <small className="text-muted">/day </small>
          </p>
          <p className="mb-0 label text-small">{this.state.currentLabel}</p>
        </div>
        <div className="chart">
          <ChartComponent
            ref={ref => (this.chart_instance = ref && ref.chart_instance)}
            type="lineWithLine"
            options={{
              ...smallLineChartOptions,
              tooltips: {
                intersect: false,
                enabled: false,
                custom: function(tooltipModel) {
                  if (tooltipModel && tooltipModel.dataPoints) {
                    var yLabel = tooltipModel.dataPoints[0].yLabel;
                    var xLabel = tooltipModel.dataPoints[0].xLabel;
                    if (xLabel === masterLabel) {
                      xLabel = "This week";
                    }
                    changeState(addCommas(yLabel), xLabel);
                  }
                }
              }
            }}
            plugins={[
              {
                afterInit: function(chart, options) {
                  var dSet = chart.data.datasets[0].data;
                  var yLabel = dSet[dSet.length - 1];
                  changeState(addCommas(yLabel), "This week");
                }
              }
            ]}
            data={data}
          />
        </div>
      </Fragment>
    );
  }
}
