import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
 
// import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import Utilization from "../../../containers/reports/driver-utilization";

class PageLayout extends Component {
  render() {
    // const { messages } = this.props.intl;
    // const messages = [];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb
              heading="menu.driver-utilization"
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Utilization />
      </Fragment>
    );
  }
}

export default  PageLayout ;
