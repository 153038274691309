
import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApiHeavy + "drill-downs/map-search";

export const GetBluePlateLocation = (userToken, building, street, zone) => {
    const criterion = { userToken,  building, street, zone};
    return axios
      .post(proxyServer + "/get-blue-plate-location", { criterion })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };


export const VehiclesGetDaySummary = (userToken, vehicleIds, startTime, endTime, geo, radius) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, geo, radius};
  return axios
    .post(proxyServer + "/get-day-summary", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
