import { GEOFENCE_VEHICLES_SELECT_REQUEST, GEOFENCE_VEHICLES_SELECT_SUCCESS } from "../actions";

  export const geofenceVehicleSelectRequest = ( history ) => ({
    type: GEOFENCE_VEHICLES_SELECT_REQUEST,
    payload: { history }
  });

  export const geofenceVehicleSelectSuccess = ( items ) => ({
    type: GEOFENCE_VEHICLES_SELECT_SUCCESS,
    payload: { items }
  });
