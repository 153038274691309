import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "dashboard";

export const DashboardSelectLast7WeekAlertCount = userToken => {
  const criterion = { userToken };

  return axios
    .post(proxyServer + "/get-last-7-week-alert-count", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const DashboardSelectTop10ByVehicles = (userToken, vehicleIds, startTime, endTime) => {
  const criterion = { userToken, vehicleIds, startTime, endTime };
  return axios
    .post(proxyServer + "/get-top10-alerts-by-vehicle", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


