import React, { Component } from "react";
import {
  Row,
  Card,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import TicketList from "./ticketsList";
import TicketForm from "./ticket-addform";
import Comment from "./comment";
import { GetTicket } from "../../../api-sc/tickets";
import IntlMessages from "../../../helpers/IntlMessages";

class SupportTab extends Component {
  constructor(props) {
    super(props);

    this.toggleSecondTab = this.toggleSecondTab.bind(this);
    this.state = {
      activeSecondTab: "1",
      isViewTicket: false,
      ticketData: {},
      ticketList: [],
    };
  }
  componentDidMount = () => {
    this.getTickets();
  };

  getTickets = async () => {
    const user = this.props.user;
    const userToken = user.userToken;

    const tickets = await GetTicket(userToken);
    if (tickets !== undefined && tickets.status !== "failed") {
      this.setState({
        ticketList: tickets,
      });
    } else {
      this.setState({
        ticketList: [],
      });
    }
  };
  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab === "3") {
        this.setState({ activeSecondTab: tab, isViewTicket: true });
      } else {
        this.setState({
          activeSecondTab: tab,
          isViewTicket: false,
        });
      }
    }
  }
  ticketDetails = (values) => {
    this.setState({ ticketData: values });
  };

  render() {
    const { isViewTicket, ticketData } = this.state;

    return (
      <Row>
        <Colxx xxs="12">
          <Card className="mb-4">
            <CardHeader className="pl-0 pr-0">
              <Nav tabs className=" card-header-tabs  ml-0 mr-0">
                <NavItem
                  className={
                    isViewTicket ? "w-40 text-center" : "w-50 text-center"
                  }
                >
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "1",
                      "nav-link": true,
                    })}
                    onClick={() => {
                      this.toggleSecondTab("1");
                    }}
                    to="#"
                  >
                    <h6>
                      {" "}
                      <IntlMessages id="ticket.list" />
                    </h6>
                  </NavLink>
                </NavItem>
                <NavItem
                  className={
                    isViewTicket ? "w-30 text-center" : "w-50 text-center"
                  }
                >
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "2",
                      "nav-link": true,
                    })}
                    onClick={() => {
                      this.toggleSecondTab("2");
                    }}
                    to="#"
                  >
                    <h6>
                      {" "}
                      <IntlMessages id="ticket.open-ticket" />{" "}
                    </h6>
                  </NavLink>
                </NavItem>
                <NavItem
                  className={
                    isViewTicket ? "w-30 text-center" : "w-0 text-center"
                  }
                >
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "3",
                      "nav-link": true,
                    })}
                    onClick={() => {
                      this.toggleSecondTab("3");
                    }}
                    to="#"
                  >
                    <h6 style={{ display: isViewTicket ? "flex" : "none" }}>
                      {" "}
                      <IntlMessages id="ticket.view" />
                    </h6>
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <Separator />
            <TabContent activeTab={this.state.activeSecondTab}>
              <TabPane tabId="1">
                <TicketList
                  history={this.props.history}
                  toggleSecondTab={this.toggleSecondTab}
                  ticketDetails={this.ticketDetails}
                  ticketList={this.state.ticketList}
                />
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Colxx xxs="12">
                    <TicketForm
                      history={this.props.history}
                      toggleSecondTab={this.toggleSecondTab}
                      getTickets={this.getTickets}
                      activeSecondTab={this.state.activeSecondTab}
                    />
                  </Colxx>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Colxx xxs="12">
                    <Comment
                      history={this.props.history}
                      ticketData={ticketData}
                      toggleSecondTab={this.toggleSecondTab}
                    />
                  </Colxx>
                </Row>
              </TabPane>
            </TabContent>
          </Card>
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return { user };
};

export default connect(mapStateToProps)(SupportTab);
