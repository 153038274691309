import React, { Component } from "react";
import { Row, CardBody, CardTitle } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import PassengerClusters from "../../data-const/passenger-types";

class ClusterTab extends Component {
  render() {
    return (
      <Row className="mb-4">
        <Colxx xxs="12">
          <CardBody>
            <CardTitle><IntlMessages id="passenger.passengers-clusters" /></CardTitle>
            
            <ol>
              {PassengerClusters.map((item, i) => (
                <li key={i + 1}> {item.title} </li>
              ))}
            </ol>
          </CardBody>
        </Colxx>
      </Row>
    );
  }
}

export default ClusterTab;
