import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import VehicleView from "../../../containers/pages/vehicle-view";

class PagesTripView extends Component {
 
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.vehicle-view" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        
        <VehicleView />

      </Fragment>
    );
  }
}

export default PagesTripView;
