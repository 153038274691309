import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { authApiAccessCheck } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";

import TripsViewTitle from "../../components/TripsViewTitle";
import CanvasJS from "../../components/CanvasJS";
import HistoryPlayback from "../../components/HistoryPlayback";
import VehicleCard from "../../components/VehicleCard";

import { TrackingBasicGetByTimeRangeOptimised } from "../../api-tasks/tracking-basic";
import { IgnitionSelectByPk } from "../../api-tasks/ignition";
import {
  AppHelperDateToDBString,
  AppHelperDurationAsHHmm,
} from "../../helpers/AppHelper";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

class TripViewContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      wayPoints: [],
      startTime: null,
      startPlace: "",
      startGeo: "",
      endTime: null,
      endPlace: "",
      endGeo: "",
      viewingObject: null,
      topSpeed: {},
      avgSpeed: 0,
    };
  }

  componentDidMount() {
    this.getHistoryState();
    if (!this.props.vehiclesAll.length) {
      this.setState({ loading: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.getHistoryState();
    }
  }

  getHistoryState = () => {
    try {
      if (!this.props.vehiclesAll.length) {
        return;
      }
      const queryValue = queryString.parse(this.props.location.search);

      if (queryValue && queryValue.param1 && queryValue.param2) {
        const user = this.props.user;
        IgnitionSelectByPk(
          user.userToken,
          queryValue.param2,
          queryValue.param1
        ).then((response) => {
          if (response && Array.isArray(response)) {
            const x = response[0] || null;

            const vehicle =
              this.props.vehiclesAll.filter(
                (z) => z.autoid === parseInt(queryValue.param2, 10)
              ) || [];

            this.setState({
              vehicle: vehicle[0],
              startTime: x.start_time,
              startPlace: x.start_place,
              startGeo: x.start_geo,
              endTime: x.end_time,
              endPlace: x.end_place,
              endGeo: x.end_geo,
              viewingObject: x,
            });

            setTimeout(() => {
              this.loadData();
            }, 5);
          }
        });
      } else {
        const x = this.props.location.state.itemObject || null;

        if (!x) return;

        const vehicle = this.props.vehiclesAll.filter(
          (z) => z.autoid === x.vehicle_id
        );

        this.setState({
          vehicle: vehicle[0],
          startTime: x.start_time,
          startPlace: x.start_place,
          startGeo: x.start_geo,
          endTime: x.end_time,
          endPlace: x.end_place,
          endGeo: x.end_geo,
          viewingObject: x,
        });
        setTimeout(() => {
          this.loadData();
        }, 5);
      }
    } catch (error) {}
  };

  loadData = () => {
    const locationState = this.props.location.state || {};
    const x = locationState.itemObject || this.state.viewingObject;
    if (!x) return;

    try {
      const user = this.props.user;
      const startTime = AppHelperDateToDBString(this.state.startTime);
      const endTime = AppHelperDateToDBString(this.state.endTime);
      const vehicleId = this.state.viewingObject.vehicle_id;

      const vehicleValue = this.state.vehicle | {};
      const timezone = vehicleValue.timezone || "Asia/Qatar";
      this.setState({ loading: true });

      TrackingBasicGetByTimeRangeOptimised(
        user.userToken,
        vehicleId,
        startTime,
        endTime,
        timezone
      ).then((response) => {
        this.setState({ loading: false });

        if (response && Array.isArray(response)) {
          let avgSpeedSum = 0;
          let avgSpeedCount = 0;
          let avgSpeed = 0;
          const topSpeed = response.length
            ? response.reduce(function (prev, current) {
                avgSpeedSum += current.speed > 9 ? current.speed : 0;
                avgSpeedCount += current.speed > 9 ? 1 : 0;
                return prev.speed > current.speed ? prev : current;
              })
            : {};

          try {
            avgSpeed = Math.floor(avgSpeedSum / avgSpeedCount);
          } catch (err) {}

          this.setState({ wayPoints: response, topSpeed, avgSpeed });
        }
      });
    } catch (error) {
      console.log("the exception TV81", error);
    }
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];

    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });

    window.localsss = intl;
    if (this.state.loading || !this.state.viewingObject) {
      return (
        <React.Fragment>
          <Row>
            <Colxx xxs="12">
              <Card>
                <CardBody>
                  {this.state.loading ? (
                    <div>
                      Please wait, It's getting ready
                      <div className="bg-info">
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      </div>
                    </div>
                  ) : (
                    `Noting to show`
                  )}
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <VehicleCard vehicle={this.state.vehicle} />
        <Row>
          <Colxx xl="3" lg="6" className="mb-4">
            <Card>
              <CardBody>
                <h5 className="mb-0 card-title text-muted">
                  Distance <small className="text-muted">(km)</small>
                </h5>
                <p className="lead color-theme-1 mb-1 value">
                  <i className="simple-icon-directions" />{" "}
                  {this.state.viewingObject.mileage}
                </p>
              </CardBody>
            </Card>
          </Colxx>

          <Colxx xl="3" lg="6" className="mb-4">
            <Card>
              <CardBody>
                <h5 className="mb-0 card-title text-muted">
                  Duration <small className="text-muted">(HH:mm)</small>
                </h5>
                <p className="lead color-theme-1 mb-1 value">
                  <i className="iconsminds-watch" />
                  {AppHelperDurationAsHHmm(
                    this.state.startTime,
                    this.state.endTime
                  )}
                </p>
              </CardBody>
            </Card>
          </Colxx>
          <Colxx xl="3" lg="6" className="mb-4">
            <Card>
              <CardBody>
                <h5 className="mb-0 card-title text-muted">
                  Top Speed <small className="text-muted">(kmph)</small>
                </h5>
                <p className="lead color-theme-1 mb-1 value">
                  <i className="simple-icon-speedometer" />{" "}
                  {this.state.topSpeed.speed
                    ? this.state.topSpeed.speed
                    : "..."}
                </p>
              </CardBody>
            </Card>
          </Colxx>
          <Colxx xl="3" lg="6" className="mb-4">
            <Card>
              <CardBody>
                <h5 className="mb-0 card-title text-muted">
                  Avg Speed <small className="text-muted">(kmph)</small>
                </h5>
                <p className="lead color-theme-1 mb-1 value">
                  <i className="simple-icon-speedometer" />{" "}
                  {this.state.avgSpeed || "..."}
                </p>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx lg="12" xl="4">
            <Row>
              <Colxx lg="4" xl="12" className="mb-4">
                <TripsViewTitle
                  title="From"
                  place={this.state.startPlace}
                  geo={this.state.startGeo}
                  time={this.state.startTime}
                  item={this.state.viewingObject}
                />
              </Colxx>
              <Colxx lg="4" xl="12" className="mb-4">
                <TripsViewTitle
                  title="To"
                  place={this.state.endPlace}
                  geo={this.state.endGeo}
                  time={this.state.endTime}
                  item={this.state.viewingObject}
                />
              </Colxx>
            </Row>
          </Colxx>
          <Colxx lg="12" xl="8" className="mb-4">
            <Card>
              <CardBody>
                <CanvasJS
                  height={349}
                  wayPoints={this.state.wayPoints || []}
                  // title={"Speed Chart"}
                  title={intl.formatMessage({
                    id: "tracking.speed-chart",
                  })}
                  selectedVehicle={this.state.vehicle || {}}
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <HistoryPlayback wayPoints={this.state.wayPoints} />
                <div className="clearfix"></div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { locale } = settings;
  return { vehiclesAll: all, user, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  withRouter(TripViewContainer)
);
