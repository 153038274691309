function main(map, maps, google) {
  return {
    map: map,
    maps: maps,
    markerArray: [],
    infowindow: new maps.InfoWindow({ content: "" }),
    loadMarkers(items) {
      const self = this;
      this.clearMarkers();
      if (items !== undefined) {
        items.forEach((item, i) => {
          const { lat, lng, title, timeText, details, type } = item;
          const position = { lat, lng };
          const infoContent = self.getInfoContent(title, timeText, details);
          self.addMarker(i, position, title, infoContent, type);
        });
      }
    },
    getInfoContent(title, time, details) {
      return `<div><b>${title}</b>
            <span> ${time} </span>
            <p> ${details} </p> </div>`;
    },
    addMarker(id, position, title, content, type) {
      const self = this;
      const marker = new this.maps.Marker({
        id,
        icon: this.getIcon(type),
        position: position,
        map: this.map,
        title: title,
      });
      marker.addListener("click", function () {
        self.infowindow.setContent(content);
        self.infowindow.open(this.map, marker);
      });

      this.markerArray.push(marker);
    },
    clearMarkers() {
      this.infowindow.close();
      this.markerArray.forEach((elMarker) => {
        elMarker.setMap(null);
      });
    },
    getIcon(color) {
      switch (color) {
        case "blue":
          return "/map-assets/blue-dot.png";

        case "yellow":
          return "/map-assets/yellow-dot.png";

        case "red":
          return "/map-assets/red-dot2.png";

        default:
          return null;
      }
    },
  };
}

module.exports = main;
