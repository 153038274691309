import React, { Component } from "react";
import { Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import { GetVisit } from "../../api-sc/visits";
import IntlMessages from "../../helpers/IntlMessages";

class VisitContainer extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      allRows: [],
    };
  }

  // componentWillUnmount() {}

  componentDidMount() {
    this.getVisits();
  }

  getVisits = async () => {
    const user = this.props.user;
    const userToken = user.userToken;

    const visits = await GetVisit(userToken);
    if (visits && Array.isArray(visits)) {
      this.setState({
        allRows: visits,
      });
    }
  };

  render() {
    const visitList = this.state.allRows || [];
    return (
      <React.Fragment>
        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <CardTitle><IntlMessages id="visits.list" /></CardTitle>
                {/* <Button onClick={this.getInvoices}>Invoice</Button> */}

                <small>{visitList.length} <IntlMessages id="support.records" /> </small>
                <div className="card-body-min-height">
                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th><IntlMessages id="visit.title"/></th>
                        <th><IntlMessages id="visit.date"/></th>
                        <th><IntlMessages id="visit.location"/></th>
                        <th><IntlMessages id="visit.status"/></th>
                      </tr>
                    </thead>
                    <tbody>
                      {visitList.map((item, i) => (
                        <tr key={i + 1}>
                          <th scope="row">{i + 1}</th>
                          <td>{item.name}</td>
                          <td>{item.visit_date}</td>
                          <td>{item.location_name}</td>
                          <td>{item.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { user } = authUser;
  return { user };
};

export default connect(mapStateToProps)(VisitContainer);
