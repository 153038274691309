import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { Row, Button } from 'reactstrap';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { TinyButton as ScrollUpButton } from 'react-scroll-up-button';
import moment from 'moment';

import { Colxx } from '../../components/common/CustomBootstrap';
import { storySelectMoreRequest } from '../../redux/actions';
import SummaryRow from '../../containers/day-summary/SummaryRow';
import VehicleSelect from '../../components/VehicleSelect';
import { VehiclesGetDaySummaryWithPlaceFource } from '../../api-tasks-analytics/vehicles';
import {
  AppHelperDateToDBString,
  AppHelperDurationAsHHmm,
  AppHelperDurationSecToHHmm,
} from '../../helpers/AppHelper';
import MapModel from "../../components/MapModel";
import IntlMessages from "../../helpers/IntlMessages";

const MutedCounters = (text, value) => {
  if (value)
    return (
      <Colxx xl="3" lg="4" sm="3" className="text-muted">
        {text}
        <p className="lead color-theme-1 mb-1 value">{value}</p>
      </Colxx>
    );
};

class ignitionStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      selectedVehicle: {},
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      allRows: [],
      mapModleGeo: "",
      mapModlePlace: "",
      mapModelOpen: false,
    };
  }

  componentDidMount() {
    // this.props.storySelectMoreRequest(this.props.history, 0);
  }

  componentDidUpdate() { }

  useCallback = () => {
    this.loadData();
  };

  findDriverName = (driverId) => {
    if (driverId && driverId > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === driverId;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return '';
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicleId && ignition.vehicleId > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicleId;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return '';
  };

  loadData = () => {
    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const vehicleId = this.state.selectedVehicle.key;

    if (!vehicleId) {
      return;
    }

    this.setState({
      loading: true, startTime: this.state.startTime.subtract(1, 'days'),
      endTime: this.state.endTime.subtract(1, 'days'),
    });
    
    VehiclesGetDaySummaryWithPlaceFource(user.userToken, [vehicleId], startTime, endTime).then(
      (response) => {

        this.setState({
          loading: false,
        });

        if (response && Array.isArray(response) && !response.error) {
          const a = response.map((x) => {
            const summaryDay = moment(x.summaryDay);
            const summaryDayText = moment(x.summaryDay).format('ddd MMM-DD');
            const vehicleName = this.findVehicleName(x);

            // format('dd DD-MMM-YYYY')
            const errands = x.errands.map((y) => {
              const startTime = moment(y.startTime);
              const endTime = moment(y.endTime);

              return {
                ...y,
                summaryDayText,
                vehicleName,
                startTimeText: startTime.format('hh:mm A'),
                endTimeText: endTime.format('hh:mm A'),
                dayDiff: endTime.diff(x.summaryDay, 'days') || 0,
                distance: y.distance.toFixed(1),
                duration: AppHelperDurationAsHHmm(
                  startTime.toDate(),
                  endTime.toDate()
                ),
                driverName: this.findDriverName(y.driverId),
              };
            });

            const idles = x.idles.map((y) => {
              const startTime = moment(y.startTime);
              const endTime = moment(y.endTime);

              return {
                ...y,
                summaryDayText,
                vehicleName,
                startTimeText: startTime.format('hh:mm A'),
                endTimeText: endTime.format('hh:mm A'),
                dayDiff: endTime.diff(x.summaryDay, 'days') || 0,
                duration: AppHelperDurationAsHHmm(
                  startTime.toDate(),
                  endTime.toDate()
                ),
                driverName: this.findDriverName(y.driverId),
                time: new Date(y.startTime),
              };
            });

            const stops = x.stops.map((y) => {
              const startTime = moment(y.startTime);
              const endTime = moment(y.endTime);

              return {
                ...y,
                summaryDayText,
                vehicleName,
                startTimeText: startTime.format('hh:mm A'),
                endTimeText: endTime.format('hh:mm A'),
                dayDiff: endTime.diff(x.summaryDay, 'days') || 0,
                duration: AppHelperDurationAsHHmm(
                  startTime.toDate(),
                  endTime.toDate()
                ),
                driverName: this.findDriverName(y.driverId),
              };
            });

            const overSpeeds = x.overSpeeds.map((y) => {
              const startTime = moment(y.eventTime);

              return {
                ...y,
                summaryDayText,
                vehicleName,
                startTimeText: startTime.format('hh:mm:ss A'),
                driverName: this.findDriverName(y.driverId),
                totalSeconds: y.totalSeconds > 15 ? '15+' : y.totalSeconds,
              };
            });

            return {
              ...x,
              summaryDay,
              summaryDayText,
              distance: x.distance.toFixed(1),
              enginHours: AppHelperDurationSecToHHmm(x.enginHours),
              idleHours: AppHelperDurationSecToHHmm(x.idleHours),
              parkingHours:
                x.parkingHours === 86400
                  ? '24:00'
                  : AppHelperDurationSecToHHmm(x.parkingHours),
              firstStartTime: x.firstStart
                ? moment(x.firstStart).format('hh:mm A')
                : '-',
              lastEndTime: x.lastEnd
                ? moment(x.lastEnd).format('hh:mm A')
                : '-',
              dayDiff: moment(x.lastEnd).diff(summaryDay, 'days') || 0,
              vehicleName,
              errands,
              idles,
              stops,
              overSpeeds,
            };
          });

          const concatedAllRows = this.state.allRows.concat(a);



          this.setState({
            allRows: concatedAllRows,
            totalCount: response.count,
            touched: true,

            loading: false,
          });
        }
      }
    );
  };

  handleChangeVehicle = (selectedVehicle) => {
    this.setState({
      selectedVehicle,
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      allRows: [],
    });

    setTimeout(() => {
      this.loadData();
    }, 5);

    setTimeout(() => {
      this.loadData();
    }, 500);

    setTimeout(() => {
      this.loadData();
    }, 1000);


    /*
   
    this.props.storySelectMoreRequest(
      this.props.history,
      0,
      selectedVehicle.key
    ); */
  };

  toggleLarge = () => {
    this.setState((prevState) => ({
      mapModelOpen: !prevState.mapModelOpen,
    }));
  };
  showModel = (geo, place) => {
    this.setState({
      mapModleGeo: geo,
      mapModlePlace: place,
      mapModelOpen: true,
    });
  };

  render() {



    const loading = this.state.loading;
    const selectedVehicle = this.state.selectedVehicle || {};
    const vehicleInfo = selectedVehicle.value || {};
    return (
      <Fragment>
        <MapModel
          geo={this.state.mapModleGeo}
          place={this.state.mapModlePlace}
          isOpen={this.state.mapModelOpen}
          closeButton={
            <Button
              color="primary"
              outline
              size="xs"
              className="mb-2"
              onClick={this.toggleLarge}
            >
              <IntlMessages id="common.close" />
              </Button>
          }
          toggle={this.toggleLarge}
        />

        <Row className="mb-2">
          <Colxx xl="5" lg="5" md="6" sm="12">
            <VehicleSelect onChange={this.handleChangeVehicle} />


          </Colxx>


        </Row>

        {this.state.selectedVehicle.value ? '' : (<div> <h1><IntlMessages id="dashboard.please-select-timeline" /> </h1> </div>)}

        {this.state.allRows.map((item, i) => (
          <Fragment key={i}>

            <Card className="mb-3">

              <CardBody>

                <span className="float-right">

                  <img
                    title={vehicleInfo.manufacture_name}
                    className="app-select-icon"
                    src={vehicleInfo.manufactureIcon}
                    alt="icon"
                  />
                  <img
                    title={vehicleInfo.typeText}
                    src={vehicleInfo.typeIcon}
                    alt="icon"
                    className="story-item-icon"
                  />
                </span>
                <h5
                  title={`#${vehicleInfo.autoid}-${item.summaryDayText}`}
                >{`${vehicleInfo.plate_number} ${vehicleInfo.fleet_number} ${vehicleInfo.model} ${vehicleInfo.year}`}</h5>

                <Row>
                  <span className="log-indicator align-middle border-theme-1 mr-1" />
                  {item.summaryDayText}
                </Row>

                <Row className="mt-2">
                  {MutedCounters("EnginHours(HH:mm)", item.enginHours)}
                  {MutedCounters("Distance(km)", item.distance)}
                  {MutedCounters("IdleHours(HH:mm)", item.idleHours)}
                  {MutedCounters("ParkingHours(HH:mm)", item.parkingHours)}
                  {MutedCounters("Errands", item.errands.length)}
                  {MutedCounters("Over Speeds", item.overSpeeds.length)}

                </Row>


                <SummaryRow
                  theRow={item}
                  onShowMapModel={this.showModel}
                  expanted={true}
                />

              </CardBody>
            </Card>
          </Fragment>
        ))}

        <button
          className="btn btn-link btn-xs"
          onClick={this.loadData}
        >
          {loading ? (
            <span className="spinner d-inline-block bg-primary p-1">
              <span className="bounce1" />
              <span className="bounce2" />
              <span className="bounce3" />
            </span>
          ) : (
              this.state.startTime.format('dddd, DD-MMM')
            )}
        </button>

        <ScrollUpButton />

        <BottomScrollListener onBottom={this.useCallback} offset="400" />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, drivers, authUser }) => {
  const { all } = vehicles;
  const { items } = drivers;
  const { user } = authUser;
  return { vehiclesAll: all, driversAll: items, user };
};

export default connect(mapStateToProps, {
  storySelectMoreRequest,
})(ignitionStory);
