import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import ErrandsTable from "./Errands";
import ErrandsHistory from "./ErrandsHistory";

const ErrandsTabView = ({
  allRows,
  loading,
  totalCount,
  findDriverName,
  wayPoints,
  vehicle,
  start,
  end
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [isViewErrands, setIsViewErrands] = useState(false);
  const[itemObject,setItemObject]=useState({})

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    if (tab === "1") {
      setIsViewErrands(false);
    }
  };

  const onClickView = (item) => {
    setIsViewErrands(true);
    setItemObject(item)
   
  };
 
  return (
    <div>
      <Nav tabs>
        <NavItem className={"w-50 text-center"}>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
           <h6>Errands</h6> 
          </NavLink>
        </NavItem>
        <NavItem className={isViewErrands?"w-50 text-center":"w-0"}>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            <h6 style={{ display: isViewErrands ? "" : "none" }}>
              View Details
            </h6>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <ErrandsTable
                allRows={allRows}
                findDriverName={findDriverName}
                totalCount={totalCount}
                loading={loading}
                toggle={toggle}
                onClickView={onClickView}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <ErrandsHistory
                wayPoints={wayPoints}
                vehicle={vehicle}
                start={start}
                end={end}
                itemObject={itemObject}
                activeTab={activeTab}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default ErrandsTabView;
