import React, { Component } from "react";
import { Route } from "react-router-dom";
import {
  Button, Row, Card, CardBody, CardTitle, Table,
  ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle
} from "reactstrap";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
// import { GeofenceGetInAndOutByDateAndVehicle } from "../../api-tasks-analytics/geofence";
import { GetGeofenceByVehicles } from "../../api-reports2/geofence";

import DateRangePicker from "../../components/DateRangePicker";
import LocationName from "../../components/LocationName";
import GeofenceSelect from "../../components/GeofenceSelect";
import { NotificationManager } from "../../components/common/react-notifications";

import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
} from "../../helpers/AppHelper";
import moment from "moment";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

const pagingLimit = -1;

const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Plate", key: "plateNumber" },
  { label: "Time", key: "time" },
  { label: "Place", key: "place" },
  { label: "Geo", key: "geo" },
  { label: "Entry", key: "typeText" },
  { label: "Driver", key: "driverName" },
];

class Idle extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedVehicles: [],
      selectedGeofences: [],
      loading: false,
      allRows: [],
      filteredRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
      fileName: "Ctraac - alerts.csv",
      isOpenSizingXs: false,
      selectedText: "Filter by",
    };
  }

  componentWillUnmount() { }

  componentDidMount() { }

  toggleSizingXs = () => {
    this.setState((prevState) => ({
      isOpenSizingXs: !prevState.isOpenSizingXs,
    }));
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
      allRows: [],
      filteredRows: [],
      limit: pagingLimit,
      skip: 0,
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  handleOnSelectedGeofenceChange = (selectedGeofences) => {
    const fences =
      selectedGeofences && selectedGeofences.length
        ? selectedGeofences
        : this.props.geofenceAll;
    this.setState({ selectedGeofences: fences || [] });

    setTimeout(() => {
      //  this.loadMasters(this.state.limit, this.state.skip);
    }, 10);
  };

  onDateRangeGo = (limit, skip) => {
    if (
      !this.state.selectedVehicles ||
      // !this.state.selectedGeofences ||
      !this.state.selectedVehicles.length // ||
      // !this.state.selectedGeofences.length
    ) {
      NotificationManager.error(
        "Please select vehicle and geofence",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    const vehicleIds = this.state.selectedVehicles.map((el) => {
      return el.key;
    });

    const geofenceIds = this.state.selectedGeofences.map((el) => {
      return el.autoid;
    });

    this.setState({ loading: true });

    GetGeofenceByVehicles(
      user.userToken,
      vehicleIds,
      startTime,
      endTime,
      geofenceIds,
    ).then((response) => {
      this.setState({
        loading: false,
        fileName: `Ctraac - Geofence Alerts, ${this.state.startTime.format(
          "YYY-MM-DD hh:mm A"
        )} to ${this.state.endTime.format("YYY-MM-DD hh:mm A")}.csv`,
      });

      if (response && Array.isArray(response)) {
        const a = response.map((x) => {
          return {
            ...x,
            typeText: x.type ? "In" : "Out",
            time: AppHelperDateAndTimeToString(x.event_time),
            vehicleName: this.findVehicleName(x),
            plateNumber: this.findPlateNumber(x),
          };
        });

        let filteredRows = null ;
        if(this.state.selectedText === 'Filter by'){

          filteredRows = a.filter((x) =>{
            return(x.typeText === 'In')
          })

          this.setState({selectedText : 'In'});

        }

        this.setState({
          allRows: a,
          filteredRows: filteredRows || a,
          totalCount: response.count,
          touched: true,
        });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      limit: pagingLimit,
      skip: 0,
      allRows: [],
      filteredRows: [],
    });
    if (this.state.selectedVehicles.length) {
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);
    }
  };

  handlePagingChange = (limit, skip) => {
    this.onDateRangeGo(limit, skip);
  };

  formatedPushButton = (itemObject, buttonText, path) => {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <Button
            size="xs"
            color="link"
            onClick={() => {
              history.push({
                pathname: path,
                state: { itemObject: itemObject },
              });
            }}
          >
            {buttonText}
          </Button>
        )}
      />
    );
    return <ButtonLive />;
  };

  findDriverName = (item) => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };

  findPlateNumber = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number}`;
        }
      }
    }
    return "";
  };


  handleFilterChange = (label) => {
    this.setState({ selectedText: `${label}` });
   
    let filteredRows = this.state.allRows || [];
 
    if (!filteredRows.length) {
      return;
    }

    switch (label) {
      case "In":
        filteredRows = filteredRows.filter((x) =>{
          return(x.typeText === 'In')
        })

        break;

      case "Out":
        filteredRows = filteredRows.filter((x) =>{
          return(x.typeText === 'Out')
        })
        break;
 
      default:
        filteredRows = filteredRows || [];
        break;
    }

    this.setState({ filteredRows: filteredRows });

    /*
    if (this.props.onChange) {
      this.props.onChange(selectedVehicle);
    }*/
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xxs="12" className="mb-1">
                    <GeofenceSelect
                      onChange={this.handleOnSelectedGeofenceChange}
                    />
                  </Colxx>

                  <Colxx xxs="12" className="mb-1">
                    <VehicleSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                    />
                  </Colxx>
                </Row>

                <Row>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      startTime={moment().startOf("week")}
                      dateOnly={true}
                    />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {this.state.selectedVehicles.length && this.state.touched ? (
                  <CardTitle>
                    {intl.formatMessage({
                      id: "geofence-alert",
                    })}
                    :{" from "}
                    {AppHelperDateAndTimeToString(
                      this.state.startTime.toDate()
                    )}
                    {" to "}
                    {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
                  </CardTitle>
                ) : (
                  <CardTitle>

                    <IntlMessages id="day-summary.search" />
                  </CardTitle>
                )}

                {!this.state.filteredRows ? (
                  <p>

                    <IntlMessages id="day-summary.nothing" />
                  </p>
                ) : (
                  ""
                )}
                <div className="card-body-min-height">


                  


                  <div className="float-right">

                  <ButtonDropdown
                    className="bd-container"
                    isOpen={this.state.isOpenSizingXs}
                    toggle={this.toggleSizingXs}
                  >
                    <DropdownToggle caret size="xs" outline color="secondary">
                      {this.state.selectedText}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: 221,
                              },
                            };
                          },
                        },
                      }}
                    >
                      <DropdownItem
                        onClick={() => this.handleFilterChange("In")}
                      >
                       In
                      </DropdownItem>

                      <DropdownItem
                        onClick={() => this.handleFilterChange("Out")}
                      >
                       Out
                      </DropdownItem>

                      <DropdownItem
                        onClick={() => this.handleFilterChange("InAndOut")}
                      >
                       In and Out
                      </DropdownItem>
     
                    </DropdownMenu>
                  </ButtonDropdown>

                    <CSVLink
                      headers={headers}
                      filename={this.state.fileName}
                      data={this.state.filteredRows}
                    >
                      <Button
                        title={this.state.fileName}
                        size="xs"
                        outline
                        color="dark"
                      >
                        <IntlMessages id="common.download" />
                      </Button>
                    </CSVLink>


                  </div>
                  {this.state.filteredRows.length + intl.formatMessage({
                    id: "geofence.records",
                  })}

                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <IntlMessages id="day-summary.vehicle" />
                        </th>
                        <th>
                          <IntlMessages id="day-summary.place" />
                        </th>

                        <th>
                          <IntlMessages id="day-summary.entry" />
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredRows.map((item, i) => (
                        <tr key={this.state.skip + i + 1}>
                          <th scope="row">{this.state.skip + i + 1}</th>
                          <td>{item.vehicleName}</td>
                          <td>
                            {item.time}
                            <br />
                            <LocationName
                              place={item.place}
                              geo={item.geo}
                              objectItem={item}
                              showGoogle
                            />
                          </td>

                          <td>{item.typeText}</td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Idle);
