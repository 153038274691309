

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "geofence-changes";

export const GeofenceAdd = (userToken, title, points, limit) => {
  const criterion = { userToken, title, points, limit };
  return axios
    .post(proxyServer + "/add", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GeofenceUpdate = (userToken, autoid, title, limit) => {
  const criterion = { userToken, autoid, title, limit };
  return axios
    .post(proxyServer + "/update", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GeofenceDelete = (userToken, autoid) => {
  const criterion = { userToken, autoid };
  return axios
    .post(proxyServer + "/delete", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GeofenceGetBySubscriber = (userToken) => {
  const criterion = { userToken };
  return axios
    .post(proxyServer + "/get-by-subscriber", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GeofenceChangesGetByVehicle = (userToken, vehicleId, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleId, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-vehicle", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GeofenceChangesGetByVehicles = (userToken, vehicleIds, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-vehicles", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GeofenceChangesGetSummaryGroupByDate = (userToken, vehicleIds, geofenceIds, startTime, endTime, offsetMinutes) => {
  const criterion = { userToken, vehicleIds, geofenceIds, startTime, endTime, offsetMinutes };
  return axios
    .post(proxyServer + "/get-summary-group-by-date", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GeofenceChangesGetSummaryGroupByDateAndVehicle = (userToken, vehicleIds, geofenceIds, startTime, endTime, offsetMinutes, limit, skip) => {
  const criterion = { userToken, vehicleIds, geofenceIds, startTime, endTime, offsetMinutes, limit, skip };
  return axios
    .post(proxyServer + "/get-summary-group-by-date-and-vehicle", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GeofenceSelectVehiclesByGeofence = (userToken, itemId) => {

  const criterion = { userToken, itemId };
  return axios
    .post(proxyServer + "/get-vehicles-by-geofence", {  criterion  })
    .then(res => {  return res.data;  })
    .catch(err => {  return err;  });
};

export const GeofenceVehicleRemove = (userToken, itemId) => {
  const criterion = { userToken, itemId };
  return axios
    .post(proxyServer + "/remove_vehicle", { criterion })
    .then(res => {  return res.data;  })
    .catch(err => {  return err; });
};

export const GeofenceVehicleRemoveAll = (userToken, geofenceId) => {
  const criterion = { userToken, geofenceId };
  return axios
    .post(proxyServer + "/remove_vehicle_all", { criterion })
    .then(res => {  return res.data;  })
    .catch(err => {  return err; });
};


export const GeofenceVehicleAddMultiple = (userToken, items) => {

  const criterion = { userToken, items };

  return axios
    .post(proxyServer + "/vehicle-add-multiple", { criterion })
    .then(res => { return res.data;  })
    .catch(err => {  return err; });
};


export const GeofenceVehicleSelectBySubscriber = (userToken) => {

  const criterion = { userToken };
  return axios
    .post(proxyServer + "/get-vehicles-by-subscriber", {  criterion })
    .then(res => {  return res.data; })
    .catch(err => { return err;  });
};


