import SelectLabelWithIcon from "../components/common/SelectLabelWithIcon";

/*
const optionsVehicleType = [
  { label: "Motorcycle", value: "Motorcycle", key: 0 },
  { label: "Car Sedan", value: "Car Sedan", key: 1 },
  { label: "Pickup", value: "Pickup", key: 2 },
  { label: "Car CUV", value: "Car CUV", key: 3 },
  { label: "Car Hatchback", value: "Car Hatchback", key: 4 },
  { label: "Car Roadster", value: "Car Roadster", key: 5 },
  { label: "Van", value: "Van", key: 6 },
  { label: "Car SUV", value: "Car SUV", key: 7 },
  { label: "Car Coupe", value: "Car Coupe", key: 8 },
  { label: "Super car", value: "Super car", key: 9 },
  { label: "Campervan", value: "Campervan", key: 10 },
  { label: "Mini Truck", value: "Mini Truck", key: 11 },
  { label: "Cabriolet", value: "Cabriolet", key: 12 },
  { label: "Minivan", value: "Minivan", key: 13 },
  { label: "Truck", value: "Truck", key: 14 },
  { label: "Big Truck", value: "Big Truck", key: 15 },
  { label: "Full Size Pickup", value: "Full Size Pickup", key: 16 },
  { label: "Mini Pickup", value: "Mini Pickup", key: 17 },
  { label: "Mini Bus", value: "Mini Bus", key: 18 },
  { label: "Delivery Truck", value: "Delivery Truck", key: 19 },
  { label: "Cement Truck", value: "Cement Truck", key: 20 },
  { label: "Dump Truck", value: "Dump Truck", key: 21 },
  { label: "Refrigerated Truck", value: "Refrigerated Truck", key: 22 },
  { label: "Tow Truck", value: "Tow Truck", key: 23 },
  { label: "Big Bus", value: "Big Bus", key: 24 },
];
*/
export const DEFAULT_VEHICLE_TYPE_ICON = "/assets/img/vehicle-type/car.png";
const root = "/assets/img/vehicle-type/";

export const optionsVehicleType = [
  { icon: `${root}car.png`, value: "Car", key: 1 },
  { icon: `${root}pickup.png`, value: "Pickup", key: 2 },
  { icon: `${root}van.png`, value: "Van", key: 3 },
  { icon: `${root}bus.png`, value: "Bus", key: 4 },
  { icon: `${root}truck.png`, value: "Truck", key: 5 },
  { icon: `${root}trailertruck.png`, value: "Trailer Truck", key: 6 },
  { icon: `${root}suv.png`, value: "SUV", key: 7 },

  { icon: `${root}beverage.png`, value: "Beverage", key: 8 },
  { icon: `${root}big-truck.png`, value: "Big Truck", key: 9 },
  { icon: `${root}bucket.png`, value: "Bucket", key: 10 },
  { icon: `${root}cabriolet.png`, value: "Cabriolet", key: 11 },
  { icon: `${root}campervan.png`, value: "Campervan", key: 12 },
  { icon: `${root}cement-mixer.png`, value: "Cement Mixer", key: 13 },
  { icon: `${root}city-transit-bus.png`, value: "City Transit Bus", key: 14 },
  { icon: `${root}city-delivery.png`, value: "CityDelivery", key: 15 },
  { icon: `${root}conventional-van.png`, value: "Conventional Van", key: 16 },
  { icon: `${root}coupe.png`, value: "Coupe", key: 17 },
  { icon: `${root}crew-size-pickup.png`, value: "Crew Size Pickup", key: 18 },
  { icon: `${root}cuv.png`, value: "CUV", key: 19 },
  { icon: `${root}dump.png`, value: "Dump", key: 20 },
  { icon: `${root}fire-truck.png`, value: "Fire Truck", key: 21 },
  { icon: `${root}fuel.png`, value: "Fuel", key: 22 },
  { icon: `${root}full-size-pickup.png`, value: "Full Size Pickup", key: 23 },
  { icon: `${root}furniture.png`, value: "Furniture", key: 24 },
  { icon: `${root}hatchback.png`, value: "Hatchback", key: 25 },
  {
    icon: `${root}heavy-semi-tractor.png`,
    value: "Heavy Semi Tractor",
    key: 26
  },
  { icon: `${root}high-profile-semi.png`, value: "High Profile Semi", key: 27 },
  { icon: `${root}home-fuel.png`, value: "Home Fuel", key: 28 },
  { icon: `${root}landscape-utility.png`, value: "Landscape Utility", key: 29 },
  { icon: `${root}large-walk-in.png`, value: "Large Walk In", key: 30 },
  {
    icon: `${root}medium-semi-tractor.png`,
    value: "Medium Semi Tractor",
    key: 31
  },
  { icon: `${root}micro.png`, value: "Micro", key: 32 },
  { icon: `${root}mini-bus.png`, value: "Mini Bus", key: 33 },
  { icon: `${root}mini-pickup.png`, value: "Mini Pickup", key: 34 },
  { icon: `${root}mini-truck.png`, value: "Mini Truck", key: 35 },
  { icon: `${root}mini-van.png`, value: "Minivan", key: 36 },
  { icon: `${root}rack.png`, value: "Rack", key: 37 },
  { icon: `${root}refrigerated-van.png`, value: "Refrigerated Van", key: 38 },
  { icon: `${root}refuse.png`, value: "Refuse", key: 39 },
  { icon: `${root}roadster.png`, value: "Roadster", key: 40 },
  { icon: `${root}school-bus.png`, value: "School Bus", key: 41 },
  { icon: `${root}sedan.png`, value: "Sedan", key: 42 },
  { icon: `${root}semi-sleeper.png`, value: "Semi Sleeper", key: 43 },
  { icon: `${root}single-axle-van.png`, value: "Single Axle Van", key: 44 },
  { icon: `${root}stake-body.png`, value: "Stake Body", key: 45 },
  { icon: `${root}step-van.png`, value: "StepVan", key: 46 },
  { icon: `${root}super-car.png`, value: "Super Car", key: 47 },
  { icon: `${root}tour-bus.png`, value: "Tour Bus", key: 48 },
  { icon: `${root}tow.png`, value: "Tow", key: 49 },
  { icon: `${root}utility-van.png`, value: "Utility Van", key: 50 },
  { icon: `${root}walk-in.png`, value: "Walk In", key: 51 }
];

const selectOptions = optionsVehicleType.map(item => ({
  label: SelectLabelWithIcon(item.value, item.icon),
  value: item.value,
  key: item.key
}));

export default selectOptions;