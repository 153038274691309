import axios from "axios";
import config from "./config";

const proxyServer = config.supportApi + "routes-app/executive";

export const ExecutiveUpdatePassword = (changePasswordInput) => {
  const criterion = changePasswordInput;

  return axios
    .post(proxyServer + "/change-password", {
      criterion,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
