import React from "react";
import {
  Popover,
  PopoverBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import IntlMessages from "../helpers/IntlMessages";
import classnames from "classnames";

class StateButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "default",
      message: "",
      messageShow: false,
      isOpenSizingXs: false
    };
  }

  handleOnClick = () => {
    this.setState(
      {
        status: "processing"
      },
      () => {
        this.props
          .onClick(this.props.value)
          .then(res => {
            this.setState({
              status: "success",
              message: res
            });
          })
          .catch(err => {
            this.setState({
              status: "fail",
              message: err
            });
          })
          .finally(() => {
            this.setState(
              {
                messageShow: true
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    messageShow: false,
                    status: "default"
                  });
                }, 3000);
              }
            );
          });
      }
    );
  };

  toggleSizingXs = () => {
    this.setState(prevState => ({
      isOpenSizingXs: !prevState.isOpenSizingXs
    }));
  };

  render() {
    return (
      <React.Fragment>
        <ButtonDropdown
          className="bd-container"
          isOpen={this.state.isOpenSizingXs}
          toggle={this.toggleSizingXs}
        >
          <DropdownToggle
            caret
            title={this.props.title}
            size={this.props.size}
            id={this.props.id}
            className={`btn-multiple-state  ${
              this.props.className
            }  ${classnames({
              "show-spinner": this.state.status === "processing",
              "show-success": this.state.status === "success",
              "show-fail": this.state.status === "fail"
            })}`}
            color={this.props.color}
            disabled={this.state.status !== "default"}
          >
            <Popover
              placement="top"
              isOpen={this.state.messageShow}
              target={this.props.id}
            >
              <PopoverBody>{this.state.message}</PopoverBody>
            </Popover>
            <span className="spinner d-inline-block">
              <span className="bounce1" />
              <span className="bounce2" />
              <span className="bounce3" />
            </span>
            <span className="icon success">
              <i className="simple-icon-check" />
            </span>
            <span className="icon fail">
              <i className="simple-icon-exclamation" />
            </span>
            <span className="label">{this.props.children}</span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              className="drop-item-danger"
              onClick={this.handleOnClick}
            >
              {this.props.confirmText ? this.props.confirmText : <IntlMessages id="common.confirm"/>}
            </DropdownItem>

            <DropdownItem className="drop-item-warning"><IntlMessages id="common.cancel"/></DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </React.Fragment>
    );
  }
}

export default StateButton;
