import React, { Fragment } from "react";
import { CardSubtitle, Row, Progress } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import LocationName from "../../../components/LocationName";
import IntlMessages from "../../../helpers/IntlMessages";
import AppLocale from "../../../lang";
import { createIntl } from "react-intl";
import { connect } from "react-redux";

// import { ThemeColors } from "../../../helpers/ThemeColors";

// const colors = ThemeColors();

class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topVehicles: [],
      topDrivers: [],
      topLongest: [],
      topFrequent: [],
      totalHours: 0,
      usedVehicles: 0,
    };
  }

  loadAll = () => {
    this.loadMasters();
    this.loadTopVehicles();
  };

  loadMasters = () => {
    const all = this.props.allRows;
    const totalSeconds = all.reduce(function (acu, x) {
      return acu + x.duration_sec;
    }, 0);
    this.setState({ totalHours: parseInt(totalSeconds / 60 / 60, 10) || 0 });

    const sortedRows = all.sort((a, b) => b.duration_sec - a.duration_sec);
    const top = sortedRows.length > 7 ? sortedRows.slice(0, 7) : sortedRows;
    this.setState({ topLongest: top });
  };

  loadTopVehicles = () => {
    const all = this.props.byVehicles || [];

    const sortedRows = all.sort((a, b) => b.times - a.times);
    const top = sortedRows.length > 10 ? sortedRows.slice(0, 10) : sortedRows;

    this.setState({ topFrequent: top, usedVehicles: all.length });

    const all2 = this.props.byVehicles || [];
    const sortedRows2 = all2.sort((a, b) => b.totalSec - a.totalSec);
    const top2 =
      sortedRows2.length > 10 ? sortedRows2.slice(0, 10) : sortedRows2;
    this.setState({ topVehicles: top2 });
  };

  loadTopDriver = () => {};

  componentDidMount() {
    this.loadAll();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.byVehicles !== this.props.byVehicles) {
      this.loadTopVehicles();
    }

    if (prevProps.allRows !== this.props.allRows) {
      this.loadMasters();
    }
  }

  render() {
    const theLongest = this.state.topLongest[0] || {};
    const theFrequent = this.state.topFrequent[0] || {};
    // console.log("the color ss", colors.themeColor2_10);
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;

    return (
      <Fragment>
        <Colxx xxs="12">
          <Row>
            <Colxx xl="3" lg="4" sm="3" className="text-muted mb-2">
              <IntlMessages id="report.idling-vehicles" />
              <p className="lead color-theme-1 mb-1 value">
                {this.state.usedVehicles || 0}
              </p>
              <small className="text-muted">
                <IntlMessages id="report.count" />
              </small>
            </Colxx>

            <Colxx xl="3" lg="4" sm="3" className="text-muted mb-2">
              <IntlMessages id="report.total-idling" />
              <p className="lead color-theme-1 mb-1 value">
                {this.state.totalHours || 0}
              </p>
              <small className="text-muted">
                <IntlMessages id="report.hours" />
              </small>
            </Colxx>
            <Colxx xl="3" lg="4" sm="3" className="text-muted mb-2">
              <IntlMessages id="report.freq-idling" />
              <p className="lead color-theme-1 mb-1 value">
                {theFrequent.times || 0}
              </p>
              <small className="text-muted">
                <IntlMessages id="report.time" />
              </small>
            </Colxx>

            <Colxx xl="3" lg="4" sm="3" className="text-muted mb-2">
              <IntlMessages id="report.longest-idling" />
              <p className="lead color-theme-1 mb-1 value">
                {theLongest.duration || 0}
              </p>
              <small className="text-muted">HH:mm </small>
            </Colxx>
          </Row>

          <Row>
            <Colxx xxs="12" lg="4" className="mb-2">
              <CardSubtitle>
                <IntlMessages id="report.top-freq" />
              </CardSubtitle>
              <Progress value={100} />
              {this.state.topFrequent.map((s, index) => {
                return (
                  <div key={index} className="mb-4">
                    <p className="mb-2">
                      {s.vehicleName}
                      <span className="float-right text-muted">
                        {intl.formatMessage({
                          id: "report.count",
                        })}{" "}
                        {s.times}
                      </span>
                    </p>
                    <span>
                      {intl.formatMessage({
                        id: "report.total",
                      })}{" "}
                      {s.totalDuration} <small>HH:mm</small>
                    </span>
                    <span className="ml-2">
                      {intl.formatMessage({
                        id: "report.avg",
                      })}{" "}
                      {s.avgDuration} <small>HH:mm</small>
                    </span>
                    <Separator />
                  </div>
                );
              })}
            </Colxx>

            <Colxx xxs="12" lg="4" className="mb-2">
              <CardSubtitle>
                <IntlMessages id="report.top-longest" />
              </CardSubtitle>
              <Progress value={100} />
              {this.state.topLongest.map((s, index) => {
                return (
                  <div key={index} className="mb-4">
                    <p className="mb-2">
                      {s.vehicleName}
                      <span className="float-right text-muted">
                        {s.duration} <small>HH:mm</small>
                      </span>
                    </p>

                    <p>
                      <i className="iconsminds-watch" /> {s.time}
                      <br />
                      <i className="iconsminds-location-2" />{" "}
                      <LocationName
                        place={s.place}
                        geo={s.geo}
                        objectItem={s}
                        showGoogle
                      />
                    </p>
                    <Separator />
                  </div>
                );
              })}
            </Colxx>

            <Colxx xxs="12" lg="4" className="mb-2">
              <CardSubtitle>
                <IntlMessages id="report.top-total" />
              </CardSubtitle>
              <Progress value={100} />
              {this.state.topVehicles.map((s, index) => {
                return (
                  <div key={index} className="mb-4">
                    <p className="mb-2">
                      {s.vehicleName}
                      <span className="float-right text-muted">
                        {intl.formatMessage({
                          id: "report.total",
                        })}{" "}
                        {s.totalDuration} <small>HH:mm</small>
                      </span>
                    </p>
                    <span>
                      {intl.formatMessage({
                        id: "report.count",
                      })}{" "}
                      {s.times}
                    </span>
                    <span className="ml-2">
                      {intl.formatMessage({
                        id: "report.avg",
                      })}{" "}
                      {s.avgDuration} <small>HH:mm</small>
                    </span>
                    <Separator />
                  </div>
                );
              })}
            </Colxx>
          </Row>
        </Colxx>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return {
    locale,
  };
};

export default connect(mapStateToProps)(Analytics);
