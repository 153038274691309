import React from "react";

class Spinner extends React.Component {
  render() {
    return (
        <div className={this.props.className}>
      <span className="spinner d-inline-block">
        <span className="bounce1" />
        <span className="bounce2" />
        <span className="bounce3" />
      </span>
      </div>
    );
  }
}
export default Spinner;
