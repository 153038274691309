import React from "react";
import { Button, ButtonGroup, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import VehicleSelectWithBatchCustom from "./VehicleSelectWithBatchCustom";
import { NotificationManager } from "./common/react-notifications";
import { GroupVehicleAddMultiple } from "../api-tasks/groups";
import IntlMessages from "../helpers/IntlMessages";
import AppLocale from "../lang";
import { createIntl } from "react-intl";

class GroupVehiclesAddByGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoSaveRemainingSec: 7,
      loading: false,
      selectedVehicles: [],
    };

    this.autoSaveTimer = null;
  }

  autoSaveTimerRestart = start => {
    this.setState({ autoSaveRemainingSec: 7 });
    if (this.autoSaveTimer) {
      clearInterval(this.autoSaveTimer);
    }

    if (start) {
      this.autoSaveTimer = setInterval(this.autoSaveCountDown, 1000);
    }
  };

  autoSaveCountDown = () => {
    const seconds = this.state.autoSaveRemainingSec - 1;
    this.setState({ autoSaveRemainingSec: seconds });

    if (seconds === 0) {
      if (this.autoSaveTimer) {
        clearInterval(this.autoSaveTimer);
      }
      this.onAddClick();
    }
  };

  onAddClick = () => {
    this.autoSaveTimerRestart(0);
    const vehicles = this.state.selectedVehicles;

    if (!vehicles.length) {
      NotificationManager.error(
        "please select vehicles to assign",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    if (!this.props.group) {
      NotificationManager.error(
        "please select group",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    this.setState({ loading: true });
    const group = this.props.group;
    const assignedItems = vehicles.map(vItem => ({
      itemId: group.autoid,
      vehicleId: vItem.item.autoid
    }));


    console.log('the gg', assignedItems );

    const user = this.props.user;
    GroupVehicleAddMultiple(user.userToken, assignedItems).then(response => {
      if (response) {
        this.setState({ loading: false, selectedVehicles: [] });
        if (response) {
          if (response.status === "success") {
            NotificationManager.success(
              response.message,
              "Success",
              3000,
              null,
              null,
              ""
            );
            if (this.props.onRefresh) {
              this.props.onRefresh();
            }
          } else {
            console.error("group.vehicle.add", response);
            NotificationManager.warning(
              response.message,
              response.status,
              3000,
              null,
              null,
              ""
            );
          }
        }
      }
    }); 
  };

  onCancel = () => {
    this.setState({ selectedVehicles: [] });
    this.autoSaveTimerRestart(0);
  };

  handleOnSelectedVehicleChange = selectedVehicles => {
    this.setState({ selectedVehicles });
    this.autoSaveTimerRestart(selectedVehicles.length);
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    let selectedText = intl.formatMessage({
      id: "group.select-vehicle",
    })
    const count = this.state.selectedVehicles.length;
    if (count) {
      selectedText =
      intl.formatMessage({
        id: "group.assign",
      }) + (count > 1 ? `${count} ${intl.formatMessage({
        id: "group.vehicle",
      })}` :intl.formatMessage({
        id: "group.vehicles-small",
      }) ) + intl.formatMessage({
        id: "group.to-group",
      });
    }

    return (
      <Card>
        <CardBody>
          {count && !this.state.loading ? (
            <span className="float-right">
              {intl.formatMessage({
              id: "group.auto-save",
            })} {this.state.autoSaveRemainingSec}s
            </span>
          ) : (
            ""
          )}

          <h6> {selectedText}</h6>
          {count ? (
            <ButtonGroup className="flex-wrap float-right">
              <Button color="warning" size="xs" onClick={this.onCancel}>
              <IntlMessages id="common.cancel" />
              </Button>
              <Button color="primary" size="xs" onClick={this.onAddClick}>
                {this.state.loading ? (
                  <span className="spinner d-inline-block">
                    <span className="bounce1" />
                    <span className="bounce2" />
                    <span className="bounce3" />
                  </span>
                ) : (
                  <IntlMessages id="common.save" />
                )}
              </Button>
            </ButtonGroup>
          ) : (
            ""
          )}

          <VehicleSelectWithBatchCustom
            placeholder={intl.formatMessage({
              id: "group.select-assign",
            })}
            vehiclesAll={this.props.options }
            selectedVehicles = {this.state.selectedVehicles}
            onChange={this.handleOnSelectedVehicleChange}
          />
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = ({settings }) => {
  const { locale } = settings;
  return {locale };
};

export default connect(mapStateToProps)(GroupVehiclesAddByGroup);
