import React from "react";
import ReactSelect from "react-select";
import { connect } from "react-redux";
import {
  Button,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";

import CustomSelectInput from "./common/CustomSelectInput";
import PassengerClusters from "../data-const/passenger-types";

const customFilterOption = (option, rawInput) => {
  if (option && rawInput) {
    const words = rawInput.split(" ");
    return words.reduce(
      (acc, cur) =>
        acc &&
        option.data.searchWords.toLowerCase().includes(cur.toLowerCase()),
      true
    );
  } else {
    return true;
  }
};

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];

    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

    return objectsByKeyValue;
  }, {});

class PassengerSelectWithBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: [],
      nestingDropdownOpen: false,
      options: [],
      batchOptions: [],
      isOpenSizingXs: false
    };
  }

  componentDidMount() {
    this.loadMasters();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.passengersAll !== this.props.passengersAll) {
      this.loadMasters();
    }
  }

  loadMasters = () => {
    const options = this.generateOptions(this.props.passengersAll);
    this.setState({ options });
    this.generateBatch(options);

  };

  generateBatch = options => {
    const all = options;

    if (!all) {
      this.setState({ batchOptions: [] });
      return;
    }

    const gModel = groupBy("clusterText");
    const resultModel = gModel(all);
    const itemModels = Object.keys(resultModel).map(function(key, index) {
      return {
        label: key,
        data: resultModel[key],
        icons: "",
        value: `m${index}`
      };
    });
  
    const result = itemModels || [];

    result.sort(function(a, b) {
      return a.label.localeCompare(b.label);
    });

    if (result[0] && result[0].label === "") {
      result[0].label = "< Others >";
    }

    this.setState({ batchOptions: result });
  };

  generateOptions = vehicles => {
    const options = vehicles.map(item => ({
      label: item.passenger_name,
      value: item.autoid.toString(),
      searchWords:
        item.passenger_name +
        " " +
        item.employee_code +
        " " +
        item.rfid_code,
      key: item.autoid,
      cluster: item.cluster,
      clusterText: PassengerClusters.filter(d => d.id === item.cluster)[0].title,
      item: item
    }));
    return options;
  };

  nestingToggle = () => {
    this.setState({
      nestingDropdownOpen: !this.state.nestingDropdownOpen
    });
  };

  handleChange = selectedVehicle => {
    this.setState({ selectedVehicle: selectedVehicle });
    if (this.props.onChange) {
      this.props.onChange(selectedVehicle);
    }
  };

  handleChangeAppend = selectedVehicle => {
    const selected = [
      ...new Set(this.state.selectedVehicle.concat(selectedVehicle))
    ];

    this.setState({ selectedVehicle: selected });
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  };

  toggleSizingXs = () => {
    this.setState(prevState => ({
      isOpenSizingXs: !prevState.isOpenSizingXs
    }));
  };

  render() {
    const classNamePrefix = this.props.classNamePrefix || "rs";
    const name = this.props.name || "allVehicles";
    return (
      <React.Fragment>
        <div className="w-100">
          <span className="float-right">
            {this.state.selectedVehicle.length} Selected
          </span>

          <ButtonGroup className="flex-wrap">
            <Button outline size="xs" onClick={() => this.handleChange([])}>
              Clear
            </Button>
            <Button
              outline
              size="xs"
              onClick={() => this.handleChange(this.state.options)}
            >
              Select All
            </Button>

            <ButtonDropdown
              className="bd-container"
              isOpen={this.state.isOpenSizingXs}
              toggle={this.toggleSizingXs}
            >
              <DropdownToggle caret size="xs" outline color="secondary">
                Select By
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: data => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: "auto",
                          maxHeight: 221
                        }
                      };
                    }
                  }
                }}
              >
                {this.state.batchOptions.map((el, index) => (
                  <DropdownItem
                    key={el.value}
                    value={index}
                    onClick={() => this.handleChangeAppend(el.data)}
                  >
                    {el.icon ? (
                      <img
                        className="app-select-icon"
                        src={el.icon}
                        alt="icon"
                      />
                    ) : (
                      ""
                    )}
                    {`${el.label} (${el.data.length})`}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </ButtonGroup>
        </div>

        <div className="w-100">
          <ReactSelect
            filterOption={customFilterOption}
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix={classNamePrefix}
            maxMenuHeight={240}
            name={name}
            options={this.state.options}
            placeholder={this.props.placeholder}
            isMulti
            onChange={this.handleChange}
            value={this.state.selectedVehicle}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ passengers }) => {
  const { items } = passengers;
  return { passengersAll: items || [] };
};

export default connect(mapStateToProps)(PassengerSelectWithBatch);
