import React, { Component } from "react";
import { Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";

import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import MegaMap from "../../components/MegaMap";
import { authApiAccessCheck } from "../../redux/actions";
import { LiveSelectByToken } from "../../api-tasks/live";

class LiveMap extends Component {
  constructor(props) {
    super();
    this.state = {
      liveUpdates: [],
      selectedVehicles: [],
    };
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidMount() {
    this.getLastUpdate();
    this.intervalId = setInterval(this.getLastUpdate, 2000);
  }

  getLastUpdate = () => {
    const user = this.props.user;

    LiveSelectByToken(user.userToken).then((res) => {
      if (res) {
        if (Array.isArray(res)) {
          this.setState({ liveUpdates: res });
        } else {
          this.props.authApiAccessCheck(this.props.history, res);
        }
      }
    });
  };

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });
  };

  render() {
    return (
      <Card>
        <CardBody>
          <Row className="mb-1">
            <Colxx xxs="12">
              <VehicleSelectWithBatch
                onChange={this.handleOnSelectedVehicleChange}
                placeholder="tracking.filter-vehicles"
              />
            </Colxx>
          </Row>

          <MegaMap
            selectedVehicles={this.state.selectedVehicles}
            vehiclesAll={this.props.vehiclesAll}
            liveUpdates={this.state.liveUpdates}
          />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user } = authUser;
  return { vehiclesAll: all, user };
};

export default connect(mapStateToProps, { authApiAccessCheck })(LiveMap);
