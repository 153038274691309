import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "tracking-basic";

export const TrackingBasicGetByTimeRange = (userToken, vehicleId, startTime, endTime )=> {
  const criterion = { userToken, vehicleId, startTime, endTime };

  return axios
    .post(proxyServer + "/get-by-time-range", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const TrackingBasicGetByTimeRangeOptimised = (userToken, vehicleId, startTime, endTime, timezone )=> {
  const criterion = { userToken, vehicleId, startTime, endTime, timezone };

  return axios
    .post(proxyServer + "/get-by-time-range-optimised", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const TrackingBasicGetByTimeRangeOptimisedWithTimeLine = (userToken, vehicleId, startTime, endTime, timezone )=> {
  const criterion = { userToken, vehicleId, startTime, endTime, timezone };

  return axios
    .post(proxyServer + "/get-by-time-range-optimised-with-timeline", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};


