import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
// import DaySummary from "../../../containers/drill-downs/day-summary";
import DaySummary from "../../../containers/reports2/day-summary";

class GeofenceAnalytics extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.drill-downs-day-summary" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <DaySummary />
      </Fragment>
    );
  }
}
export default GeofenceAnalytics;
