import React, { Component } from "react";
import {
  Row,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import classnames from "classnames";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import VehicleSettings from "./vehicle-settings";
import CommonSettings from "./common-settings";

class SettingsTab extends Component {
  constructor(props) {
    super();

    this.toggleSecondTab = this.toggleSecondTab.bind(this);
    this.state = {
      activeSecondTab: "1"
    };
  }

  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab
      });
    }
  }
  render() {
    const userDetails = this.props.userDetails || {};
    const userRole = userDetails.role || 3;

    if (userRole === 3) {
      return (
        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <Row>
                  <Colxx xxs="12">
                    <div className="alert alert-info" role="alert">
                      <IntlMessages id="group.user-access" />
                    </div>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      );
    }

    return (
      <Row>
        <Colxx xxs="12">
          <Card className="mb-4">
            <CardHeader className="pl-0 pr-0">
              <Nav tabs className=" card-header-tabs  ml-0 mr-0">
                <NavItem className="w-50 text-center">
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "1",
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggleSecondTab("1");
                    }}
                    to="#"
                  >
                    <h6><IntlMessages id="vehicle.vehicle-setting-tab" /></h6>
                  </NavLink>
                </NavItem>
                <NavItem className="w-50 text-center">
                  <NavLink
                    className={classnames({
                      active: this.state.activeSecondTab === "2",
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggleSecondTab("2");
                    }}
                    to="#"
                  >
                    <h6> <IntlMessages id="vehicle.common-setting-tab" /> </h6>
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <Separator  />
            <TabContent activeTab={this.state.activeSecondTab}>
              <TabPane tabId="1">
                <VehicleSettings history={this.props.history} />
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Colxx xxs="12">
                    <CommonSettings />
                  </Colxx>
                </Row>
              </TabPane>
            </TabContent>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
 

const mapStateToProps = ({ authUser }) => {
  
  const { user, details } = authUser;

  return {  user, userDetails: details };
};

export default connect(mapStateToProps, {})(SettingsTab);

