import React from "react";
import IntlMessages from "../../helpers/IntlMessages";

import { Button } from "reactstrap";

class CustomSubmitButton extends React.Component {
  render() {
    const progressing = this.props.validating || this.props.updating;
    let text = this.props.text || <IntlMessages id="common.submit" />;
    if (this.props.validating) {
      text = "Validating";
    } else if (this.props.updating) {
      text = "Updating";
    }

    return (
      <Button color="primary" type="submit" onClick={() => { if (this.props.onClick) { this.props.onClick(); } }} >
        {text}
        {progressing && (
          <span className="spinner d-inline-block">
            <span className="bounce1" />
            <span className="bounce2" />
            <span className="bounce3" />
          </span>
        )}
      </Button>
    );
  }
}
export default CustomSubmitButton;
