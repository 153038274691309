

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "groups";

export const GroupRemove = (userToken, autoid) => {
  const criterion = { userToken, autoid };
  return axios
    .post(proxyServer + "/remove", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupUpdate = (userToken, autoid, name, details) => {
  const criterion = { userToken, autoid, name, details };
  return axios
    .post(proxyServer + "/update", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupAdd = (userToken, title, details) => {
  const criterion = { userToken, title, details };
  return axios
    .post(proxyServer + "/add", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupSelectBySubscriber = (userToken) => {

  const criterion = { userToken };
  return axios
    .post(proxyServer + "/get-by-subscriber", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

// ------------------

export const GroupVehicleAdd = (userToken, itemId, vehicleId) => {
  const criterion = { userToken, itemId, vehicleId };
  return axios
    .post(proxyServer + "/add_vehicle", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupVehicleAddMultiple = (userToken, items) => {

  const criterion = { userToken, items };

  return axios
    .post(proxyServer + "/vehicle-add-multiple", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupVehicleRemove = (userToken, itemId) => {
  const criterion = { userToken, itemId };
  return axios
    .post(proxyServer + "/remove_vehicle", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const GroupSelectVehiclesByGroup = (userToken, itemId) => {

  const criterion = { userToken, itemId };
  return axios
    .post(proxyServer + "/get-vehicles-by-group", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
