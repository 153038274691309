/* eslint-disable no-loop-func */
import React, { Component } from "react";
import {
  Button,
  CardTitle,
  Modal,
  ModalBody,
  Row,
  Progress,
  Badge,
} from "reactstrap";
import { createIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";

import { authApiAccessCheck } from "../../redux/actions";
import IntlMessages from "../../helpers/IntlMessages";
import MapModel from "../../components/MapModel";
import AppLocale from "../../lang";
import DetailsTab from "../day-summary/details-tab";
import {
  AppHelperDateAndTimeToString,
  AppHelperDurationAsHHmm,
  AppHelperDurationSecToHHmm,
} from "../../helpers/AppHelper";
import { Colxx } from "../../components/common/CustomBootstrap";

const LIMIT_PER_CALL = 10;

const getFirstKmText = (startTime, eventTime) => {
  if (eventTime) {
    const firstNkmTime = moment(eventTime);

    let duration = moment.duration(firstNkmTime.diff(startTime));
    let seconds = duration.asSeconds();

    if(seconds <= 0) { return "-"; }

    return `${firstNkmTime.format("hh:mmA")} (${(seconds/60).toFixed(0)}min)`;
  }

  return "-";
};

class DaySummaryTable extends Component {
  constructor() {
    super();
    this.state = {
      displayVehicles: [],
      startTime: moment(),
      endTime: moment(),
      mapModleGeo: "",
      mapModlePlace: "",
      mapModelOpen: false,
      allRows: [],
      fetchDataProgress: 0,
      countOfProgressing: 0,
      countOfPending: 0,
      countOfDone: 0,
      countOfFailed: 0,
      fetchFailedCount: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.displayVehicles !== this.props.displayVehicles) {
      //   const duration = moment.duration(
      //     this.props.endTime.diff(this.props.startTime)
      //   );

      this.setState({
        displayVehicles: this.props.displayVehicles,
        startTime: this.props.startTime,
        endTime: this.props.endTime,
        fetchDataProgress: 0,
        allRows: [],
        countOfProgressing: 0,
        countOfPending: 0,
        countOfDone: 0,
        countOfFailed: 0,
        fetchFailedCount: 0,
      });

      setTimeout(() => {
        this.fetchData();
      }, 100);
    }
  }

  findDriverName = (driverId) => {
    if (driverId && driverId > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === driverId;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicleId && ignition.vehicleId > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicleId;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };

  progressCalc = () => {
    // cancel if all done
    if (this.state.allRows.length > 0) {
      return;
    }

    const displayVehicles = this.state.displayVehicles;

    let countOfProgressing = 0;
    let countOfPending = 0;
    let countOfDone = 0;
    let countOfFailed = 0;
    for (const v of displayVehicles) {
      countOfProgressing += v.callStatus === "progressing" ? 1 : 0;
      countOfPending += v.callStatus === "pending" ? 1 : 0;
      countOfDone += v.callStatus === "done" ? 1 : 0;
      countOfFailed += v.callStatus === "failed" ? 1 : 0;
    }

    this.setState({
      countOfPending,
      countOfDone,
      countOfFailed,
    });

    const countOfTotal =
      countOfProgressing + countOfPending + countOfDone + countOfFailed;
    const progressingPercentage =
      ((countOfDone + countOfFailed) / countOfTotal) * 100;

    if (progressingPercentage === 100) {
      if (this.state.countOfFailed > 0) {
        setTimeout(() => {
          this.setState({ loadingModalIsOpen: false });
        }, 5000);
      } else {
        this.setState({ loadingModalIsOpen: false });
      }

      const allRecords = this.state.displayVehicles.map((v) => {
        return v.callResponse ? v.callResponse.allRecords : [];
      });
      const allRows = allRecords.flat();

      this.setState({ allRows: allRows });
    }

    this.setState({ fetchDataProgress: progressingPercentage });
  };

  fetchData = () => {
    // cancel all when fetch data 100%
    if (this.state.fetchDataProgress === 100) {
      return;
    }

    const displayVehicles = this.state.displayVehicles;
    // const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    // const endTime = AppHelperDateToDBString(this.state.endTime.toDate());

    let callCounter = 0;
    let pendingCounter = 0;
    let progressingCounter = 0;

    this.setState({ loadingModalIsOpen: true });

    for (let index = 0; index < displayVehicles.length; index++) {
      const v = displayVehicles[index];

      if (v.callStatus === "progressing") {
        progressingCounter += 1;
        if (!(progressingCounter % LIMIT_PER_CALL)) {
          break;
        }
      } else if (v.callStatus === "pending") {
        pendingCounter += 1;
        if (!(pendingCounter % LIMIT_PER_CALL)) {
          break;
        }

        v.callStatus = "progressing";
        displayVehicles[index] = v;

        v.call
          .then((response) => {
            let allRecords = [];

            if (response && Array.isArray(response) && !response.error) {
              allRecords = response.map((x) => {
                const summaryDay = moment(x.summaryDay);
                const summaryDayText = moment(x.summaryDay).format(
                  "ddd MMM-DD"
                );
                const vehicleName = this.findVehicleName(x);

                // format('dd DD-MMM-YYYY')
                const errands = x.errands.map((y) => {
                  const startTime = moment(y.startTime);
                  const endTime = moment(y.endTime);


                  const first3kmTextErrands = getFirstKmText(
                    startTime,
                    y.first3kmTime
                  );

                  return {
                    ...y,
                    summaryDayText,
                    vehicleName,
                    startTimeText: startTime.format("hh:mm A"),
                    endTimeText: endTime.format("hh:mm A"),
                    dayDiff: endTime.diff(x.summaryDay, "days") || 0,
                    distance: y.distance.toFixed(1),
                    duration: AppHelperDurationAsHHmm(
                      startTime.toDate(),
                      endTime.toDate()
                    ),
                    driverName: this.findDriverName(y.driverId),
                    first3kmText: first3kmTextErrands,
                  };
                });

                const idles = x.idles.map((y) => {
                  const startTime = moment(y.startTime);
                  const endTime = moment(y.endTime);

                  return {
                    ...y,
                    summaryDayText,
                    vehicleName,
                    startTimeText: startTime.format("hh:mm A"),
                    endTimeText: endTime.format("hh:mm A"),
                    dayDiff: endTime.diff(x.summaryDay, "days") || 0,
                    duration: AppHelperDurationAsHHmm(
                      startTime.toDate(),
                      endTime.toDate()
                    ),
                    driverName: this.findDriverName(y.driverId),
                    time: new Date(y.startTime),
                  };
                });

                const stops = x.stops.map((y) => {
                  const startTime = moment(y.startTime);
                  const endTime = moment(y.endTime);

                  return {
                    ...y,
                    summaryDayText,
                    vehicleName,
                    startTimeText: startTime.format("hh:mm A"),
                    endTimeText: endTime.format("hh:mm A"),
                    dayDiff: endTime.diff(x.summaryDay, "days") || 0,
                    duration: AppHelperDurationAsHHmm(
                      startTime.toDate(),
                      endTime.toDate()
                    ),
                    driverName: this.findDriverName(y.driverId),
                  };
                });

                const overSpeeds = x.overSpeeds.map((y) => {
                  const startTime = moment(y.eventTime);

                  return {
                    ...y,
                    summaryDayText,
                    vehicleName,
                    startTimeText: startTime.format("hh:mm:ss A"),
                    driverName: this.findDriverName(y.driverId),
                    totalSeconds: y.totalSeconds > 15 ? "15+" : y.totalSeconds,
                  };
                });


                const firstStartMoment = moment(x.firstStart);

                const first1kmText = getFirstKmText(
                  firstStartMoment,
                  x.first1KmTime
                );

                const first3kmText = getFirstKmText(
                  firstStartMoment,
                  x.first3KmTime
                );

                const first10kmText = getFirstKmText(
                  firstStartMoment,
                  x.first10KmTime
                );

                return {
                  ...x,
                  summaryDay,
                  summaryDayText,
                  distance: (x.distance || 0).toFixed(1),
                  enginHours: AppHelperDurationSecToHHmm(x.enginHours),
                  idleHours: AppHelperDurationSecToHHmm(x.idleHours),
                  parkingHours:
                    x.parkingHours === 86400
                      ? "24:00"
                      : AppHelperDurationSecToHHmm(x.parkingHours),
                  firstStartTime: x.firstStart
                    ? firstStartMoment.format("hh:mm A")
                    : "-",
                  lastEndTime: x.lastEnd
                    ? moment(x.lastEnd).format("hh:mm A")
                    : "-",
                  dayDiff: moment(x.lastEnd).diff(summaryDay, "days") || 0,
                  vehicleName,
                  errands,
                  idles,
                  stops,
                  overSpeeds,
                  first1kmText,
                  first3kmText,
                  first10kmText,
                };
              });
            }

            v.callResponse = {
              allRecords: allRecords,
              loading: false,
            };

            v.callStatus = "done";

            const vehicleList = this.state.displayVehicles;
            vehicleList[index] = v;
            this.setState({ displayVehicles: vehicleList });

            setTimeout(() => {
              this.progressCalc();
            }, 100);
          })
          .catch((error) => {
            v.callStatus = "failed";
            displayVehicles[index] = v;

            const vehicleList = this.state.displayVehicles;
            vehicleList[index] = v;
            this.setState({
              displayVehicles: vehicleList,
              fetchFailedCount: this.state.fetchFailedCount + 1,
            });

            // console.error(error);

            setTimeout(() => {
              this.progressCalc();
            }, 100);
          });
      } else {
        callCounter += 1;
      }
    }

    this.setState({ displayVehicles, countOfProgressing: progressingCounter });

    setTimeout(() => {
      this.progressCalc();
    }, 100);

    if (callCounter !== displayVehicles.length) {
      setTimeout(() => {
        this.fetchData();
      }, 1000 * 2);
    }
  };

  toggleLarge = () => {
    this.setState((prevState) => ({
      mapModelOpen: !prevState.mapModelOpen,
    }));
  };

  showModel = (geo, place) => {
    this.setState({
      mapModleGeo: geo,
      mapModlePlace: place,
      mapModelOpen: true,
    });
  };

  getBadgeColor = (status) => {
    switch (status) {
      case "done":
        return "light";
      case "progressing":
        return "info";
      case "pending":
        return "warning";
      case "failed":
        return "danger";
      default:
        return "light";
    }
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];

    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;

    const displayVehicles = this.state.displayVehicles || [];

    return (
      <>
        <Modal
          isOpen={this.state.loadingModalIsOpen}
          size="lg"
          key="mega-loader"
        >
          <ModalBody>
            <Row>
              <Colxx xxs="12">
                <div className="d-block">
                  <h3 className="d-inline pr-1">
                  {this.state.fetchDataProgress !==100 ? (
                        <span className="spinner d-inline-block bg-primary">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                        
                      ) :  null}
                    Loading {Math.round(this.state.fetchDataProgress)}%
                  </h3>
                  <p className="d-inline">
                    Pending:{" "}
                    {this.state.countOfPending + this.state.countOfProgressing}{" "}
                    | Done: {this.state.countOfDone}
                  </p>
                </div>
                <Progress
                  value={this.state.fetchDataProgress}
                  className="mb-2"
                />

                <div
                  className="d-block"
                  style={{ maxHeight: "300px", overflow: "auto" }}
                >
                  {displayVehicles.map((v, i) => (
                    <Badge
                      color={this.getBadgeColor(v.callStatus)}
                      key={v.item.key}
                      className={"mb-1 mr-1"}
                    >
                      <span>
                        {v.item.plate_number} {v.item.fleet_number}
                      </span>
                    </Badge>
                  ))}
                </div>
                {this.state.countOfFailed > 0 && (
                  <div className="d-block" key="faild-count">
                    <p className="d-inline text-danger">
                      Failed: {this.state.countOfFailed} vehicle fetch, try
                      again in 2 minutes
                    </p>
                  </div>
                )}
              </Colxx>
            </Row>
          </ModalBody>
        </Modal>

        <MapModel
          key="mapModel"
          geo={this.state.mapModleGeo}
          place={this.state.mapModlePlace}
          isOpen={this.state.mapModelOpen}
          closeButton={
            <Button
              color="primary"
              outline
              size="xs"
              className="mb-2"
              onClick={this.toggleLarge}
            >
              <IntlMessages id="common.close" />
            </Button>
          }
          toggle={this.toggleLarge}
        />

        {this.state.displayVehicles.length && this.state.touched ? (
          <CardTitle>
            {intl.formatMessage({
              id: "day-summary",
            })}
            :{" from "}
            {AppHelperDateAndTimeToString(this.state.startTime.toDate())}
            {" to "}
            {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
          </CardTitle>
        ) : (
          <CardTitle>
            {intl.formatMessage({
              id: "day-summary.search",
            })}
          </CardTitle>
        )}

        {!this.state.allRows ? (
          <p>
            {intl.formatMessage({
              id: "day-summary.nothing",
            })}
          </p>
        ) : (
          ""
        )}
        <div className="w-100 ">
          <small className="float-right">
            00:00 = HH:mm, HH = Hour, mm = Minuts
          </small>
          <div className="clearfix"> </div>
        </div>
        <div className="card-body-min-height d-block">
          <DetailsTab
            allRows={this.state.allRows}
            onShowMapModel={this.showModel}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  DaySummaryTable
);
