import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Button, Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";

import { Colxx } from "../../components/common/CustomBootstrap";
import DriverSelect2 from "../../components/DriverSelect2";
import { authApiAccessCheck } from "../../redux/actions";
import { IgnitionGetTripsByDriver } from "../../api-tasks/ignition";
import DateRangePicker from "../../components/DateRangePicker";
import LocationName from "../../components/LocationName";

import { NotificationManager } from "../../components/common/react-notifications";

import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
  AppHelperDurationAsHHmm,
} from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";

const pagingLimit = -1;

const headers = [
  { label: "Vehicle", key: "vehicleName" },
  { label: "Start Time", key: "startTime" },
  { label: "Start Place", key: "start_place" },
  { label: "Start geo", key: "start_geo" },
  { label: "End Time", key: "endTime" },
  { label: "End Place", key: "end_place" },
  { label: "End geo", key: "end_geo" },
  { label: "HH:mm", key: "duration" },
  { label: "Km", key: "mileage" },
];

class Trips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDriver: null,
      loading: false,
      allRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
      fileName: "Ctraac - trips.csv",
    };
  }

  componentWillUnmount() {}

  componentDidMount() {}

  handleChangeDriver = (selectedDriver) => {
    console.log("selectedDriver", selectedDriver);
    this.setState({
      selectedDriver: selectedDriver,
      allRows: [],
      limit: pagingLimit,
      skip: 0,
    });
    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  onDateRangeGo = (limit, skip) => {
    if (!this.state.selectedDriver) {
      NotificationManager.error(
        "please select driver",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const v = this.state.selectedDriver.value;
    const driverId = this.state.selectedDriver.key;

    this.setState({ loading: true });

    IgnitionGetTripsByDriver(
      user.userToken,
      driverId,
      startTime,
      endTime,
      limit,
      skip
    ).then((response) => {
      console.log("dr response", response);
      this.setState({
        loading: false,
        limit,
        skip,
        fileName: `Ctraac - Trips, Driver ${v.driver_name} ${
          v.employee_code
        } ${this.state.startTime.format(
          "YYY-MM-DD hh:mm A"
        )} to ${this.state.endTime.format("YYY-MM-DD hh:mm A")}.csv`,
      });

      if (response && Array.isArray(response.rows)) {
        const a = response.rows.map((x) => {
          return {
            ...x,
            duration: AppHelperDurationAsHHmm(x.start_time, x.end_time),
            startTime: AppHelperDateAndTimeToString(x.start_time),
            endTime: AppHelperDateAndTimeToString(x.end_time),
            vehicleName: this.findVehicleName(x),
          };
        });

        this.setState({
          allRows: a,
          totalCount: response.count,
          touched: true,
        });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      limit: pagingLimit,
      skip: 0,
      allRows: [],
    });
    if (this.state.selectedDriver) {
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);
    }
  };

  handlePagingChange = (limit, skip) => {
    this.onDateRangeGo(limit, skip);
  };

  formatedPushButton = (itemObject, buttonText, path) => {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <Button
            size="xs"
            color="link"
            onClick={() => {
              history.push({
                pathname: path,
                state: { itemObject: itemObject },
              });
            }}
          >
            {buttonText}
          </Button>
        )}
      />
    );
    return <ButtonLive />;
  };

  findVehicleName = (item) => {
    const ignition = item || {};
    if (ignition.vehicle_id && ignition.vehicle_id > 0) {
      if (this.props.vehiclesAll && this.props.vehiclesAll.length) {
        const foo = this.props.vehiclesAll.filter(function (value) {
          return value.autoid === ignition.vehicle_id;
        });

        if (foo && foo.length) {
          return `${foo[0].plate_number} ${foo[0].fleet_number} ${foo[0].model} ${foo[0].typeText}`;
        }
      }
    }
    return "";
  };

  render() {
    const selectedDriver = this.state.selectedDriver || {};
    const currentAppLocale = AppLocale[this.props.locale];
    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="4" lg="4" md="6" sm="12">
                    <DriverSelect2
                      onChange={this.handleChangeDriver}
                      defaultSelect
                      value={this.state.selectedDriver}
                    />
                  </Colxx>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      suggestions="months"
                      startTime={moment().startOf("month")}
                    />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {selectedDriver.value && this.state.touched ? (
                  <CardTitle>
                    {intl.formatMessage({
                      id: "report.trips-of-vehicle",
                    })}{" "}
                    : {selectedDriver.value.driver_name}{" "}
                    {selectedDriver.value.employee_code}
                    {" from "}
                    {AppHelperDateAndTimeToString(
                      this.state.startTime.toDate()
                    )}
                    {" to "}
                    {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
                  </CardTitle>
                ) : (
                  <CardTitle>
                    {" "}
                    {intl.formatMessage({
                      id: "report.try-search-driver",
                    })}{" "}
                  </CardTitle>
                )}

                {this.state.touched && !this.state.totalCount ? (
                  <p>
                    {intl.formatMessage({
                      id: "report.nothing-show",
                    })}
                  </p>
                ) : (
                  ""
                )}
                <div className="card-body-min-height">
                  <div className="float-right">
                    <CSVLink
                      headers={headers}
                      filename={this.state.fileName}
                      data={this.state.allRows}
                    >
                      <Button
                        title={this.state.fileName}
                        size="xs"
                        outline
                        color="dark"
                      >
                        <IntlMessages id="common.download" />
                      </Button>
                    </CSVLink>
                  </div>
                  {this.state.allRows.length +
                    " " +
                    intl.formatMessage({
                      id: "report.records",
                    })}

                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <IntlMessages id="report.vehicle" />
                        </th>
                        <th>
                          <IntlMessages id="report.start" />
                        </th>
                        <th>
                          <IntlMessages id="report.end" />
                        </th>
                        <th>KM</th>
                        <th>HH:mm</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.allRows.map((item, i) => (
                        <tr key={this.state.skip + i + 1}>
                          <th scope="row">{this.state.skip + i + 1}</th>
                          <td>{item.vehicleName}</td>
                          <td>
                            {item.startTime}
                            <br />
                            <LocationName
                              place={item.start_place}
                              geo={item.start_geo}
                              objectItem={item}
                              showGoogle
                            />
                          </td>
                          <td>
                            {item.endTime}
                            <br />
                            <LocationName
                              place={item.end_place}
                              geo={item.end_geo}
                              objectItem={item}
                              showGoogle
                            />
                          </td>

                          <td>{item.mileage}</td>
                          <td>{item.duration}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser, drivers, settings }) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { locale } = settings;
  return { vehiclesAll: all, user, driversAll: items, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Trips);
