import React, { Component } from "react";
import { Row, CardBody, Jumbotron } from "reactstrap";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { authApiAccessCheck, vehicleSelectAllRequest } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";
import Spinner from "../../components/common/Spinner";
import IntlMessages from "../../helpers/IntlMessages";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { NotificationManager } from "../../components/common/react-notifications";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";
import { VehicleSubscriptionRenewal } from "../../api-tasks/vehicles";


class CommonSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedVehicles: [],
      txtSecureKey: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showMessage = (response) => {
    if (response.status === "success") {
      NotificationManager.success(
        response.message + " and the action scheduled",
        response.status.toUpperCase(),
        3000,
        null,
        null,
        ""
      );
    } else {
      NotificationManager.error(
        response.message,
        response?.status?.toUpperCase(),
        3000,
        null,
        null,
        ""
      );
    }
  };

  componentDidMount() {}

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });
  };

  handleRenewalUpdate = () => {

    const userToken =  this.props.user.userToken ;
    const vehicleIds = this.state.selectedVehicles.map((vehicle) => { return vehicle.key; });
    const secureKey = this.state.txtSecureKey;
    
   

    VehicleSubscriptionRenewal(userToken, vehicleIds, secureKey).then((response) => {
       
      if (response) {
        this.showMessage(response);
        if (response.status === "success") {
          this.props.vehicleSelectAllRequest(true, this.props.history);

          this.setState({ selectedVehicles: [], txtSecureKey: "" });
        }
      }
    });
  }

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;

    return (
      <Row className="mb-4">
        <Colxx xl="6" lg="6" md="12">
          <CardBody>
            <VehicleSelectWithBatch
              onChange={this.handleOnSelectedVehicleChange}
              allowExpired={true}

            />
            <Row className="d-none d-lg-block">
              <CardBody>
                <Jumbotron>
                  <p className="lead">
                    <IntlMessages id="vehicles.info" />
                  </p>
                  <hr className="my-4" />
                  <p>
                    Handle multiple vehicles at once. Select vehicles from the
                    list and update the settings.
                  </p>
                </Jumbotron>
              </CardBody>
            </Row>
          </CardBody>
        </Colxx>
        <Colxx xl="6" lg="6" md="12">
          <CardBody>
            {this.state.loading ? (
              <Spinner className="float-right bg-info" />
            ) : (
              ""
            )}

            <h5>Subscription renewal</h5>

            <div className="table-responsive">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Secure Key"
                value={this.state.txtSecureKey}
                onChange={this.onChange}
                name="txtSecureKey"
              />

              <button className="btn btn-primary btn-sm"
              onClick={this.handleRenewalUpdate}
              >
                Renewal for 365 days
              </button>
            </div>
            {this.state.loading ? (
              <Spinner className="float-right bg-info" />
            ) : (
              ""
            )}

            {this.state.loading ? (
              <Spinner className="float-right bg-info" />
            ) : (
              ""
            )}
          </CardBody>
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => {
  const { user } = authUser;
  const { locale } = settings;
  return { user, locale };
};

export default connect(mapStateToProps, { authApiAccessCheck, vehicleSelectAllRequest })(
  injectIntl(CommonSettings)
);
