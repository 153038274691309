import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Button, Row, Card, CardBody, CardTitle, Table } from "reactstrap";
import { connect } from "react-redux";
import { Colxx } from "../../components/common/CustomBootstrap";
import { authApiAccessCheck } from "../../redux/actions";
import { IgnitionGetTripsByVehicle } from "../../api-tasks/ignition";
import DateRangePicker from "../../components/DateRangePicker";
import LocationName from "../../components/LocationName";
import PagingBar from "../../components/PagingBar";
import { NotificationManager } from "../../components/common/react-notifications";

import {
  AppHelperDateAndTimeToString,
  AppHelperDateToDBString,
  AppHelperDurationAsHHmm,
} from "../../helpers/AppHelper";

import moment from "moment";
import IntlMessages from "../../helpers/IntlMessages";

const pagingLimit = 20;

class TripsVehicleTabPage extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedVehicle: null,
      loading: false,
      filteredRows: [],
      allRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    if (this.props.selectedVehicle) {
      this.handleChangeVehicle(this.props.selectedVehicle);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedVehicle !== this.props.selectedVehicle) {
      this.handleChangeVehicle(this.props.selectedVehicle);
    }
  }

  handleChangeVehicle = (selectedVehicle) => {
    this.setState({
      selectedVehicle: selectedVehicle,
      allRows: [],
      filteredRows: [],
      limit: pagingLimit,
      skip: 0,
    });
    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  onDateRangeGo = (limit, skip) => {
    if (!this.state.selectedVehicle) {
      NotificationManager.error(
        "please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const startTime = AppHelperDateToDBString(this.state.startTime.toDate());
    const endTime = AppHelperDateToDBString(this.state.endTime.toDate());
    const vehicleId = this.state.selectedVehicle.autoid;

    this.setState({ loading: true });

    IgnitionGetTripsByVehicle(
      user.userToken,
      vehicleId,
      startTime,
      endTime,
      limit,
      skip
    ).then((response) => {
      this.setState({ loading: false, limit, skip });

      if (response && Array.isArray(response.rows)) {
        this.setState({
          allRows: response.rows,
          filteredRows: response.rows,
          totalCount: response.count,
          touched: true,
        });
      }
    });
  };

  handleChangeDateRange = (startTime, endTime) => {
    this.setState({
      startTime,
      endTime,
      limit: pagingLimit,
      skip: 0,
      allRows: [],
      filteredRows: [],
    });
    if (this.state.selectedVehicle) {
      setTimeout(() => {
        this.onDateRangeGo(this.state.limit, this.state.skip);
      }, 10);
    }
  };

  handlePagingChange = (limit, skip) => {
    this.onDateRangeGo(limit, skip);
  };

  formatedPushButton = (itemObject, buttonText, path) => {
    const ButtonLive = () => (
      <Route
        render={({ history }) => (
          <Button
            size="xs"
            color="link"
            onClick={() => {
              history.push({
                pathname: path,
                state: { itemObject: itemObject },
              });
            }}
          >
            <IntlMessages id={buttonText} />
          </Button>
        )}
      />
    );
    return <ButtonLive />;
  };

  render() {
    const selectedVehicle = this.state.selectedVehicle || {};
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row>
                  <Colxx xl="5" lg="5" md="6" sm="12">
                    <DateRangePicker
                      onChange={this.handleChangeDateRange}
                      suggestions="months"
                      startTime={moment().startOf("month")}
                    />
                  </Colxx>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="tracking.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {selectedVehicle.value && this.state.touched ? (
                  <CardTitle>
                    <IntlMessages id="tracking.trips-of-vehicle" /> :{" "}
                    {selectedVehicle.value.plate_number}{" "}
                    {selectedVehicle.value.fleet_number}{" "}
                    <IntlMessages id="tracking.from" />{" "}
                    {AppHelperDateAndTimeToString(
                      this.state.startTime.toDate()
                    )}{" "}
                    <IntlMessages id="tracking.to" />{" "}
                    {AppHelperDateAndTimeToString(this.state.endTime.toDate())}
                  </CardTitle>
                ) : (
                  <CardTitle>
                    <IntlMessages id="tracking.try-search-filter-or-select-vehicle" />{" "}
                  </CardTitle>
                )}

                {this.state.touched && !this.state.totalCount ? (
                  <p>
                    <IntlMessages id="tracking.nothing-to-show" />
                  </p>
                ) : (
                  ""
                )}
                <div className="card-body-min-height">
                  {this.state.totalCount > this.state.limit ? (
                    <PagingBar
                      totalCount={this.state.totalCount}
                      limit={this.state.limit}
                      skip={this.state.skip}
                      onChange={this.handlePagingChange}
                      outline
                      loading={this.state.loading}
                    />
                  ) : (
                    ""
                  )}

                  <Table responsive hover bordered striped>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <IntlMessages id="tracking.start" />
                        </th>
                        <th>
                          <IntlMessages id="tracking.end" />
                        </th>
                        <th>
                          <IntlMessages id="tracking.km" />
                        </th>
                        <th>
                          <IntlMessages id="tracking.HH:mm" />
                        </th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredRows.map((item, i) => (
                        <tr key={this.state.skip + i + 1}>
                          <th scope="row">{this.state.skip + i + 1}</th>
                          <td>
                            {AppHelperDateAndTimeToString(item.start_time)}
                            <br />
                            <LocationName
                              place={item.start_place}
                              geo={item.start_geo}
                              objectItem={item}
                              showGoogle
                            />
                          </td>
                          <td>
                            {AppHelperDateAndTimeToString(item.end_time)}
                            <br />
                            <LocationName
                              place={item.end_place}
                              geo={item.end_geo}
                              objectItem={item}
                              showGoogle
                            />
                          </td>

                          <td>{item.mileage}</td>
                          <td>
                            {AppHelperDurationAsHHmm(
                              item.start_time,
                              item.end_time
                            )}
                          </td>
                          <td>
                            {this.formatedPushButton(
                              item,
                              "common.view",
                              "/app/pages/trip-view"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <PagingBar
                    totalCount={this.state.totalCount}
                    limit={this.state.limit}
                    skip={this.state.skip}
                    onChange={this.handlePagingChange}
                    outline
                    loading={this.state.loading}
                  />
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, authUser }) => {
  const { all } = vehicles;
  const { user } = authUser;
  return { vehiclesAll: all, user };
};

export default connect(mapStateToProps, { authApiAccessCheck })(
  TripsVehicleTabPage
);
