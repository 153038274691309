import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "passenger";
const proxyServerAlerts = config.serverApi + "passenger-changes";

export const PassengerAdd = ( userToken, cluster, name, code, RFID) => {

  const criterion = { userToken, cluster, name, code, RFID};

  return axios
    .post(proxyServer + "/add", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const PassengerUpdate = ( userToken, passengerId, cluster, name, code) => {

  const criterion = { userToken, passengerId, cluster, name, code};

  return axios
    .post(proxyServer + "/update", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const PassengerSelectBySubscriber = (userToken) => {

    const criterion = {
      userToken
    };
    return axios
      .post(proxyServer + "/get-by-subscriber", {
        criterion
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

  export const PassengerVehicleAdd = (userToken, items) => {

    const criterion = {
      userToken,
      items
    };
  
    return axios
      .post(proxyServer + "/vehicle-add", {
        criterion
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
  };

export const PassengerSelectVehiclesByPassenger = (userToken, passengerId) => {

  const criterion = {
    userToken,
    passengerId
  };
  return axios
    .post(proxyServer + "/get-vehicles-by-passenger", {
      criterion
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const PassengerChangesGetByPassengers = (userToken, passengerIds, startTime, endTime, limit, skip) => {
  const criterion = { userToken, passengerIds, startTime, endTime, limit, skip };
  return axios
    .post(proxyServerAlerts + "/get-by-passengers", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
