import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

// import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import SettingsTab from "../../../containers/vehicle-master/settings-tab";

class VehicleMaster extends Component {
  render() {
    // const { messages } = this.props.intl;
    // const messages = [];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb
              heading="menu.vehicle-master"
              match={this.props.match}
            />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <SettingsTab history={this.props.history} />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles }) => {
  const { all } = vehicles;
  return { vehiclesAll: all };
};

export default connect(mapStateToProps)(injectIntl(VehicleMaster));
