import React from "react";
import ReactSelect from "react-select";
import { connect } from "react-redux";
import {
  Button,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";

import CustomSelectInput from "../components/common/CustomSelectInput";
import { defaultColor, themeColorStorageKey } from "../constants/defaultValues";
import IntlMessages from "../helpers/IntlMessages";
import AppLocale from "../lang";
import { createIntl } from "react-intl";

const fullAccesEmails = ['admin@nto.qa', 'tracking@nto.qa'];

const PAGE_SIZE = 30;

const customFilterOption = (option, rawInput) => {
  if (option && rawInput) {
    const words = rawInput.split(" ");
    return words.reduce(
      (acc, cur) =>
        acc &&
        option.data.searchWords.toLowerCase().includes(cur.toLowerCase()),
      true
    );
  } else {
    return true;
  }
};

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];

    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

    return objectsByKeyValue;
  }, {});

function connectedStatus(
  updateTime,
  noDataTime,
  reminigDays,
  expiryExtensionDays
) {
  if (reminigDays < 0) {
    if (expiryExtensionDays < 1) {
      return "Expired";
    } else {
      return `Expiry extension ${expiryExtensionDays} days`;
    }
  }
  if (!updateTime) return "-";
  if (new Date() - new Date(updateTime) < noDataTime) return "Connected";
  return "No data";
}

const LabelWithIcon = (vehicle) => {
  //  const reminigDays = moment(vehicle.expiry_date).diff(moment(), "days");
  const noDataMin = vehicle.hasOwnProperty("attributes.liveMap.noDataMin")
    ? vehicle.attributes.liveMap.noDataMin
    : 2880;

  const noDataInMillisecond = noDataMin * 60 * 1000;

  const trackingUpdateTime = vehicle.tracking_last_time;

  return (
    <div key={vehicle.key}>
      <img
        title={vehicle.manufacture_name}
        className="app-select-icon"
        src={vehicle.manufactureIcon}
        alt="icon"
      />
      <img
        title={vehicle.typeText}
        className="app-select-icon"
        src={vehicle.typeIcon}
        alt="icon"
      />
      <strong title="Plate">{vehicle.plate_number}</strong>{" "}
      <strong title="Asset No">{vehicle.fleet_number}</strong>{" "}
      <span title="Model">{vehicle.model}</span>{" "}
      <span title="Cluster">{vehicle.project}</span>
      <span className="mb-1 mr-1 badge badge-light">
        {connectedStatus(
          trackingUpdateTime,
          noDataInMillisecond,
          vehicle.reminigDays,
          vehicle.expiryExtensionDays
        )}
      </span>
    </div>
  );
};

const getColor = () => {
  return localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;
};

const getPageLabel = (pageNumber) => {
  return `${PAGE_SIZE * pageNumber + 1}-${PAGE_SIZE * pageNumber + PAGE_SIZE}`;
};

class VehicleSelectWithBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: [],
      nestingDropdownOpen: false,
      options: [],
      batchOptions: [],
      isOpenSizingXs: false,
      isOpenSizingXsGroup: false,
      isOpenSizingXsPage: false,
      selectedPageText: "By Page",
      displyLimit: 25,
      vehicleGroups: [],
      vehicleGroupsFiltered: [],
      searchTexGroup: "",
      inputValue: "",
    };
  }

  componentDidMount() {
    this.loadMasters();

    this.setState({
      vehicleGroups: this.props.groups,
      vehicleGroupsFiltered: this.props.groups,
    });

    if (this.props.defaultSelectAll) {
      setTimeout(() => {
        this.handleChange(this.state.options);
      }, 100);
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.vehiclesAll !== this.props.vehiclesAll) {
      this.loadMasters();
    }

    if (prevProps.whiteList !== this.props.whiteList) {
      this.loadMasters();
    }

    if (prevProps.groups !== this.props.groups) {
      this.setState({
        vehicleGroups: this.props.groups,
        vehicleGroupsFiltered: this.props.groups,
      });
    }
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
    if (
      inputValue &&
      (inputValue.includes(" ") ||
        inputValue.includes("\n") ||
        inputValue.includes(","))
    ) {
      const plateNumbers = inputValue
        .split(" ")
        .join(",")
        .split("\n")
        .join(",")
        .split("'")
        .join(",")
        .split(",");

      let smartSelected = [];
      plateNumbers.forEach((element) => {
        if (!element) {
          return;
        }
        const a = this.state.options.filter((el) => {
          return el.plate_number === element || el.fleet_number === element;
        });

        smartSelected = smartSelected.concat(a);
      });

      if (smartSelected.length) {
        this.handleChangeAppend(smartSelected);
      }
    }
  };

  whiteListContain = (autoid) => {
    const aList = this.props.whiteList.filter((el) => {
      return el.vehicle_id === autoid;
    });

    if (aList.length) {
      return true;
    }

    return false;
  };

  loadMasters = () => {
    const whiteList = this.props.whiteList;

    let aList = this.props.vehiclesAll;
    let bList = null;
    if (whiteList && whiteList.length) {
      bList = this.props.vehiclesAll.filter((el) => {
        return this.whiteListContain(el.autoid);
      });
    }

    const options = this.generateOptions(bList || aList);
    this.setState({ options });
    this.generateBatch(options);
  };

  onSearchGroup = (event) => {
    let searchQuery = event.target.value.toLowerCase();
    let arrData = searchQuery.split(" ");
    let tempDataRows = this.state.vehicleGroups;
    let filteredDataRows = this.state.vehicleGroups;
    arrData.forEach((element) => {
      filteredDataRows = tempDataRows.filter((el) => {
        const item = {
          ...el,
        };

        return item.group_name.toLowerCase().indexOf(element) !== -1;
      });

      tempDataRows = filteredDataRows;
    });

    this.setState({
      vehicleGroupsFiltered: filteredDataRows,
      searchTexGroup: searchQuery || "",
    });
  };

  generateBatch = (options) => {
    const all = options;

    if (!all) {
      this.setState({ batchOptions: [] });
      return;
    }

    const gModel = groupBy("model");
    const resultModel = gModel(all);
    const itemModels = Object.keys(resultModel).map(function (key, index) {
      return {
        label: key,
        data: resultModel[key],
        icons: "",
        value: `m${index}`,
      };
    });

    const gType = groupBy("typeText");
    const resultType = gType(all);
    const itemType = Object.keys(resultType).map(function (key, index) {
      return {
        label: key,
        data: resultType[key],
        icon: resultType[key][0].typeIcon,
        value: `t${index}`,
      };
    });

    const gProject = groupBy("project");
    const resultProject = gProject(all);
    const itemProject = Object.keys(resultProject).map(function (key, index) {
      const el = !key || key === "null" ? "No Cluster" : key;
      return {
        label: `${el}`,
        data: resultProject[key],
        icon: "",
        value: `l${index}`,
      };
    });

    const gPage = groupBy("page");
    const resultPage = gPage(all);
    const itemPage = Object.keys(resultPage).map(function (key, index) {
      return {
        label: key,
        data: resultPage[key],
        icon: "",
        value: `m${index}`,
      };
    });

    const resultB = itemModels || [];

    const resultA = itemProject.concat(resultB) || [];
    const result = resultA.concat(itemType);

    result.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });

    if (result[0] && !result[0].label) {
      result[0].label = "Others";
    }

    this.setState({ batchOptions: result, pageOptions: itemPage });
  };

  generateOptions = (vehicles) => {
    
    const userEmail = this.props?.userDetails?.email;
    const isFullAccess = fullAccesEmails.includes(userEmail);
    const allowExpired = this.props.allowExpired || isFullAccess;

    const options = vehicles.map((item, index) => ({
      isDisabled:
        item.reminigDays < 0 &&
        item.expiryExtensionDays < 1 &&
        !allowExpired
          ? true
          : false,
      label: LabelWithIcon(item),
      value: item.autoid.toString(),
      searchWords:
        item.manufacture_name +
        " " +
        item.plate_number +
        " " +
        item.fleet_number +
        " " +
        item.model +
        " " +
        item.project +
        " " +
        item.year,
      key: item.autoid,
      autoid: item.autoid,
      plate_number: item.plate_number,
      fleet_number: item.fleet_number,
      model: item.model,
      project: item.project,
      typeText: item.typeText,
      typeIcon: item.typeIcon,
      manufactureIcon: item.manufactureIcon,
      item: item,
      page: getPageLabel(Math.floor(index / PAGE_SIZE)),
    }));

    return options;
  };

  nestingToggle = () => {
    this.setState({
      nestingDropdownOpen: !this.state.nestingDropdownOpen,
    });
  };

  handleChange = (selectedVehicle) => {
    let selected = selectedVehicle;

    selected = selected.filter((el) => {
      return el.isDisabled === false;
    });

    this.setState({
      selectedVehicle: selected,
      selectedPageText: "By Page",
    });
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  };

  handleChangeAppend = (selectedVehicle) => {
    let selected = [
      ...new Set(this.state.selectedVehicle.concat(selectedVehicle)),
    ];

    selected = selected.filter((el) => {
      return el.isDisabled === false;
    });

    this.setState({ selectedVehicle: selected, selectedPageText: "By Page" });
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  };

  handleChangeAppendGroup = (selectedGroup) => {
    const selectedVehicle = [];
    this.state.options.forEach((el) => {
      if (selectedGroup.vehicleData.indexOf(el.value) !== -1) {
        selectedVehicle.push(el);
      }
    });

    let selected = [
      ...new Set(this.state.selectedVehicle.concat(selectedVehicle)),
    ];

    selected = selected.filter((el) => {
      return el.isDisabled === false;
    });

    this.setState({ selectedVehicle: selected, selectedPageText: "By Page" });
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  };

  handleChangePage = (selectedPage) => {
    let selected = selectedPage.data;

    selected = selected.filter((el) => {
      return el.isDisabled === false;
    });

    this.setState({
      selectedVehicle: selected,
      selectedPageText: selectedPage.label,
    });
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  };

  toggleSizingXs = () => {
    this.setState((prevState) => ({
      isOpenSizingXs: !prevState.isOpenSizingXs,
    }));
  };

  toggleSizingXsGroup = () => {
    this.setState((prevState) => ({
      isOpenSizingXsGroup: !prevState.isOpenSizingXsGroup,
    }));
  };

  toggleSizingXsPage = () => {
    this.setState((prevState) => ({
      isOpenSizingXsPage: !prevState.isOpenSizingXsPage,
    }));
  };

  render() {
    let classNamePrefix = this.props.classNamePrefix || "rs";
    const name = this.props.name || "allVehicles";

    let color = getColor();

    let isDark = false;

    if (color.indexOf("dark") > -1) {
      classNamePrefix = "rs-dark";
      isDark = true;
    }
    const currentAppLocale = AppLocale[this.props.locale];
    const intl = createIntl({
      locale: currentAppLocale.locale,
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;

    const groups = this.state.vehicleGroupsFiltered || [];
    const pageOptions = this.state.pageOptions || [];

    return (
      <React.Fragment>
        <div className="w-100">
          <span className="float-right">
            {this.state.selectedVehicle.length}{" "}
            <IntlMessages id="tracking.selected" />
          </span>

          <ButtonGroup className="flex-wrap">
            <Button
              outline
              size="xs"
              onClick={() => this.handleChange([])}
              key="clear-btn"
            >
              <IntlMessages id="common.clear" />
            </Button>

            <Button
              outline
              size="xs"
              onClick={() => this.handleChange(this.state.options)}
              key="select-all-btn"
            >
              <IntlMessages id="common.select-all" />
            </Button>

            {pageOptions.length > 1 ? (
              <ButtonDropdown
                className="bd-container"
                isOpen={this.state.isOpenSizingXsPage}
                toggle={this.toggleSizingXsPage}
                key="by-page-btn"
              >
                <DropdownToggle caret size="xs" outline color="secondary">
                  {this.state.selectedPageText}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: (data) => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: 221,
                          },
                        };
                      },
                    },
                  }}
                >
                  {pageOptions.map((el, index) => (
                    <DropdownItem
                      key={index}
                      value={el}
                      onClick={() => this.handleChangePage(el)}
                    >
                      {`${el.label}`}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            ) : null}

            <ButtonDropdown
              className="bd-container"
              isOpen={this.state.isOpenSizingXs}
              toggle={this.toggleSizingXs}
              key="select-by-btn"
            >
              <DropdownToggle caret size="xs" outline color="secondary">
                <IntlMessages id="common.select-by" />
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 891,
                    fn: (data) => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: "auto",
                          maxHeight: 221,
                        },
                      };
                    },
                  },
                }}
              >
                {this.state.batchOptions.map((el, index) => (
                  <DropdownItem
                    key={el.value}
                    value={index}
                    onClick={() => this.handleChangeAppend(el.data)}
                  >
                    {el.icon ? (
                      <img
                        className="app-select-icon"
                        src={el.icon}
                        alt="icon"
                      />
                    ) : (
                      ""
                    )}
                    {`${el.label} (${el.data.length})`}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>

            <ButtonDropdown
              className="bd-container"
              isOpen={this.state.isOpenSizingXsGroup}
              toggle={this.toggleSizingXsGroup}
              key="by-group-btn"
            >
              <DropdownToggle caret size="xs" outline color="secondary">
                By Group
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: (data) => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: "auto",
                          maxHeight: 221,
                        },
                      };
                    },
                  },
                }}
              >
                <input
                  className="input2 driver-td-action"
                  type="text"
                  onChange={this.onSearchGroup}
                  value={this.state.searchTexGroup}
                  placeholder={"Search group"}
                />

                {groups.map((el, index) => (
                  <DropdownItem
                    key={el.autoid}
                    value={el}
                    onClick={() => this.handleChangeAppendGroup(el)}
                  >
                    {`${el.group_name} (${el.vehicleData.length})`}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </ButtonGroup>
        </div>

        <div className="w-100">
          <div
            className={
              this.state.selectedVehicle.length < this.state.displyLimit
                ? ""
                : "d-none"
            }
          >
            <ReactSelect
              filterOption={customFilterOption}
              components={{ Input: CustomSelectInput }}
              className="react-select-dark"
              classNamePrefix={classNamePrefix}
              maxMenuHeight={240}
              name={name}
              options={this.state.options}
              placeholder={"Search vehicle, copy & paste list from excel or whatsapp"}
              isMulti
              onChange={this.handleChange}
              value={this.state.selectedVehicle}
              theme={(theme) => ({
                ...theme,
                colors: isDark
                  ? {
                      ...theme.colors,
                      primary25: "#313131",
                      primary: "#313131",
                    }
                  : {
                      ...theme.colors,
                    },
              })}
              onInputChange={this.handleInputChange}
            />
          </div>

          <div
            className={
              this.state.selectedVehicle.length < this.state.displyLimit
                ? "d-none"
                : ""
            }
          >
            <h4 className="d-inline">
              Selected Vehicles {this.state.selectedVehicle.length}{" "}
            </h4>
            <Button
              color="link"
              className="d-inline"
              size="xs"
              onClick={() => this.setState({ displyLimit: 100000 })}
              key="show-selected-btn"
            >
              Show selected
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicles, settings, groups , authUser}) => {
  const { user, details  } = authUser;

  const { all } = vehicles;
  const { locale } = settings;
  const { items } = groups;

  return { vehiclesAll: all, locale, groups: items, userDetails : details, user };
};

export default connect(mapStateToProps)(VehicleSelectWithBatch);
