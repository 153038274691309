
const optionsManufacturesModels = [
    { name: "" },
    { name: "." },
    { name: "Actros" },
    { name: "APV" },
    { name: "10 TON TRU" },
    { name: "19 M3 Dump" },
    { name: "3 TON MILL" },
    { name: "3 TON PICK" },
    { name: "7 TON BOOM" },
    { name: "Actros" },
    { name: "Altima" },
    { name: "Alto-K10" },
    { name: "APV" },
    { name: "BACKHOE LO" },
    { name: "Baleno" },
    { name: "Benz-B" },
    { name: "BOBCAT" },
    { name: "BOBCAT NEW" },
    { name: "BOOM TRUCK" },
    { name: "BUS" },
    { name: "Canter" },
    { name: "Car" },
    { name: "CASE JCB" },
    { name: "Ciaz" },
    { name: "CIAZ 1.4 G" },
    { name: "CIAZ GL 1." },
    { name: "CIAZ GLX 1" },
    { name: "Coaster" },
    { name: "Corolla" },
    { name: "DIESEL TAN" },
    { name: "Duster" },
    { name: "DZIRE" },
    { name: "DZIRE 1.2 " },
    { name: "DZIRE 1.2L" },
    { name: "EXCAVATOR" },
    { name: "GRAND VITA" },
    { name: "Hexa XTA " },
    { name: "Hiace" },
    { name: "Hilux" },
    { name: "HYUNDAI- 3" },
    { name: "HYUNDAI-Pi" },
    { name: "i20" },
    { name: "Innova-2" },
    { name: "Kizashi" },
    { name: "KIZASHI 2." },

  ];

  export default optionsManufacturesModels;
  