

import axios from "axios";
import config from "../api-config/config";

const proxyServer = config.serverApi + "over-speed";

export const OverSpeedGetByVehicles = (userToken, vehicleIds, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleIds, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-vehicles", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const OverSpeedGetByVehicle = (userToken, vehicleId, startTime, endTime, limit, skip) => {
  const criterion = { userToken, vehicleId, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-vehicle", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const OverSpeedGetByDrivers  = (userToken, driverIds, startTime, endTime, limit, skip) => {
  const criterion = { userToken, driverIds, startTime, endTime, limit, skip };
  return axios
    .post(proxyServer + "/get-by-drivers", { criterion })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};
